import React, { useState, useEffect } from "react";
import { getUserData } from "../../helpers/utils";
import axios from "axios";
import api from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { submitform } from "../../helpers/Formhelper";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const SiteSettings = () => {
  const User = getUserData();
  const initialState = {
    email: User?.email || "",
    password: "",
    confirmPassword: "",
    profileimg: User?.profileimg || null,
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState(initialState.email);
  const [password, setPassword] = useState(initialState.password);
  const [confirmPassword, setConfirmPassword] = useState(
    initialState.confirmPassword
  );
  const [profileimg, setProfileimg] = useState(initialState.profileimg);

  const handleUpload = async (selectedFile) => {
    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await axios.post(
        `${domainpath}/api/upload/image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);

      // Assuming the response contains the image path in response.data.imagePath
      if (response.data.imagePath) {
        setProfileimg(response.data.imagePath);
      } else {
        console.error("Image path not found in response");
      }
    } catch (error) {
      console.error("Error uploading image", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    var formData = {
      email: email,
      password: password,
      profileimg: profileimg,
    };
    try {
      const response = await api.UpdateUser(User?._id, formData);
      if (response.status === 200) {
        toast.success("Account Updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom-toast",
        });
        setLoading(false);
      } else {
        console.error("Error updating staff:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating staff:", error);
      setLoading(false);
    } finally {
      // Stop the loader regardless of success or failure
      setLoading(false);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };
  return (
    <>
      <div className="p-8 account-settings-main">
        <div className="  mb-8">
          <h1 className="account-settings">Account Settings</h1>
          <form id="updateaccount">
            <div className="relative lg:w-52 lg:h-40 hrp-profile-header-image">
              <label
                htmlFor="profileimg"
                className="absolute transform -translate-x-1/2 -translate-y-1/2 text-gray-700 font-medium hrp-label-position"
              >
                <input
                  type="file"
                  id="profileimg"
                  accept="image/*"
                  name="profileimg"
                  onChange={(e) => handleUpload(e.target.files[0])}
                  className="hidden"
                />
                <div className="w-full bg-white rounded-full flex items-center justify-center border-2 border-white hrp-profile-section-image ">
                  <img
                    src={
                      profileimg &&
                      profileimg.trim() !== "" &&
                      profileimg !== "NA"
                        ? `${domainpath}/uploads/${profileimg}`
                        : domainpath + "/uploads/deafultman.png"
                    }
                    alt="Profile"
                    className="w-full h-full rounded-full"
                  />
                </div>
              </label>
            </div>
            <div className="animated-form profileheadlinediv">
              <div className="form-group">
                <input
                  id="profileheadline"
                  name="email"
                  value={User?.email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border rounded w-full py-2 px-3 text-gray-700 "
                  type="text"
                  placeholder=""
                />
                <label htmlFor="profileheadline" className="profileheadline">
                  Email
                </label>
              </div>
            </div>
            <div className="animated-form profileheadlinediv">
              <div className="form-group">
                <input
                  id="profileheadline"
                  name="password"
                  className="border rounded w-full py-2 px-3 text-gray-700 "
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder=""
                />
                <label htmlFor="profileheadline" className="profileheadline">
                  Password
                </label>
              </div>
            </div>
            <div className="animated-form profileheadlinediv">
              <div className="form-group">
                <input
                  id="profileheadline"
                  name="confirmpassword"
                  className="border rounded w-full py-2 px-3 text-gray-700 "
                  type="password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  placeholder=""
                />
                <label htmlFor="profileheadline" className="profileheadline">
                  Confirm Password
                </label>
              </div>
            </div>
            {error && <p className="text-red-500 text-xs italic">{error}</p>}
            <div className="flex gap-2">
              <button
                className="ViewDetails-Cancel  text-white px-4 py-2 "
                onClick={handleSubmit}
              >
                Save Change
              </button>
              <button
                className="ViewDetails-btn  text-white px-4 py-2 "
                onClick={handleSubmit}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
        {loading && (
          <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
            <span className="RoadmapSpinner"></span>
          </div>
        )}
        <ToastContainer />
      </div>
    </>
  );
};
export default SiteSettings;
