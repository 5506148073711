import React, { useEffect, useState } from "react";
import image1 from "../../img/employeebg.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import nextSectionImage from "../../img/nextsectionimg.png";
import imgclient from "../../img/Ellipse.png";
// import Vector from "../../img/Vector.png";
import api from "../../Api.js";
import { usePopup } from "../Common/PopupContext";
import { useHistory } from "react-router-dom";
import Vectorcandidate from "../../img/Vector.png";
import Stepfour from "../../image/fluent_communication-16-regular.png";
import Stepfive from "../../image/fluent-mdl2_onboarding.png";
import Login from "../Website/Login";
const Employers = () => {
  const history = useHistory();
  const [message, setMessage] = useState("");
  const testimonials = [
    {
      id: 1,
      name: "John Williams",
      position: "iOS Developer",
      image: imgclient, // Replace with the actual image source
      quote:
        "HirePros has been a crucial partner in our job search. Their platform made it easy to connect with top talent in the tech industry. The streamlined application process and effective candidate matching have made hiring a breeze!",
    },
    {
      id: 2,
      name: "Jane Smith",
      position: "Project Manager",
      image: imgclient, // Replace with the actual image source
      quote:
        "Our experience with HirePros has been outstanding. The job portal's intuitive interface and advanced search features have allowed us to find qualified candidates quickly. It's our go-to platform for all our hiring needs!",
    },
    {
      id: 3,
      name: "David Brown",
      position: "HR Director",
      image: imgclient, // Replace with the actual image source
      quote:
        "HirePros has significantly enhanced our recruitment process. The platform's ease of use and comprehensive tools have streamlined our hiring workflow, helping us fill positions faster with top-quality candidates.",
    },
    {
      id: 4,
      name: "Emily Johnson",
      position: "Tech Lead",
      image: imgclient, // Replace with the actual image source
      quote:
        "With HirePros, we’ve been able to find the right talent efficiently. Their job portal offers powerful filtering options and a user-friendly experience that makes it simple to manage applications and connect with the best candidates.",
    },
    {
      id: 5,
      name: "Michael Lee",
      position: "CEO",
      image: imgclient, // Replace with the actual image source
      quote:
        "HirePros has transformed our hiring process. Their platform's robust features and insightful analytics have provided us with valuable data, leading to more informed hiring decisions and quicker placements.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const submitform = (formId) => {
    const formData = {};
    const form = document.getElementById(formId);

    for (var i = 0; i < form.elements.length; i++) {
      var element = form.elements[i];

      if (element.name && element.type !== "submit") {
        var nameParts = element.name.split("[");

        if (nameParts.length > 1) {
          var currentObj = formData;

          for (var j = 0; j < nameParts.length - 1; j++) {
            var key = nameParts[j].replace("]", "");
            if (!currentObj[key]) {
              currentObj[key] = {};
            }
            currentObj = currentObj[key];
          }
          var key = nameParts[nameParts.length - 1].replace("]", "");

          if (key === "tags") {
            if (!currentObj[key]) {
              currentObj[key] = [];
            }
            currentObj[key] = currentObj[key].concat(
              element.value.split(",").map((tag) => tag.trim())
            );
          } else {
            if (currentObj[key]) {
              if (!Array.isArray(currentObj[key])) {
                currentObj[key] = [currentObj[key]];
              }
              currentObj[key].push(element.value);
            } else {
              currentObj[key] = element.value;
            }
          }
        } else {
          formData[element.name] = element.value;
        }
      }
    }
    return formData;
  };
  const handleSubmit = async (e) => {
    var formData = submitform("contactus");
    e.preventDefault();
    try {
      const response = await api.contactus(formData);
      setMessage("Your message has been sent successfully!");
    } catch (error) {
      setMessage("There was an error sending your message. Please try again.");
    }
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "data:text/javascript;base64,LyogQWxsaSBBSSB3aWRnZXQgZm9yIHd3dy5oaXJlcHJvcy5jby51ayAqLwooZnVuY3Rpb24gKHcsZCxzLG8sZixqcyxmanMpIHt3WydBbGxpSlNXaWRnZXQnXT1vO3dbb10gPSB3W29dIHx8IGZ1bmN0aW9uICgpIHsgKHdbb10ucSA9IHdbb10ucSB8fCBbXSkucHVzaChhcmd1bWVudHMpIH07anMgPSBkLmNyZWF0ZUVsZW1lbnQocyksIGZqcyA9IGQuZ2V0RWxlbWVudHNCeVRhZ05hbWUocylbMF07anMuaWQgPSBvOyBqcy5zcmMgPSBmOyBqcy5hc3luYyA9IDE7IGZqcy5wYXJlbnROb2RlLmluc2VydEJlZm9yZShqcywgZmpzKTt9KHdpbmRvdywgZG9jdW1lbnQsICdzY3JpcHQnLCAnYWxsaScsICdodHRwczovL3N0YXRpYy5hbGxpYWkuY29tL3dpZGdldC92MS5qcycpKTthbGxpKCdpbml0JywgJ3NpdGVfV3RkekJOTUs2aUVORGN0eScpO2FsbGkoJ29wdGltaXplJywgJ2FsbCcpOw==";
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  /* ======= For Popup ====== */
  const { openPopup } = usePopup();
  const [isPopupOpen, setIsPopupOpen] = useState([]);
  const [popupComponent, setPopupComponent] = useState(null);

  const handleClosePopup = (message) => {
    console.log(message);
    setPopupComponent(null);
    openPopup();
  };

  // const hendlAddEducation = () => {
  //   const popup = (
  //     <AddEducation onClose={() => handleClosePopup("Popup closed")} />
  //   );
  //   setPopupComponent(popup);
  //   openPopup(popup);
  // };
  /* ======= For Popup end ====== */
  const stats = [
    {
      id: 1,
      icon: "📂", // Replace with the actual icon
      value: "12,376",
      label: "Live Jobs",
    },
    {
      id: 2,
      icon: "👥", // Replace with the actual icon
      value: "89,562",
      label: "Jobs Candidate",
    },
    {
      id: 3,
      icon: "📄", // Replace with the actual icon
      value: "28,166",
      label: "Active Resume",
    },
    {
      id: 4,
      icon: "🏢", // Replace with the actual icon
      value: "8,966",
      label: "Companies",
    },
  ];

  const handleClickEmployee = () => {
    const popup = (
      <Login
        onClose={() => handleClosePopup("Popup closed")}
        employee="employee"
      />
    );
    setPopupComponent(popup);
    openPopup(popup);
  };
  return (
    <>
      <div className="hrp-main-section  employers-page ">
        <section className="hrp-container hrp-section-1">
          <div className="hrp-sub-section  ">
            <div className="container m-auto">
              <div className="flex flex-col md:flex-row items-center py-10  lg:px-14 md:px-10  xl:mx-10 2xl:mx-10 ">
                <div className="md:w-1/2 mb-8 md:mb-0 hrp-Employees-outer">
                  <h1
                    className="lg:text-4xl hrp-Employees-heading font-semibold mb-4 text-[#343434]"
                    style={{ color: "#3E3E3E" }}
                  >
                    Find and Hire the Best Employees
                  </h1>
                  <p className="xl:text-3xl text-gray-600 mb-4">
                    Join thousands of companies who trust us to build
                  </p>
                  <button
                    className="py-2 px-4 btnpostjob"
                    onClick={() => history.push("/post-job")}
                  >
                    Post a Job
                  </button>
                </div>
                <div className="flex md:w-1/2 justify-center">
                  <img
                    src={image1}
                    alt="Employees illustration"
                    className="w-full lg:w-full lg:w-1/4 mb-8 md:mb-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hrp-container  hrp-section-2">
          <div className="hrp-sub-section hrp-Employees-outer md:px-10">
            <div className="lg:flex flex-row-reverse md:flex-row items-center lg:py-10 container m-auto">
              <div className="flex lg:w-1/2 md:w-full mb-8 md:mb-0">
                <img
                  src={nextSectionImage}
                  alt="Creative Agency"
                  className="rounded-lg "
                />
              </div>
              <div className="lg:w-1/2 md:w-full px-2 lg:px-10 md:px-3">
                <h1
                  className="lg:text-3xl font-bold mb-4"
                  style={{ color: "#3E3E3E" }}
                >
                  Find Top Talent Fast With HirePros
                </h1>
                <p
                  className="text-[#797979]-700 mb-4"
                  style={{ fontSize: "14px", color: "#797979" }}
                >
                  Discover The Perfect Candidates For Your Company's Needs With
                  HireProse. Our Platform Offers A Comprehensive Search Of Open
                  Positions On The Web, Personalized Salary Estimates, And
                  Reviews On Over 600,000 Companies Worldwide. Whether You're
                  Looking For Specific Skills Or A Cultural Fit, HireProse
                  Streamlines The Hiring Process, Making It Easier Than Ever To
                  Connect With The Right Talent.
                </p>
                <ul
                  className="list-disc lg:ml-5 list-inside mb-4 text-gray-700 w-full"
                  // style={{ width: "100%" }}
                >
                  <li className="mb-2 lg:flex items-start">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 inline-block"
                      style={{ stroke: "#003366" }} // Set the color of the SVG
                    >
                      <path
                        d="M20 6L9 17L4 12"
                        stroke="#003366"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span
                      className="font-semibold"
                      style={{ color: "#003366", fontSize: "13px" }}
                    >
                      Efficient Matching:
                    </span>
                    <p style={{ color: "#797979", fontSize: "12px" }}>
                      Leverage Advanced Algorithms To Find Candidates Who Meet
                      Your Exact Criteria.
                    </p>
                  </li>
                  <li className="mb-2 lg:flex items-start">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 inline-block"
                      style={{ stroke: "#003366" }} // Set the color of the SVG
                    >
                      <path
                        d="M20 6L9 17L4 12"
                        stroke="#003366"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span
                      className="font-semibold"
                      style={{ color: "#003366", fontSize: "13px" }}
                    >
                      Comprehensive Insights:
                    </span>
                    <p style={{ color: "#797979", fontSize: "12px" }}>
                      Access In-Depth Reviews And Salary Estimates To Make
                      Informed Hiring Decisions.
                    </p>
                  </li>
                  <li className="mb-2 lg:flex items-start">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 inline-block"
                      style={{ stroke: "#003366" }} // Set the color of the SVG
                    >
                      <path
                        d="M20 6L9 17L4 12"
                        stroke="#003366"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span
                      className="font-semibold"
                      style={{ color: "#003366", fontSize: "13px" }}
                    >
                      User-Friendly Interface:
                    </span>
                    <p style={{ color: "#797979", fontSize: "12px" }}>
                      Enjoy A Seamless Experience From Job Posting To Candidate
                      Selection.
                    </p>
                  </li>
                </ul>

                <button
                  className="btnpostjob py-2 px-4 rounded-lg"
                  onClick={() => handleClickEmployee()}
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="hrp-container  hrp-section-3">
          <div className=" hrp-sub-section ">
            <div className="container m-auto ">
              <div className="relative vectorcandidate-main employers-page-processes">
                <div className="Vectorcandidate-image">
                  <img className="m-auto" src={Vectorcandidate}></img>
                </div>
                <div className="lg:flex">
                  <div className="ApplyforJobs-outer firststep">
                    <div>
                      <div className="ApplyforJobs-section">
                        <div>
                          <div className="ApplyforJobs">
                            <div>
                              <svg
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.052 31.3542H14.0074C8.77925 31.3542 6.16591 31.3542 4.54133 29.699C2.91675 28.0438 2.91675 25.3794 2.91675 20.0521C2.91675 14.7248 2.91675 12.0604 4.54133 10.4052C6.16591 8.75 8.77925 8.75 14.0074 8.75H19.5534C24.7815 8.75 27.3963 8.75 29.0209 10.4052C30.2707 11.6783 30.558 13.5494 30.6251 16.7708V18.9583"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M27.7085 26.9791H23.3335M23.3335 31.3541C22.1732 31.3541 21.0604 30.8931 20.2399 30.0727C19.4194 29.2522 18.9585 28.1394 18.9585 26.9791C18.9585 25.8188 19.4194 24.706 20.2399 23.8855C21.0604 23.065 22.1732 22.6041 23.3335 22.6041M27.7085 31.3541C28.8688 31.3541 29.9816 30.8931 30.8021 30.0727C31.6226 29.2522 32.0835 28.1394 32.0835 26.9791C32.0835 25.8188 31.6226 24.706 30.8021 23.8855C29.9816 23.065 28.8688 22.6041 27.7085 22.6041M23.3335 8.74992L23.1877 8.29784C22.4658 6.052 22.1056 4.92909 21.2466 4.28742C20.3862 3.64575 19.2458 3.64575 16.962 3.64575H16.5785C14.2962 3.64575 13.1543 3.64575 12.2954 4.28742C11.435 4.92909 11.0747 6.052 10.3529 8.29784L10.2085 8.74992"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div className="flex justify-center">
                            <div className="JobDiscussions">
                              <div className="JobDiscussions-inner"></div>
                            </div>
                          </div>
                          <div className="ApplyforJobs-text">Post Job</div>
                        </div>
                      </div>
                      <div className="ApplyforJobs-contents">
                        <p className="text-center">
                          Create and publish your job listing to attract
                          qualified candidates.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="ApplyforJobs-outer secondstep">
                    <div>
                      <div className="ApplyforJobs-section">
                        <div>
                          <div className="ApplyforJobs">
                            <div>
                              <svg
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M27.7083 24.7917V27.7084H10.2083V24.7917C10.2083 24.7917 10.2083 18.9584 18.9583 18.9584C27.7083 18.9584 27.7083 24.7917 27.7083 24.7917ZM23.3333 11.6667C23.3333 10.8015 23.0767 9.9556 22.596 9.23613C22.1153 8.51666 21.432 7.95591 20.6326 7.62478C19.8331 7.29364 18.9535 7.207 18.1048 7.37581C17.2561 7.54462 16.4766 7.9613 15.8647 8.57316C15.2529 9.18501 14.8362 9.96456 14.6674 10.8132C14.4986 11.6619 14.5852 12.5416 14.9164 13.341C15.2475 14.1404 15.8082 14.8237 16.5277 15.3044C17.2472 15.7852 18.093 16.0417 18.9583 16.0417C20.1187 16.0417 21.2315 15.5808 22.0519 14.7603C22.8724 13.9399 23.3333 12.8271 23.3333 11.6667ZM28 19.0459C28.7972 19.7814 29.4399 20.6682 29.8906 21.6547C30.3413 22.6413 30.5909 23.7077 30.625 24.7917V27.7084H35V24.7917C35 24.7917 35 19.7605 28 19.0459ZM26.25 7.29175C25.8094 7.29178 25.3714 7.36066 24.9521 7.49592C25.8053 8.71941 26.2627 10.1751 26.2627 11.6667C26.2627 13.1584 25.8053 14.6141 24.9521 15.8376C25.3714 15.9728 25.8094 16.0417 26.25 16.0417C27.4103 16.0417 28.5231 15.5808 29.3436 14.7603C30.1641 13.9399 30.625 12.8271 30.625 11.6667C30.625 10.5064 30.1641 9.39363 29.3436 8.57316C28.5231 7.75269 27.4103 7.29175 26.25 7.29175ZM11.6667 14.5834H7.29167V10.2084H4.375V14.5834H0V17.5001H4.375V21.8751H7.29167V17.5001H11.6667V14.5834Z"
                                  fill="white"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div className="flex justify-center">
                            <div className="JobDiscussions">
                              <div className="JobDiscussions-inner"></div>
                            </div>
                          </div>
                          <div className="ApplyforJobs-text">
                            Invite Candidates
                          </div>
                        </div>
                      </div>
                      <div className="ApplyforJobs-contents">
                        <p className="text-center">
                          Invite suitable candidates to apply for your job
                          openings.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="ApplyforJobs-outer thirdstep">
                    <div>
                      <div className="ApplyforJobs-section">
                        <div>
                          <div className="ApplyforJobs">
                            <div>
                              <svg
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.77292 4.77163C8.7455 4.42481 9.8004 4.38112 10.7983 4.64634C11.7963 4.91156 12.6903 5.47321 13.3623 6.25713C14.0344 7.04105 14.4529 8.01037 14.5626 9.0371C14.6723 10.0638 14.468 11.0997 13.9767 12.0079L29.594 27.6266L27.5319 29.6887L11.9131 14.07C11.0047 14.5598 9.96916 14.7629 8.94298 14.6526C7.91681 14.5422 6.94816 14.1235 6.16468 13.4516C5.3812 12.7798 4.81967 11.8863 4.55408 10.889C4.28848 9.89167 4.3313 8.83727 4.67687 7.86475L7.93917 11.127C8.35173 11.5255 8.9043 11.746 9.47786 11.741C10.0514 11.736 10.6001 11.506 11.0056 11.1004C11.4112 10.6948 11.6413 10.1462 11.6463 9.57261C11.6512 8.99905 11.4308 8.44649 11.0323 8.03392L7.77292 4.77163ZM22.8915 7.51767L27.5319 4.93933L29.594 7.00288L27.0156 11.6418L24.4373 12.1581L21.3456 15.2512L19.2821 13.1891L22.3752 10.096L22.8915 7.51767ZM13.0944 19.3768L15.1579 21.4389L7.42292 29.1725C7.15998 29.4361 6.80613 29.5893 6.43392 29.6004C6.06171 29.6115 5.69933 29.4799 5.42108 29.2324C5.14284 28.9849 4.9698 28.6404 4.93745 28.2694C4.90509 27.8985 5.01588 27.5292 5.24708 27.2373L5.36083 27.1104L13.0944 19.3768Z"
                                  fill="white"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div className="flex justify-center">
                            <div className="JobDiscussions">
                              <div className="JobDiscussions-inner"></div>
                            </div>
                          </div>
                          <div className="ApplyforJobs-text">
                            Screening Tools
                          </div>
                        </div>
                      </div>
                      <div className="ApplyforJobs-contents">
                        <p className="text-center">
                          Utilize advanced screening tools to filter and
                          shortlist the best candidates.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="ApplyforJobs-outer fourthstep">
                    <div>
                      <div className="ApplyforJobs-section">
                        <div>
                          <div className="ApplyforJobs">
                            <div>
                              <img src={Stepfour} alt="Profile Image Overlay" />
                            </div>
                          </div>
                          <div className="flex justify-center">
                            <div className="JobDiscussions">
                              <div className="JobDiscussions-inner"></div>
                            </div>
                          </div>
                          <div className="ApplyforJobs-text">
                            Easy Communication
                          </div>
                        </div>
                      </div>
                      <div className="ApplyforJobs-contents">
                        <p className="text-center">
                          Communicate effortlessly with applicants through our
                          integrated messaging system.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="ApplyforJobs-outer fifthstep">
                    <div>
                      <div className="ApplyforJobs-section">
                        <div>
                          <div className="ApplyforJobs">
                            <div>
                              <img src={Stepfive} alt="Profile Image Overlay" />
                            </div>
                          </div>
                          <div className="flex justify-center">
                            <div className="JobDiscussions">
                              <div className="JobDiscussions-inner"></div>
                            </div>
                          </div>
                          <div className="ApplyforJobs-text">Hire</div>
                        </div>
                      </div>
                      <div className="ApplyforJobs-contents">
                        <p className="text-center">
                          Make offers and onboard new hires seamlessly.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hrp-container hrp-section-4">
          <div className=" hrp-sub-section bg-gray-100 hrp-Employees-outer ">
            <div className="  py-16">
              <div className="max-w-7xl mx-auto text-center hrp-Employees-outer">
                <h1
                  className="lg:text-3xl font-bold mb-4"
                  style={{ color: "#3E3E3E" }}
                >
                  Testimonials From Our Partners
                </h1>
                <p className="text-gray-600 mb-12 hrp-testimonials  ">
                  "At HirePros, our mission is to simplify and enhance the
                  recruitment process. Here's what our partners have to say
                  about their experience with our platform:"
                </p>

                <div className="container m-auto">
                  <Carousel {...settings}>
                    {testimonials.map((testimonial) => (
                      <div className=" hrp-testimonials-main">
                        <div
                          key={testimonial.id}
                          className="relative bg-white shadow-md justify-center rounded-lg md:p-8 lg:p-8 p-4 md:items-center mx-4"
                        >
                          <div className="testimonials-section-first">
                            <div className="relative  rounded-full border-4  border-white z-10 mx-auto md:mx-0 w-28 lg:-mt-16  ">
                              <img
                                src={testimonial.image}
                                alt={testimonial.name}
                                className="w-full h-full object-cover rounded-full"
                              />
                            </div>
                          </div>
                          <div className=" md:mt-0  testimonials-section-second">
                            <div className=" hrp-testimonials-inner-section-1">
                              <svg
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18 22.5H9.165C9.44378 20.6435 10.1067 18.8656 11.1114 17.2797C12.1161 15.6939 13.4405 14.3351 15 13.29L17.685 11.49L16.035 9L13.35 10.8C11.0899 12.3061 9.23656 14.3468 7.9544 16.7411C6.67223 19.1353 6.00091 21.8091 6 24.525V34.5C6 35.2956 6.31607 36.0587 6.87868 36.6213C7.44129 37.1839 8.20435 37.5 9 37.5H18C18.7956 37.5 19.5587 37.1839 20.1213 36.6213C20.6839 36.0587 21 35.2956 21 34.5V25.5C21 24.7044 20.6839 23.9413 20.1213 23.3787C19.5587 22.8161 18.7956 22.5 18 22.5ZM39 22.5H30.165C30.4438 20.6435 31.1067 18.8656 32.1114 17.2797C33.1161 15.6939 34.4405 14.3351 36 13.29L38.685 11.49L37.05 9L34.35 10.8C32.0899 12.3061 30.2366 14.3468 28.9544 16.7411C27.6722 19.1353 27.0009 21.8091 27 24.525V34.5C27 35.2956 27.3161 36.0587 27.8787 36.6213C28.4413 37.1839 29.2044 37.5 30 37.5H39C39.7957 37.5 40.5587 37.1839 41.1213 36.6213C41.6839 36.0587 42 35.2956 42 34.5V25.5C42 24.7044 41.6839 23.9413 41.1213 23.3787C40.5587 22.8161 39.7957 22.5 39 22.5Z"
                                  fill="#FFA500"
                                />
                              </svg>
                            </div>
                            <div className="hrp-testimonials-inner-middle">
                              <div className="p-6">
                                <h4 className=" ">{testimonial.name}</h4>
                                <p className="">{testimonial.position}</p>
                              </div>
                              <blockquote className="text-gray-600 mb-4 lg:p-6 md:p-0">
                                {testimonial.quote}
                              </blockquote>
                            </div>
                            <div className="hrp-testimonials-inner-section-2">
                              <svg
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M30 25.5H38.835C38.5562 27.3565 37.8933 29.1344 36.8886 30.7203C35.8839 32.3061 34.5595 33.6649 33 34.71L30.315 36.51L31.965 39L34.65 37.2C36.9101 35.6939 38.7634 33.6532 40.0456 31.2589C41.3278 28.8647 41.9991 26.1909 42 23.475V13.5C42 12.7044 41.6839 11.9413 41.1213 11.3787C40.5587 10.8161 39.7956 10.5 39 10.5H30C29.2044 10.5 28.4413 10.8161 27.8787 11.3787C27.3161 11.9413 27 12.7044 27 13.5V22.5C27 23.2956 27.3161 24.0587 27.8787 24.6213C28.4413 25.1839 29.2044 25.5 30 25.5ZM9 25.5H17.835C17.5562 27.3565 16.8933 29.1344 15.8886 30.7203C14.8839 32.3061 13.5595 33.6649 12 34.71L9.315 36.51L10.95 39L13.65 37.2C15.9101 35.6939 17.7634 33.6532 19.0456 31.2589C20.3278 28.8647 20.9991 26.1909 21 23.475V13.5C21 12.7044 20.6839 11.9413 20.1213 11.3787C19.5587 10.8161 18.7956 10.5 18 10.5H9C8.20435 10.5 7.44129 10.8161 6.87868 11.3787C6.31607 11.9413 6 12.7044 6 13.5V22.5C6 23.2956 6.31607 24.0587 6.87868 24.6213C7.44129 25.1839 8.20435 25.5 9 25.5Z"
                                  fill="#FFA500"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          {/* Sucess And Award  Section */}
          <div className="bg-gray-100 py-16 suceessection">
            <div className="max-w-7xl mx-auto text-center">
              <h2
                className="text-3xl font-bold mb-4"
                style={{
                  color: "#3E3E3E",
                }}
              >
                Our Success & Award
              </h2>
              <p
                className="text-gray-600 mb-12"
                style={{
                  width: "70%",
                  margin: "0 auto",
                  padding: "0 0 20px 0",
                }}
              >
                At HirePros, we’re proud of our industry accolades and
                achievements. Our commitment to innovation and excellence has
                earned us recognition, showcasing our dedication to providing
                exceptional recruitment solutions and exceptional client value.
              </p>
              <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-8 md:m-10 mx-3">
                <div className="bg-white p-6 rounded-lg shadow cardsucees">
                  <div className="text-orange-500 mb-2 p-7">
                    <i
                      className="fas fa-briefcase text-4xl md:px-9 md:py-9 p-6 rounded-full "
                      style={{
                        color: " #FFA500",
                        backgroundColor: "#F1F4F7",
                        borderRadius: "50px",
                      }}
                    ></i>
                  </div>
                  <h3
                    className="text-3xl font-medium mb-2"
                    style={{
                      color: " #FFA500",
                    }}
                  >
                    12,376
                  </h3>
                  <p
                    className="text-gray-600"
                    style={{
                      color: "#797979",
                    }}
                  >
                    Live Jobs
                  </p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow cardsucees">
                  <div className="text-orange-500 mb-2 p-7">
                    <i
                      className="fas fa-users text-3xl  md:px-8 md:py-9 p-6 rounded-full"
                      style={{
                        color: " #FFA500",

                        backgroundColor: "#F1F4F7",
                        borderRadius: "50px",
                      }}
                    ></i>
                  </div>
                  <h3
                    className="text-3xl font-medium mb-2"
                    style={{
                      color: " #FFA500",
                    }}
                  >
                    89,562
                  </h3>
                  <p
                    className="text-gray-600"
                    style={{
                      color: "#797979",
                    }}
                  >
                    Jobs Candidate
                  </p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow cardsucees">
                  <div className="text-orange-500 mb-2 p-7">
                    <i
                      className="fas fa-file-alt text-4xl  md:px-9 md:py-8 px-6 py-5 rounded-full"
                      style={{
                        color: " #FFA500",

                        backgroundColor: "#F1F4F7",
                        borderRadius: "50px",
                      }}
                    ></i>
                  </div>
                  <h3
                    className="text-3xl font-medium mb-2"
                    style={{
                      color: " #FFA500",
                    }}
                  >
                    28,166
                  </h3>
                  <p
                    className="text-gray-600"
                    style={{
                      color: "#797979",
                    }}
                  >
                    Active Resume
                  </p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow cardsucees">
                  <div className="text-orange-500 mb-2 p-7">
                    <i
                      className="fas fa-building text-4xl  md:px-9 md:py-8 px-6 py-5 rounded-full"
                      style={{
                        color: " #FFA500",

                        backgroundColor: "#F1F4F7",
                        borderRadius: "50px",
                      }}
                    ></i>
                  </div>
                  <h3
                    className="text-3xl font-medium mb-2"
                    style={{
                      color: " #FFA500",
                    }}
                  >
                    8,966
                  </h3>
                  <p
                    className="text-gray-600"
                    style={{
                      color: "#797979",
                    }}
                  >
                    Companies
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Sucess And Award  Section */}
        </section>
        <sction className="hrp-container hrp-section-5">
          <div className=" hrp-sub-section ">
            <div className="  py-16 contactus">
              <div className="max-w-4xl mx-auto text-center">
                <h1
                  className="lg:text-3xl font-bold mb-4"
                  style={{ color: "#3E3E3E" }}
                >
                  Contact Us
                </h1>
                <p className="text-gray-600 mb-12">
                  We’re here to help! Whether you have questions, feedback, or
                  need assistance, please reach out to us. Our team is dedicated
                  to providing prompt and effective support to ensure your
                  experience is seamless and satisfying.
                </p>
                <form
                  onSubmit={handleSubmit}
                  id="contactus"
                  className="space-y-6 animated-form"
                  style={{
                    width: "75%",
                    margin: "0 auto",
                  }}
                >
                  <div className="form-group">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      placeholder=""
                      className="w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#FFA500]"
                    />
                    <label htmlFor="name" className="form-input-label">
                      Name
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      id="company"
                      type="text"
                      name="company"
                      placeholder=""
                      className="w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#FFA500]"
                    />
                    <label htmlFor="company" className="form-input-label">
                      Company
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder=""
                      className="w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#FFA500]"
                    />
                    <label htmlFor="email" className="form-input-label">
                      Email
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder=""
                      className="w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#FFA500]"
                    />
                    <label htmlFor="phone" className="form-input-label">
                      Phone Number
                    </label>
                  </div>
                  <div className="form-group relative">
                    <textarea
                      id="query"
                      name="query"
                      placeholder=""
                      className="w-full px-4 py-2 focus:outline-none focus:border-[#FFA500] h-32 peer"
                    />
                    <label
                      htmlFor="query"
                      className="form-input-label absolute top-2 left-4 transition-all duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:left-4 peer-focus:-top-2 peer-focus:left-4 peer-focus:text-[#FFA500] text-gray-600"
                    >
                      Query
                    </label>
                  </div>

                  <div style={{ textAlign: "left" }}>
                    <button
                      type="submit"
                      className="joblist-btnclear w-50 text-white"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </sction>
      </div>
    </>
  );
};
export default Employers;
