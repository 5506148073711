// AdminLayout.js
import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Footer from "./Footer";
import "../../components/Layout/menubaar.css";
import { Header, EmployerHeader } from "./Header";
import Dashboard from "../SuperAdmin/Dasboard";
import { SuperAdmin } from "./dashboardmenus";
import { PopupProvider } from "../Common/PopupContext";
import Profile from "../Candidates/Profile";
import Messages from "../Candidates/Messages";
import AccountSettings from "../Candidates/AccountSettings";
import Manageuser from "../SuperAdmin/Manageuser";
import ManageApplication from "../SuperAdmin/ManageApplication.js";
import Managejobs from "../SuperAdmin/Managejobs.js";
import Blogs from "../SuperAdmin/Blogs.js";
import AddBlog from "../SuperAdmin/AddBlog.js";
import EditBlog from "../SuperAdmin/EditBlog.js";
import Jobpost from "../Empolyers/postJob.js";
import jobApplication from "../SuperAdmin/JobApplication.js";
import ViewProfile from "../Empolyers/UserProfile.js";
import Sitemap from "../SuperAdmin/Sitemap.js";
import Robotpage from "../SuperAdmin/Robotpage.js";
import Tickets from "../SuperAdmin/Ticket.js";
import AddTickets from "../SuperAdmin/Addticket.js";
import EditTicket from "../SuperAdmin/EditTicket.js";
import SingleTicketPage from "../Empolyers/SingleTickets.js";
function SuperAdminLayout({ userRole }) {
  const getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null; // Return null if the cookie is not found
  };
  const useLocalStorage = (key) => {
    // Retrieve from localStorage
    const [storedValue, setStoredValue] = useState(() => {
      const item = localStorage.getItem(key);
      if (item) return item;

      // If not found in localStorage, try to retrieve it from cookies
      const cookieValue = getCookie(key);
      if (cookieValue) {
        // Set to localStorage if found in cookies
        localStorage.setItem(key, cookieValue);
        return cookieValue;
      }

      return null;
    });

    return storedValue;
  };
  const user = useLocalStorage("accessToken");
  console.log(user);

  useEffect(() => {}, [user]);
  let { path } = useRouteMatch(); // Get the base path for nested routes

  return (
    <PopupProvider>
      <div className="">
        <EmployerHeader />
        <div className=" container m-auto hrp-min-h-screen ">
          {user ? <SuperAdmin /> : ""}
          <section className="mt-5">
            <div className="bg-white container JobCategories-heading-admin mb-20 m-auto">
              <Switch>
                <Route exact path={path} component={Dashboard} />
                <Route path={`${path}/manage-user`} component={Manageuser} />
                <Route path={`${path}/manage-jobs`} component={Managejobs} />
                <Route path={`${path}/blogs`} component={Blogs} />
                <Route path={`${path}/add-blog`} component={AddBlog} />
                <Route path={`${path}/edit-blog/:id`} component={EditBlog} />
                <Route path={`${path}/messages`} component={Messages} />
                <Route path={`${path}/jobpost/:id`} component={Jobpost} />
                <Route path={`${path}/job/:jobid`} component={jobApplication} />
                <Route path={`${path}/robot`} component={Robotpage} />
                <Route path={`${path}/sitemap`} component={Sitemap} />
                <Route path={`${path}/tickets`} component={Tickets} />
                <Route path={`${path}/add-ticket`} component={AddTickets} />
                <Route
                  path={`${path}/ticket/:id`}
                  component={SingleTicketPage}
                />
                <Route
                  path={`${path}/edit-ticket/:id`}
                  component={EditTicket}
                />
                <Route
                  path={`${path}/application/:applicationId`}
                  component={ViewProfile}
                />
                <Route
                  path={`${path}/job-applications`}
                  component={ManageApplication}
                />
                <Route
                  path={`${path}/account-settings`}
                  component={AccountSettings}
                />
                {/* Add other routes as needed */}
              </Switch>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </PopupProvider>
  );
}

export default SuperAdminLayout;
