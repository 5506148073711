import React, { useState } from "react";
import api from "../../Api";
import { useLocation } from "react-router-dom"; // Use react-router-dom to access params and redirect
import { usePopup } from "../Common/PopupContext";
import Login from "../Website/Login";
const ResetPassword = () => {
  const location = useLocation();

  // Use URLSearchParams to extract the token from the URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { openPopup } = usePopup();
  const [isPopupOpen, setIsPopupOpen] = useState([]);
  const [popupComponent, setPopupComponent] = useState(null);

  const handleClosePopup = () => {
    console.log();
    setPopupComponent(null);
    openPopup();
  };
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== newPassword) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    var data = {
      newPassword,
    };
    try {
      const response = await api.resetPassword(token, data);
      if (response.status == 200) {
        setLoading(false);
        setSuccess("Password reset successfully. You can now log in.");
        setTimeout(() => {
          const popup = (
            <Login onClose={() => handleClosePopup("Popup closed")} />
          );
          setPopupComponent(popup);
          openPopup(popup);
        }, 2000);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError("Failed to reset password. Please try again.");
    }
  };

  return (
    <div>
      <div className="login_inform login_inform-page ">
        <div className="flex justify-center items-center login_inform-inner">
          <div className="forgotpass-form  form_upto m-8 loginDiv-page">
            <div className=" rounded px-6 pt-4 pb-6 mb-4">
              <h2 className="text-center">Reset Your Password</h2>
              <form onSubmit={handleSubmit} className="animated-form">
                <div className="mb-4 form-group">
                  <input
                    type="password"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <label htmlFor="newPassword" className="password-label">
                    New Password
                  </label>
                </div>
                <div className="mb-4 form-group">
                  <input
                    type="password"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  <label htmlFor="confirmPassword" className="password-label">
                    Confirm Password
                  </label>
                </div>
                {error && <p className="text-red-500">{error}</p>}
                {success && <p className="text-green-500">{success}</p>}
                <div className="text-center">
                  <button
                    type="submit"
                    className="login-form-btn bg-[#003366] text-white py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                    disabled={loading}
                  >
                    {loading ? "Resetting..." : "Reset Password"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
