import React, { useState, useEffect } from "react";
import LoginUser from "../popup/LoginUserpopup";
import { usePopup } from "../Common/PopupContext";
import { getUserData } from "../../helpers/utils";
const Footer = () => {
  const { openPopup } = usePopup();
  const User = getUserData();
  const [popupComponent, setPopupComponent] = useState(null);
  const handleClosePopup = (message) => {
    console.log(message);
    setPopupComponent(null);
    openPopup();
  };
  const handlePost = (e) => {
    if (User?.role == "applicant") {
      const popup = (
        <LoginUser onClose={() => handleClosePopup("Popup closed")} />
      );
      setPopupComponent(popup);
      openPopup(popup);
    } else {
      window.location.href = "/post-job";
    }
  };
  return (
    <footer className="footer-color text-white p-5 text-center ">
      <div className="md:flex justify-between footer-section-outer container m-auto">
        <div className=" footer-section">
          <ul className="md:flex">
            <li className="Browse-left">
              <a href="/terms-conditions" target="_blank">
                Terms & Conditions
              </a>
            </li>
            <li className="Browse-left">
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/anti-slavery" target="_blank">
                Anti Slavery
              </a>
            </li>
          </ul>
        </div>
        <div className=" footer-section">
          <ul className="md:flex ">
            <li className="Browse-left">
              {" "}
              <a href="/jobs" target="_blank">
                Browse job
              </a>
            </li>
            <li className="Browse-left">
              <a href="/courses" target="_blank">
                Browse Courses
              </a>
            </li>
            <li className="Browse-left">
              {" "}
              <a target="_blank" onClick={(e) => handlePost()}>
                Post a job
              </a>
            </li>
            <li className="Browse-left">
              <a href="/blogs" target="_blank">
                Blogs
              </a>
            </li>
            <li className=" ">
              <a href="/seller-signup" target="_blank">
                Become A Seller
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
