import React, { useState } from "react";
import { submitform } from "../../helpers/Formhelper";
import api from "../../Api";
import axios from "axios";
import { getUserData } from "../../helpers/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const RegisterForm = ({ logo, socialLogin, onClose }) => {
  const [loading, setLoading] = useState(false);
  const User = getUserData();
  const [positions, setpositions] = useState([
    "Hiring Manager",
    "Recruiter",
    "HR Manager/Coordinator",
    "Talent Acquisition Specialist",
    "HR Business Partner",
    "Employee Relations Specialist",
    "Compensation and Benefits Manager",
    "Training and Development Coordinator",
    "Onboarding Specialist",
    "Payroll Specialist",
  ]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    var formdata = submitform("staffcreate");
    formdata.employerId = User?._id;
    console.log(formdata);

    try {
      const response = await api.addStaff(formdata);
      if (response.status !== 500) {
        toast.success("Course Created Successffully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        onClose("");
        window.location.reload();
        setLoading(false);
      } else {
        console.error("Error posting job:", response.message);
        setLoading(false);
        toast.error(response.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error submitting course:", error);
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      // Stop the loader regardless of success or failure
      setLoading(false);
    }
  };
  const [password, setPassword] = useState("");
  const [phone, setphone] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };
  function formatPhoneNumber(value) {
    // Remove all non-numeric characters from the input
    const cleaned = ("" + value).replace(/\D/g, "");

    // Limit the input to 11 digits
    const limited = cleaned.substring(0, 11);

    // Check if the input has the correct length for formatting
    if (limited.length >= 10) {
      // Format the number in the 123-456-7890 format
      return `${limited.slice(0, 3)}-${limited.slice(3, 6)}-${limited.slice(
        6,
        10
      )}`;
    } else if (limited.length > 6) {
      // Format the number partially as 123-456-
      return `${limited.slice(0, 3)}-${limited.slice(3, 6)}-${limited.slice(
        6
      )}`;
    } else if (limited.length > 3) {
      // Format the number partially as 123-
      return `${limited.slice(0, 3)}-${limited.slice(3)}`;
    } else {
      // Return the cleaned input if less than 3 digits
      return limited;
    }
  }

  const handleChange = (e) => {
    const phonevalue = e.target.value;
    // Format the mobile number
    const formattedNumber = formatPhoneNumber(phonevalue);
    setphone(formattedNumber);
    // Set the formatted number in formData
  };
  return (
    <div className="flex justify-center h-screen login_inform-inner m-auto p-20 job-page">
      <div className="flex">
        <div
          className="form_upto hrp-employer-add-user m-3 loginDiv"
          id="step_1"
        >
          <div className="bg-white shadow-md rounded p-8 mb-4">
            <div className="text-center mb-4">
              <h1 className="hrp-heading-size text-center hrp-About">
                Cerate Staff Account
              </h1>
            </div>
            <form
              onSubmit={handleSubmit}
              id="staffcreate"
              className="register-form-section animated-form"
            >
              <div className="mb-2 grid grid-cols-2 gap-x-5">
                <div className="form-group">
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="firstName" className="form-input-label">
                    First Name
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="lastName" className="form-input-label">
                    Last Name
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="email" className="form-input-label">
                    Email
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    onChange={(e) => handleChange(e)}
                    value={phone}
                    inputMode="tel"
                    pattern="\d{3}-\d{3}-\d{4}"
                    title="Phone number should be in the format: 123-456-7890"
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="phone" className="form-input-label">
                    Phone
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="password" className="form-input-label">
                    Password
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    id="confirm-password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label
                    htmlFor="confirm-password"
                    className="form-input-label"
                  >
                    Confirm Password
                  </label>
                </div>
              </div>
              {error && <p className="text-red-500 text-xs italic">{error}</p>}
              <div className="form-group">
                <select
                  id="role"
                  name="position"
                  className="shadow w-full border rounded py-2 px-3"
                >
                  <option value="" disabled>
                    Select a role
                  </option>
                  {positions.map((role, index) => (
                    <option key={index} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
                <label htmlFor="role" className="form-input-label">
                  Role
                </label>
              </div>

              <div className="flex ">
                <button
                  type="submit"
                  className="hrp-find-course-btn py-2 px-10 rounded-full w-full"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <button
          className="text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default RegisterForm;
