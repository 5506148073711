import React, { useEffect, useState } from "react";
import "react-data-table-component-extensions/dist/index.css";
import { getUserData } from "../../helpers/utils";
import { usePopup } from "../Common/PopupContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Api";
import axios from "axios";
const BASE_URL_PLATFORM = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const Ropot = () => {
  const User = getUserData();
  const [formData, setFormData] = useState({
    json: "",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchRobotsTxt = async () => {
      try {
        const response = await axios.get(BASE_URL_PLATFORM + "/api/get-robots");
        if (response.status === 200) {
          setFormData({ json: response.data.content });
        }
      } catch (error) {
        console.error("Error fetching robots.txt:", error);
        // setFetchError("Failed to load robots.txt content.");
      }
    };

    fetchRobotsTxt();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await api.updateRobotTxt({ content: formData.json });
      if (response.status == 200) {
        toast.success("Updated Successffully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom-toast",
        });
        setLoading(false);
      } else {
        console.error("Error posting job:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting course:", error);
      // alert("Failed to submit course. Please try again.");
    } finally {
      // Stop the loader regardless of success or failure
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the field belongs to the meta object

    setFormData({
      ...formData,
      [name]: value, // Update the non-meta field
    });

    // Call handleChangeSlug whenever the title changes
  };
  return (
    <form onSubmit={handleSubmit} className="p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-6">Robots.txt</h2>
      <div className="animated-form profileheadlinediv">
        <div className="form-group">
          <textarea
            name="json"
            placeholder=""
            value={formData.json}
            onChange={handleChange}
            className="border rounded  w-full py-2 px-3 text-gray-700"
            id="json"
            rows={7}
          ></textarea>
          <label htmlFor="json" className="profileheadline description-label">
            Data
          </label>
        </div>
      </div>

      {/* HTML Content */}
      <div className="flex justify-between  gap-3 mt-5">
        <button
          type="submit"
          className="hrp-find-course-btn px-8 py-2 rounded-full"
        >
          Update
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <ToastContainer />
    </form>
  );
};

export default Ropot;
