import React from "react";
import RecentJobsicon from "../../image/recent-job.png";
import businesscenter from "../../image/business-center.png";
const jobs = [
  {
    title: "Web Designer, Graphic Designer, UI/UX Designer & Art",
    salary: "$25000 - $30000",
    location: "Drive Protons, NV 876",
    posted: "16 minutes ago",
  },
  // Add more job objects as needed
];

const RecentJobs = () => {
  return (
    <div className="bg-white p-5  ">
      <div className="container px-5 m-auto py-10">
        <div className="Recent-Jobs-heading">
          <h3 className="text-center   Recent-Jobs-inner">Recent Jobs</h3>
          <p className="text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
        {jobs.map((job, index) => (
          <div
            key={index}
            className="border border-gray-300 rounded-lg py-8 px-4  flex items-center  gap-8 bg-white shadow-md m-4"
          >
            <div className="Recent-Jobs-image">
              <img src={RecentJobsicon} alt="Job Icon" className="" />
            </div>

            <div className="flex-grow">
              <div className="">
                <div className="Browse-Job-btn">
                  <img src={businesscenter}></img>
                  <button> Full Time</button>
                </div>
              </div>
              <h2 className="text-lg font-semibold">{job.title}</h2>
              <div className="flex flex-wrap gap-2 mt-2 text-gray-600 text-sm">
                <div className="flex items-center">
                  <span className="mr-1">🖌️</span> Graphic Designer
                </div>
                <div className="flex items-center">
                  <span className="mr-1">💵</span> {job.salary}
                </div>
                <div className="flex items-center">
                  <span className="mr-1">📍</span> {job.location}
                </div>
                <div className="flex items-center">
                  <span className="mr-1">🕒</span>
                  {job.posted}
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <a
                href="#"
                className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition duration-200"
              >
                Browse Job
                <span className="ml-2">🔔</span>
              </a>
            </div>
          </div>
        ))}

        {/* {jobs.map((job, index) => (
          <div key={index} className="border p-3 mb-3 rounded">
            <p>{job.title}</p>
            <p>Salary: {job.salary}</p>
            <p>Location: {job.location}</p>
            <p>Posted: {job.posted}</p>
          </div>
        ))} */}
        <div className="text-center">
          <button className="p-2 bg-blue-500 text-white rounded">
            Browse all jobs
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecentJobs;
