import React, { useState, useEffect } from "react";
import Rectangle964 from "../../image/Rectangle964.png";
import Enrollpopuppage from "../popup/Profilepagepopup/Enrollpopup.js";
import { PopupProvider } from "../Common/PopupContext";
import { usePopup } from "../Common/PopupContext";
import api from "../../Api";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const clientdomainpath = process.env.REACT_APP_DOMAIN;
const CourseCard = () => {
  const [data, setdata] = useState([]);
  const [cousredata, setcousredata] = useState([]);
  let { courseid } = useParams();
  useEffect(() => {
    async function getCoursesbyid() {
      try {
        const response = await api.getCoursebyId(courseid);
        console.log(response);

        if (response.status !== 400) {
          setcousredata(response);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getCoursesbyid();
    async function getCpurses() {
      try {
        const response = await api.getAllCourses();
        if (response.status !== 400) {
          setdata(response);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getCpurses();
  }, []);

  const { openPopup } = usePopup();
  const [isPopupOpen, setIsPopupOpen] = useState([]);
  const [popupComponent, setPopupComponent] = useState(null);

  const handleClosePopup = (message) => {
    console.log(message);
    setPopupComponent(null);
    openPopup();
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Show 4 courses per slide
    slidesToScroll: 1, // Scroll 4 courses at a time
    arrows: true, // Optional: Add arrows for easier navigation
    responsive: [
      {
        breakpoint: 1024, // For larger screens
        settings: {
          slidesToShow: 3, // Show 3 courses per slide on medium screens
          slidesToScroll: 3, // Scroll 3 at a time
        },
      },
      {
        breakpoint: 768, // For tablet screens
        settings: {
          slidesToShow: 2, // Show 2 courses per slide on tablets
          slidesToScroll: 2, // Scroll 2 at a time
        },
      },
      {
        breakpoint: 480, // For mobile screens
        settings: {
          slidesToShow: 1, // Show 1 course per slide on small screens
          slidesToScroll: 1, // Scroll 1 at a time
        },
      },
    ],
  };
  const Enrollpopup = (data) => {
    const popup = (
      <Enrollpopuppage
        onClose={() => handleClosePopup("Popup closed")}
        course={data}
      />
    );
    setPopupComponent(popup);
    openPopup(popup); // Added this line
  };
  const Courses = () => {
    return (
      <div className="w-full mb-10 hrp-course-card-outer">
        {/* Slider */}
        <Slider {...settings}>
          {data.map((course, index) => {
            const originalPrice = course.discountPrice
              ? course.price.toFixed(2)
              : null;

            return (
              <div
                key={index}
                className="coursecard p-4 w-full h-full max-w-xs mx-auto my-4 border border-gray-300 rounded-md shadow-md"
                style={{ height: "460px" }}
              >
                {/* Course Link */}
                <a href={clientdomainpath + "/courses/" + course._id}>
                  {/* Course Thumbnail */}
                  <img
                    className="imgse renderCategoryEdit text uploadImage-logo"
                    src={
                      course.courseThumbnail &&
                      course.courseThumbnail.trim() !== "" &&
                      course.courseThumbnail !== "NA"
                        ? course.courseThumbnail.startsWith("http://") ||
                          course.courseThumbnail.startsWith("https://")
                          ? course.courseThumbnail
                          : course.courseThumbnail.startsWith("/uploads/")
                          ? `${domainpath}${course.courseThumbnail}`
                          : `${domainpath}/uploads/${course.courseThumbnail}`
                        : `${domainpath}/uploads/defaultblog.png` // Use default if the logo is not present
                    }
                    alt="Course"
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite loop
                      e.target.src = `${domainpath}/uploads/defaultblog.png`; // Use the default image on error
                    }}
                  />
                </a>

                {/* Course Details */}
                <div className="py-4">
                  <a href={"/courses/" + course._id}>
                    <h3 className="text-lg font-bold hrp-course-card-title">
                      {course.courseTitle}
                    </h3>
                  </a>
                  <p className="mt-1 hrp-find-cours-card-description">
                    {course.courseDescription.includes("<img")
                      ? "Description contains an image. Please check the course details for more information."
                      : course.courseDescription
                          .split(" ")
                          .slice(0, 25)
                          .join(" ")}
                    {course.courseDescription.split(" ").length > 25 &&
                    !course.courseDescription.includes("<img")
                      ? "..."
                      : ""}
                  </p>
                  <p className="text-sm text-gray-600 hrp-course-card-instructor">
                    {course.instructorName}
                  </p>

                  {/* Pricing */}
                  <div className="mt-4 flex items-center">
                    <span className="text-lg font-bold text-green-600 hrp-course-card-price">
                      £{course.discountPrice.toFixed(2)}
                    </span>
                    {originalPrice && (
                      <span className="ml-2 text-sm line-through text-gray-500 hrp-course-card-originalPrice">
                        £{originalPrice}
                      </span>
                    )}
                  </div>

                  {/* Course Levels */}
                  <div className="hrp-card-tittle mt-2 flex flex-wrap">
                    {course.courseLevel &&
                      course.courseLevel.map(
                        (level, index) =>
                          level && (
                            <p
                              key={index}
                              className="border rounded-full px-2 py-1 m-1 text-sm text-blue-800 border-blue-800"
                              style={{
                                color: "#003366",
                                border: "1px solid #003366",
                                fontSize: "13px",
                              }}
                            >
                              {level}
                            </p>
                          )
                      )}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  };

  return (
    <div className="hrp-main-section course-page">
      <div className="  hrp-container  ">
        <section className=" hrp-sub-section hrp-course-section-1 ">
          <div className="flex py-10 mt-10   hrp-sub-section-container container m-auto">
            <div className="w-3/5 pr-20  ">
              <h2
                className="hrp-heading-section-1"
                style={{ color: "#003366" }}
              >
                {cousredata?.courseTitle}
              </h2>
              <p className="mt-2 hrp-course-section text-gray-600">
                {cousredata?.courseDescription}
              </p>
              {/* <div className="flex items-center mt-4 hrp-rating-section">
                <div className="flex items-center">
                  <span>4.5</span>
                  <span className="text-yellow-500">★ ★ ★ ★ ☆ </span>
                  <span className="text-gray-500">(93,973 ratings)</span>
                  <span className="ml-2 ">62,781 Students</span>
                </div>
              </div> */}
              {/* <div className="flex items-center mt-2 text-gray-600">
                <span className="flex">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 21C10.75 21 9.57933 20.7627 8.488 20.288C7.39667 19.8133 6.44667 19.1717 5.638 18.363C4.82933 17.5543 4.18767 16.6043 3.713 15.513C3.23833 14.4217 3.00067 13.2507 3 12C2.99933 10.7493 3.237 9.57867 3.713 8.488C4.189 7.39733 4.83033 6.44733 5.637 5.638C6.44367 4.82867 7.39367 4.187 8.487 3.713C9.58034 3.239 10.7513 3.00133 12 3C13.3667 3 14.6627 3.29167 15.888 3.875C17.1133 4.45833 18.1507 5.28333 19 6.35V4H21V10H15V8H17.75C17.0667 7.06667 16.225 6.33333 15.225 5.8C14.225 5.26667 13.15 5 12 5C10.05 5 8.396 5.67933 7.038 7.038C5.68 8.39667 5.00067 10.0507 5 12C4.99933 13.9493 5.67867 15.6037 7.038 16.963C8.39734 18.3223 10.0513 19.0013 12 19C13.75 19 15.2793 18.4333 16.588 17.3C17.8967 16.1667 18.6673 14.7333 18.9 13H20.95C20.7 15.2833 19.721 17.1877 18.013 18.713C16.305 20.2383 14.3007 21.0007 12 21ZM14.8 16.2L11 12.4V7H13V11.6L16.2 14.8L14.8 16.2Z"
                      fill="#666666"
                    />
                  </svg>
                  &nbsp; Last updated 1/2024
                </span>
                <span className="mx-2">•</span>
                <span className="flex">
                  {" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.6 9H20.4M3.6 15H20.4M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12Z"
                      stroke="#666666"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.4999 3C9.81525 5.69961 8.92212 8.81787 8.92212 12C8.92212 15.1821 9.81525 18.3004 11.4999 21M12.4999 3C14.1846 5.69961 15.0777 8.81787 15.0777 12C15.0777 15.1821 14.1846 18.3004 12.4999 21"
                      stroke="#666666"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  &nbsp; English
                </span>
              </div> */}
            </div>
            <div className="w-2/5  flex justify-end items-center ">
              <div className="hrp-sub-section-image">
                <img
                  src={domainpath + "/uploads/" + cousredata?.courseThumbnail}
                  alt="Course"
                  className="w-full object-cover rounded-t-md"
                />
                <button
                  className="my-10 w-full rounded-full "
                  onClick={(e) => Enrollpopup(cousredata)}
                >
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </section>
        <div className=" hrp-sub-section hrp-section-1 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-section-2 hrp-About-course py-6">
              <h2 className="  mb-2 hrp-About">About </h2>
              <p className="">
                Our client has developed a one-stop blockchain data platform to
                make on-chain and off-chain data indexing, querying, and sharing
                extremely fast, reliable, flexible, etc. We help developers
                build real-time, reliable, data-intensive apps in minutes — all
                without worrying about data processing in their frontends or
                backends! This is done through ready-to-use, flexible, and
                customizable APIs.
              </p>
            </div>
          </div>
        </div>{" "}
        <div className=" hrp-sub-section hrp-section-1 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-section-2 hrp-About-course">
              <p className="">
                <strong>Skills:</strong>
              </p>
              <ul className="list-disc list-inside mb-4">
                {cousredata?.skills?.map((skill, index) => (
                  <li key={index}>{skill}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className=" hrp-sub-section hrp-section-2 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    What you'll learn
                  </h3>
                </div>
              </div>
              <div className="p-6">
                {/* <h4 className="text-md font-semibold mb-4">Why join us</h4> */}
                <ul className="list-disc list-inside text-gray-700">
                  <li>
                    Build microservices to serve our customers Build real-time
                  </li>
                  <li>
                    systems that can handle millions of TPS in sub-10ms latency
                  </li>
                  <li> Own the end-to-end product lifecycle of identifying,</li>
                  <li>designing, and building new features</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section hrp-section-3 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    What you'll learn
                  </h3>
                </div>
              </div>
              <div className="p-6">
                {/* <h4 className="text-md font-semibold mb-4">Why join us</h4> */}
                <div className="lg:flex ">
                  <ul className="list-disc list-inside text-gray-700 lg:w-1/2">
                    <li>Solidity Programming By Building Projects</li>
                    <li>Smart Contract Usage (Theory + Practice)</li>
                    <li>The Development Functions Of Ethereum</li>
                    <li>How Decentralized Technology Works</li>
                  </ul>
                  <ul className="list-disc list-inside text-gray-700 lg:w-1/2">
                    <li>Solidity Programming By Building Projects</li>
                    <li>Smart Contract Usage (Theory + Practice)</li>
                    <li>The Development Functions Of Ethereum</li>
                    <li>How Decentralized Technology Works</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section hrp-section-4 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    Course Objectives
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <h4 className="text-md font-semibold mb-4">Why join us</h4>
                <ul className="list-disc list-inside text-gray-700"></ul>
                {typeof cousredata?.courseObjectives === "string" ? (
                  cousredata?.courseObjectives
                    .split(/\r?\n•\s*/)
                    .filter((courseobj) => courseobj.trim() !== "")
                    .map((courseobj, index) => (
                      <li key={index}>{courseobj.trim()}</li>
                    ))
                ) : Array.isArray(cousredata?.courseObjectives) ? (
                  cousredata?.courseObjectives.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))
                ) : (
                  <li>No valid responsibilities found.</li>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section hrp-section-4 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    Target Audience
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <h4 className="text-md font-semibold mb-4">Why join us</h4>
                <ul className="list-disc list-inside text-gray-700"></ul>
                {typeof cousredata?.targetAudience === "string" ? (
                  cousredata?.targetAudience
                    .split(/\r?\n•\s*/)
                    .filter((item) => item.trim() !== "")
                    .map((item, index) => <li key={index}>{item.trim()}</li>)
                ) : Array.isArray(cousredata?.targetAudience) ? (
                  cousredata?.targetAudience.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))
                ) : (
                  <li>No valid target audience information found.</li>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section hrp-section-4 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    Prerequisites
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <h4 className="text-md font-semibold mb-4">Why join us</h4>
                <ul className="list-disc list-inside text-gray-700"></ul>
                {typeof cousredata?.prerequisites === "string" ? (
                  cousredata?.prerequisites
                    .split(/\r?\n•\s*/)
                    .filter((item) => item.trim() !== "")
                    .map((item, index) => <li key={index}>{item.trim()}</li>)
                ) : Array.isArray(cousredata?.prerequisites) ? (
                  cousredata?.prerequisites.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))
                ) : (
                  <li>No valid prerequisites found.</li>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section hrp-section-4 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    Course Resources
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <h4 className="text-md font-semibold mb-4">Why join us</h4>
                <ul className="list-disc list-inside text-gray-700"></ul>
                {typeof cousredata?.courseResources === "string" ? (
                  cousredata?.courseResources
                    .split(/\r?\n•\s*/)
                    .filter((item) => item.trim() !== "")
                    .map((item, index) => <li key={index}>{item.trim()}</li>)
                ) : Array.isArray(cousredata?.courseResources) ? (
                  cousredata?.courseResources.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))
                ) : (
                  <li>No valid course resources found.</li>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section hrp-section-4 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    Benefits
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <h4 className="text-md font-semibold mb-4">Why join us</h4>
                <ul className="list-disc list-inside text-gray-700">
                  <li>
                    Top-notch team - Did we mention this? You’ll work with the
                    sharpest crew you’ve ever met. We are a team of 10+
                    experienced engineers, having built data infrastructure and
                    products as early employees at Databricks, Uber, Coinbase,
                    Confluent, Salesforce, Zoom, LinkedIn, and so on.
                  </li>
                  <li>
                    Unlimited vacation policy - we need you at your best at all
                    times. Our expected minimum time off lets you schedule your
                    work around your life.
                  </li>
                  <li>
                    Competitive compensation - we firmly believe amazing talent
                    is the No. 1 reason for success, and thus we provide
                    globally leading compensation, stock, and benefits.
                  </li>
                  <li>All of the standard benefits -Healthcare, 401k etc.</li>
                  <li>
                    Travel With Purpose - we are a fully distributed, 100%
                    remote-friendly team, however, we recognize the value of
                    in-person time. We will pay for round-trip flights, hotel,
                    transportation, and food on weekdays for you to visit our
                    headquarters in San Francisco, or other members of your team
                    around the world.
                  </li>
                  <li>
                    Continuous learning/training policy - we sponsor you for the
                    conferences and training programs you feel would add to your
                    development in the company. Sponsored visas - for employees
                    in need of them, such as H1b and Green Card.
                  </li>

                  {typeof cousredata?.courseObjectives === "string" ? (
                    cousredata?.courseObjectives
                      .split(/\r?\n•\s*/)
                      .filter((courseobj) => courseobj.trim() !== "")
                      .map((courseobj, index) => (
                        <li key={index}>{courseobj.trim()}</li>
                      ))
                  ) : Array.isArray(cousredata?.courseObjectives) ? (
                    cousredata?.courseObjectives.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))
                  ) : (
                    <li>No valid responsibilities found.</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section  hrp-section-5 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div>
              <div className="text-center  hrp-broad-selection py-10">
                <h1>A broad selection of courses</h1>
                <p>
                  Choose from over 220,000 online video courses with new
                  additions published every month
                </p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className=" hrp-sub-section  hrp-section-5 ">
          <div className="m-auto container hrp-sub-section-container  flex ">
            <Courses />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CourseCard;
