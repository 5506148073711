import React, { useState, useEffect } from "react";

const Presentation = ({ onClose, onSave, initialValues, category, index }) => {
  const [formData, setFormData] = useState({
    title: "",
    presenter: "",
    event_date: "",
    description: "",
    link: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
    }
  }, [initialValues]);
  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose("");
    // Handle form submission logic here
  };
  return (
    <div className=" flex justify-center h-screen  login_inform-inner m-auto p-20 job-page">
      <div className="flex ">
        <div className="form_upto  jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <>
              <h1 className="hrp-heading-size text-center hrp-About">
                Presentation
              </h1>

              <form
                onSubmit={handleSubmit}
                className="register-form-section animated-form"
              >
                <div className="form-group">
                  <input
                    type="text"
                    id="title"
                    placeholder=""
                    value={formData.title}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    required
                  />
                  <label htmlFor="title" className="form-input-label">
                    Title
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="presenter"
                    placeholder=""
                    value={formData.presenter}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    required
                  />
                  <label htmlFor="presenter" className="form-input-label">
                    Presenter
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="date"
                    id="event_date"
                    placeholder=""
                    value={formData.event_date}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    required
                  />
                  <label htmlFor="event_date" className="form-input-label">
                    Event Date
                  </label>
                </div>
                <div className="form-group">
                  <textarea
                    id="description"
                    placeholder=""
                    value={formData.description}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="description" className="form-input-label">
                    Description
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="url"
                    id="link"
                    placeholder=""
                    value={formData.link}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="link" className="form-input-label">
                    Link (optional)
                  </label>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="hrp-find-course-btn py-3 px-10 rounded-full"
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
        <button
          className="  text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6	 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};
export default Presentation;
