// AdminLayout.js
import React, { useState,useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Footer from "./Footer.js";
import "../../components/Layout/menubaar.css";
import "../../../src/css/responcive.css";
import { Header, CandidateHeader } from "./Header.js";
import Dasboard from "../SellerCourse/Dashboard.js";
import { Seller } from "./dashboardmenus.js";
import { PopupProvider } from "../Common/PopupContext.js";
import ManageCourse from "../SellerCourse/ManageCourse.js";
import EditCourse from "../SellerCourse/EditCourse.js";
import AddCourse from "../SellerCourse/AddCousre.js";
import EnrollmentEnquiries from "../SellerCourse/EnrollmentEnquiries.js";
import EnrollmentSingle from "../SellerCourse/EnrollmentSingle.js";

function ApplicantLayout({ userRole }) {
  const getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null; // Return null if the cookie is not found
  };
  const useLocalStorage = (key) => {
    // Retrieve from localStorage
    const [storedValue, setStoredValue] = useState(() => {
      const item = localStorage.getItem(key);
      if (item) return item;

      // If not found in localStorage, try to retrieve it from cookies
      const cookieValue = getCookie(key);
      if (cookieValue) {
        // Set to localStorage if found in cookies
        localStorage.setItem(key, cookieValue);
        return cookieValue;
      }

      return null;
    });

    return storedValue;
  };
  const user = useLocalStorage("accessToken");
  console.log(user);

  useEffect(() => {}, [user]);

  let { path } = useRouteMatch(); // Get the base path for nested routes

  return (
    <PopupProvider>
      <div className="">
        <CandidateHeader />

        <div className="container m-auto hrp-min-h-screen">
          <div className="w-full hrp-seller-dashboard hrp-min-width">
            {user ? <Seller /> : ""}
            <section className="mt-5">
              <div className="bg-white container JobCategories-heading-admin m-auto 	">
                <Switch>
                  <Route exact path={path} component={Dasboard} />

                  <Route
                    exact
                    path={`${path}/courses`}
                    component={ManageCourse}
                  />
                  <Route
                    exact
                    path={`${path}/courses/edit/:id`}
                    component={EditCourse}
                  />
                  <Route
                    exact
                    path={`${path}/courses/add`}
                    component={AddCourse}
                  />
                  <Route
                    exact
                    path={`${path}/enrollment-enquiries`}
                    component={EnrollmentEnquiries}
                  />
                  <Route
                    exact
                    path={`${path}/enrollment-enquiries/:id`}
                    component={EnrollmentSingle}
                  />
                </Switch>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </PopupProvider>
  );
}

export default ApplicantLayout;
