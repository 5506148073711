import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
import Select2 from "react-select2-wrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FeatherIcon from "feather-icons-react";
import { useHistory, useParams } from "react-router-dom";
const domainpath = process.env.REACT_APP_DOMAIN;
const EnrollmentEnquiries = () => {
  const User = getUserData();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const history = useHistory();
  useEffect(() => {
    const userid = User._id;

    async function getEnrollment() {
      try {
        const response = await api.getEnrollmentList();
        if (response.status !== 400) {
          setData(response);
        } else if (response.status === 400) {
          // Handle 400 status if needed
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }

    getEnrollment();
  }, []);
  const handleWithdraw = async (jobId) => {
    const formdata = {
      userId: User?._id,
    };

    try {
      const response = await api.jobwithdraw(jobId, formdata);

      if (response.status === 200) {
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        // Update the application state directly to reflect the withdrawal
        setData((prevApplications) =>
          prevApplications.filter((application) => application.jobId !== jobId)
        );
        setTimeout(() => {
          window.location.reload();
        }, 1500);

        setLoading(false);
      } else {
        console.error("Error updating staff:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error withdrawing application:", error);
    } finally {
      setLoading(false);
    }
  };
  const status = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
    { value: "In Progress", label: "In Progress" },
    { value: "Completed", label: "Completed" },
    { value: "On Hold", label: "On Hold" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Awaiting Payment", label: "Awaiting Payment" },
    { value: "Reviewed", label: "Reviewed" },
    { value: "Under Review", label: "Under Review" },
  ];
  const columns = [
    {
      name: "Course Title",
      selector: (row) => (
        <a
          href={"/dashboard/enrollment-enquiries/" + row?._id}
          style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
        >
          {row?.courseTitle}
          <FeatherIcon
            icon="eye"
            onClick={() =>
              history.push(domainpath + "/course/" + row?.courseId)
            }
          />
        </a>
      ),
      width: "30%",
      sortable: true,
    },
    {
      name: "Applicant",
      selector: (row) => row?.firstName + row?.lastName,
      sortable: true,
      width: "10%",
    },
    {
      name: "Phone",
      selector: (row) => row?.mobileNumber,
      sortable: true,
      width: "10%",
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
      width: "15%",
    },

    {
      name: "Status",
      selector: (row) => (
        <div
          className="relative job-search-label-section singleselect2 statusenroll"
          style={{ width: "100%", textAlign: "center" }}
        >
          {" "}
          <Select2
            id="status"
            className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
            name="status"
            multiple={false}
            data={status.map((type) => ({
              id: type.value,
              text: type.label,
            }))}
            defaultValue={row?.status}
            options={{
              placeholder: "Select Job Status",
              theme: "classic",
              width: "resolve",
            }}
            onSelect={(e) => handlechangeEnrollStatus(e, row?._id)}
          />
        </div>
      ),
      style: {
        display: "block",
        marginTop: "4px",
        width: "20%",
      },
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => new Date(row.enrolledAt).toLocaleDateString(),
      sortable: true,
      width: "15%",
    },
  ];

  const tableData = {
    columns,
    data,
  };
  const handlechangeEnrollStatus = async (e, id) => {
    setLoading(true);
    e.preventDefault();
    var formdata = {
      status: e.target.value,
    };
    try {
      const res = await api.updateEnrollstatus(id, formdata);
      toast.success(res.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 1200);
      console.log(res);
    } catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="p-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
          Enrollment Enquiries
        </h2>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default EnrollmentEnquiries;
