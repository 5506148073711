import React, { useState, useEffect, useRef } from "react";
import api from "../../Api";
import { jobSectors } from "../../helpers/Object";
import SearchableDropdown from "../../helpers/SearchableDropdown";

const Generateai = ({ onClose, onSave, data }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    positions: "1",
    jobDesignation: "",
    companyName: "",
    salary: "",
    payFrequency: "Monthly",
    location: "",
    jobType: "Full-Time",
    industry: "",
  });
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    console.log(data);

    setFormData({
      positions: data?.positions || "1",
      jobDesignation: data?.title || "",
      companyName: data?.company?.name || "",
      salary: data?.salary || "",
      payFrequency: "Monthly", // Ensure "Monthly" is used here for consistency
      location: data?.location?.address || "",
      jobType: "Full-Time",
      industry: data?.industry || "",
    });
    setInputValue(data?.location?.address || ""); // Set initial input value

    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"],
          componentRestrictions: { country: "uk" },
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const addressComponents = place.address_components;

        // Parse the address components
        const addressData = {
          address: place.formatted_address,
          city: "",
          area: "",
          pincode: "",
          lat: place.geometry.location.lat(), // Latitude
          long: place.geometry.location.lng(),
        };

        // Update address fields based on address_components
        addressComponents.forEach((component) => {
          const types = component.types;
          if (types.includes("locality")) {
            addressData.city = component.long_name;
          }
          if (types.includes("sublocality") || types.includes("neighborhood")) {
            addressData.area = component.long_name;
          }
          if (types.includes("postal_code")) {
            addressData.pincode = component.long_name;
          }
        });

        // Update formData with parsed location data
        setFormData((prevFormData) => ({
          ...prevFormData,
          location: {
            ...prevFormData.location,
            ...addressData,
          },
        }));
        setInputValue(place.formatted_address);
      });

      return () => {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      };
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "location") {
      setInputValue(value);
      setFormData((prevData) => ({ ...prevData, location: value }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      // Debug: Log the initial value of applicationDeadline
      console.log("Initial applicationDeadline:", formData.applicationDeadline);

      // Check if applicationDeadline is a valid date string
      let deadline = formData.applicationDeadline
        ? new Date(formData.applicationDeadline)
        : new Date(); // Fallback to current date if undefined

      // Ensure applicationDeadline is valid and handle fallback
      if (isNaN(deadline.getTime())) {
        console.warn(
          "Invalid applicationDeadline, falling back to current date."
        );
        deadline = new Date(); // Fallback to current date if invalid
      }

      // Convert to ISO string if valid
      const formDataWithDates = {
        ...formData,
        applicationDeadline: deadline.toISOString(),
        jobid: data?._id,
      };

      const response = await api.generateai(formDataWithDates);
      onSave(response);
      onClose("");
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };
  const selectdays = [
    { name: "Range", value: "Range" },
    { name: "Fixed", value: "Fixed" },
    { name: "Hourly Rate", value: "Hourly" },
    { name: "Annual Salary", value: "Annual " },
    { name: "Monthly Salary", value: "Monthly" },
    { name: "Yearly Salary", value: "Yearly" },
    { name: "Weekly Pay", value: "Weekly" },
    { name: "Daily Rate", value: "Daily" },
    { name: "Per Project", value: "Per-Project" },
  ];
  const jobtype = [
    { name: "Full-Time", value: "Full-Time" },
    { name: "Part-Time", value: "Part-Time" },
    { name: "Permanent", value: "Permanent" },
    { name: "Temporary contract", value: "Temporary contract" },
    { name: "Internship", value: "Internship" },
    { name: "Freelance", value: "Freelance" },
  ];
  const handleDropdownChange = (selectedItem, fieldName) => {
    if (selectedItem) {
      // Check if selectedItem is an object with value property or a plain string
      const value =
        typeof selectedItem === "string" ? selectedItem : selectedItem.value;
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value, // Use the selected value for updating formData
      }));
    } else {
      console.warn(`No value found for selectedItem: ${selectedItem}`);
    }
  };
  return (
    <div className="flex justify-center h-screen m-auto 2xl:p-20 py-20 job-information-popup">
      <div className="flex">
        <div
          className="form_upto jobapply-width gen-jobapply-width m-3 loginDiv"
          id="step_1"
        >
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4 generateai-page">
            <div className="hrp-heading-job">
              <h1 className="hrp-heading-size text-center hrp-About">
                Job Information
              </h1>
            </div>
            <form onSubmit={handleSubmit} className=" justify-center p-5">
              <p className="flex justify-start lg:gap-4 gap-0 my-3">
                <div>I am looking to hire</div>
                <input
                  type="number"
                  id="positions"
                  name="positions"
                  value={formData.positions}
                  onChange={handleChange}
                  required
                  placeholder="1"
                  className="job-information-popup-input"
                />
                <div>for the job designation of</div>
                <input
                  style={{ width: "30%" }}
                  type="text"
                  id="jobDesignation"
                  name="jobDesignation"
                  value={formData.jobDesignation}
                  onChange={handleChange}
                  required
                  placeholder="Designation i.e Sales Executive"
                  className="job-information-popup-input"
                />
              </p>

              <p className="flex justify-start items-center lg:gap-4 gap-0 my-3">
                <div>For my company,</div>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Your Company Name "
                  required
                  className="job-information-popup-input"
                />
                belongs to <div>industry</div>
                <span className="job-information-popup-input">
                  <SearchableDropdown
                    options={jobSectors.map((sector) => ({
                      value: sector.value,
                      label: sector.label,
                    }))}
                    className="job-information-popup-input"
                    multiple={false}
                    name="industry"
                    required
                    value={jobSectors.find(
                      (sector) => sector.value === formData.industry
                    )}
                    onChange={(selectedItem) =>
                      handleDropdownChange(selectedItem, "industry")
                    }
                  />
                </span>
              </p>

              <p className="flex justify-start items-center lg:gap-1 gap-0 my-3">
                ,<label htmlFor="salary">we offer a salary of</label>
                <input
                  type="text"
                  id="salary"
                  name="salary"
                  value={formData.salary}
                  onChange={handleChange}
                  placeholder="20000"
                  className="job-information-popup-input"
                />
                <label htmlFor="payFrequency">paid per</label>
                <span className="job-information-popup-input job-information-popup-input-width ">
                  <SearchableDropdown
                    options={selectdays.map((day) => ({
                      label: day.name, // Assuming each item in selectdays has a 'name' property
                      value: day.value, // Assuming each item in selectdays has a 'value' property
                    }))}
                    className=""
                    multiple={false}
                    name="payFrequency"
                    required
                    value={{
                      value: formData.payFrequency,
                      label: formData.payFrequency,
                    }}
                    onChange={(selectedItem) =>
                      handleDropdownChange(selectedItem, "payFrequency")
                    }
                  />
                </span>
                <label htmlFor="location">For the location</label>
              </p>

              <p className="flex justify-start items-center lg:gap-2 gap-0 my-3">
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={inputValue}
                  onChange={handleChange}
                  required
                  className="job-information-popup-input"
                  ref={inputRef}
                />
                <div htmlFor="jobType">The job is</div>
                <span className="job-information-popup-input job-information-popup-input-width">
                  <SearchableDropdown
                    options={jobtype.map((type) => ({
                      label: type.name,
                      value: type.value,
                    }))}
                    name="jobType"
                    value={{
                      value: formData.jobType,
                      label:
                        jobtype.find((type) => type.value === formData.jobType)
                          ?.name || formData.jobType, // Use label instead of name
                    }}
                    onChange={(selectedItem) =>
                      handleDropdownChange(selectedItem, "jobType")
                    }
                  />
                </span>
                .
              </p>

              <div style={{ textAlign: "center" }}>
                <button
                  type="submit"
                  className="px-5 py-3 bg-blue-900 rounded-full text-white"
                >
                  Generate
                </button>
              </div>
            </form>
          </div>
        </div>
        <button
          className="text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
    </div>
  );
};

export default Generateai;
