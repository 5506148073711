import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "select2/dist/js/select2.min.js";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
const SingleTicketPage = () => {
  const { id } = useParams();

  const [course, setCourse] = useState({
    name: "",
    status: "",
    applicant: "",
    phone: "",
    email: "",
    query: "",
    messages: [],
    notes: [],
  });
  const [newMessage, setNewMessage] = useState("");
  const [newNote, setNewNote] = useState("");
  const [showMessages, setShowMessages] = useState(true);
  const [showNotes, setShowNotes] = useState(false);
  const User = getUserData();

  useEffect(() => {
    async function getenroll() {
      try {
        const response = await api.getSingleEnroll(id);
        if (response.status !== 400) {
          setCourse({
            name: response?.courseId.courseTitle,
            status: response?.status,
            applicant: `${response?.firstName} ${response?.lastName}`,
            phone: response?.mobileNumber,
            email: response?.email,
            query: response?.query,
            messages: response?.messages, // Assuming this might come from somewhere else or initialized empty
            notes: response?.notes, // Same for notes
          });
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getenroll();
  }, [id]);

  const handleUpdateStatus = (newStatus) => {
    setCourse((prevCourse) => ({ ...prevCourse, status: newStatus }));
  };

  const handleAddMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      const messageData = {
        message: newMessage, // The message content
        sender: User?._id, // The ID of the user sending the message
      };

      try {
        // Make the API request to post the message
        const response = await api.addMessageEnquiry(id, messageData);

        if (response && response.data) {
          // Update the local state with the newly added message
          const updatedMessages = [
            ...course.messages,
            { createdAt: new Date().toLocaleString(), message: newMessage },
          ];
          setCourse((prevCourse) => ({
            ...prevCourse,
            messages: updatedMessages,
          }));
          setNewMessage(""); // Clear the input field
        } else {
          // Handle errors here (e.g., show a notification to the user)
          console.error("Failed to add message", response);
        }
      } catch (error) {
        // Handle any errors that occur during the request
        console.error("Error adding message:", error);
      }
    }
  };

  const handleAddNote = async (e) => {
    e.preventDefault();
    if (newNote.trim()) {
      const noteData = {
        note: newNote, // The note content
        sender: User?._id, // The ID of the user sending the note
      };

      try {
        // Make the API request to post the note
        const response = await api.addNotesEnquiry(id, noteData);

        if (response && response.data) {
          // Update the local state with the newly added note
          const updatedNotes = [
            ...course.notes,
            { createdAt: new Date().toLocaleString(), note: newNote },
          ];
          setCourse((prevCourse) => ({
            ...prevCourse,
            notes: updatedNotes,
          }));
          setNewNote(""); // Clear the input field
        } else {
          // Handle errors here (e.g., show a notification to the user)
          console.error("Failed to add note", response);
        }
      } catch (error) {
        // Handle any errors that occur during the request
        console.error("Error adding note:", error);
      }
    }
  };

  const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
    { value: "In Progress", label: "In Progress" },
    { value: "Completed", label: "Completed" },
    { value: "On Hold", label: "On Hold" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Awaiting Payment", label: "Awaiting Payment" },
    { value: "Reviewed", label: "Reviewed" },
    { value: "Under Review", label: "Under Review" },
  ];

  return (
    <div className="single-enroll-page flex  ">
      <div className="w-full p-4">
        <h2 className="text-xl font-semibold mb-4" style={{ color: "#003366" }}>
          Course Details
        </h2>
        <div className="bg-white rounded-lg p-6 mb-4 hrp-ticket-details">
          <div>
            {/* Course Information */}
            <div className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center gap-4">
              <div className="flex items-center gap-2">
                <h1
                  className="text-lg font-medium"
                  style={{ color: "#343434" }}
                >
                  Course Name:
                </h1>
                <h3 className="text-sm" style={{ color: "#3e3e3e" }}>
                  {course.name || "N/A"}
                </h3>
              </div>

              <div className="flex items-start gap-2 mt-2 sm:mt-0">
                <h1
                  className="text-lg font-medium"
                  style={{ color: "#343434" }}
                >
                  Status:
                </h1>
                <div className="relative job-search-label-section singleselect2">
                  <Select2
                    id="statusOptions"
                    className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                    name="statusOptions"
                    multiple={false}
                    data={statusOptions.map((statusOption) => ({
                      id: statusOption.value,
                      text: statusOption.label,
                    }))}
                    options={{
                      placeholder: "Select Options",
                      theme: "classic",
                      width: "resolve",
                    }}
                    value={course.status}
                    onChange={(e) =>
                      setCourse({ ...course, status: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            {/* Applicant Information */}
            {[
              { label: "Applicant", value: course.applicant },
              { label: "Phone", value: course.phone },
              { label: "Email", value: course.email },
              { label: "Query", value: course.query },
            ].map((field, index) => (
              <div
                className="flex flex-col sm:flex-row gap-2 mt-2 items-center"
                key={index}
              >
                <h1
                  className="text-lg font-medium"
                  style={{ color: "#343434" }}
                >
                  {field.label}:
                </h1>
                <h3 className="text-sm" style={{ color: "#3e3e3e" }}>
                  {field.value || "N/A"}
                </h3>
              </div>
            ))}
          </div>
        </div>

        <div>
          <div className="flex gap-2 mb-4">
            <button
              className={`mt-2 px-4 py-2 hrp-ticket-btn ${
                showMessages ? "activebtn" : "inactive"
              } text-white`}
              onClick={() => {
                setShowMessages(true);
                setShowNotes(false);
              }}
            >
              Message
            </button>
            <button
              className={`mt-2 px-4 py-2 hrp-ticket-btn ${
                showNotes ? "activebtn" : "inactive"
              } text-white`}
              onClick={() => {
                setShowMessages(false);
                setShowNotes(true);
              }}
            >
              Note
            </button>
          </div>

          {showMessages && (
            <div className="mb-6 mt-5">
              <h3
                className="text-lg font-semibold mb-4"
                style={{ color: "#003366" }}
              >
                Messages
              </h3>
              <div className="mt-4">
                <div className="animated-form profileheadlinediv">
                  <div className="form-group">
                    <textarea
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      placeholder=""
                      className="border rounded w-full py-2 px-3 text-gray-700"
                      id="newMessage"
                      rows={7}
                    ></textarea>
                    <label
                      htmlFor="newMessage"
                      className="profileheadline description-label"
                    >
                      Write Messages
                    </label>
                  </div>
                </div>
                <button
                  onClick={(e) => handleAddMessage(e)}
                  className="mt-2 text-white px-4 py-2 rounded-full"
                  style={{ backgroundColor: "#003366" }}
                >
                  Add Message
                </button>
              </div>
              <div className="space-y-4 mt-5 h-96 overflow-auto p-8 pl-0">
                {course.messages.map((message, index) => (
                  <div
                    key={index}
                    className="bg-gray-100 p-3 rounded-md shadow"
                  >
                    <div className="flex justify-between items-center">
                      <p className="text-sm " style={{ color: "#003366" }}>
                        {message?.sender}
                      </p>
                      <p className="text-sm text-gray-600">
                        {new Date(message.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                    <p className="text-gray-800">{message.message}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {showNotes && (
            <div className="mb-6 mt-5">
              <h3
                className="text-lg font-semibold mb-4"
                style={{ color: "#003366" }}
              >
                Notes
              </h3>
              <div className="mt-4">
                <div className="animated-form profileheadlinediv">
                  <div className="form-group">
                    <textarea
                      value={newNote}
                      onChange={(e) => setNewNote(e.target.value)}
                      placeholder=""
                      className="border rounded w-full py-2 px-3 text-gray-700"
                      id="newNote"
                      rows={7}
                    ></textarea>
                    <label
                      htmlFor="newNote"
                      className="profileheadline description-label"
                    >
                      Write Note...
                    </label>
                  </div>
                </div>
                <button
                  onClick={(e) => handleAddNote(e)}
                  className="mt-2 text-white px-4 py-2 rounded-full"
                  style={{ backgroundColor: "#003366" }}
                >
                  Add Note
                </button>
              </div>
              <div className="space-y-4 mt-5 h-96 overflow-auto p-8 pl-0">
                {course.notes.map((note, index) => (
                  <div
                    key={index}
                    className="bg-gray-100 p-3 rounded-md shadow"
                  >
                    <div className="flex justify-between items-center">
                      <p className="text-sm" style={{ color: "#003366" }}>
                        {note?.sender}
                      </p>
                      <p className="text-sm text-gray-600">
                        {new Date(note.createdAt).toLocaleDateString()}
                      </p>
                    </div>

                    <p className="text-gray-800">{note.note}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleTicketPage;
