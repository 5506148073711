import React, { useState } from "react";
import Footer from "../Layout/Footer";
import "../Layout/menubaar.css";
import { Header, EmployerHeader } from "../Layout/Header";
import JobSearchForm from "../Homepage/JobSearchForm";
import { Menubar } from "../Layout/dashboardmenus";
import Financeimage from "../../image/mdi_finance.png";
import { useLocalStorage } from "../Layout/useLocalStorage";
import RecentJobs from "../Homepage/RecentJobs";
import { PopupProvider } from "../Common/PopupContext";

function App() {
  const user = useLocalStorage("useraccessToken");
  const [skills, setSkills] = useState([]);
  const [categories, setCategories] = useState([
    "Accounting / Finance",
    "Marketing",
    "Design",
    "Development",
    "Human Resource",
    "Project Management",
    "Customer Service",
    "Health and Care",
  ]);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  return (
    <PopupProvider>
      <div className="CareerOpportunities  ">
        <div className="CareerOpportunities-inner container m-auto">
          <JobSearchForm />
        </div>
      </div>
      {user ? <Menubar /> : ""}
    </PopupProvider>
  );
}
export default App;
