import React, { useState } from "react";
import api from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Deleteprofile = ({ onClose, profile_id }) => {
  // Handle form submission
  const ProfileDelete = async (profileid) => {
    try {
      const response = await api.deleteProfile(profileid);
      // Replace with your API endpoint
      if (response.status === 200) {
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        onClose("");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        // console.error(response);
      }
    } catch (error) {
      console.error("Error fetching profile submenu data:", error);
    }
  };

  return (
    <div className=" flex justify-center h-screen  login_inform-inner m-auto p-20 job-page">
      <div className="flex ">
        <div className="form_upto  jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <>
              <div>
                <div className="p-10">
                  <h2 className="text-xl font-semibold mb-4">
                    Confirm Deletion
                  </h2>
                  <p className="mb-4">
                    Are you sure you want to delete this item?
                  </p>
                  <div className="flex  gap-4">
                    <button
                      onClick={(e) => ProfileDelete(profile_id)}
                      className="bg-red-500 text-white px-4 py-2 rounded"
                    >
                      Delete
                    </button>
                    <button
                      onClick={onClose}
                      className="bg-gray-300 px-4 py-2 rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
        <button
          className="  text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6	 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Deleteprofile;
