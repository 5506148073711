import React, { useState, useEffect } from "react";

const Presentation = ({ onClose, onSave, initialValues, category, index }) => {
  const [formData, setFormData] = useState({
    sample_title: "",
    sample_description: "",
    link_to_sample: "",
  });

  const handleChange = (e) => {
    const { id, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: type === "file" ? files[0] : value,
    }));
  };
  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
    }
  }, [initialValues]);
  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose("");
    // Handle form submission logic here
  };
  return (
    <div className=" flex justify-center h-screen  login_inform-inner m-auto p-20 job-page">
      <div className="flex ">
        <div className="form_upto  jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <>
              <h1 className="hrp-heading-size text-center hrp-About">Work</h1>
              <form
                onSubmit={handleSubmit}
                className="work-sample-form-section animated-form"
              >
                <div className="form-group">
                  <input
                    type="text"
                    id="sample_title"
                    placeholder=""
                    defaultValue={formData.sample_title}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    required
                  />
                  <label htmlFor="sample_title" className="form-input-label">
                    Sample Title
                  </label>
                </div>
                <div className="form-group">
                  <textarea
                    id="sample_description"
                    placeholder=""
                    defaultValue={formData.sample_description}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label
                    htmlFor="sample_description"
                    className="form-input-label"
                  >
                    Description
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="url"
                    id="link_to_sample"
                    placeholder=""
                    defaultValue={formData.link_to_sample}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="link_to_sample" className="form-input-label">
                    Link to Sample
                  </label>
                </div>
                {/* <div className="form-group">
                  <input
                    type="file"
                    id="file"
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="file" className="form-input-label">
                    Upload File
                  </label>
                </div> */}
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="hrp-find-course-btn py-3 px-10 rounded-full"
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
        <button
          className="  text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6	 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};
export default Presentation;
