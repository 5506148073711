// App.js
import React, { useState, useEffect } from "react";
import "../src/css/responcive.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import { getUserRole } from "./helpers/utils.js";
import "./components/Layout/menubaar.css";
// import Login from "../src/components/Login";
import api from "./Api.js";
import Dashboard from "./components/Website/Dashboard.js";
import UploadCV from "./components/Website/UploadCV.js";
import ApplicantLayout from "./components/Layout/ApplicantLayout.js";
import EmployerLayout from "./components/Layout/EmployerLayout.js";
import Websitelayout from "./components/Layout/Websitelayout.js";
import SellerCourse from "./components/Layout/SellerLayout.js";
import SuperAdminLayout from "./components/Layout/SuperAdminLayout.js";
import Manageuser from "./components/Candidates/Manageuser.js";
import Messages from "./components/Candidates/Messages.js";
import Jobspage from "./components/Candidates/Jobspage.js";
import AccountSettings from "./components/Candidates/AccountSettings.js";
import DeleteAccount from "./components/Candidates/DeleteAccount.js";
import UserProfile from "./components/Candidates/UserProfile.js";
import Profile from "./components/Candidates/Profile.js";
import NotFound from "./components/Website/NotFound.js";
import PermissionDenied from "./components/Website/PermissionDenied.js";

const App = () => {
  const userRole = getUserRole();
  console.log(userRole);

  const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (!userRole) {
          return <Redirect to="/" />; // Redirect if not logged in
        }
        if (allowedRoles.includes(userRole)) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/permission-denied" />;
        }
      }}
    />
  );
  var roles = ["employer", "staff"];
  // const [clicks, setClicks] = useState(0);
  // const [location, setLocation] = useState({ lat: null, long: null });
  // const startTime = Date.now();
  // const domainpath = "https://api.hirepros.co.uk";
  // useEffect(() => {
  //   // Track clicks on the page
  //   const handleDocumentClick = () => {
  //     setClicks((prevClicks) => prevClicks + 1);
  //   };
  //   document.addEventListener("click", handleDocumentClick);

  //   // Get the user's geolocation
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setLocation({
  //           lat: position.coords.latitude,
  //           long: position.coords.longitude,
  //         });
  //       },
  //       (error) => {
  //         console.error("Error getting geolocation:", error);
  //       }
  //     );
  //   }

  //   const handleUnload = () => {
  //     const endTime = Date.now();
  //     const timeSpent = (endTime - startTime) / 1000; // time in seconds
  //     const visitorData = {
  //       referer: document.referrer,
  //       date: new Date(startTime).toISOString(),
  //       timeSpent,
  //       url: window.location.href,
  //       site: window.location.host, // Replace with your actual site name
  //       city: "Unknown", // Populate this if you can determine the city
  //       pageUrl: window.location.pathname,
  //       clicks,
  //       lat: location.lat,
  //       long: location.long,
  //     };
  //     // Send data to backend
  //     fetch(`${domainpath}/api/visitor`, {
  //       // Update URL to match your server
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: visitorData,
  //     }).catch((error) => console.error("Error sending visitor data:", error));
  //   };

  //   window.addEventListener("beforeunload", handleUnload);

  //   return () => {
  //     document.removeEventListener("click", handleDocumentClick);
  //     window.removeEventListener("beforeunload", handleUnload);
  //   };
  // }, [startTime, clicks, location]);
  return (
    <Router>
      <Switch>
        {console.log(userRole)}
        {/* Role-Based Routing */}
        <Route
          path="/dashboard"
          render={(props) => {
            // Render appropriate layout based on role
            if (userRole === "applicant") {
              return <ApplicantLayout {...props} />;
            }
            if (userRole === "superadmin") {
              return <SuperAdminLayout {...props} />;
            }
            if (userRole === "employer") {
              return <EmployerLayout {...props} />;
            }
            if (userRole === "staff") {
              return <EmployerLayout {...props} />;
            }
            if (userRole === "seller") {
              return <SellerCourse {...props} />;
            }
            // If none of the roles match, redirect to permission denied or some fallback
            return <PermissionDenied />;
          }}
        />
        {/* Websitelayout for all other routes */}
        <Route path="/" render={(props) => <Websitelayout {...props} />} />
        {/* Permission Denied Page */}
        <Route path="/permission-denied" component={PermissionDenied} />
        {/* Catch-all route for 404 - Page Not Found */}
        <Route path="/404" component={NotFound} />
        {/* Catch-all route for 404 - Page Not Found */}
        <Route component={() => <Redirect to="/404" />} />
      </Switch>
    </Router>
  );
};

export default App;
