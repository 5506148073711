import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Adduser from "../popup/AddUserEmployer";
import EditUser from "../popup/EditUserEmployer";
import { getUserData } from "../../helpers/utils";
import { usePopup } from "../Common/PopupContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Api";
import Cookies from "js-cookie";
import { encryptData, decryptData } from "../../helpers/encryption";
import FeatherIcon from "feather-icons-react";
const Manageuser = () => {
  const { openPopup } = usePopup();
  const User = getUserData();
  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(0);
  const [data, setdata] = useState([]);
  const [popupComponent, setPopupComponent] = useState(null);
  const handleLoginClick = () => {
    const popup = <Adduser onClose={() => handleClosePopup("Popup closed")} />;
    setPopupComponent(popup);
    openPopup(popup);
  };

  const handleDelete = async (jobId) => {
    const response = await api.deleteStaff(`${jobId}`);
    console.log(response);

    if (response.status != 500) {
      toast.success(response.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setRender(render + 1);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toast.error("There was an error deleting the job!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleClosePopup = (message) => {
    console.log(message);
    setPopupComponent(null);
    openPopup();
  };
  const handleEditUser = (id) => {
    const popup = (
      <EditUser id={id} onClose={() => handleClosePopup("Popup closed")} />
    );
    setPopupComponent(popup);
    openPopup(popup);
  };
  const handleSecertLogin = async (id) => {
    setLoading(true);
    try {
      const userData = {
        userId: id,
      };
      const response = await api.SeceretLoginInEmployer(userData);
      console.log(response);

      if (response.status !== 400) {
        // Show success toast
        toast.success("Login Successfully", {
          position: "top-right",
          autoClose: 900,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom-toast",
        });

        // Destructure tokens and user details from API response
        const { accessToken, refreshToken } = response?.user;
        const encryptedUserData = encryptData(response.user);

        // Get the existing tokens and user data from localStorage and cookies
        const existingAccessToken =
          localStorage.getItem("accessToken") || Cookies.get("accessToken");
        const existingRefreshToken =
          localStorage.getItem("refreshToken") || Cookies.get("refreshToken");
        const existingUser =
          localStorage.getItem("user") || Cookies.get("user");

        // Rename and store existing values as admin keys in localStorage and cookies
        if (existingAccessToken) {
          localStorage.setItem("adminaccessToken", existingAccessToken);
          Cookies.set("adminaccessToken", existingAccessToken);
        }
        if (existingRefreshToken) {
          localStorage.setItem("adminrefreshToken", existingRefreshToken);
          Cookies.set("adminrefreshToken", existingRefreshToken);
        }
        if (existingUser) {
          localStorage.setItem("adminuser", existingUser);
          Cookies.set("adminuser", existingUser);
        }

        // Log for debugging (optional)
        console.log("Existing tokens and user renamed as admin keys", {
          adminAccessToken: localStorage.getItem("adminaccessToken"),
          adminRefreshToken: localStorage.getItem("adminrefreshToken"),
          adminUser: localStorage.getItem("adminuser"),
        });

        // Set new tokens and user data from API response in localStorage and cookies
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("user", encryptedUserData);
        Cookies.set("accessToken", accessToken);
        Cookies.set("refreshToken", refreshToken);
        Cookies.set("user", encryptedUserData);

        // If keepMeLoggedIn is true, persist in localStorage; otherwise, use sessionStorage

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("user", encryptedUserData);

        // Redirect to the appropriate URL
        setTimeout(() => {
          setLoading(false);
          window.location.href = "/dashboard";
        }, 2000);
      } else if (response.status === 400) {
        // Handle login failure
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );
        toast.error(response.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            borderRadius: "34px",
          },
        });
        setLoading(false);
      }
    } catch (error) {
      // Handle errors
      console.error(error);
      toast.error(error.response?.data?.msg || "An error occurred", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
          borderRadius: "34px",
        },
      });
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <div
          className="flex justify-between items-center hover:underline"
          style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
        >
          <FeatherIcon
            icon="eye"
            onClick={(e) => handleSecertLogin(row?._id)}
            className="cursor-pointer"
          />
          <span
            style={{ marginLeft: "10px" }}
            className="cursor-pointer"
            onClick={(e) => handleSecertLogin(row?._id)}
          >
            {row?.name}
          </span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row?.position,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-2">
          <button
            className="manage-edit-btn text-white px-4 py-2"
            onClick={(e) => handleEditUser(row._id)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-edit-2 "
            >
              <g>
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
              </g>
            </svg>
          </button>
          <button
            className="manage-delete-btn text-white px-3 py-2 "
            onClick={(e) => handleDelete(row._id)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-trash-2 "
              color="red"
            >
              <g>
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </g>
            </svg>
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    async function getusers() {
      try {
        const response = await api.getStaff(User?._id);
        console.log(response);

        if (response.status !== 400) {
          setdata(response);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getusers();
  }, [render]);

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="py-8">
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex justify-between ">
          <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
            Manage User
          </h2>
          <button
            className="manage-add-btn text-white px-3 py-1 Job-Post-btn"
            onClick={handleLoginClick}
          >
            Add User
          </button>
        </div>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Manageuser;
