import React from "react";
import { Header } from "../Layout/Header";
import Footer from "../Layout/Footer";
import logoimage from "../../image/company-logo-transparent-png-19.png";
import { PopupProvider } from "../Common/PopupContext";
const JobPosting = () => {
  return (
    <>
      <PopupProvider>
        <Header />
        <div className="container mx-auto p-4 ">
          <div className="main-JobPosting-div">
            <div class="bg-white px-6  flex  JobPosting-inner-div items-center">
              <div class="flex items-center gap-3 job-section-1">
                <div className=" py-10">
                  <img src={logoimage} alt="Company Logo" class=" " />
                </div>
                <div class="ml-4  py-10">
                  <h2 class="">Jobfuture LLC</h2>
                  <div class="location-inner">
                    <span>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.00014 14.1257L8.4809 13.584C9.02643 12.9592 9.51709 12.3665 9.95366 11.8027L10.314 11.3272C11.8188 9.2998 12.5716 7.69066 12.5716 6.50133C12.5716 3.96266 10.5251 1.90475 8.00014 1.90475C5.47519 1.90475 3.42871 3.96266 3.42871 6.50133C3.42871 7.69066 4.18147 9.2998 5.68623 11.3272L6.04662 11.8027C6.66943 12.6007 7.32103 13.375 8.00014 14.1257Z"
                          stroke="#3E3E3E"
                          strokeWidth="1.14286"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.00047 8.38093C9.05244 8.38093 9.90523 7.52814 9.90523 6.47617C9.90523 5.4242 9.05244 4.57141 8.00047 4.57141C6.94849 4.57141 6.0957 5.4242 6.0957 6.47617C6.0957 7.52814 6.94849 8.38093 8.00047 8.38093Z"
                          stroke="#3E3E3E"
                          strokeWidth="1.14286"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <h1>United States of America</h1>
                  </div>
                  <div className="Salery-heading">
                    <h1>Salery</h1>
                  </div>
                  <div class="salery-inner">
                    <span>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.9493 7.83334H12.1533C11.204 7.83334 10.4993 8.53934 10.4993 9.33334C10.4993 10.1273 11.204 10.8333 12.1527 10.8333H13.9647C14.102 10.8247 14.1613 10.732 14.166 10.676V7.99068C14.1613 7.93468 14.102 7.84201 13.9647 7.83401L13.9493 7.83334ZM13.9 6.83334C13.944 6.83334 13.9867 6.83334 14.0267 6.83601C14.6067 6.87134 15.1207 7.30934 15.164 7.92134C15.1667 7.96134 15.1667 8.00468 15.1667 8.04468V10.622C15.1667 10.662 15.1667 10.7053 15.164 10.7453C15.1207 11.3573 14.6067 11.7953 14.026 11.8313C13.9867 11.8333 13.944 11.8333 13.8993 11.8333H12.154C10.724 11.8333 9.5 10.748 9.5 9.33334C9.5 7.91868 10.724 6.83334 12.1533 6.83334H13.8887H13.9Z"
                          fill="#4B8700"
                        />
                        <path
                          d="M12.6663 9.33332C12.6663 9.51013 12.5961 9.6797 12.4711 9.80473C12.3461 9.92975 12.1765 9.99999 11.9997 9.99999C11.8229 9.99999 11.6533 9.92975 11.5283 9.80473C11.4032 9.6797 11.333 9.51013 11.333 9.33332C11.333 9.15651 11.4032 8.98694 11.5283 8.86192C11.6533 8.73689 11.8229 8.66666 11.9997 8.66666C12.1765 8.66666 12.3461 8.73689 12.4711 8.86192C12.5961 8.98694 12.6663 9.15651 12.6663 9.33332Z"
                          fill="#4B8700"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.8997 6.83332C14.0909 6.82609 14.2813 6.86257 14.4563 6.93999C14.385 5.86932 14.1883 5.04399 13.5723 4.42732C13.073 3.92865 12.4403 3.70732 11.659 3.60199L11.631 3.59865C11.624 3.59336 11.6169 3.58825 11.6097 3.58332L9.11901 1.93199C8.68698 1.65004 8.18223 1.49991 7.66634 1.49991C7.15045 1.49991 6.64571 1.65004 6.21367 1.93199L3.72367 3.58332C3.71621 3.58824 3.70887 3.59335 3.70167 3.59865L3.67367 3.60199C2.89234 3.70732 2.25967 3.92865 1.76034 4.42732C1.26167 4.92665 1.04034 5.55932 0.935008 6.34065C0.833008 7.10065 0.833008 8.07065 0.833008 9.29599V9.37065C0.833008 10.596 0.833008 11.5667 0.935008 12.326C1.04034 13.1073 1.26167 13.74 1.76034 14.2393C2.25967 14.738 2.89234 14.9593 3.67367 15.0647C4.43367 15.1667 5.40367 15.1667 6.62901 15.1667H8.70367C9.92901 15.1667 10.8997 15.1667 11.659 15.0647C12.4403 14.9593 13.073 14.738 13.5723 14.2393C14.1883 13.6227 14.385 12.7973 14.4563 11.726C14.3217 11.786 14.1763 11.822 14.0257 11.8313C13.9863 11.8333 13.9437 11.8333 13.899 11.8333H13.445C13.369 12.728 13.1977 13.2 12.865 13.532C12.583 13.814 12.1963 13.9833 11.5257 14.0733C10.841 14.1653 9.93767 14.1667 8.66634 14.1667H6.66634C5.39501 14.1667 4.49234 14.1653 3.80634 14.0733C3.13634 13.9833 2.74967 13.814 2.46767 13.532C2.18567 13.25 2.01634 12.8633 1.92634 12.1927C1.83434 11.508 1.83301 10.6047 1.83301 9.33332C1.83301 8.06199 1.83434 7.15932 1.92634 6.47332C2.01634 5.80332 2.18567 5.41665 2.46767 5.13465C2.74967 4.85265 3.13634 4.68332 3.80701 4.59332C4.49234 4.50132 5.39501 4.49999 6.66634 4.49999H8.66634C9.93767 4.49999 10.841 4.50132 11.5263 4.59332C12.1963 4.68332 12.583 4.85265 12.865 5.13465C13.1977 5.46665 13.369 5.93932 13.445 6.83332H13.8883H13.8997ZM6.62901 3.49999H8.70367C9.04834 3.49999 9.37234 3.49999 9.67701 3.50199L8.56634 2.76532C8.03301 2.41199 7.29967 2.41199 6.76634 2.76532L5.65501 3.50199C5.96034 3.49999 6.28434 3.49999 6.62834 3.49999"
                          fill="#4B8700"
                        />
                        <path
                          d="M4 6.16666C3.86739 6.16666 3.74021 6.21934 3.64645 6.3131C3.55268 6.40687 3.5 6.53405 3.5 6.66666C3.5 6.79926 3.55268 6.92644 3.64645 7.02021C3.74021 7.11398 3.86739 7.16666 4 7.16666H6.66667C6.79927 7.16666 6.92645 7.11398 7.02022 7.02021C7.11399 6.92644 7.16667 6.79926 7.16667 6.66666C7.16667 6.53405 7.11399 6.40687 7.02022 6.3131C6.92645 6.21934 6.79927 6.16666 6.66667 6.16666H4Z"
                          fill="#4B8700"
                        />
                        <path
                          d="M12.6663 9.33332C12.6663 9.51013 12.5961 9.6797 12.4711 9.80473C12.3461 9.92975 12.1765 9.99999 11.9997 9.99999C11.8229 9.99999 11.6533 9.92975 11.5283 9.80473C11.4032 9.6797 11.333 9.51013 11.333 9.33332C11.333 9.15651 11.4032 8.98694 11.5283 8.86192C11.6533 8.73689 11.8229 8.66666 11.9997 8.66666C12.1765 8.66666 12.3461 8.73689 12.4711 8.86192C12.5961 8.98694 12.6663 9.15651 12.6663 9.33332Z"
                          fill="#4B8700"
                        />
                      </svg>
                    </span>
                    <h1>$150,000 - $200,000/year</h1>
                  </div>
                  <div class=" items-center mt-2 rating-section-outer">
                    <div className="rating-section">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.73366 15L4.46699 12.8667L2.06699 12.3333L2.30033 9.86667L0.666992 8L2.30033 6.13333L2.06699 3.66667L4.46699 3.13333L5.73366 1L8.00033 1.96667L10.267 1L11.5337 3.13333L13.9337 3.66667L13.7003 6.13333L15.3337 8L13.7003 9.86667L13.9337 12.3333L11.5337 12.8667L10.267 15L8.00033 14.0333L5.73366 15ZM7.30032 10.3667L11.067 6.6L10.1337 5.63333L7.30032 8.46667L5.86699 7.06667L4.93366 8L7.30032 10.3667Z"
                          fill="#4B8700"
                        />
                      </svg>
                      <span class="text-gray-500 ml-2">Ratings</span>
                    </div>
                    <span class="text-yellow-400 pl-7 Ratings-star">★★★★☆</span>
                  </div>
                </div>
              </div>
              <div class="text-gray-600 px-7 Experience-section  job-section-2 ">
                <div>
                  <p class="job-section-heading">Job Type:</p>
                  <div className="job-section-data">
                    <p>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.66634 14C2.29967 14 1.9859 13.8696 1.72501 13.6087C1.46412 13.3478 1.33345 13.0338 1.33301 12.6667V10H5.99967V11.3333H9.99967V10H14.6663V12.6667C14.6663 13.0333 14.5359 13.3473 14.275 13.6087C14.0141 13.87 13.7001 14.0005 13.333 14H2.66634ZM7.33301 10V8.66668H8.66634V10H7.33301ZM1.33301 8.66668V5.33334C1.33301 4.96668 1.46367 4.6529 1.72501 4.39201C1.98634 4.13112 2.30012 4.00045 2.66634 4.00001H5.33301V2.66668C5.33301 2.30001 5.46368 1.98623 5.72501 1.72534C5.98634 1.46445 6.30012 1.33379 6.66634 1.33334H9.33301C9.69967 1.33334 10.0137 1.46401 10.275 1.72534C10.5363 1.98668 10.6668 2.30045 10.6663 2.66668V4.00001H13.333C13.6997 4.00001 14.0137 4.13068 14.275 4.39201C14.5363 4.65334 14.6668 4.96712 14.6663 5.33334V8.66668H9.99967V7.33334H5.99967V8.66668H1.33301ZM6.66634 4.00001H9.33301V2.66668H6.66634V4.00001Z"
                          fill="#3E3E3E"
                        />
                      </svg>
                    </p>
                    <h1>Fulltime</h1>
                  </div>
                  <p class=" mt-2 job-section-heading">Experience:</p>
                  <div className="job-section-data">
                    <p>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.833 1.83334H3.16634C2.42996 1.83334 1.83301 2.4303 1.83301 3.16668V12.8333C1.83301 13.5697 2.42996 14.1667 3.16634 14.1667H12.833C13.5694 14.1667 14.1663 13.5697 14.1663 12.8333V3.16668C14.1663 2.4303 13.5694 1.83334 12.833 1.83334Z"
                          stroke="#3E3E3E"
                          strokeWidth="0.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.917 6.49999C10.5413 6.49999 10.1809 6.35073 9.91526 6.08506C9.64958 5.81938 9.50033 5.45905 9.50033 5.08332C9.50033 4.7076 9.35107 4.34726 9.08539 4.08159C8.81972 3.81591 8.45938 3.66666 8.08366 3.66666H7.91699C7.54127 3.66666 7.18093 3.81591 6.91526 4.08159C6.64958 4.34726 6.50033 4.7076 6.50033 5.08332C6.50033 5.45905 6.35107 5.81938 6.08539 6.08506C5.81972 6.35073 5.45938 6.49999 5.08366 6.49999C4.70794 6.49999 4.3476 6.64925 4.08192 6.91492C3.81625 7.1806 3.66699 7.54093 3.66699 7.91666V8.08332C3.66699 8.45905 3.81625 8.81938 4.08192 9.08506C4.3476 9.35073 4.70794 9.49999 5.08366 9.49999C5.45938 9.49999 5.81972 9.64925 6.08539 9.91492C6.35107 10.1806 6.50033 10.5409 6.50033 10.9167C6.50033 11.2924 6.64958 11.6527 6.91526 11.9184C7.18093 12.1841 7.54127 12.3333 7.91699 12.3333H8.08366C8.45938 12.3333 8.81972 12.1841 9.08539 11.9184C9.35107 11.6527 9.50033 11.2924 9.50033 10.9167C9.50033 10.5409 9.64958 10.1806 9.91526 9.91492C10.1809 9.64925 10.5413 9.49999 10.917 9.49999C11.2927 9.49999 11.6531 9.35073 11.9187 9.08506C12.1844 8.81938 12.3337 8.45905 12.3337 8.08332V7.91666C12.3337 7.54093 12.1844 7.1806 11.9187 6.91492C11.6531 6.64925 11.2927 6.49999 10.917 6.49999Z"
                          stroke="#3E3E3E"
                          strokeWidth="0.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.1231 9.00166C10.8575 8.73599 10.7082 8.37568 10.7082 7.99999C10.7082 7.6243 10.8575 7.264 11.1231 6.99833C11.3887 6.73265 11.538 6.37235 11.538 5.99666C11.538 5.62097 11.3887 5.26066 11.1231 4.99499L11.0051 4.87699C10.7394 4.61136 10.3791 4.46213 10.0034 4.46213C9.62775 4.46213 9.26745 4.61136 9.00178 4.87699C8.87022 5.00857 8.71404 5.11295 8.54214 5.18416C8.37025 5.25537 8.18601 5.29202 7.99994 5.29202C7.81388 5.29202 7.62964 5.25537 7.45775 5.18416C7.28585 5.11295 7.12966 5.00857 6.99811 4.87699C6.73244 4.61136 6.37213 4.46213 5.99644 4.46213C5.62075 4.46213 5.26045 4.61136 4.99478 4.87699L4.87678 4.99466C4.61114 5.26033 4.46191 5.62064 4.46191 5.99633C4.46191 6.37202 4.61114 6.73232 4.87678 6.99799C5.00836 7.12954 5.11273 7.28573 5.18394 7.45763C5.25516 7.62952 5.29181 7.81376 5.29181 7.99983C5.29181 8.18589 5.25516 8.37013 5.18394 8.54202C5.11273 8.71392 5.00836 8.8701 4.87678 9.00166C4.61114 9.26733 4.46191 9.62764 4.46191 10.0033C4.46191 10.379 4.61114 10.7393 4.87678 11.005L4.99444 11.123C5.26012 11.3886 5.62042 11.5379 5.99611 11.5379C6.3718 11.5379 6.73211 11.3886 6.99778 11.123C7.12933 10.9914 7.28552 10.887 7.45741 10.8158C7.62931 10.7446 7.81355 10.708 7.99961 10.708C8.18567 10.708 8.36991 10.7446 8.54181 10.8158C8.71371 10.887 8.86989 10.9914 9.00144 11.123C9.26712 11.3886 9.62742 11.5379 10.0031 11.5379C10.3788 11.5379 10.7391 11.3886 11.0048 11.123L11.1228 11.0053C11.3884 10.7397 11.5376 10.3793 11.5376 10.0037C11.5376 9.62797 11.3884 9.26766 11.1228 9.00199"
                          stroke="#3E3E3E"
                          strokeWidth="0.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </p>
                    <h1>2 Year</h1>
                  </div>
                  <p class=" mt-2 job-section-heading">Proposal:</p>
                  <div className="job-section-data">
                    <p>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.33301 12H8.66634V10.6667H7.33301V12ZM7.99967 1.33334C7.1242 1.33334 6.25729 1.50578 5.44845 1.84081C4.63961 2.17584 3.90469 2.66691 3.28563 3.28596C2.03539 4.53621 1.33301 6.2319 1.33301 8.00001C1.33301 9.76812 2.03539 11.4638 3.28563 12.7141C3.90469 13.3331 4.63961 13.8242 5.44845 14.1592C6.25729 14.4942 7.1242 14.6667 7.99967 14.6667C9.76778 14.6667 11.4635 13.9643 12.7137 12.7141C13.964 11.4638 14.6663 9.76812 14.6663 8.00001C14.6663 7.12453 14.4939 6.25762 14.1589 5.44879C13.8238 4.63995 13.3328 3.90502 12.7137 3.28596C12.0947 2.66691 11.3597 2.17584 10.5509 1.84081C9.74206 1.50578 8.87515 1.33334 7.99967 1.33334ZM7.99967 13.3333C5.05967 13.3333 2.66634 10.94 2.66634 8.00001C2.66634 5.06001 5.05967 2.66668 7.99967 2.66668C10.9397 2.66668 13.333 5.06001 13.333 8.00001C13.333 10.94 10.9397 13.3333 7.99967 13.3333ZM7.99967 4.00001C7.29243 4.00001 6.61415 4.28096 6.11406 4.78106C5.61396 5.28116 5.33301 5.95943 5.33301 6.66668H6.66634C6.66634 6.31305 6.80682 5.97392 7.05687 5.72387C7.30691 5.47382 7.64605 5.33334 7.99967 5.33334C8.3533 5.33334 8.69243 5.47382 8.94248 5.72387C9.19253 5.97392 9.33301 6.31305 9.33301 6.66668C9.33301 8.00001 7.33301 7.83334 7.33301 10H8.66634C8.66634 8.50001 10.6663 8.33334 10.6663 6.66668C10.6663 5.95943 10.3854 5.28116 9.88529 4.78106C9.38519 4.28096 8.70692 4.00001 7.99967 4.00001Z"
                          fill="#4B8700"
                        />
                      </svg>
                    </p>
                    <h1>Less Then 5</h1>
                  </div>
                </div>
              </div>
              <div class="text-gray-600  px-7 Experience-section job-section-3">
                <p class="Expertise-inner">Skills and Expertise:</p>
                <div class="flex ">
                  <span class="">Adobe Photoshop</span>
                  <span class="">Graphic Design</span>
                </div>
                <div className="flex">
                  <span class="">Adobe Illustrator</span>
                  <span class="">Logo Design</span>
                  <span class="">Illustration</span>
                </div>
                <p class=" mt-2 Expertise-inner">Expertise:</p>
                <div className="Expertise-inner-h">
                  <p>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_3_1695)">
                        <path
                          d="M4.66634 6.41668C5.43989 6.41668 6.18176 6.10939 6.72874 5.56241C7.27572 5.01542 7.58301 4.27356 7.58301 3.50001C7.58301 2.72646 7.27572 1.9846 6.72874 1.43762C6.18176 0.890634 5.43989 0.583344 4.66634 0.583344C3.89279 0.583344 3.15093 0.890634 2.60395 1.43762C2.05697 1.9846 1.74967 2.72646 1.74967 3.50001C1.74967 4.27356 2.05697 5.01542 2.60395 5.56241C3.15093 6.10939 3.89279 6.41668 4.66634 6.41668ZM4.66634 6.41668C5.84059 6.41668 6.86667 6.86001 7.59642 7.59676C8.02908 8.03542 8.35276 8.56948 8.54142 9.15601M4.66634 6.41668C2.33301 6.41668 0.583008 8.16668 0.583008 10.5V13.4167H6.41634M6.99967 10.9807L9.44384 12.8333L13.4163 7.58334"
                          stroke="#3E3E3E"
                          strokeWidth="2"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3_1695">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </p>
                  <p> Intermediate</p>
                </div>
              </div>
              <div class="flex flex-col items-end gap-4 job-section-4">
                <button class="this-position-btn ">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1 18.55L12 18.65L11.89 18.55C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5C9.04 5 10.54 6 11.07 7.36H12.93C13.46 6 14.96 5 16.5 5C18.5 5 20 6.5 20 8.5C20 11.39 16.86 14.24 12.1 18.55ZM16.5 3C14.76 3 13.09 3.81 12 5.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5C2 12.27 5.4 15.36 10.55 20.03L12 21.35L13.45 20.03C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3Z"
                      fill="#003366"
                    />
                  </svg>
                  Save Job
                </button>
                <button class=" this-position-btn-2 ">
                  Apply for this position
                </button>
              </div>
            </div>
            <section className="bg-white p-6 mb-6 About-Company-section">
              <h2 className="About-Company">About Company</h2>
              <p className="">
                Our client has developed a one-stop blockchain data platform to
                make on-chain and off-chain data indexing, querying, and sharing
                extremely fast, reliable, flexible, etc... We help developers
                build real-time, reliable, data-intensive apps in minutes — all
                without worrying about data processing in their frontends or
                backends! This is done through ready-to-use, flexible, and
                customizable APIs.
              </p>

              <h2 className="Job-Description">Job Description</h2>
              <p className="">
                We’re looking for an early Backend Engineer to join us. We store
                and index a massive amount of data, and need to make the storage
                layer efficient and the query engine fast. This role will
                interact with widely used big data open-source projects. This
                role will design, implement, optimize, and maintain the backend
                to accommodate large-scale data and queries.
              </p>
            </section>

            <section className="bg-white p-6 mb-6 rounded-lg shadow-md">
              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">Skills</h3>
                <ul className="list-disc list-inside">
                  <li>Skill 1</li>
                  <li>Skill 2</li>
                  <li>Skill 3</li>
                  <li>Skill 4</li>
                </ul>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-2">Details</h3>
                <ul className="list-disc list-inside">
                  <li>Detail 1</li>
                  <li>Detail 2</li>
                  <li>Detail 3</li>
                  <li>Detail 4</li>
                </ul>
              </div>
            </section>

            <section className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-2">
                Subscribe to Our Newsletter!
              </h3>
              <p>Get latest updates and information.</p>
              <form className="flex mt-4">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="flex-grow p-2 border border-gray-300 rounded-l"
                />
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-r hover:bg-blue-700"
                >
                  Subscribe
                </button>
              </form>
            </section>
          </div>
        </div>
        <Footer />
      </PopupProvider>
    </>
  );
};

export default JobPosting;
