import React, { useState, useEffect } from "react";
import api from "../../Api";
import { usePopup } from "../Common/PopupContext";
import { getUserData } from "../../helpers/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SitemapPopup = ({ onClose, data }) => {
  // --------------- profile card end -----------------------------------------------------
  const [loading, setLoading] = useState(false);
  const [uploaddata, setuploaddata] = useState("");
  const [Data, setdata] = useState();
  useEffect(() => {
    setdata(data);
  }, [data]);
  var domainpath = process.env.REACT_APP_DOMAIN;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the data to the API
      const response = await api.updateSiteMap({ xmlContent: data });

      if (response.status === 200) {
        toast.success("Sitemap updated successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          onClose("");
        }, 1200);
      } else {
        toast.error("Failed to update sitemap", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error submitting sitemap:", error);
      toast.error("An error occurred while updating the sitemap", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleChange = (e) => {
    setdata(e.target.value); // Update the `data` state with the new content
  };
  return (
    <div className="flex justify-center h-screen  login_inform-inner m-auto p-20 job-page">
      <div className="flex ">
        <div className="form_upto  jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <form
              className="register-form-section animated-form"
              onSubmit={handleSubmit}
            >
              <div className="animated-form profileheadlinediv">
                <div className="form-group">
                  <textarea
                    name="json"
                    placeholder=""
                    value={Data}
                    onChange={handleChange}
                    className="border rounded w-full py-2 px-3 font-mono bg-black text-white custom-scrollbar"
                    id="json"
                    rows={25}
                    style={{
                      whiteSpace: "pre",
                      overflowWrap: "normal",
                      color: "white", // Ensures text color is white
                      fontSize: "16px", // Adjusts font size for better readability
                      lineHeight: "1.5", // Adds better spacing between lines for code-like look
                    }}
                  ></textarea>
                  <label
                    htmlFor="json"
                    className="profileheadline description-label text-white"
                  >
                    Sitemap
                  </label>
                </div>
              </div>
              <div className="flex justify-between gap-3 mt-5">
                <button
                  className="hrp-find-course-btn px-8 py-2 rounded-full"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <button
          className="  text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6	 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};
export default SitemapPopup;
