import React, { useState, useEffect } from "react";

import api from "../../Api";
import { useHistory } from "react-router-dom";

const Exchange = () => {
  const history = useHistory();
  const [amount, setAmount] = useState("100");
  const [quantity, setQuantity] = useState("1");
  const [currency, setCurrency] = useState("");
  const [tranactionType, setTranactionType] = useState("Buy");
  const [walletData, setWalletData] = useState({});
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    // Load transactions and wallet balance on page load
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await api?.getTransaction();
      var sortedKeys = Object.keys(response).sort();
      var sortedData = {};
      sortedKeys.forEach(function (key) {
        sortedData[key] = response[key];
      });
      setWalletData(sortedData);
    } catch (error) {
      console.error(error);
    }
  };
  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();
    history.push("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // try {

    //   const response = await api.createTransaction(userData);
    //   await fetchData();
    //   // Handle success, maybe update transactions or wallet balance
    // } catch (error) {
    //   console.error(error);
    //   // Handle error
    // }
  };

  return (
    <div className="container">
      <div className="logout-button">
        <button onClick={handleLogout}>Logout</button>
      </div>

      <div className="content">test </div>
    </div>
  );
};

export default Exchange;
