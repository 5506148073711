import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FeatherIcon from "feather-icons-react";
import api from "../../Api";
import Select2 from "react-select2-wrapper";
import { getUserData } from "../../helpers/utils";
import { useHistory } from "react-router-dom";
const domainpath = process.env.REACT_APP_DOMAIN;

const ApplicationList = () => {
  const User = getUserData();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Store filtered data
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [statusCounts, setStatusCounts] = useState({}); // Store status counts
  const status = [
    { id: "Reviewed", text: "Reviewed" },
    { id: "Applying", text: "Applying" },
    { id: "In Review", text: "In Review" },
    { id: "Rejected", text: "Reject" },
    { id: "Shortlisted", text: "Shortlisted" },
    { id: "Applied", text: "Awaiting Review" },
    { id: "Interviewed", text: "Interviewed" },
    { id: "Interview Scheduled", text: "Interview Scheduled" },
    { id: "Offered", text: "Offered" },
    { id: "Hired", text: "Hired" },
  ];
  const statuses = [
    "All",
    "Applied",
    "Reviewed",
    "Shortlisted",
    "Interview Scheduled",
    "Interviewed",
    "In Review",
    "Offered",
    "Hired",
    "Rejected",
  ];

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await api.getAllApplication();
        if (response.status === 200) {
          setData(response?.applications);
          setFilteredData(response?.applications); // Set initial filtered data
          calculateStatusCounts(response?.applications); // Calculate counts initially
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  // UseEffect to filter data when selectedStatus changes
  useEffect(() => {
    filterDataByStatus(selectedStatus, data); // Filter data by the selected status
  }, [selectedStatus, data]);

  // Function to filter data by status
  const filterDataByStatus = (selectedStatus, data) => {
    if (selectedStatus === "All") {
      setFilteredData(data); // If 'All', show all data
    } else {
      const filtered = data.filter(
        (application) => application.status === selectedStatus
      );
      setFilteredData(filtered); // Set filtered data based on status
    }
  };

  // Function to calculate status counts
  const calculateStatusCounts = (applications) => {
    const counts = {
      All: applications.length,
    };
    statuses.slice(1).forEach((status) => {
      counts[status] = applications.filter(
        (application) => application.status === status
      ).length;
    });
    setStatusCounts(counts);
  };

  const handleUpdateApplicationStatus = async (e, id) => {
    e.preventDefault();
    var formdata = {
      status: e.target.value,
      userId: User?._id,
    };
    try {
      const res = await api.PostApplicationStatus(id, formdata);
      if (res.status == 200) {
        toast.success(res.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom-toast",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "custom-toast",
      });
    }
  };

  const columns = [
    {
      name: "Job Title",
      selector: (row) => (
        <a
          href={`${domainpath}/jobs/${row?.jobId?._id}`}
          className=" hover:underline"
          style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
        >
          {row?.jobId?.title}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Applicant Name",
      selector: (row) => row?.applicantId?.name,
      sortable: true,
    },

    {
      name: "Email",
      selector: (row) => row?.applicantId?.email,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className="relative job-search-label-section singleselect2 statusenroll"
          style={{ width: "100%", textAlign: "center" }}
        >
          <Select2
            data={status}
            className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
            defaultValue={row.status}
            onSelect={(e) => handleUpdateApplicationStatus(e, row._id)}
            options={{
              placeholder: "Select Status",
              width: "100%",
              theme: "classic",
            }}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-1">
          <button
            className=" ManageApplication-View px-3 py-2 text-white "
            onClick={() =>
              history.push(`/dashboard/application/${row?._id}`, row?.profileId)
            }
          >
            View Application
          </button>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: filteredData, // Use filtered data for the table
  };

  return (
    <div className="p-8">
      <h2 className="text-xl font-bold mb-4" style={{ color: "#003366" }}>
        Manage Applications
      </h2>
      <div className="bg-white p-4 rounded-lg shadow">
        {/* Scrollable container for statuses */}
        <div
          className="flex gap-2 mb-4 overflow-x-auto custom-scrollbar"
          style={{ whiteSpace: "nowrap", paddingBottom: "10px" }}
        >
          {statuses.map((status) => (
            <>
              <button
                key={status}
                className={`mt-2 px-4 py-2 hrp-ticket-btn ${
                  selectedStatus === status ? "activebtn" : "inactive"
                } text-white`}
                onClick={() => setSelectedStatus(status)}
              >
                {status} ({statusCounts[status] || 0})
              </button>
            </>
          ))}
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={filteredData} // Use filtered data here
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ApplicationList;
