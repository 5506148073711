import React, { useState, useEffect } from "react";
import UploadCVimage from "../../image/bg_upload_CV.png";
import api from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePopup } from "../Common/PopupContext";
import ApplicationSucess from "../popup/ApplicationSuccess";
const UploadCV = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cv, setCv] = useState(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const { openPopup } = usePopup();
  const [popupComponent, setPopupComponent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  var domainpath = process.env.REACT_APP_DOMAIN;
  const handleClosePopup = (message) => {
    console.log(message);
    setPopupComponent(null);
    openPopup();
  };

  useEffect(() => {
    // if (!document.getElementById("my-custom-script")) {
    //   const script = document.createElement("script");
    //   script.id = "my-custom-script"; // Give the script an ID to identify it
    //   script.type = "text/javascript";
    //   script.src =
    //     "data:text/javascript;base64,LyogQWxsaSBBSSB3aWRnZXQgZm9yIHd3dy5oaXJlcHJvcy5jby51ayAqLwooZnVuY3Rpb24gKHcsZCxzLG8sZixqcyxmanMpIHt3WydBbGxpSlNXaWRnZXQnXT1vO3dbb10gPSB3W29dIHx8IGZ1bmN0aW9uICgpIHsgKHdbb10ucSA9IHdbb10ucSB8fCBbXSkucHVzaChhcmd1bWVudHMpIH07anMgPSBkLmNyZWF0ZUVsZW1lbnQocyksIGZqcyA9IGQuZ2V0RWxlbWVudHNCeVRhZ05hbWUocylbMF07anMuaWQgPSBvOyBqcy5zcmMgPSBmOyBqcy5hc3luYyA9IDE7IGZqcy5wYXJlbnROb2RlLmluc2VydEJlZm9yZShqcywgZmpzKTt9KHdpbmRvdywgZG9jdW1lbnQsICdzY3JpcHQnLCAnYWxsaScsICdodHRwczovL3N0YXRpYy5hbGxpYWkuY29tL3dpZGdldC92MS5qcycpKTthbGxpKCdpbml0JywgJ3NpdGVfV3RkekJOTUs2aUVORGN0eScpO2FsbGkoJ29wdGltaXplJywgJ2FsbCcpOw==";
    //   script.async = true;

    //   // Add a console log to verify the script is running
    //   script.onload = () => console.log("Script loaded and running");
    //   document.head.appendChild(script);
    // }
  }, []);
  const handleCVUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("resume", e.target.files[0]);
    try {
      const res = await api.uploadcv(formData);
      if (res != 500) {
        res.joblink = domainpath;
        res.joblink = domainpath;
        const semd_email = await api.simpleupload(res);
        setLoading(false);
        const popup = (
          <ApplicationSucess
            onClose={() => handleClosePopup("Popup closed")}
            profileid={res?.newProfile?._id}
          />
        );
        setPopupComponent(popup);
        openPopup(popup);
        setUploadedFileName(e.target.files[0].name);
      }
      setResponse(res.data);
      setLoading(false);
      setError(null);
    } catch (err) {
      setError(err.response ? err.response.data : err.message);
      setResponse(null);
      setLoading(false);
      toast.error(err.response ? err.response.data : err.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // className: "custom-toast",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };
  return (
    <>
      <div className="upload-cv-main-outer section-1-uploade-cv">
        <div className="container m-auto ">
          <section className="sub-section-1-uploade-cv">
            <div className="UploadCV-border ">
              <div className=" md:flex items-center upload-cv-main justify-center p-10">
                <div className="upload-cv-section md:w-1/2">
                  <div className="upload-cv-text">
                    <h1> Upload Your CV </h1>
                    <h2>
                      Easily upload your CV to get started. Providing your CV
                      helps us recommend suitable job opportunities and
                      streamline your job application process.
                    </h2>
                  </div>
                  <div className="upload-section">
                    <input
                      type="file"
                      id="file-upload"
                      hidden
                      accept=".pdf, .docx"
                      onChange={handleCVUpload}
                    />
                    <label htmlFor="file-upload" className="upload-label">
                      Upload CV
                    </label>

                    {loading && <p>Uploading...</p>}
                    {uploadedFileName && !loading && (
                      <p className="upload-success-message">
                        Uploaded: {uploadedFileName}
                      </p>
                    )}
                    {error && !loading && (
                      <p className="upload-error-message">Error: {error}</p>
                    )}

                    <p className="supported-formats">
                      Supported Formats: doc, docx, rtf, pdf, up to 2 MB
                    </p>
                  </div>
                </div>
                <div className="md:w-1/2 UploadCVimage-section">
                  <img className="" src={UploadCVimage}></img>
                </div>
              </div>
            </div>
          </section>
          <section className="sub-section-2-uploade-cv">
            <div className="container m-auto">
              <div className="upload-cv-main py-14 px-10">
                <div className="flex  grid md:grid-cols-3 md:grid-cols-1  md:gap-5 ">
                  <div className="UploadCV-card">
                    <div className="UploadCV-circle">1</div>
                    <div className="UploadCV-content">
                      <h2>Upload Your CV</h2>
                      <p>
                        Start by uploading your most recent CV. This helps us
                        understand your qualifications and experience.
                      </p>
                    </div>
                  </div>
                  <div className="UploadCV-card">
                    <div className="UploadCV-circle">2</div>
                    <div className="UploadCV-content">
                      <h2>Build Your Profile</h2>
                      <p>
                        Create a detailed profile by filling in your personal
                        information, skills, and career preferences to stand out
                        to potential employers.
                      </p>
                    </div>
                  </div>
                  <div className="UploadCV-card">
                    <div className="UploadCV-circle">3</div>
                    <div className="UploadCV-content">
                      <h2> Apply for Job Matches</h2>
                      <p>
                        Based on your profile, we match you with suitable job
                        opportunities. Review and apply to the jobs that best
                        fit your career goals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {loading && (
          <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
            <span className="RoadmapSpinner"></span>
          </div>
        )}
        <ToastContainer />
      </div>
    </>
  );
};
export default UploadCV;
