import React, { useState, useEffect } from "react";
import Courselogo from "../../image/courselogo.png";
import api from "../../Api";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const clientdomainpath = process.env.REACT_APP_DOMAIN;

const ManageCourses = () => {
  const [data, setData] = useState([]); // Single state for storing course data
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const coursesPerPage = 6;

  // Fetch courses from the API
  useEffect(() => {
    async function getCourses() {
      try {
        const response = await api.getCourses();
        if (response.status !== 400) {
          setData(response); // Assuming `response.data` contains the courses
        } else {
          console.error("Failed to fetch courses");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    }
    getCourses();
  }, []);

  // Filter courses based on the search query
  const filteredCourses = data.filter((course) =>
    course.courseTitle.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = filteredCourses.slice(
    indexOfFirstCourse,
    indexOfLastCourse
  );

  const totalPages = Math.ceil(filteredCourses.length / coursesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="container mx-auto p-4 hrp-manage-course">
      <h1 className="text-xl font-bold mb-4" style={{ color: "#003366" }}>
        Manage Courses
      </h1>

      <div className=" justify-between  hrp-button-mobile items-center md:flex gap-2 mb-4">
        <div className="relative w-full">
          <input
            type="text"
            placeholder="Search Course"
            className="border w-full border-gray-300 rounded-full p-3  focus:outline-none "
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} // Updates search query
          />
          <button className=" absolute right-1 hrp-find-course-btn top-1  bottom-1 px-4 py-2 rounded-full">
            Search
          </button>
        </div>

        <div className="flex space-x-2 md:w-52  md:mt-0 mt-2">
          <a href="/dashboard/courses/add">
            <button className="hrp-find-course-btn px-4 py-2 rounded-full">
              + Add Course
            </button>
          </a>
        </div>
      </div>

      {/* Conditionally render if there are no courses */}
      {filteredCourses.length === 0 ? (
        <div className="text-center mt-4">
          <p className="text-lg font-semibold" style={{ color: "#003366" }}>
            No courses found
          </p>
        </div>
      ) : (
        <div className="grid lg:grid-cols-3 gap-4 md:grid-cols-2 grid-cols-1 ">
          {currentCourses.map((course, index) => (
            <div
              key={index}
              className="bg-white  p-4 w-full hrp-cours-single-card"
            >
              <div className="items-start justify-between">
                <div className="hrp-course-card-logo">
                  <a href={clientdomainpath + "/courses/" + course._id}>
                    <img
                      className="imgse renderCategoryEdit text uploadImage-logo"
                      src={
                        course.courseThumbnail &&
                        course.courseThumbnail.trim() !== "" &&
                        course.courseThumbnail !== "NA"
                          ? course.courseThumbnail.startsWith("http://") ||
                            course.courseThumbnail.startsWith("https://")
                            ? course.courseThumbnail
                            : course.courseThumbnail.startsWith("/uploads/")
                            ? `${domainpath}${course.courseThumbnail}`
                            : `${domainpath}/uploads/${course.courseThumbnail}`
                          : `${domainpath}/uploads/defaultblog.png` // Use default if the logo is not present
                      }
                      alt="Course"
                      onError={(e) => {
                        e.target.onerror = null; // Prevents infinite loop
                        e.target.src = `${domainpath}/uploads/defaultblog.png`; // Use the default image on error
                      }}
                    />
                  </a>
                </div>
                <div>
                  <div className="flex">
                    <div className="hrp-card-descreption">
                      <a
                        href={clientdomainpath + "/courses/" + course._id}
                        className="  hover:underline"
                      >
                        <h3 className="my-2" style={{ color: "#003366" }}>
                          {course.courseTitle}
                        </h3>
                      </a>
                      <p className="">{course.courseDescription}</p>
                    </div>
                    <div className="">
                      <button className="text-gray-500">
                        <svg
                          className="my-1 "
                          width="4"
                          height="4"
                          viewBox="0 0 4 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="2" cy="2" r="2" fill="#3E3E3E" />
                        </svg>
                        <svg
                          className=""
                          width="4"
                          height="4"
                          viewBox="0 0 4 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="2" cy="2" r="2" fill="#3E3E3E" />
                        </svg>
                        <svg
                          className="my-1 "
                          width="4"
                          height="4"
                          viewBox="0 0 4 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="2" cy="2" r="2" fill="#3E3E3E" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="flex justify-between mt-4 ">
                    <div className="hrp-card-tittle">
                      <div className="mt-4 flex items-center">
                        <span className="text-lg font-bold hrp-course-card-price">
                          £{course.discountPrice.toFixed(2)}
                        </span>
                        <span className="ml-2 text-sm line-through hrp-course-card-originalPrice">
                          £{course.price.toFixed(2)}
                        </span>
                      </div>
                      <div className="flex flex-wrap	">
                        {course.courseLevel &&
                          course.courseLevel.map((level, index) => (
                            <p
                              key={index}
                              className="border-t-2 rounded-full px-2 py-1 m-1"
                              style={{
                                color: "#003366",
                                border: "1px solid #003366",
                                fontSize: "13px",
                              }}
                            >
                              {level}
                            </p>
                          ))}
                      </div>
                    </div>
                    <div className="flex items-end">
                      <a href={`/dashboard/courses/edit/${course?._id}`}>
                        <button className=" hrp-find-course-btn px-6 py-1 rounded-full">
                          Edit
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="flex justify-between mt-4">
        <button
          onClick={handlePrevPage}
          className={`px-4 py-2 rounded-full ${
            currentPage === 1
              ? " hrp-find-course-btn cursor-not-allowed"
              : "hrp-find-course-btn "
          }`}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <nav className="inline-flex space-x-2">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              className={`px-2 py-1 rounded-full next-preview-btn-rounded ${
                currentPage === index + 1
                  ? "next-preview-btn-active"
                  : "next-preview-btn"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </nav>
        <button
          onClick={handleNextPage}
          className={`px-4 py-1 rounded-full ${
            currentPage === totalPages
              ? "hrp-find-course-btn cursor-not-allowed"
              : "hrp-find-course-btn"
          }`}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ManageCourses;
