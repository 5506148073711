import React, { useState, useEffect } from "react";
import api from "../../Api";
import { usePopup } from "../Common/PopupContext";
import { NavLink, useHistory } from "react-router-dom";
import { encryptData, decryptData } from "../../helpers/encryption";
import { ToastContainer, toast } from "react-toastify";
import sellersignupimg from "../../image/sellersignupimg.png";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Login";
const SignUp = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setIsMessage] = useState(null);
  const [role, setSelectedRole] = useState("applicant");
  const [validationMessage, setValidationMessage] = useState("");
  const { openPopup } = usePopup();
  const history = useHistory();
  const [isPopupOpen, setIsPopupOpen] = useState([]);
  const [popupComponent, setPopupComponent] = useState(null);

  const handleClosePopup = () => {
    console.log();
    setPopupComponent(null);
    openPopup();
  };
  const handleChange = (e) => {
    const value = e.target.value;

    // Allow only numeric values and limit to 10 digits
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (numericValue.length <= 10) {
      setPhone(numericValue); // Update state with numeric value up to 10 digits
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    if (!name) {
      setValidationMessage("Name is required.");
      return;
    }

    if (!email) {
      setValidationMessage("Email is required.");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setValidationMessage("Email is invalid.");
      return;
    }

    if (!phone) {
      setValidationMessage("Phone number is required.");
      return;
    }

    if (!password) {
      setValidationMessage("Password is required.");
      return;
    }

    if (password !== confirmPassword) {
      setValidationMessage("Passwords do not match.");
      return;
    }

    try {
      setLoading(true);
      const userData = { name, email, password, phone };
      let response; // Declare response with let so it can be reassigned

      response = await api.sellerSignup(userData);

      if (response.status !== 400) {
        toast.success("User Created Successfully", {
          position: "top-right",
          autoClose: 900,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            borderRadius: "34px",
            backgroundColor: "#ffa500",
            color: "#fff",
          },
        });

        setLoading(false);
        setTimeout(() => {
          history.push(`./login`);
        }, 1000);

        // Redirect or perform other actions as needed
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      console.error(error);
    }
  };
  const socialLogin = async (e) => {
    e.preventDefault();
    try {
      window.location.href = `${api.socialLogin}?role=${role}`;
    } catch (error) {
      console.error(error);
    }
  };
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };

  const handleClick = () => {
    const popup = <Login onClose={() => handleClosePopup("Popup closed")} />;
    setPopupComponent(popup);
    openPopup(popup);
  };
  return (
    <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen bg-gray-50">
      {/* Left Side - Illustration */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        {/* Plant illustration */}
        <div className=" ">
          <img src={sellersignupimg} alt="Plant" className="" />
        </div>
      </div>

      {/* Right Side - Form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        <div className="flex justify-center hrp-register-section items-center login_inform-inner">
          <div className="w-full">
            <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
              <div className="mb-1 text-center py-5 HirePros-login-heading">
                <h2 className="">Create An Account</h2>
              </div>
              <form
                onSubmit={handleRegisterSubmit}
                className="register-form-section animated-form"
              >
                <div className="form-group">
                  <input
                    type="text"
                    id="fname"
                    placeholder=""
                    onChange={(e) => setName(e.target.value)}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="fname" className="form-input-label">
                    Name
                  </label>
                </div>

                <div className={`mb-2 ${"md:grid md:grid-cols-2 md:gap-x-5"}`}>
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder=""
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label htmlFor="email" className="form-input-label">
                      Email
                    </label>
                  </div>
                  <div className={`form-group`}>
                    <input
                      type="number"
                      id="phone"
                      name="phone"
                      required
                      value={phone}
                      maxLength={10} // Setting the maximum length to 10 digits
                      min={1000000000} // Minimum 10 digits
                      max={9999999999}
                      onChange={(e) => handleChange(e)}
                      placeholder=""
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label htmlFor="phone" className="form-input-label">
                      Phone
                    </label>
                  </div>
                  {/* Password and Confirm Password in one row with two columns */}
                  <div className={`form-group ${"col-span-1"}`}>
                    <input
                      type="password"
                      id="password"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder=""
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label htmlFor="password" className="form-input-label">
                      Password
                    </label>
                  </div>

                  <div className={`form-group ${"col-span-1"}`}>
                    <input
                      id="cpassword"
                      type="password"
                      placeholder=""
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label htmlFor="cpassword" className="form-input-label">
                      Confirm Password
                    </label>
                  </div>
                </div>

                {error && (
                  <p className="text-red-500 text-xs italic">{error}</p>
                )}
                <div className=" register-promotional-emails mb-6">
                  <input type="checkbox" name="terms" required />
                  <div className="terms-policy">
                    I agree <a href=""> Terms condation </a> &{" "}
                    <a href=""> Privacy Policy</a>
                  </div>
                </div>
                <div className="Forgot_00">
                  <div className="">
                    <button
                      className="  text-white py-2  px-4 rounded w-full  register-form-btn"
                      type="submit"
                    >
                      Register
                    </button>
                  </div>
                  <div className="line-after-login my-2">
                    <div className="line-after-login-inner">
                      <div className="line-after-login-first"></div>
                      <div className="line-after-login-or">or</div>
                      <div className="line-after-login-second"></div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <button
                      className=" text-white login-google-btn  py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={socialLogin}
                    >
                      {isLoading ? "Loading..." : "Sign Up with google"}
                    </button>
                  </div>
                  {/* <div className="text-center Any-Account-section">
                    <p>
                      Not having Any Account?
                      <span
                        className="cursor-pointer	"
                        onClick={(e) => handleClick(e)}
                      >
                        Login here.
                      </span>
                    </p>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default SignUp;
