import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Select2 from "react-select2-wrapper";
import api from "../../Api";
import { useHistory } from "react-router-dom";
import { getUserData } from "../../helpers/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const TicketForm = () => {
  const User = getUserData();
  const [data, setdata] = useState([]);
  const [render, setRender] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [formData, setFormData] = useState({
    jobId: "",
    subject: "",
    description: "",
    status: "Open",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    // If the textarea id is "Query", map it to the "description" field in formData
    if (id === "description") {
      setFormData({ ...formData, description: value });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleSelectChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleMultiSelectChange = (e, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: Array.from(e.target.selectedOptions, (option) => option.value),
    }));
  };
  useEffect(() => {
    async function getJobs() {
      try {
        const response = await api.getJobsByuserId(User._id);
        if (response.status !== 400) {
          setdata(
            response.map((job) => ({
              id: job._id, // Assuming _id is the unique identifier for jobs
              text: job.title, // Job title will be displayed in the dropdown
            }))
          );
        } else if (response.status === 400) {
        }
      } catch (error) {
        console.error(error);
      }
    }
    getJobs();
  }, []);

  const dummyJobStatusOptions = [
    { value: "Open", label: "Open" },
    { value: "In Progress", label: "In Progress" },
    { value: "Closed", label: "Closed" },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData);
    try {
      const response = await api.addTicket(formData);
      if (response.status == 201) {
        toast.success("Ticket Created Successffully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom-toast",
        });
        setLoading(false);

        setTimeout(() => {
          history.push(`./edit-ticket/${response?.ticket?._id}`);
        }, 1000);
      } else {
        console.error("Error posting job:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting course:", error);
      // alert("Failed to submit course. Please try again.");
    } finally {
      // Stop the loader regardless of success or failure
      setLoading(false);
    }
  };
  return (
    <div className="hrp-ticket-create">
      <div className="w-3/4 m-auto p-10 ">
        <form
          onSubmit={handleSubmit}
          className="animated-form profileheadlinediv space-y-6"
        >
          <div className="flex justify-between items-center">
            <div
              className="py-5 text-3xl font-bold"
              style={{ color: "#003366" }}
            >
              Create Ticket
            </div>
            <div className="w-36">
              <div className="relative job-search-label-section singleselect2">
                <Select2
                  id="statusOptions"
                  className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                  name="statusOptions"
                  multiple={false}
                  defaultValue={formData.status}
                  onChange={(e) => handleSelectChange(e, "status")}
                  data={dummyJobStatusOptions.map((status) => ({
                    id: status.value,
                    text: status.label,
                  }))}
                  options={{
                    placeholder: "",
                    theme: "classic",
                    width: "resolve",
                  }}
                />
                <label
                  htmlFor="statusOptions"
                  className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                >
                  Status
                </label>
              </div>
            </div>
          </div>

          <input type="hidden" id="userId" value={formData.userId} />

          <div className="grid grid-cols-2 gap-5">
            <div className="relative job-search-label-section singleselect2">
              <Select2
                id="jobId"
                className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                name="jobId"
                multiple={false}
                defaultValue={formData.jobId}
                onChange={(e) => handleSelectChange(e, "jobId")}
                data={data}
                options={{
                  placeholder: "Select a job",
                  theme: "classic",
                  width: "resolve",
                }}
              />
              <label
                htmlFor="jobId"
                className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
              >
                Job ID
              </label>
            </div>

            <div className="form-group">
              <input
                id="subject"
                placeholder=""
                value={formData.subject}
                onChange={handleChange}
                className="border rounded w-full py-2 px-3 text-gray-700"
                rows="4"
              />
              <label htmlFor="subject" className="profileheadline">
                Subject
              </label>
            </div>
          </div>
          <div className="form-group">
            <textarea
              id="description"
              placeholder=""
              value={formData.description}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3 text-gray-700"
              rows="4"
            />
            <label htmlFor="Query" className="profileheadline">
              Query
            </label>
          </div>

          <div className="flex justify-end mt-6">
            <button type="submit" className="Job-Post-btn text-white">
              Save
            </button>
          </div>
        </form>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default TicketForm;
