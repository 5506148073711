// Login.js
import React, { useState, useEffect } from "react";
import api from "../../Api";
import { usePopup } from "../Common/PopupContext";
import { NavLink, useHistory } from "react-router-dom";
import { encryptData, decryptData } from "../../helpers/encryption";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./ForgotPassword";
const Login = ({ onClose, redirect_url, candidate, employee, jobId }) => {
  const spinnerStyle = {
    display: "flex",
    width: "100%",
    alignItem: "center",
    justifyContent: "center",
  };

  const spinnerInnerStyle = {
    boxSizing: "border-box",
    display: "block",
    width: "50px",
    height: "50px",
    margin: "10px",
    border: "3px solid #000",
    borderRadius: "50%",
    borderTopColor: "transparent",
    animation: "spin 1.2s linear infinite",
  };
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setIsMessage] = useState(null);
  const [role, setSelectedRole] = useState("applicant");
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);

  useEffect(() => {
    // Check for existing session
    const encryptedUserData = localStorage.getItem("user");
    if (encryptedUserData) {
      const userData = decryptData(encryptedUserData); // assuming you have a decryptData function
      if (userData && userData.accessToken) {
        // Redirect to dashboard or maintain the session
        history.push(redirect_url || "/dashboard");
      }
    }
  }, [history, redirect_url]);
  useEffect(() => {
    if (
      candidate !== undefined &&
      candidate !== null &&
      typeof candidate === "object" &&
      Object.keys(candidate).length > 0
    ) {
      console.log("applicant");
      setSelectedRole("applicant");
    }
    if (
      employee !== undefined &&
      employee !== null &&
      typeof employee === "object" &&
      Object.keys(employee).length > 0
    ) {
      console.log("employer");
      setSelectedRole("employer");
    }
  }, [candidate, employee]);
  function handleClick(showArray, hideArray) {
    showArray.map((id) => {
      document.getElementById(id).style = "display:block";
    });
    hideArray.map((id) => {
      document.getElementById(id).style = "display:none";
    });
  }
  function start() {
    document.querySelector("#step_2").classList.add("dv-step_2-show");
  }
  const [validationMessage, setValidationMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setValidationMessage("Email is required.");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setValidationMessage("Email is invalid.");
      return;
    }

    if (!password) {
      setValidationMessage("Password is required.");
      return;
    }
    setLoading(true);
    try {
      const userData = { email, password };
      console.log(userData);
      const response = await api.Login(userData);
      console.log(response);
      const { token, refreshToken } = response;
      if (response.status !== 400) {
        toast.success("Login Successfully", {
          position: "top-right",
          autoClose: 900,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom-toast",
        });
        const { accessToken, refreshToken, _id, role } = response;
        const encryptedUserData = encryptData(response);
        localStorage.setItem("user", encryptedUserData);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        if (keepMeLoggedIn) {
          localStorage.setItem("user", encryptedUserData);
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
        } else {
          sessionStorage.setItem("user", encryptedUserData);
          sessionStorage.setItem("accessToken", accessToken);
          sessionStorage.setItem("refreshToken", refreshToken);
        }
        console.log(redirect_url);

        setTimeout(() => {
          var redirectdata = localStorage.getItem("redirect_url");
          console.log(redirectdata);

          // Correct the condition to check for valid redirectdata
          if (
            redirectdata !== undefined &&
            redirectdata !== "" &&
            redirectdata !== null
          ) {
            setLoading(false);
            window.location.href = redirectdata;
            localStorage.removeItem("redirect_url");
          } else if (redirect_url !== null && redirect_url !== undefined) {
            setLoading(false);
            window.location.href = redirect_url;
          } else {
            setLoading(false);
            window.location.href = "/dashboard";
          }
        }, 2000);
      } else if (response.status == 400) {
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );
        toast.error(response.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom-toast",
          style: {
            borderRadius: "34px",
            // Replace with your desired background color
          },
        });
        setLoading(false);
        // Handle unexpected response status
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
          borderRadius: "34px",
          // Replace with your desired background color
        },
      });
      setLoading(false);
      // Handle error
    }
  };

  const socialLogin = async (e) => {
    e.preventDefault();
    try {
      window.location.href = `${api.socialLogin}?role=${role}`;
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeepMeLoggedInChange = (e) => {
    setKeepMeLoggedIn(e.target.checked);
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    if (!name) {
      setValidationMessage("Name is required.");
      return;
    }

    if (!email) {
      setValidationMessage("Email is required.");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setValidationMessage("Email is invalid.");
      return;
    }

    if (!phone) {
      setValidationMessage("Phone number is required.");
      return;
    }

    if (role == "employer" && !company) {
      setValidationMessage("Company name is required.");
      return;
    }

    if (!password) {
      setValidationMessage("Password is required.");
      return;
    }

    if (password !== confirmPassword) {
      setValidationMessage("Passwords do not match.");
      return;
    }

    try {
      setLoading(true);
      const userData = { name, email, password, phone, company };
      console.log(role);

      let response; // Declare response with let so it can be reassigned
      if (role === "employer") {
        response = await api.employerSignup(userData);
      } else if (role === "applicant") {
        response = await api.applicantSignup(userData);
      }
      if (response.status !== 400) {
        toast.success("User Created Successfully", {
          position: "top-right",
          autoClose: 900,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom-toast",
          style: {
            borderRadius: "34px",
            backgroundColor: "#ffa500",
            color: "#fff",
          },
        });

        setLoading(false);
        setTimeout(() => {
          document.getElementById("step_2").style = "display:none";
          document.getElementById("step_1").style = "display:block";
        }, 1000);

        // Redirect or perform other actions as needed
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      console.error(error);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };
  function formatPhoneNumber(value) {
    // Remove all non-numeric characters from the input
    const cleaned = ("" + value).replace(/\D/g, "");

    // Limit the input to 11 digits
    const limited = cleaned.substring(0, 11);

    // Check if the input has the correct length for formatting
    if (limited.length >= 10) {
      // Format the number in the 123-456-7890 format
      return `${limited.slice(0, 3)}-${limited.slice(3, 6)}-${limited.slice(
        6,
        10
      )}`;
    } else if (limited.length > 6) {
      // Format the number partially as 123-456-
      return `${limited.slice(0, 3)}-${limited.slice(3, 6)}-${limited.slice(
        6
      )}`;
    } else if (limited.length > 3) {
      // Format the number partially as 123-
      return `${limited.slice(0, 3)}-${limited.slice(3)}`;
    } else {
      // Return the cleaned input if less than 3 digits
      return limited;
    }
  }

  const { openPopup } = usePopup();
  const [isPopupOpen, setIsPopupOpen] = useState([]);
  const [popupComponent, setPopupComponent] = useState(null);

  const handleClosePopup = () => {
    console.log();
    setPopupComponent(null);
    openPopup();
  };
  const handleforgot = () => {
    setLoading(true);

    if (redirect_url != undefined) {
      const popup = (
        <ForgotPassword
          onClose={() => handleClosePopup("Popup closed")}
          redirect_url={redirect_url}
        />
      );
      setPopupComponent(popup);
      openPopup(popup);
    } else {
      history.push("./forgotpassword");
      window.location.reload();
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;

    // Allow only numeric values and limit to 10 digits
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (numericValue.length <= 10) {
      setPhone(numericValue); // Update state with numeric value up to 10 digits
    }
  };

  return (
    <>
      <div className="w-full flex justify-center h-screen  login_inform-inner m-auto p-20">
        <div className="flex">
          <div>
            <div
              className="form_upto  m-3 loginDiv loginDiv-width "
              id="step_1"
              style={{
                display:
                  (candidate && Object.keys(candidate).length !== 0) ||
                  (employee && Object.keys(employee).length !== 0)
                    ? "none"
                    : "block",
              }}
            >
              <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
                <div className="mb-1 text-center py-10">
                  <div className="text-center HirePros-login-heading ">
                    {/* <img
                    src={logo}
                    alt="logo"
                    style={{ width: "60%", margin: "0 auto" }}
                  /> */}
                    <h2 className="  ">Hi, Welcome Back !</h2>
                    <h4>still don't have an account? Sign up</h4>
                  </div>
                </div>
                {/* Display error message */}
                {isMessage && (
                  <button className="btn-loginmessage" type="text" danger>
                    {isMessage}
                  </button>
                )}
                <form
                  className="login-form-section animated-form"
                  onSubmit={handleSubmit}
                >
                  <div className="mb-1 form-group">
                    <input
                      className="  border rounded w-full py-2 px-3 text-gray-700  "
                      type="text"
                      id="email"
                      placeholder=""
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="email" className="Email-label">
                      Email
                    </label>
                  </div>
                  <div className="mb-2 form-group">
                    <input
                      id="password"
                      className="  border rounded w-full py-2 px-3 text-gray-700 mb-3 "
                      type="password"
                      placeholder=""
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="password" className="password-label">
                      Password
                    </label>
                  </div>
                  {validationMessage && (
                    <div className="mb-4 text-red-500">{validationMessage}</div>
                  )}
                  <div className="mb-6 flex items-center justify-between forgot-password-section-font">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                        checked={keepMeLoggedIn}
                        onChange={handleKeepMeLoggedInChange}
                      />
                      <span className="ml-2 text-gray-700">Remember Me</span>
                    </label>
                    <NavLink
                      className="forgot-pass-link color"
                      to="/forgotpassword"
                      onClick={() => handleforgot()}
                    >
                      Forgot Password?
                    </NavLink>
                  </div>
                  <div>{isMessage != "null" ? isMessage : ""}</div>
                  <div className="">
                    <button
                      className="  login-form-btn bg-[#003366] text-white py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                      type="submit"
                    >
                      {isLoading ? "Loading..." : "Log in"}
                    </button>
                  </div>
                  <div className="line-after-login my-2">
                    <div className="line-after-login-inner">
                      <div className="line-after-login-first"></div>
                      <div className="line-after-login-or">or</div>
                      <div className="line-after-login-second"></div>
                    </div>
                  </div>
                  <div className="mb-6">
                    <button
                      className=" text-white login-google-btn  py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={socialLogin}
                    >
                      {isLoading ? "Loading..." : "Sign in with google"}
                    </button>
                  </div>
                  <div className="keepForget text-center">
                    <p>
                      Don't have an account?
                      <span
                        onClick={(e) => {
                          handleClick(["step_2"], ["step_1"]);
                          start();
                        }}
                      >
                        <NavLink
                          className="forgot-pass-link color cursor-pointer	"
                          to=""
                        >
                          Signup
                        </NavLink>
                      </span>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <div className="form_upto  m-3 loginDiv loginDiv-width">
              <div
                className="hidden  loginDiv"
                id="step_2"
                style={{
                  display:
                    (candidate && Object.keys(candidate).length !== 0) ||
                    (employee && Object.keys(employee).length !== 0)
                      ? "block"
                      : "none",
                }}
              >
                <div className="flex justify-center hrp-register-section items-center login_inform-inner">
                  <div className="w-full">
                    <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
                      <div className="mb-1 text-center py-5 HirePros-login-heading">
                        <h2 className="">Create Account</h2>
                      </div>
                      <div className="register-user-select">
                        {/* <div className="Applicant-btn">
                    <butto>Applicant</butto>
                  </div>
                  <div className="Employer">
                    <butto>Employer</butto>
                  </div> */}
                        <div className="flex justify-center items-center w-full">
                          <div className="relative inline-flex items-center bg-blue-100 rounded-full w-full ">
                            <button
                              className={`px-4 py-2 rounded-full  w-1/2 ${
                                role === "applicant"
                                  ? "bg-white active-border-btn "
                                  : ""
                              }`}
                              onClick={() => setSelectedRole("applicant")}
                            >
                              Applicant
                            </button>
                            <button
                              className={`px-4 py-2 rounded-full w-1/2 ${
                                role === "employer"
                                  ? "bg-white active-border-btn "
                                  : ""
                              }`}
                              onClick={() => setSelectedRole("employer")}
                            >
                              Employer
                            </button>
                          </div>
                        </div>
                      </div>
                      <form
                        onSubmit={handleRegisterSubmit}
                        className="register-form-section animated-form"
                      >
                        <div className="form-group">
                          <input
                            type="text"
                            id="fname"
                            placeholder=""
                            onChange={(e) => setName(e.target.value)}
                            className="shadow w-full border rounded py-2 px-3"
                          />
                          <label htmlFor="fname" className="form-input-label">
                            Name
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            id="email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder=""
                            className="shadow w-full border rounded py-2 px-3"
                          />
                          <label htmlFor="email" className="form-input-label">
                            Email
                          </label>
                        </div>
                        <div
                          className={`mb-2 ${
                            role === "employer"
                              ? "md:grid md:grid-cols-2 md:gap-x-5"
                              : "md:grid md:grid-cols-1 md:gap-x-5"
                          }`}
                        >
                          <div
                            className={`form-group ${
                              role !== "employer" ? "col-span-2" : ""
                            }`}
                          >
                            <input
                              type="number"
                              id="phone"
                              name="phone"
                              required
                              value={phone}
                              onChange={(e) => handleChange(e)}
                              placeholder=""
                              maxLength={10} // Setting the maximum length to 10 digits
                              min={1000000000} // Minimum 10 digits
                              max={9999999999}
                              // inputMode="tel"
                              // pattern="\d{3}-\d{3}-\d{4}"
                              // title="Phone number should be in the format: 123-456-7890"
                              className="shadow w-full border rounded py-2 px-3"
                            />
                            <label htmlFor="phone" className="form-input-label">
                              Phone
                            </label>
                          </div>

                          {role === "employer" && (
                            <div className="form-group">
                              <input
                                id="company"
                                type="text"
                                onChange={(e) => setCompany(e.target.value)}
                                placeholder=""
                                className="shadow w-full border rounded py-2 px-3"
                              />
                              <label
                                htmlFor="company"
                                className="form-input-label"
                              >
                                Company
                              </label>
                            </div>
                          )}

                          {/* Password and Confirm Password in one row with two columns */}
                          <div
                            className={`form-group ${
                              role === "employer" ? "col-span-1" : "col-span-1"
                            }`}
                          >
                            <input
                              type="password"
                              id="password"
                              required
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder=""
                              className="shadow w-full border rounded py-2 px-3"
                            />
                            <label
                              htmlFor="password"
                              className="form-input-label"
                            >
                              Password
                            </label>
                          </div>

                          <div
                            className={`form-group ${
                              role === "employer" ? "col-span-1" : "col-span-1"
                            }`}
                          >
                            <input
                              id="cpassword"
                              type="password"
                              placeholder=""
                              value={confirmPassword}
                              onChange={handleConfirmPasswordChange}
                              className="shadow w-full border rounded py-2 px-3"
                            />
                            <label
                              htmlFor="cpassword"
                              className="form-input-label"
                            >
                              Confirm Password
                            </label>
                          </div>
                        </div>

                        {error && (
                          <p className="text-red-500 text-xs italic">{error}</p>
                        )}
                        <div className=" register-promotional-emails mb-6">
                          <input type="checkbox" name="terms" required />
                          <div className="terms-policy">
                            I agree <a href=""> Terms condation </a> &{" "}
                            <a href=""> Privacy Policy</a>
                          </div>
                        </div>
                        <div className="Forgot_00">
                          <div className="">
                            <button
                              className="  text-white py-2  px-4 rounded w-full  register-form-btn"
                              type="submit"
                            >
                              Register
                            </button>
                          </div>
                          <div className="line-after-login my-2">
                            <div className="line-after-login-inner">
                              <div className="line-after-login-first"></div>
                              <div className="line-after-login-or">or</div>
                              <div className="line-after-login-second"></div>
                            </div>
                          </div>
                          <div className="mb-2">
                            <button
                              className=" text-white login-google-btn  py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                              type="button"
                              onClick={socialLogin}
                            >
                              {isLoading ? "Loading..." : "Sign Up with google"}
                            </button>
                          </div>
                          <div className="text-center Any-Account-section">
                            <p>
                              Not having Any Account?
                              <span
                                className="cursor-pointer	"
                                onClick={() =>
                                  handleClick(["step_1"], ["step_2"])
                                }
                              >
                                Login here.
                              </span>
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className=" relative text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-7	 h-7 -top-6  text-gray-600 hover:text-gray-900"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        {loading && (
          <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
            <span className="RoadmapSpinner"></span>
          </div>
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default Login;
