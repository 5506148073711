// Message.js
import React from "react";

const Message = ({ user, text, timestamp }) => {
  const isCurrentUser = user === "current"; // Adjust this condition based on how you determine the current user

  return (
    <div
      className={`flex items-start mb-4 ${isCurrentUser ? "justify-end" : ""}`}
    >
      {!isCurrentUser && (
        <div className="w-10 h-10 bg-blue-900 rounded-full mr-2"></div>
      )}
      <div
        className={`chat-box text-black p-2 rounded ${
          isCurrentUser ? "bg-gray-200" : "bg-blue-100"
        }`}
      >
        <p>{text}</p>
        <p className="text-xs text-right">{timestamp}</p>
      </div>
      {isCurrentUser && (
        <div className="w-10 h-10 bg-green-900 rounded-full ml-2"></div>
      )}
    </div>
  );
};

export default Message;
