import React, { useState, useEffect } from "react";
import api from "../../Api.js";
import { useParams } from "react-router-dom";
import default123 from "../../image/DefaultImage.png";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const AntiSlavery = () => {
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState();
  useEffect(() => {
    const fetchBlog = async () => {
      setLoading(true);
      try {
        const res = await api.getBlogbySlug(slug);
        if (res.status == 200) {
          const data = res.blog;
          console.log(data);

          setFormData(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
        // Assuming getBlogById is an API method
      } catch (err) {
        console.error("Error fetching blog data:", err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchBlog();
  }, [slug]);
  function createMarkup() {
    return { __html: formData?.html };
  }
  return (
    <>
      <div className=" justify-center items-center min-h-screen py-10 ">
        <div className=" rounded-md  mx-4">
          <div>
            <div className="hrp-single_blog">
              <div className="hrp-blog">
                <h1>{formData?.title}</h1>
                <img
                  src={domainpath + "/uploads/" + formData?.featured_image}
                  alt="Image"
                />
              </div>
              <div className="hrp-blog-descreption">
                <h3>{formData?.title}</h3>
                <div className="hrp-blo_content">
                  <p dangerouslySetInnerHTML={createMarkup()}></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
    </>
  );
};

export default AntiSlavery;
