import React, { useState } from "react";
import { getUserData } from "../../helpers/utils";
import api from "../../Api";

const AccountSettings = () => {
  const user = getUserData();
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate if passwords match
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Prepare data for API request
    const userData = {
      email,
      password,
    };

    try {
      // Replace with actual API endpoint
      const response = await api.UpdateAccount(userData);
      if (!response.ok) {
        throw new Error("Failed to update account.");
      }

      // Handle success
      alert("Account updated successfully!");
      setError(null);
    } catch (error) {
      // Handle API errors
      setError(error.message);
    }
  };

  return (
    <>
      <div className="p-8 account-settings-main">
        <div className="mb-8">
          <h1 className="account-settings">Account Settings</h1>
          <form onSubmit={handleSubmit}>
            <div className="animated-form profileheadlinediv">
              <div className="form-group">
                <input
                  id="email"
                  className="border rounded w-full py-2 px-3 text-gray-700"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Enter your email"
                  required
                />
                <label htmlFor="email" className="profileheadline">
                  Email
                </label>
              </div>
            </div>
            <div className="animated-form profileheadlinediv">
              <div className="form-group">
                <input
                  id="password"
                  className="border rounded w-full py-2 px-3 text-gray-700"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Enter your new password"
                  required
                />
                <label htmlFor="password" className="profileheadline">
                  Password
                </label>
              </div>
            </div>
            <div className="animated-form profileheadlinediv">
              <div className="form-group">
                <input
                  id="confirmPassword"
                  className="border rounded w-full py-2 px-3 text-gray-700"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  placeholder="Confirm  password"
                  required
                />
                <label htmlFor="confirmPassword" className="profileheadline">
                  Confirm Password
                </label>
              </div>
            </div>
            {error && <div className="text-red-500">{error}</div>}
            <div>
              <button
                type="submit"
                className="ViewDetails-btn text-white px-4 py-2"
              >
                Update Settings
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
