import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import ChatList from "../../helpers/ChatList";
import MessagePanel from "../../helpers/MessagePanel";
import { io } from "socket.io-client";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";

const Messages = () => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageList, setMessageList] = useState([]);
  const scrollRef = useRef(null);
  const [socket, setSocket] = useState(null); // Store the socket connection in state
  const User = getUserData();

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const handleSelectChat = (chat) => {
    if (chat.applicationId !== selectedChat?.applicationId) {
      setSelectedChat(chat);
      console.log(chat);

      if (chat.applicationId && socket) {
        socket.emit("joinRoom", { applicationId: chat.applicationId });
        loadMessages(chat.applicationId);
      }
    }
  };

  useEffect(() => {
    async function getMessages() {
      try {
        const response = await api.getMessages();
        setChats(response);
      } catch (error) {
        console.error("Failed to load messages:", error);
      }
    }
    getMessages();
  }, []);

  const loadMessages = async (applicationId) => {
    try {
      const response = await api.getMessagesByApplications(applicationId);
      const participants = response?.participants;

      const messages = response?.messages.map((msg, index) => {
        const sender = participants.find(
          (participant) => participant._id === User?._id
        );

        return {
          user: sender ? sender.name : "Unknown Sender", // Map sender ID to participant name
          text: msg.message,
          timestamp: moment(msg.createdAt).format("hh:mm A"), // Format timestamp
          key: index,
        };
      });

      setMessageList(messages);
      scrollToBottom();
    } catch (error) {
      console.error("Failed to load messages:", error);
    }
  };

  const handleSendMessage = (text) => {
    if (selectedChat && text.trim() !== "" && socket) {
      if (!selectedChat.applicationId) {
        console.error("Error: No applicationId available for this chat.");
        return;
      }

      const sender = selectedChat.participants.find(
        (participant) => participant._id === User?._id
      );

      const newMessage = {
        user: sender ? sender.name : "Unknown Sender",
        applicationId: selectedChat.applicationId,
        text,
        timestamp: moment().format("hh:mm A"),
      };

      // Emit the message to the socket server
      socket.emit(
        "sendMessage",
        {
          applicationId: selectedChat.applicationId,
          senderId: User?._id,
          message: text,
        },
        (acknowledgment) => {
          console.log("Server received the message:", acknowledgment);
        }
      );

      // Update the chat locally by adding the new message to the current message list
      setMessageList((prevMessages) => [...prevMessages, newMessage]);
      scrollToBottom();
    }
  };

  useEffect(() => {
    const handleReceiveMessage = (data) => {
      if (selectedChat && data.applicationId === selectedChat.applicationId) {
        setMessageList((prevMessages) => [
          ...prevMessages,
          {
            user: data.senderId, // The actual sender
            text: data.message,
            timestamp: moment(data.createdAt).format("hh:mm A"),
          },
        ]);
        scrollToBottom();
      }
    };

    if (socket) {
      socket.off("receiveMessage").on("receiveMessage", handleReceiveMessage);
    }

    return () => {
      if (socket) {
        socket.off("receiveMessage", handleReceiveMessage);
      }
    };
  }, [selectedChat, socket]);

  useEffect(() => {
    if (User && !socket) {
      const newSocket = io.connect("http://194.164.89.146:3000");
      setSocket(newSocket);

      return () => {
        if (newSocket) {
          newSocket.disconnect();
        }
      };
    }
  }, [User, socket]);

  return (
    <div className="flex main-chat-panel">
      <div className="w-1/3 border-r overflow-y-auto">
        <ChatList chats={chats} onSelectChat={handleSelectChat} />
      </div>
      <div className="w-2/3 flex flex-col">
        {selectedChat ? (
          <div className="flex-1 overflow-y-auto p-4" ref={scrollRef}>
            <MessagePanel
              selectedChat={selectedChat}
              onSendMessage={handleSendMessage}
              messageList={messageList}
            />
          </div>
        ) : (
          <div className="flex-1 p-4 flex items-center justify-center">
            Select a chat to start messaging
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
