import React, { useState } from "react";
import api from "../../Api";
import { NavLink, useHistory } from "react-router-dom";
import logo from "../../image/logo-change.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const RegisterForm = () => {
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMessage, setIsMessage] = useState(null);
  const [role, setSelectedRole] = useState("applicant");
  function start() {
    document.querySelector("#step_2").classList.add("dv-step_2-show");
  }
  const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = { email, password };
      console.log(userData);
      const response = await api.Login(userData);
      console.log(response);
      const { token, refreshToken } = response;
      if (response.status !== 400) {
        const { accessToken, refreshToken, _id } = response;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("userId", _id);
        console.log("Login successful:", response);
        history.push("/dashboard");
      } else {
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [validationMessage, setValidationMessage] = useState("");
  const socialLogin = async (e) => {
    e.preventDefault();
    try {
      window.location.href = `${api.socialLogin}`;
    } catch (error) {
      console.error(error);
    }
  };
  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    if (!name) {
      setValidationMessage("Name is required.");
      return;
    }

    if (!email) {
      setValidationMessage("Email is required.");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setValidationMessage("Email is invalid.");
      return;
    }

    if (!phone) {
      setValidationMessage("Phone number is required.");
      return;
    }

    if (role == "employer" && !company) {
      setValidationMessage("Company name is required.");
      return;
    }

    if (!password) {
      setValidationMessage("Password is required.");
      return;
    }

    if (password !== confirmPassword) {
      setValidationMessage("Passwords do not match.");
      return;
    }

    try {
      setLoading(true);
      const userData = { name, email, password, phone, company };
      console.log(role);

      let response; // Declare response with let so it can be reassigned
      if (role === "employer") {
        response = await api.employerSignup(userData);
      } else if (role === "applicant") {
        response = await api.applicantSignup(userData);
      }
      if (response.status !== 400) {
        toast.success("User Created Successfully", {
          position: "top-right",
          autoClose: 900,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            borderRadius: "34px",
            backgroundColor: "#ffa500",
            color: "#fff",
          },
        });

        setLoading(false);
        setTimeout(() => {
          document.getElementById("step_2").style = "display:none";
          document.getElementById("step_1").style = "display:block";
        }, 1000);

        // Redirect or perform other actions as needed
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      console.error(error);
    }
  };
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;

    // Allow only numeric values and limit to 10 digits
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (numericValue.length <= 10) {
      setPhone(numericValue); // Update state with numeric value up to 10 digits
    }
  };

  return (
    <div className="login_inform login_inform-page">
      {/* Registration form */}
      <div className="flex justify-center items-center login_inform-inner ">
        <div className="  loginDiv m-8" id="">
          <div className="flex justify-center  items-center login_inform-inner">
            <div className="w-full">
              <div className="     px-6 pt-4 pb-6 mb-4">
                <div className="mb-1 text-center HirePros-login-heading">
                  <img
                    className="m-auto"
                    src={logo}
                    alt="logo"
                    width={"100px"}
                  />
                  <h2 className="mt-5">Create Account</h2>
                </div>
                <div className="register-user-select">
                  {/* <div className="Applicant-btn">
                    <butto>Applicant</butto>
                  </div>
                  <div className="Employer">
                    <butto>Employer</butto>
                  </div> */}
                  <div className="flex justify-center items-center w-full">
                    <div className="relative inline-flex items-center bg-blue-100 rounded-full w-full ">
                      <button
                        className={`px-4 py-2 rounded-full  w-1/2 ${
                          role === "applicant"
                            ? "bg-white active-border-btn "
                            : ""
                        }`}
                        onClick={() => setSelectedRole("applicant")}
                      >
                        Applicant
                      </button>
                      <button
                        className={`px-4 py-2 rounded-full w-1/2 ${
                          role === "employer"
                            ? "bg-white active-border-btn "
                            : ""
                        }`}
                        onClick={() => setSelectedRole("employer")}
                      >
                        Employer
                      </button>
                    </div>
                  </div>
                </div>
                <form
                  onSubmit={handleRegisterSubmit}
                  className="register-form-section animated-form"
                >
                  <div className="form-group">
                    <input
                      type="text"
                      id="fname"
                      placeholder=""
                      onChange={(e) => setName(e.target.value)}
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label htmlFor="fname" className="form-input-label">
                      Name
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder=""
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label htmlFor="email" className="form-input-label">
                      Email
                    </label>
                  </div>
                  <div
                    className={`mb-2 ${
                      role === "employer"
                        ? "md:grid md:grid-cols-2 md:gap-x-5"
                        : "md:grid md:grid-cols-1 md:gap-x-5"
                    }`}
                  >
                    <div
                      className={`form-group ${
                        role !== "employer" ? "col-span-2" : ""
                      }`}
                    >
                      <input
                        type="number"
                        id="phone"
                        name="phone"
                        required
                        value={phone}
                        onChange={(e) => handleChange(e)}
                        placeholder=""
                        maxLength={10} // Setting the maximum length to 10 digits
                        min={1000000000} // Minimum 10 digits
                        max={9999999999}
                        // inputMode="tel"
                        // pattern="\d{3}-\d{3}-\d{4}"
                        // title="Phone number should be in the format: 123-456-7890"
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="phone" className="form-input-label">
                        Phone
                      </label>
                    </div>

                    {role === "employer" && (
                      <div className="form-group">
                        <input
                          id="company"
                          type="text"
                          onChange={(e) => setCompany(e.target.value)}
                          placeholder=""
                          className="shadow w-full border rounded py-2 px-3"
                        />
                        <label htmlFor="company" className="form-input-label">
                          Company
                        </label>
                      </div>
                    )}

                    {/* Password and Confirm Password in one row with two columns */}
                    <div
                      className={`form-group ${
                        role === "employer" ? "col-span-1" : "col-span-1"
                      }`}
                    >
                      <input
                        type="password"
                        id="password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder=""
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="password" className="form-input-label">
                        Password
                      </label>
                    </div>

                    <div
                      className={`form-group ${
                        role === "employer" ? "col-span-1" : "col-span-1"
                      }`}
                    >
                      <input
                        id="cpassword"
                        type="password"
                        placeholder=""
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="cpassword" className="form-input-label">
                        Confirm Password
                      </label>
                    </div>
                  </div>

                  {error && (
                    <p className="text-red-500 text-xs italic">{error}</p>
                  )}
                  <div className=" register-promotional-emails mb-6">
                    <input type="checkbox" name="terms" required />
                    <div className="terms-policy">
                      I agree <a href=""> Terms condation </a> &{" "}
                      <a href=""> Privacy Policy</a>
                    </div>
                  </div>
                  <div className="Forgot_00">
                    <div className="">
                      <button
                        className="  text-white py-2  px-4 rounded w-full  register-form-btn"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                    <div className="line-after-login my-2">
                      <div className="line-after-login-inner">
                        <div className="line-after-login-first"></div>
                        <div className="line-after-login-or">or</div>
                        <div className="line-after-login-second"></div>
                      </div>
                    </div>
                    <div className="mb-2">
                      <button
                        className=" text-white login-google-btn  py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={socialLogin}
                      >
                        {isLoading ? "Loading..." : "Sign Up with google"}
                      </button>
                    </div>
                    <div className="text-center Any-Account-section">
                      <p>
                        Not having Any Account?
                        <a href="/login" className="cursor-pointer">
                          Login here.
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default RegisterForm;
