// src/context/PopupContext.js
import React, { createContext, useContext, useState, useCallback } from "react";
import Popup from "../../helpers/Popup";

const PopupContext = createContext();

export const usePopup = () => useContext(PopupContext);

export const PopupProvider = ({ children }) => {
  const [popupContent, setPopupContent] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = useCallback((content) => {
    setPopupContent(content);
    setShowPopup(true);
  }, []);

  const closePopup = useCallback(() => {
    setShowPopup(false);
    setPopupContent(null);
  }, []);

  return (
    <PopupContext.Provider value={{ openPopup, closePopup }}>
      {children}
      <Popup show={showPopup} onClose={closePopup}>
        {popupContent}
      </Popup>
    </PopupContext.Provider>
  );
};
