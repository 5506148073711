export const submitform = (formid) => {
  const formData = {};
  const form = document.getElementById(formid);

  for (let i = 0; i < form.elements.length; i++) {
    const element = form.elements[i];

    if (element.name && element.type !== "submit") {
      const nameParts = element.name.split(/[\[\]]+/).filter(Boolean);
      let currentObj = formData;

      for (let j = 0; j < nameParts.length - 1; j++) {
        const key = nameParts[j];
        if (!currentObj[key]) {
          currentObj[key] = {};
        }
        currentObj = currentObj[key];
      }

      const key = nameParts[nameParts.length - 1];
      if (key === "tags") {
        if (!currentObj[key]) {
          currentObj[key] = [];
        }
        currentObj[key] = currentObj[key].concat(
          element.value.split(",").map((tag) => tag.trim())
        );
      } else {
        if (currentObj[key]) {
          if (!Array.isArray(currentObj[key])) {
            currentObj[key] = [currentObj[key]];
          }
          currentObj[key].push(element.value);
        } else {
          currentObj[key] = element.value;
        }
      }
    }
  }
  return formData;
};
