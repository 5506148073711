import React, { useState, useEffect, useRef } from "react";
import "./../css/SearchableDropdown.css"; // Import the CSS styles

const SearchableDropdown = ({
  options,
  multiple,
  placeholder,
  value,
  onChange,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState(
    multiple ? value || [] : value ? [value] : []
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null); // Ref for the input field

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedItems(multiple ? value || [] : value ? [value] : []);
  }, [value, multiple]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setDropdownVisible(true); // Show dropdown when typing
  };

  const handleItemSelect = (option) => {
    if (multiple) {
      let newSelection;
      if (selectedItems.some((item) => item.value === option.value)) {
        newSelection = selectedItems.filter(
          (item) => item.value !== option.value
        );
      } else {
        newSelection = [...selectedItems, option];
      }
      setSelectedItems(newSelection);
      onChange(newSelection.map((item) => item.value)); // Pass the updated selection array to onChange
    } else {
      setSelectedItems([option]);
      setDropdownVisible(false);
      onChange(option.value); // Pass the selected value to onChange
    }
    setSearchTerm(""); // Clear search term after selection
  };

  const removeSelectedItem = (option) => {
    const newSelection = selectedItems.filter(
      (item) => item.value !== option.value
    );
    setSelectedItems(newSelection);
    onChange(newSelection.map((item) => item.value)); // Pass the updated selection array to onChange
    setDropdownVisible(true); // Keep dropdown open after clearing
    setSearchTerm(""); // Clear search term
    if (inputRef.current) {
      inputRef.current.focus(); // Ensure input field is focused
    }
  };

  const filteredOptions = options.filter((option) => {
    const label = option.label || option;
    return label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
    setSearchTerm(""); // Clear search term when opening dropdown again
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus(); // Focus on input when dropdown is opened
      }
    }, 0); // Delay focus to ensure dropdown is fully open
  };

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <div
        className="dropdown-input"
        onClick={handleDropdownToggle}
        tabIndex="0"
      >
        {multiple && selectedItems.length > 0 ? (
          <div className="selected-items">
            {selectedItems.map((item, index) => (
              <div key={index} className="selected-item">
                {item.label}
                <span onClick={() => removeSelectedItem(item)}>&times;</span>
              </div>
            ))}
          </div>
        ) : !multiple && selectedItems.length > 0 && !dropdownVisible ? (
          <div className="selected-text">{selectedItems[0].label}</div>
        ) : (
          <input
            type="text"
            placeholder={placeholder || "Search..."}
            value={searchTerm}
            onChange={handleSearch}
            style={{ border: "none", outline: "none", flex: "1" }}
            ref={inputRef} // Attach the ref to the input
          />
        )}
        <svg
          className="dropdown-icon"
          viewBox="0 0 24 24"
          onClick={handleDropdownToggle}
        >
          <path d="M7 10l5 5 5-5z"></path>
        </svg>
      </div>
      {dropdownVisible && (
        <div className="dropdown-list">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className={`dropdown-item ${
                  selectedItems.some((item) => item.value === option.value)
                    ? "selected"
                    : ""
                }`}
                onClick={() => handleItemSelect(option)}
                style={{
                  backgroundColor: selectedItems.some(
                    (item) => item.value === option.value
                  )
                    ? "#ffa500"
                    : "transparent",
                  color: selectedItems.some(
                    (item) => item.value === option.value
                  )
                    ? "white"
                    : "black",
                }}
              >
                {option.label || option}
              </div>
            ))
          ) : (
            <div className="dropdown-item">No options found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
