import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";
import Select2 from "react-select2-wrapper";
const domainpath = process.env.REACT_APP_DOMAIN;
const Managejobs = () => {
  const User = getUserData();
  let paramdata = useParams();
  const [data, setdata] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Store filtered data
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [statusCounts, setStatusCounts] = useState({});
  const [render, setRender] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const statuses = [
    "All",
    "Applied",
    "Reviewed",
    "Shortlisted",
    "Interview Scheduled",
    "Interviewed",
    "In Review",
    "Offered",
    "Hired",
    "Rejected",
  ];
  const columns = [
    {
      name: "Applicant  Name",

      selector: (row) => (
        <a
          href={`${domainpath}/profile/${row?.profie}`}
          className=" hover:underline"
          style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
        >
          {row?.applicantname}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Application Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Score",
      selector: (row) => {
        let color;
        if (row?.score < 60) {
          color = "font-semibold text-lg text-red-500"; // Tailwind CSS class for red color
        } else if (row?.score < 80) {
          color = "font-semibold text-lg text-yellow-500"; // Tailwind CSS class for orange color
        } else if (row?.score == "N/A") {
          color = "font-semibold text-lg text-red-500"; // Tailwind CSS class for green color
        } else {
          color = "font-semibold text-lg text-green-500"; // Tailwind CSS class for green color
        }
        return (
          <span className={color}>
            {row?.score != "N/A" ? row?.score : "0"}%
          </span>
        );
      },
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-1">
          <button
            className=" ManageApplication-View px-6 py-2 text-white "
            onClick={() =>
              history.push(`/dashboard/application/${row?.id}`, row?.profie)
            }
          >
            View Application
          </button>
          {/* <button className=" ManageApplication-Update px-3 py-2 text-white ">
            Update
          </button> */}
        </div>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    async function getjobs() {
      try {
        const response = await api.getAllApplicationByJObId(paramdata?.jobid);
        console.log(response);

        if (response.status == 200) {
          console.log(response);
          setLoading(false);
          const data = response.applications.map((application) => ({
            jobTitle: response.jobTitle,
            joblocation: response.joblocation,
            company: response.company,
            jobstatus: response?.jobStatus,
            id: application?.applicationId,
            applicantname: application?.applicantId.name,
            profie: application.profileId,
            status: application.status,
            score: application.score,
          }));

          calculateStatusCounts(data);
          setdata(data);
          setFilteredData(data);
        } else if (response.status == 400) {
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false); // Handle error
      } finally {
        setLoading(false);
      }
    }
    getjobs();
  }, [render]);

  const tableData = {
    columns,
    data: filteredData,
  };
  console.log(data);
  const status = [
    { value: "Publish", label: "Published" },
    { value: "Hold", label: "Hold" },
    { value: "Disabled", label: "Disabled" },
    { value: "Closed", label: "Closed" },
    { value: "Draft", label: "Draft" },
  ];

  const handleUpdateJObStatus = async (e) => {
    setLoading(true);
    var id = paramdata?.jobid;
    e.preventDefault();
    var formdata = {
      status: e.target.value,
    };
    try {
      const res = await api.UpdateJObStatus(id, formdata);
      toast.success(res.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 1200);
      console.log(res);
    } catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    filterDataByStatus(selectedStatus, data);
    calculateStatusCounts(data); // Always update counts when data changes
  }, [selectedStatus, data]);

  const filterDataByStatus = (selectedStatus, data) => {
    if (selectedStatus === "All") {
      setFilteredData(data);
    } else {
      const filtered = data.filter(
        (application) => application.status === selectedStatus
      );
      setFilteredData(filtered);
    }
  };

  const calculateStatusCounts = (applications) => {
    const counts = {
      All: applications.length,
    };
    statuses.slice(1).forEach((status) => {
      counts[status] = applications.filter(
        (application) => application.status === status
      ).length;
    });
    setStatusCounts(counts);
  };
  return (
    <div className="p-8 hrp-manage-job">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow ">
        <div className="flex justify-between">
          <div>
            <h2 className="text-lg font-bold " style={{ color: "#003366" }}>
              {data[0]?.jobTitle}
            </h2>
            <span> {data[0]?.joblocation}</span>
            <br></br>
            <span> {data[0]?.company}</span>
          </div>
          <div className="relative job-search-label-section singleselect2">
            {" "}
            <Select2
              id="status"
              className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
              name="status"
              multiple={false}
              data={status.map((type) => ({
                id: type.value,
                text: type.label,
              }))}
              defaultValue={data[0]?.jobstatus}
              options={{
                placeholder: "Select Job Status",
                theme: "classic",
                width: "resolve",
              }}
              onSelect={(e) => handleUpdateJObStatus(e)}
            />
          </div>
        </div>
        <div
          className="flex gap-2 mb-4 overflow-x-auto custom-scrollbar"
          style={{ whiteSpace: "nowrap", paddingBottom: "10px" }}
        >
          {statuses.map((status) => (
            <button
              key={status}
              className={`mt-2 px-4 py-2 hrp-ticket-btn ${
                selectedStatus === status ? "activebtn" : "inactive"
              } text-white`}
              onClick={() => setSelectedStatus(status)}
            >
              {status} ({statusCounts[status] || 0})
            </button>
          ))}
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        )}
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Managejobs;
