import React, { forwardRef, useImperativeHandle } from "react";
import ReactDOM from "react-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Section = ({ title, children }) => (
  <div>
    <h2 className="text-xl font-bold mb-4">{title}</h2>
    {children}
  </div>
);

const InterestBadge = ({ name }) => (
  <div className="flex items-center justify-center hrp-InterestBadge-btn">
    {name}
  </div>
);
const interests = ["Travelling", "Cricket", "Listening Music"];
const awards = [
  {
    title: "Perfect Attendance Programs",
    duration: "Dec 2017 - Present",
    months: "8 Month",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in ipsum tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
  },
  {
    title: "Top Performer Recognition",
    duration: "Dec 2017 - Present",
    months: "8 Month",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in ipsum tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
  },
];
const Award = ({ title, duration, months, description }) => (
  <div className=" md:flex space-x-4   items-start hrp-border-left">
    <div className="relative hrp-position flex-shrink-0">
      {/* <div className="absolute left-0 h-full w-0.5 bg-yellow-500 transform -translate-x-2/3"></div> */}
      <div className=" relative  flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
    </div>
    <div className="w-full">
      <div className="md:flex justify-between pb-3">
        <div className="">
          <h3 className="text-xl pb-2 hrp-company-name">{title}</h3>
          {/* <p className=" mb-2 hrp-company-name">{title}</p> */}
        </div>

        <div className=" justify-between  text-gray-600 mb-4">
          <div>{duration}</div>
          <div className=" flex md:justify-end">{months}</div>
        </div>
      </div>
      <p className="hrp-description pb-5 pt-1  hrp-responcive-description ">
        {description}
      </p>
    </div>{" "}
    <ToastContainer />
  </div>
);
const ProfileDownload = forwardRef(({ Profiles, domainpath, tools }, ref) => {
  console.log(Profiles);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous"; // Allow CORS
      img.src = url;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  useImperativeHandle(ref, () => ({
    downloadCV() {
      const container = document.createElement("div");

      const profileImageUrl =
        Profiles?.jobProfileImage &&
        Profiles?.jobProfileImage !== "NA" &&
        Profiles?.jobProfileImage !== ""
          ? Profiles.jobProfileImage.startsWith("https://")
            ? Profiles.jobProfileImage
            : `${domainpath}/uploads/${Profiles.jobProfileImage
                .replace("/uploads/uploads/", "/uploads/")
                .replace("/uploads/", "/uploads/")}`
          : `${domainpath}/uploads/deafultman.png`;

      loadImage(profileImageUrl).then(() => {
        document.body.appendChild(container);
        ReactDOM.render(
          <div className="">
            <div className="hrp-main-section hrp-profile-mobile-main">
              <div className="hrp-container">
                <div
                  className="hrp-sub-section hrp-profile-mobile-responcive hrp-background-color  "
                  style={{ borderBottom: "1px solid #c6c6c6" }}
                >
                  <div className=" m-auto container p-6 lg:pt-20 md:pt-10">
                    <div className="flex  lg:gap-5 gap-3 md:gap-3 space-x-4">
                      <div className="w-1/4  ">
                        <div className="hrp-user-profile-image">
                          <img
                            src={
                              Profiles?.jobProfileImage &&
                              Profiles?.jobProfileImage !== "NA" &&
                              Profiles?.jobProfileImage !== ""
                                ? Profiles.jobProfileImage.startsWith(
                                    "https://"
                                  )
                                  ? Profiles.jobProfileImage
                                  : `${domainpath}${Profiles.jobProfileImage
                                      .replace("/uploads/uploads/", "/uploads/")
                                      .replace("/uploads/", "/uploads/")}`
                                : domainpath + "/uploads/deafultman.png"
                            }
                            alt="Profile"
                            className="w-full h-full rounded-full"
                          />
                        </div>
                        {Profiles?.location &&
                        Profiles?.location !== "" &&
                        Profiles?.location !== undefined &&
                        Profiles?.location !== null ? (
                          <div className="flex justify-center mt-2">
                            <h1 className="flex" style={{ color: "#3e3e3e" }}>
                              <span>
                                <svg
                                  width={16}
                                  hanging={16}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                  fill="#3e3e3e"
                                >
                                  <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                                </svg>
                              </span>
                              &nbsp; {Profiles?.location}
                            </h1>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="m-auto w-3/4">
                        <div className="md:flex justify-between pb-3">
                          <div>
                            <div className="hrp-user-profile-details">
                              <h2 className=" hrp-user-name text-gray-600 ">
                                {Profiles?.salutation} {Profiles?.name}
                              </h2>
                              <div className="lg:flex gap-2 items-center ">
                                <p className="">{Profiles?.jobTitle}</p>

                                <h1>at {Profiles?.location} </h1>
                              </div>
                            </div>
                            <div className="md:flex gap-5 mt-3 ">
                              <div className="  hrp-userprofile-role-heading">
                                <p className="text-gray-600  ">
                                  Year Experience
                                </p>
                                <h4 className=" hrp-heading-size hrp-heading-color ">
                                  {Profiles?.experience}
                                </h4>
                              </div>
                              {Profiles?.currentCTC != undefined ||
                              Profiles?.currentCTC != null ? (
                                <div className="hrp-userprofile-role-heading">
                                  <p className="text-gray-600">Current CTC</p>
                                  <h4 className="hrp-heading-color hrp-heading-size">
                                    {Profiles.currentCTC}
                                  </h4>
                                </div>
                              ) : null}

                              {Profiles?.languages.length > 0 ? (
                                <div className="hrp-userprofile-role-heading">
                                  <p className="text-gray-600  ">Langauge</p>
                                  {Profiles?.languages.map((language) => (
                                    <h4 className="hrp-heading-color hrp-heading-size ">
                                      {language}
                                    </h4>
                                  ))}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>{" "}
                          {/* {User?.role == "employer " ? ( */}
                          <div className="lg:w-1/5">
                            <div className="mt-4 ">
                              {/* {appData == true ? (
                                <>
                                  <div className="mt-2">
                                    <button
                                      className="hrp-btn-white w-full px-9 py-2 flex justify-center"
                                      onClick={(e) => handleShortlist(e)}
                                    >
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M18 12.9999H17.32L15.32 14.9999H17.23L19 16.9999H5L6.78 14.9999H8.83L6.83 12.9999H6L3 15.9999V19.9999C3 20.5303 3.21071 21.039 3.58579 21.4141C3.96086 21.7892 4.46957 21.9999 5 21.9999H19C19.5304 21.9999 20.0391 21.7892 20.4142 21.4141C20.7893 21.039 21 20.5303 21 19.9999V15.9999L18 12.9999ZM17 7.94988L12.05 12.8999L8.5 9.35988L13.46 4.40988L17 7.94988ZM12.76 2.28988L6.39 8.65988C6.2973 8.75239 6.22375 8.86228 6.17357 8.98325C6.12339 9.10423 6.09756 9.23391 6.09756 9.36488C6.09756 9.49585 6.12339 9.62553 6.17357 9.7465C6.22375 9.86747 6.2973 9.97736 6.39 10.0699L11.34 14.9999C11.73 15.4099 12.36 15.4099 12.75 14.9999L19.11 8.65988C19.2027 8.56736 19.2763 8.45747 19.3264 8.3365C19.3766 8.21553 19.4024 8.08585 19.4024 7.95488C19.4024 7.82391 19.3766 7.69423 19.3264 7.57325C19.2763 7.45228 19.2027 7.34239 19.11 7.24988L14.16 2.29988C14.0698 2.20548 13.9615 2.13017 13.8416 2.0784C13.7218 2.02664 13.5927 1.99949 13.4621 1.99856C13.3315 1.99763 13.2021 2.02293 13.0815 2.07298C12.9609 2.12302 12.8516 2.19678 12.76 2.28988Z" />
                                      </svg>
                                      &nbsp; Short List
                                    </button>
                                  </div>
                                  <div className="mt-2">
                                    <button
                                      className=" hrp-btn-reject w-full flex  px-8 py-2 justify-center"
                                      onClick={(e) => handlerejectApplicant(e)}
                                    >
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.5 5.5L19.098 12.006M4.5 5.5L5.105 15.525C5.259 18.092 5.337 19.375 5.979 20.299C6.296 20.755 6.705 21.141 7.179 21.43C7.85 21.84 8.681 21.963 10 22M20 15L13 22M20 22L13 15M3 5.5H21M16.056 5.5L15.373 4.092C14.92 3.156 14.693 2.689 14.302 2.397C14.2151 2.33232 14.1232 2.27479 14.027 2.225C13.594 2 13.074 2 12.035 2C10.969 2 10.436 2 9.995 2.234C9.89752 2.28621 9.80453 2.34642 9.717 2.414C9.322 2.717 9.101 3.202 8.659 4.171L8.053 5.5"
                                          stroke="#FF0000"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      &nbsp; Reject
                                    </button>
                                  </div>
                                </>
                              ) : (
                                ""
                              )} */}
                            </div>
                          </div>
                          {/* ) : (
                            <div className="flex  md:gap-0 gap-3 mt-2 md:mt-0">
                              <div className="hrp-profile-shere cursor-pointer">
                                <svg
                                  onClick={(e) => handleShare(e)}
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 22C17.1667 22 16.4583 21.7083 15.875 21.125C15.2917 20.5417 15 19.8333 15 19C15 18.8833 15.0083 18.7623 15.025 18.637C15.0417 18.5117 15.0667 18.3993 15.1 18.3L8.05 14.2C7.76667 14.45 7.45 14.646 7.1 14.788C6.75 14.93 6.38333 15.0007 6 15C5.16667 15 4.45833 14.7083 3.875 14.125C3.29167 13.5417 3 12.8333 3 12C3 11.1667 3.29167 10.4583 3.875 9.875C4.45833 9.29167 5.16667 9 6 9C6.38333 9 6.75 9.071 7.1 9.213C7.45 9.355 7.76667 9.55067 8.05 9.8L15.1 5.7C15.0667 5.6 15.0417 5.48767 15.025 5.363C15.0083 5.23833 15 5.11733 15 5C15 4.16667 15.2917 3.45833 15.875 2.875C16.4583 2.29167 17.1667 2 18 2C18.8333 2 19.5417 2.29167 20.125 2.875C20.7083 3.45833 21 4.16667 21 5C21 5.83333 20.7083 6.54167 20.125 7.125C19.5417 7.70833 18.8333 8 18 8C17.6167 8 17.25 7.92933 16.9 7.788C16.55 7.64667 16.2333 7.45067 15.95 7.2L8.9 11.3C8.93333 11.4 8.95833 11.5127 8.975 11.638C8.99167 11.7633 9 11.884 9 12C9 12.116 8.99167 12.237 8.975 12.363C8.95833 12.489 8.93333 12.6013 8.9 12.7L15.95 16.8C16.2333 16.55 16.55 16.3543 16.9 16.213C17.25 16.0717 17.6167 16.0007 18 16C18.8333 16 19.5417 16.2917 20.125 16.875C20.7083 17.4583 21 18.1667 21 19C21 19.8333 20.7083 20.5417 20.125 21.125C19.5417 21.7083 18.8333 22 18 22Z"
                                    fill="#003366"
                                  />
                                </svg>
                              </div>
                              <div className="hrp-profile-shere cursor-pointer">
                                <FeatherIcon
                                  icon="download"
                                  onClick={(e) => downloadCV(Profiles)}
                                />
                              </div>
                            </div>
                          )} */}
                        </div>
                        {Profiles?.skills.length > 0 ? (
                          <>
                            <h3 className="hrp-heading-color hrp-user-name font-semibold flex md:mt-2 lg:mt-5">
                              Skill sets
                            </h3>
                            <div className="mt-2">
                              <div className="mt-2 flex flex-wrap gap-2">
                                {Profiles?.skills.map((skill) => (
                                  <span
                                    key={skill}
                                    className=" hrp-heading-color hrp-profile-skill lg:px-3 lg:py-2  md:py-1 md:px-2 rounded-full text-sm bg-white"
                                  >
                                    {skill}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {Profiles?.languages.length > 0 ? (
                          <div className="mt-6">
                            <h3 className="hrp-heading-color text-2xl font-semibold">
                              Language Known
                            </h3>
                            <div className="mt-2 flex flex-wrap gap-2">
                              {Profiles?.languages.map((lang) => (
                                <span
                                  key={lang}
                                  className=" hrp-heading-color hrp-profile-skill px-3 py-2 rounded-full text-sm"
                                >
                                  {lang}
                                </span>
                              ))}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Section>
                  {Profiles?.tools?.length > 0 && (
                    <section className=" hrp-container hrp-section-1  md:py-8">
                      <div className="hrp-sub-section  ">
                        <div className="lg:flex   gap-10  container m-auto ">
                          <div className="lg:w-1/4">
                            <h2 className="hrp-heading-color text-center lg:text-2xl px-10 mb-6">
                              Applications & Tools Known
                            </h2>
                          </div>
                          <div className="lg:w-3/4 md:mx-10">
                            <div className="grid lg:grid-cols-7 md:grid-cols-5 grid-cols-2 gap-6  md:ml-3 md:mr-0  mx-4">
                              {Profiles?.tools?.map((profileTool, index) => {
                                const matchedTool = tools?.find(
                                  (tool) => tool.name === profileTool
                                );

                                return (
                                  <div
                                    className="hrp-main-section"
                                    key={
                                      matchedTool?.id ||
                                      `${profileTool}-${index}`
                                    }
                                  >
                                    <div className="hrp-container">
                                      <div className="hrp-sub-section">
                                        <div className="flex flex-col items-center justify-center p-4 hrp-Applications-prfile">
                                          <div className="h-12 w-12 flex justify-center items-center">
                                            {matchedTool?.icon ? (
                                              matchedTool.icon
                                            ) : (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                              >
                                                <path d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4l54.1 0 109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109 0-54.1c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7L352 176c-8.8 0-16-7.2-16-16l0-57.4c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
                                              </svg>
                                            )}
                                          </div>
                                          <p className="text-center text-gray-700">
                                            {matchedTool?.name || profileTool}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </Section>
                {/* ------------title="Major Projects"---------------------- */}
                {Profiles?.projects?.length > 0 ? (
                  <Section>
                    <section className=" hrp-container hrp-section-1  md:py-8">
                      <div className="hrp-sub-section  ">
                        <div className="md:flex  gap-3 md:gap-10 container m-auto">
                          <div className="w-1/4">
                            <h2 className=" hrp-heading-color text-center lg:text-2xl px-10 mb-6">
                              Major Projects
                            </h2>
                          </div>
                          <div className="w-3/4">
                            <div className="  ml-3">
                              {Profiles?.projects.map((project) => (
                                <div className=" md:flex space-x-4   items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className=" relative  flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="flex justify-between ">
                                      <div className="">
                                        <h3 className="text-xl pb-2 hrp-company-name">
                                          {project?.title}
                                        </h3>
                                        <p className=" mb-2 hrp-company-name">
                                          {project?.company}
                                        </p>
                                      </div>

                                      <div className=" justify-between  text-gray-600 mb-4">
                                        <div>{project?.duration}</div>
                                        {/* <div className=" flex justify-end">{months}</div> */}
                                      </div>
                                    </div>
                                    <p className="hrp-description pb-5 pt-1  hrp-responcive-description ">
                                      {project?.description}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </Section>
                ) : (
                  ""
                )}
                {/* ------------------------title="Work History"------------------------ */}
                {Profiles?.employment?.length > 0 ? (
                  <Section>
                    <section className=" hrp-container hrp-section-1  md:py-8">
                      <div className="hrp-sub-section  ">
                        <div className="md:flex   md:gap-10 container m-auto">
                          <div className="md:w-1/4">
                            <h2 className=" hrp-heading-color text-center lg:text-2xl md:px-10 mb-6     hrp-mobile-responcive-heading">
                              Work History
                            </h2>
                          </div>
                          <div className="m-auto w-3/4">
                            <div className="  ml-3">
                              {Profiles?.employment.map((job) => (
                                <div className=" md:flex space-x-4   items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    {/* <div className="absolute left-0 h-full w-0.5 bg-yellow-500 transform -translate-x-2/3"></div> */}
                                    <div className=" relative  flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="md:flex justify-between pb-3">
                                      <div className="">
                                        <h3 className="text-xl pb-2 hrp-company-name">
                                          {job?.role}
                                        </h3>
                                        <p className=" mb-2 hrp-company-name">
                                          {job?.company}
                                        </p>
                                      </div>

                                      <div className=" justify-between  text-gray-600 mb-4">
                                        <div>{job?.duration}</div>
                                        {/* <div className=" flex justify-end">{years}</div> */}
                                      </div>
                                    </div>
                                    <p className="hrp-description pb-5 pt-1  hrp-responcive-description ">
                                      {job?.description}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </Section>
                ) : (
                  ""
                )}

                {Profiles?.accomplishments?.length > 0 &&
                Profiles.accomplishments.some(
                  (acc) =>
                    acc.online_profile?.length > 0 ||
                    acc.work_sample?.length > 0 ||
                    acc.research_publication?.length > 0 ||
                    acc.presentation?.length > 0 ||
                    acc.patent?.length > 0 ||
                    acc.certification?.length > 0
                ) ? (
                  <Section>
                    <section className="hrp-container hrp-section-1 md:py-8">
                      <div className="hrp-sub-section">
                        <div className="md:flex gap-3 md:gap-10 container m-auto">
                          <div className="md:w-1/4">
                            <h2 className="hrp-heading-color text-center lg:text-2xl md:px-10 mb-6     hrp-mobile-responcive-heading">
                              Accomplishments
                            </h2>
                          </div>
                          <div className="m-auto w-3/4">
                            {/* Online Profiles */}
                            {Profiles?.accomplishments[0].online_profile
                              ?.length > 0 && (
                              <div className="ml-3 mb-6">
                                <h2
                                  className="text-xl font-bold mb-4 capitalize"
                                  style={{ color: "#343434" }}
                                >
                                  Online Profiles
                                </h2>
                                {Profiles.accomplishments[0].online_profile.map(
                                  (profile, index) => (
                                    <div
                                      key={index}
                                      className="md:flex space-x-4 items-start hrp-border-left mb-4"
                                    >
                                      <div className="relative hrp-position flex-shrink-0">
                                        <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                      </div>
                                      <div className="w-full">
                                        <h3 className="text-xl pb-2 hrp-company-name">
                                          {profile.profile_type}
                                        </h3>
                                        <p className="mb-2 hrp-company-name">
                                          <a
                                            href={profile.profile_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {profile.profile_url}
                                          </a>
                                        </p>
                                        <p className="mb-2 hrp-company-name">
                                          {profile.profile_description}
                                        </p>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {/* Work Samples */}
                            {Profiles?.accomplishments?.[0]?.work_sample
                              ?.length > 0 && (
                              <div className="ml-3 mb-6">
                                <h2
                                  className="text-xl font-bold mb-4 capitalize"
                                  style={{ color: "#343434" }}
                                >
                                  Work Samples
                                </h2>
                                {Profiles.accomplishments[0].work_sample.map(
                                  (work, index) => (
                                    <div
                                      key={index}
                                      className="md:flex space-x-4 items-start hrp-border-left mb-4"
                                    >
                                      <div className="relative hrp-position flex-shrink-0">
                                        <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                      </div>
                                      <div className="w-full">
                                        <h3 className="text-xl pb-2 hrp-company-name">
                                          {work.sample_title}
                                        </h3>
                                        <p className="hrp-description pt-1">
                                          <a
                                            href={work.link_to_sample}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {work.link_to_sample}
                                          </a>
                                        </p>
                                        <p className="hrp-description pb-5 pt-1">
                                          {work.sample_description}
                                        </p>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {/* Research Publications */}
                            {Profiles?.accomplishments?.[0]
                              ?.research_publication?.length > 0 && (
                              <div className="ml-3 mb-6">
                                <h2
                                  className="text-xl font-bold mb-4 capitalize"
                                  style={{ color: "#343434" }}
                                >
                                  Research Publications
                                </h2>
                                {Profiles.accomplishments[0].research_publication.map(
                                  (pub, index) => (
                                    <div
                                      key={index}
                                      className="md:flex space-x-4 items-start hrp-border-left mb-4"
                                    >
                                      <div className="relative hrp-position flex-shrink-0">
                                        <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                      </div>
                                      <div className="w-full">
                                        <h3 className="text-xl pb-2 hrp-company-name">
                                          {pub.title}
                                        </h3>
                                        <p className="mb-2 hrp-company-name">
                                          <a
                                            href={pub.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {pub.url}
                                          </a>
                                        </p>
                                        <p className="hrp-description pt-1">
                                          Author: {pub.author}
                                        </p>
                                        <p className="hrp-description pt-1">
                                          Journal: {pub.journal}
                                        </p>
                                        <p className="hrp-description pt-1">
                                          Abstract: {pub.abstract}
                                        </p>
                                        <p className="hrp-description pb-5 pt-1">
                                          {pub.description}
                                        </p>
                                        <div className="text-gray-600 mb-4">
                                          <div>
                                            Published on: {pub.publish_date}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {/* Presentations */}
                            {Profiles?.accomplishments?.[0]?.presentation
                              ?.length > 0 && (
                              <div className="ml-3 mb-6">
                                <h2
                                  className="text-xl font-bold mb-4 capitalize"
                                  style={{ color: "#343434" }}
                                >
                                  Presentations
                                </h2>
                                {Profiles.accomplishments[0].presentation.map(
                                  (pres, index) => (
                                    <div
                                      key={index}
                                      className="md:flex space-x-4 items-start hrp-border-left mb-4"
                                    >
                                      <div className="relative hrp-position flex-shrink-0">
                                        <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                      </div>
                                      <div className="w-full">
                                        <h3 className="text-xl pb-2 hrp-company-name">
                                          {pres.title}
                                        </h3>
                                        <p className="mb-2 hrp-company-name">
                                          Presenter: {pres.presenter}
                                        </p>
                                        <p className="hrp-description pb-5 pt-1">
                                          <a
                                            href={pres.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {pres.link}
                                          </a>
                                        </p>
                                        <p className="hrp-description pb-5 pt-1">
                                          {pres.description}
                                        </p>
                                        <div className="text-gray-600 mb-4">
                                          <div>
                                            Event Date: {pres.event_date}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {/* Patents */}
                            {Profiles?.accomplishments?.[0]?.patent?.length >
                              0 && (
                              <div className="ml-3 mb-6">
                                <h2
                                  className="text-xl font-bold mb-4 capitalize"
                                  style={{ color: "#343434" }}
                                >
                                  Patents
                                </h2>
                                {Profiles.accomplishments[0].patent.map(
                                  (pat, index) => (
                                    <div
                                      key={index}
                                      className="md:flex space-x-4 items-start hrp-border-left mb-4"
                                    >
                                      <div className="relative hrp-position flex-shrink-0">
                                        <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                      </div>
                                      <div className="w-full">
                                        <h3 className="text-xl pb-2 hrp-company-name">
                                          {pat.title}
                                        </h3>
                                        <p className="mb-2 hrp-company-name">
                                          Patent Number: {pat.patent_number}
                                        </p>
                                        <p className="mb-2 hrp-company-name">
                                          Inventors: {pat.inventor}
                                        </p>
                                        <p className="hrp-description pb-5 pt-1">
                                          {pat.description}
                                        </p>
                                        <div className="text-gray-600 mb-4">
                                          <div>
                                            Date Granted: {pat.date_granted}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {/* Certifications */}
                            {Profiles?.accomplishments?.[0]?.certification
                              ?.length > 0 && (
                              <div className="ml-3 mb-6">
                                <h2
                                  className="text-xl font-bold mb-4 capitalize"
                                  style={{ color: "#343434" }}
                                >
                                  Certifications
                                </h2>
                                {Profiles.accomplishments[0].certification.map(
                                  (cert, index) => (
                                    <div
                                      key={index}
                                      className="md:flex space-x-4 items-start hrp-border-left mb-4"
                                    >
                                      <div className="relative hrp-position flex-shrink-0">
                                        <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                      </div>
                                      <div className="w-full">
                                        <h3 className="text-xl pb-2 hrp-company-name">
                                          {cert.certificate_name}
                                        </h3>
                                        <p className="mb-2 hrp-company-name">
                                          Certification ID:{" "}
                                          {cert.certification_id}
                                        </p>
                                        <p className="hrp-description pb-5 pt-1">
                                          {cert.description}
                                        </p>
                                        <div className="text-gray-600 mb-4">
                                          <div>
                                            Date Issued: {cert.issue_date}
                                          </div>
                                          <div>Issued By: {cert.issuer}</div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  </Section>
                ) : null}

                {/* ---------------------title="Education"-------------------------------------------- */}
                <Section>
                  <section className=" hrp-container hrp-section-1  md:py-8">
                    <div className="hrp-sub-section  ">
                      <div className="md:flex  gap-3 md:gap-10 container m-auto ">
                        <div className="md:w-1/4">
                          <h2 className=" hrp-heading-color text-center lg:text-2xl md:px-10 mb-6     hrp-mobile-responcive-heading">
                            Education
                          </h2>
                        </div>
                        <div className="m-auto w-3/4">
                          <div className="  ml-3">
                            {Profiles?.education.map((edu) => (
                              <div className=" md:flex space-x-4   items-start hrp-border-left">
                                <div className="relative hrp-position flex-shrink-0">
                                  {/* <div className="absolute left-0 h-full w-0.5 bg-yellow-500 transform -translate-x-2/3"></div> */}
                                  <div className=" relative  flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                </div>
                                <div className="w-full">
                                  <div className="md:flex justify-between pb-3">
                                    <div className="">
                                      <h3 className="text-xl  hrp-heading-color">
                                        {edu?.degree}
                                      </h3>
                                    </div>
                                  </div>
                                  <p className="hrp-description pb-5 pt-1  hrp-responcive-description ">
                                    {edu?.institution}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </Section>
                {/* -----------------------------title="Interests"------------------------------------------ */}
                <Section>
                  <section className=" hrp-container hrp-section-1  md:py-8">
                    <div className="hrp-sub-section  ">
                      <div className="md:flex  gap-3 md:gap-10 container m-auto">
                        <div className="md:w-1/4">
                          <h2 className=" hrp-heading-color text-center lg:text-2xl md:px-10 mb-6     hrp-mobile-responcive-heading">
                            Interests
                          </h2>
                        </div>
                        <div className="m-auto w-3/4">
                          <div className="md:flex md:space-x-3 space-y-3 md:space-y-0 md:ml-3">
                            {interests.map((interest) => (
                              <InterestBadge key={interest} name={interest} />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </Section>
                {/* --------------------------title="Awards"-------------------------------- */}
                <Section>
                  <section className=" hrp-container hrp-section-1  md:py-8">
                    <div className="hrp-sub-section  ">
                      <div className="md:flex container  md:gap-10 m-auto">
                        <div className="md:w-1/4">
                          <h2 className=" hrp-heading-color text-center lg:text-2xl md:px-10 mb-6     hrp-mobile-responcive-heading">
                            Awards
                          </h2>
                        </div>
                        <div className="m-auto w-3/4">
                          <div className="  ml-3">
                            {awards.map((award) => (
                              <Award key={award.title} {...award} />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </Section>
                {/* --------------------------title="career profile"-------------------------------- */}
                {/* {console.log(Profiles)} */}
                {Profiles?.careerProfile != {} &&
                Profiles?.careerProfile != null &&
                Profiles?.careerProfile != undefined ? (
                  <Section>
                    <section className="hrp-container hrp-section-1 md:py-8">
                      <div className="hrp-sub-section">
                        <div className="md:flex container md:gap-10 m-auto">
                          <div className="md:w-1/4">
                            <h2 className="hrp-heading-color text-center lg:text-2xl md:px-10 mb-6     hrp-mobile-responcive-heading">
                              Career Profile
                            </h2>
                          </div>
                          <div className="m-auto w-3/4">
                            <div className="ml-3">
                              {/* Industry */}
                              {Profiles?.careerProfile?.industry && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Industry
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {Profiles?.careerProfile?.industry}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Department */}
                              {Profiles?.careerProfile?.department && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Department
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {Profiles?.careerProfile?.department}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Role */}
                              {Profiles?.careerProfile?.role && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Role
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {Profiles?.careerProfile?.role}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Job Type */}
                              {Profiles?.careerProfile?.jobType && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Job Type
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {Profiles?.careerProfile?.jobType}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Desired Job Type */}
                              {Profiles?.careerProfile?.desired_jobtype && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Desired Job Type
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.desired_jobtype
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Employment Type */}
                              {Profiles?.careerProfile?.employmentType && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Employment Type
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.employmentType
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Shift */}
                              {Profiles?.careerProfile?.shift && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Shift
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {Profiles?.careerProfile?.shift}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Preferred Work Location */}
                              {Profiles?.careerProfile
                                ?.preferred_work_location && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Preferred Work Location
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.preferred_work_location
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Expected Salary */}
                              {Profiles?.careerProfile?.expectedSalary && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Expected Salary
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.expectedSalary
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Current Salary */}
                              {Profiles?.careerProfile?.currentSalary && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Current Salary
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {Profiles?.careerProfile?.currentSalary}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Current Job Status */}
                              {Profiles?.careerProfile?.currentJobStatus && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Current Job Status
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.currentJobStatus
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Job Availability */}
                              {Profiles?.careerProfile?.jobAvailability && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Job Availability
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.jobAvailability
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Job Seeking Status */}
                              {Profiles?.careerProfile?.jobSeekingStatus && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Job Seeking Status
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.jobSeekingStatus
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Employment Status */}
                              {Profiles?.careerProfile?.employmentStatus && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Employment Status
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.employmentStatus
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </Section>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>,
          container
        );
        html2canvas(container, { useCORS: true }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 210;
          const pageHeight = 295;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = 0;

          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          const originalString = Profiles?.jobTitle || "Profile";
          const modifiedString = originalString.replace(/ /g, "-");
          pdf.save(`${Profiles?.firstName}-${modifiedString}.pdf`);
          document.body.removeChild(container);
        });
      });
    },
  }));
  return <div></div>;
});

export default ProfileDownload;
