import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
import ApplyJobs from "../popup/Applyjob";
import { usePopup } from "../Common/PopupContext";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const domainpath = process.env.REACT_APP_DOMAIN;
const Savedjobs = () => {
  const { openPopup } = usePopup();
  const [popupComponent, setPopupComponent] = useState(null);
  const User = getUserData();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [data, setData] = useState([]);

  useEffect(() => {
    const userid = User._id;
    async function getjobs() {
      try {
        const response = await api.saveAllJob(userid);
        if (response.status !== 400) {
          setData(response.savedJobs);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getjobs();
  }, []);
  const handleClosePopup = (message) => {
    setPopupComponent(null);
    openPopup();
  };
  const jobApply = (jobid) => {
    const popup = (
      <ApplyJobs
        onClose={() => handleClosePopup("Popup closed")}
        JobId={jobid}
      />
    );

    setPopupComponent(popup);
    openPopup(popup);
  };
  const handleUnsavedJOb = async (e, jobid) => {
    var id = User?._id;
    e.preventDefault();
    try {
      const res = await api.unsavedJOb(id, jobid);
      if (res.status === 200) {
        toast.success(res.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);

        setLoading(false);
      } else {
        console.error("Error updating staff:", res.message);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "Job Title",
      selector: (row) => (
        <a
          href={domainpath + "/jobs/" + row?.jobId}
          target="_blank"
          style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
        >
          {row.title}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.company,
      sortable: true,
    },
    {
      name: "Saved Date",
      selector: (row) => new Date(row.savedDate).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-1 ">
          <button
            className="manage-edit-btn text-white px-2 py-2"
            onClick={(e) => jobApply(row.jobId)}
          >
            Apply
          </button>
          <button
            className="manage-delete-btn text-white  px-2 lg:px-6 py-2"
            onClick={(e) => handleUnsavedJOb(e, row?.jobId)}
          >
            Remove
          </button>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="p-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
          Manage Jobs{" "}
        </h2>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Savedjobs;
