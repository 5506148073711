import React, { useEffect, useState } from "react";
import api from "../../Api.js";
import default123 from "../../image/DefaultImage.png";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const Blogs = () => {
  const [AllBlogs, setAllBlogs] = useState([]);
  useEffect(() => {
    async function GetAllBlogs() {
      const response = await api.getBlogs();
      if (response.status == 200) {
        const blogsData = response?.blogs;
        console.log(blogsData);
        setAllBlogs(blogsData);
      }
    }
    GetAllBlogs();
  }, []);
  function extractFirst30Words(htmlContent) {
    // Create a temporary element to hold the HTML content
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;

    // Traverse through child nodes to extract the text content and preserve inline styles
    let wordCount = 0;
    const first30WordsHTML = [];

    function traverseNodes(node) {
      if (wordCount >= 30) return;

      if (node.nodeType === Node.TEXT_NODE) {
        // Split text content into words
        const words = node.textContent.trim().split(/\s+/);
        for (const word of words) {
          if (wordCount < 30) {
            first30WordsHTML.push(word);
            wordCount++;
          }
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        // Skip img tags
        if (node.tagName.toLowerCase() === "img") {
          return;
        }

        // Preserve inline styles
        const clonedNode = node.cloneNode(false);
        clonedNode.style.cssText = node.style.cssText;

        // Add opening tag of the cloned node to the result
        first30WordsHTML.push(clonedNode.outerHTML);

        // Traverse child nodes
        node.childNodes.forEach((child) => traverseNodes(child));

        // Add closing tag of the cloned node to the result
        first30WordsHTML.push(`</${clonedNode.tagName.toLowerCase()}>`);
      }
    }

    // Start traversing from the body element
    tempElement.childNodes.forEach((child) => traverseNodes(child));

    // Join the extracted words and return the HTML string, removing any extra spaces
    return first30WordsHTML.join(" ").replace(/\s+/g, " ").trim();
  }
  return (
    <>
      <div className="container mx-auto p-6">
        <div>
          <p
            className="text-center text-2xl font-bold my-10"
            style={{ color: "#003366" }}
          >
            Blogs
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {AllBlogs?.map((item) => (
            <div
              className=" p-3 overflow-hidden shadow-lg cursor-pointer hover:shadow-xl  card_blog hrp-card_blog"
              key={item.id}
              onClick={(e) => (window.location.href = `/blogs/${item?.slug}`)}
            >
              <div className="w-full">
                <img
                  src={
                    item?.featured_image !== "null" &&
                    item?.featured_image &&
                    item?.featured_image.length > 0
                      ? domainpath + "/uploads/" + item.featured_image
                      : default123
                  }
                  alt="Blog"
                  className="w-full h-48 object-cover"
                />
              </div>
              <div className=" blogs_content">
                <a
                  key={item.id}
                  onClick={() => (window.location.href = `/blog/${item?.slug}`)}
                >
                  <h3 className="">{item?.title}</h3>
                </a>
                {/* Extracting the first 30 words from HTML content */}
                {item.html && (
                  <p
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: extractFirst30Words(item.html),
                    }}
                  ></p>
                )}
              </div>
              <div className="hrp-read-more">
                <p className="">Read More</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Blogs;
