import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
const Savedjobs = () => {
  const User = getUserData();
  const [data, setData] = useState([]);
  console.log(User);
  useEffect(() => {
    const userid = User._id;
    async function getjobs() {
      try {
        const response = await api.saveAllJob(userid);
        console.log(response);
        if (response.status !== 400) {  
          setData(response.savedJobs);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getjobs();
  }, []);
  const columns = [
    {
      name: "Alert ID",
      selector: (row) => row.alertId,
      sortable: true,
    },
    {
      name: "Job Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.company.name,
      sortable: true,
    },
    {
      name: "Alert Date",
      selector: (row) => row.alertDate,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-1">
          <button className="manage-edit-btn text-white px-2 py-2">
            View Alert
          </button>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="p-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
          Manage Jobs{" "}
        </h2>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
    </div>
  );
};

export default Savedjobs;
