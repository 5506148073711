import React, { useState, useEffect } from "react";
import api from "../../../Api";
import { getUserData } from "../../../helpers/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Enroll = ({ onClose, onSave, course }) => {
  const User = getUserData(); // Retrieve user data

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    query: "",
  });

  // UseEffect to set default values if User is not null or undefined
  useEffect(() => {
    if (User) {
      setFormData({
        firstName: User.name ? User.name.split(" ")[0] : "", // Assuming name is "FirstName LastName"
        lastName: User.name ? User.name.split(" ")[1] : "",
        mobileNumber: formatPhoneNumber(User.phone) || "",
        email: User.email || "",
        query: "",
      });
    }
    // Only run this effect once when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function formatPhoneNumber(value) {
    // Remove all non-numeric characters from the input
    const cleaned = ("" + value).replace(/\D/g, "");

    // Limit the input to 11 digits
    const limited = cleaned.substring(0, 11);

    // Check if the input has the correct length for formatting
    if (limited.length >= 10) {
      // Format the number in the 123-456-7890 format
      return `${limited.slice(0, 3)}-${limited.slice(3, 6)}-${limited.slice(
        6,
        10
      )}`;
    } else if (limited.length > 6) {
      // Format the number partially as 123-456-
      return `${limited.slice(0, 3)}-${limited.slice(3, 6)}-${limited.slice(
        6
      )}`;
    } else if (limited.length > 3) {
      // Format the number partially as 123-
      return `${limited.slice(0, 3)}-${limited.slice(3)}`;
    } else {
      // Return the cleaned input if less than 3 digits
      return limited;
    }
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "mobileNumber") {
      // Format the mobile number
      const formattedNumber = formatPhoneNumber(value);

      // Set the formatted number in formData
      setFormData({
        ...formData,
        [id]: formattedNumber,
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.courseId = course._id;
    try {
      const response = await api.enroll(formData);
      if (response.status == 201) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        onSave(formData);
        console.log("Enrollment successful", response.data);
        onClose("");
      }
    } catch (error) {
      console.error("Enrollment failed", error.response.data);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      onClose("");
    } finally {
      onClose("");
    }
  };

  return (
    <div className="flex justify-center h-screen login_inform-inner m-auto p-20 job-page">
      <div className="flex">
        <div className="form_upto jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <>
              <h1 className="hrp-heading-size text-center hrp-About">
                Submit Enrollment Request
              </h1>
              <form
                onSubmit={handleSubmit}
                className="register-form-section animated-form"
              >
                <div className="grid grid-cols-2 gap-5">
                  <div className="form-group">
                    <input
                      type="text"
                      id="firstName"
                      placeholder=""
                      value={formData.firstName}
                      onChange={handleChange}
                      className="shadow w-full border rounded py-2 px-3"
                      required
                    />
                    <label htmlFor="firstName" className="form-input-label">
                      First Name
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      id="lastName"
                      placeholder=""
                      required
                      value={formData.lastName}
                      onChange={handleChange}
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label htmlFor="lastName" className="form-input-label">
                      Last Name
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="tel"
                      id="mobileNumber"
                      placeholder=""
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      className="shadow w-full border rounded py-2 px-3"
                      required
                      inputMode="tel"
                      pattern="\d{3}-\d{3}-\d{4}"
                      title="Phone number should be in the format: 123-456-7890"
                    />
                    <label htmlFor="mobileNumber" className="form-input-label">
                      Mobile Number
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      placeholder=""
                      value={formData.email}
                      onChange={handleChange}
                      className="shadow w-full border rounded py-2 px-3"
                      required
                    />
                    <label htmlFor="email" className="form-input-label">
                      Email
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    id="query"
                    placeholder=""
                    value={formData.query}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    rows={8}
                  />
                  <label htmlFor="query" className="form-input-label">
                    Query
                  </label>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="hrp-find-course-btn py-3 px-10 rounded-full"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
        <button
          className="text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Enroll;
