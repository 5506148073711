import React, { useState, useEffect } from "react";
import api from "../../Api";
import SearchCourse from "../Website/SearchCourseForm";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const clientdomainpath = process.env.REACT_APP_DOMAIN;

const CoursePage = () => {
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [searchjobdata, setsearchjobdata] = useState([]);

  useEffect(() => {
    async function getCourses() {
      try {
        const response = await api.getAllCourses();
        if (response.status !== 400) {
          setdata(response);
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getCourses();
  }, []);

  function CourseCard({ course }) {
    return (
      <div className="bg-white shadow-md rounded-lg p-3 flex mb-4 hrp-find-course-card ">
        <div>
          <a href={clientdomainpath + "/courses/" + course._id}>
            {" "}
            <img
              src={domainpath + "/uploads/" + course.courseThumbnail}
              alt={`${course.courseTitle} course image`}
              className="w-80 h-60 object-cover rounded-t-md"
            />
          </a>
        </div>
        <div className="ml-4 w-full flex">
          <div className="w-4/5 pr-10">
            <div>
              <a href={clientdomainpath + "/courses/" + course._id}>
                <h2 className="hrp-find-course-title">{course.courseTitle}</h2>
              </a>
            </div>
            <p className="mt-1 hrp-find-cours-card-description">
              {course.courseDescription}
            </p>
            <p className="hrp-find-course-card-instructor mt-1">
              {course.instructorName}
            </p>
            {/* <div className="flex items-center mt-2 hrp-rating-section">
              <div className="flex items-center">
                <span>{course.rating}</span> &nbsp;
                <span className="hrp-course-card-rating flex">
                  {Array.from({ length: Math.floor(course.rating) }, (_, i) => (
                    <svg
                      key={i}
                      className="w-4 h-4 fill-current"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 .587l3.668 7.455 8.165 1.193-5.915 5.76L19.735 24 12 20.02 4.265 24l1.817-8.995-5.915-5.76 8.165-1.193z" />
                    </svg>
                  ))}
                </span>
                <span className="text-gray-500">(93,973 ratings)</span>
              </div>
            </div> */}
            {/* <p className="mt-1 hrp-find-course-card-color">
              {Array.isArray(course.courseDuration)
                ? course.courseDuration.join(", ")
                : course.courseDuration}{" "}
              - {course.courseCurriculum.length} Lectures -{" "}
              {Array.isArray(course.courseLevel)
                ? course.courseLevel.join(", ")
                : course.courseLevel}{" "}
              Level
            </p> */}
            <div className="flex flex-wrap	">
              {course.courseLevel != ""
                ? course.courseLevel.map((level, index) => (
                    <p
                      key={index}
                      className="border-t-2 rounded-full px-2 py-1 m-1"
                      style={{
                        color: "#003366",
                        border: "1px solid #003366",
                        fontSize: "13px",
                      }}
                    >
                      {level}
                    </p>
                  ))
                : ""}
            </div>
            <div className="mt-4 flex items-center">
              <span className="text-lg font-bold hrp-course-card-price">
                £{course.discountPrice.toFixed(2)}
              </span>
              <span className="ml-2 text-sm line-through hrp-course-card-originalPrice">
                £{course.price.toFixed(2)}
              </span>
            </div>
          </div>
          <div className="w-1/5 flex justify-end items-center">
            <div className="items-center mt-4 hrp-card-originalPrice">
              {/* <div className="flex text-center justify-center">
                <span className="font-bold">£{course.price}</span>
                &nbsp;
                <span className="text-gray-400 line-through ml-2">
                  £{course.discountPrice}
                </span>
              </div> */}
              <a href={`/courses/${course._id}`}>
                <button className="hrp-find-course-btn px-10 py-3 rounded-full">
                  Quick view
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function CourseList() {
    return (
      <div className="container mx-auto p-4">
        {searchjobdata.map((course, index) => (
          <CourseCard key={index} course={course} />
        ))}
      </div>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Show 4 courses per slide
    slidesToScroll: 1, // Scroll 4 courses at a time
    arrows: true, // Optional: Add arrows for easier navigation
    responsive: [
      {
        breakpoint: 1024, // For larger screens
        settings: {
          slidesToShow: 3, // Show 3 courses per slide on medium screens
          slidesToScroll: 3, // Scroll 3 at a time
        },
      },
      {
        breakpoint: 768, // For tablet screens
        settings: {
          slidesToShow: 2, // Show 2 courses per slide on tablets
          slidesToScroll: 2, // Scroll 2 at a time
        },
      },
      {
        breakpoint: 480, // For mobile screens
        settings: {
          slidesToShow: 1, // Show 1 course per slide on small screens
          slidesToScroll: 1, // Scroll 1 at a time
        },
      },
    ],
  };
  const Courses = () => {
    return (
      <div className="w-full mb-10 hrp-course-card-outer">
        {/* Slider */}
        <Slider {...settings}>
          {data.map((course, index) => {
            const originalPrice = course.discountPrice
              ? course.price.toFixed(2)
              : null;

            return (
              <div
                key={index}
                className="coursecard p-4 w-full h-full max-w-xs mx-auto my-4 border border-gray-300 rounded-md shadow-md"
                style={{ minHeight: "400px" }}
              >
                {/* Course Link */}
                <a href={clientdomainpath + "/courses/" + course._id}>
                  {/* Course Thumbnail */}
                  <img
                    className="imgse renderCategoryEdit text uploadImage-logo"
                    src={
                      course.courseThumbnail &&
                      course.courseThumbnail.trim() !== "" &&
                      course.courseThumbnail !== "NA"
                        ? course.courseThumbnail.startsWith("http://") ||
                          course.courseThumbnail.startsWith("https://")
                          ? course.courseThumbnail
                          : course.courseThumbnail.startsWith("/uploads/")
                          ? `${domainpath}${course.courseThumbnail}`
                          : `${domainpath}/uploads/${course.courseThumbnail}`
                        : `${domainpath}/uploads/defaultblog.png` // Use default if the logo is not present
                    }
                    alt="Course"
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite loop
                      e.target.src = `${domainpath}/uploads/defaultblog.png`; // Use the default image on error
                    }}
                  />
                </a>

                {/* Course Details */}
                <div className="py-4">
                  <a href={"/courses/" + course._id}>
                    <h3 className="text-lg font-bold hrp-course-card-title">
                      {course.courseTitle}
                    </h3>
                  </a>
                  <p className="mt-1 hrp-find-cours-card-description">
                    {course.courseDescription.includes("<img")
                      ? "Description contains an image. Please check the course details for more information."
                      : course.courseDescription
                          .split(" ")
                          .slice(0, 25)
                          .join(" ")}
                    {course.courseDescription.split(" ").length > 25 &&
                    !course.courseDescription.includes("<img")
                      ? "..."
                      : ""}
                  </p>
                  <p className="text-sm text-gray-600 hrp-course-card-instructor">
                    {course.instructorName}
                  </p>

                  {/* Pricing */}
                  <div className="mt-4 flex items-center">
                    <span className="text-lg font-bold text-green-600 hrp-course-card-price">
                      £{course.discountPrice.toFixed(2)}
                    </span>
                    {originalPrice && (
                      <span className="ml-2 text-sm line-through text-gray-500 hrp-course-card-originalPrice">
                        £{originalPrice}
                      </span>
                    )}
                  </div>

                  {/* Course Levels */}
                  <div className="hrp-card-tittle mt-2 flex flex-wrap">
                    {course.courseLevel &&
                      course.courseLevel.map(
                        (level, index) =>
                          level && (
                            <p
                              key={index}
                              className="border rounded-full px-2 py-1 m-1 text-sm text-blue-800 border-blue-800"
                              style={{
                                color: "#003366",
                                border: "1px solid #003366",
                                fontSize: "13px",
                              }}
                            >
                              {level}
                            </p>
                          )
                      )}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  };

  const handleFormData = (data) => {
    setsearchjobdata(data);
    console.log("Form Data from Child:", data);
  };

  return (
    <div className="hrp-main-section course-page">
      <div className="hrp-container">
        <div className="hrp-sub-section hrp-background-color">
          <div className="hrp-sub-section-container">
            <SearchCourse onSubmit={handleFormData} />
          </div>
        </div>
        {searchjobdata?.length > 0 ? (
          <>
            <div className="hrp-sub-section">
              <div className="m-auto container hrp-sub-section-container">
                <div className="joblistheader justify-between w-full p5-10">
                  <div className="joblistheader-first">
                    <h1>{searchjobdata?.length} results</h1>
                  </div>
                  <div className="joblistheader-second">
                    <button className="joblist-btnclear">Clear</button>
                    <span>Sort</span>
                    <select>
                      <option value="latest">Latest</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="hrp-sub-section">
              <div className="m-auto container hrp-sub-section-container pb-20">
                <CourseList />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="hrp-sub-section">
          <div className="m-auto container hrp-sub-section-container">
            <div>
              <div className="text-center hrp-broad-selection py-10">
                <h1>A broad selection of courses</h1>
                <p>
                  Choose from over 220,000 online video courses with new
                  additions published every month
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section">
          <div className="m-auto container hrp-sub-section-container flex">
            <Courses />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePage;
