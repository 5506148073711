const domainpath = "https://hirepros.co.uk";
export const jobSectors = [
  { value: "General", label: "General" },
  { value: "Information Technology", label: "Information Technology" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Finance", label: "Finance and Accounting" },
  { value: "Engineering", label: "Engineering" },
  { value: "Sales", label: "Sales and Marketing" },
  { value: "HR", label: "Human Resources" },
  { value: "Education", label: "Education and Training" },
  { value: "CustomerService", label: "Customer Service" },
  { value: "Design", label: "Design and Creative" },
  { value: "BusinessDev", label: "Business Development" },
  { value: "Legal", label: "Legal" },
  { value: "Manufacturing", label: "Manufacturing and Operations" },
  { value: "RandD", label: "Research and Development" },
  { value: "Consulting", label: "Consulting" },
  { value: "Media", label: "Media and Communications" },
  { value: "Construction", label: "Construction" },
  { value: "Hospitality", label: "Hospitality and Tourism" },
  { value: "Transportation", label: "Transportation and Logistics" },
  { value: "Retail", label: "Retail and Wholesale" },
  { value: "RealEstate", label: "Real Estate" },
  { value: "Government", label: "Government and Public Administration" },
  { value: "NonProfit", label: "Non-Profit and Social Services" },
  { value: "Energy", label: "Energy and Utilities" },
  { value: "Agriculture", label: "Agriculture and Forestry" },
  { value: "Environmental", label: "Environmental Services" },
  { value: "Telecommunications", label: "Telecommunications" },
  { value: "Automotive", label: "Automotive" },
  { value: "Aerospace", label: "Aerospace and Defense" },
  { value: "Pharmaceutical", label: "Pharmaceuticals" },
  { value: "Biotechnology", label: "Biotechnology" },
  { value: "Insurance", label: "Insurance" },
  { value: "Food", label: "Food and Beverage" },
  { value: "Arts", label: "Arts and Entertainment" },
  { value: "Sports", label: "Sports and Recreation" },
  { value: "Fashion", label: "Fashion and Apparel" },
  { value: "Beauty and Wellness", label: "Beauty and Wellness" }, // Added Beauty and Wellness
];

export const salaryRanges = [
  { label: "£15,000 - £20,000", value: "15000-20000" },
  { label: "£20,001 - £25,000", value: "20001-25000" },
  { label: "£25,001 - £30,000", value: "25001-30000" },
  { label: "£30,001 - £35,000", value: "30001-35000" },
  { label: "£35,001 - £40,000", value: "35001-40000" },
  { label: "£40,001 - £45,000", value: "40001-45000" },
  { label: "£45,001 - £50,000", value: "45001-50000" },
  { label: "£50,001 - £55,000", value: "50001-55000" },
  { label: "£55,001 - £60,000", value: "55001-60000" },
  { label: "£60,001 - £65,000", value: "60001-65000" },
  { label: "£65,001 - £70,000", value: "65001-70000" },
  { label: "£70,001 - £75,000", value: "70001-75000" },
  { label: "£75,001 - £80,000", value: "75001-80000" },
  { label: "£80,001 - £85,000", value: "80001-85000" },
  { label: "£85,001 - £90,000", value: "85001-90000" },
  { label: "£90,001 - £95,000", value: "90001-95000" },
  { label: "£95,001 - £100,000", value: "95001-100000" },
  { label: "£100,001 - £110,000", value: "100001-110000" },
  { label: "£110,001 - £120,000", value: "110001-120000" },
  { label: "£120,001 - £130,000", value: "120001-130000" },
  { label: "£130,001 - £140,000", value: "130001-140000" },
  { label: "£140,001 - £150,000", value: "140001-150000" },
  { label: "£150,001+", value: "150001+" },
];
export const radiusOptions = [
  { label: "1 mile", value: "1" },
  { label: "2 miles", value: "2" },
  { label: "5 miles", value: "5" },
  { label: "10 miles", value: "10" },
  { label: "15 miles", value: "15" },
  { label: "20 miles", value: "20" },
  { label: "25 miles", value: "25" },
  { label: "30 miles", value: "30" },
  { label: "35 miles", value: "35" },
  { label: "40 miles", value: "40" },
  { label: "45 miles", value: "45" },
  { label: "50 miles", value: "50" },
  { label: "60 miles", value: "60" },
  { label: "70 miles", value: "70" },
  { label: "80 miles", value: "80" },
  { label: "90 miles", value: "90" },
  { label: "100 miles", value: "100" },
];
export const jobCategories = {
  General: ["Administrative", "Management", "Operations", "Finance", "HR"],
  "Information Technology": [
    "Software Development",
    "Network Administration",
    "Database Management",
    "Cybersecurity",
    "Data Analysis",
    "IT Support",
  ],
  Healthcare: [
    "Nursing",
    "Medical Assisting",
    "Pharmacy",
    "Health Administration",
    "Medical Research",
    "Public Health",
  ],
  "Beauty and Wellness": [
    "Esthetics",
    "Massage Therapy",
    "Cosmetology",
    "Hair Styling",
    "Nail Technicians",
    "Spa Management",
    "Personal Training",
    "Nutrition",
    "Yoga and Pilates Instructors",
    "Skincare Specialists",
  ],
  Finance: [
    "Accounting",
    "Financial Analysis",
    "Investment Banking",
    "Risk Management",
    "Insurance",
    "Audit",
  ],
  Engineering: [
    "Mechanical Engineering",
    "Electrical Engineering",
    "Civil Engineering",
    "Chemical Engineering",
    "Structural Engineering",
    "Environmental Engineering",
  ],
  Sales: [
    "Sales Management",
    "Business Development",
    "Account Management",
    "Retail Sales",
    "Market Research",
    "Customer Relationship Management",
  ],
  HR: [
    "Recruitment",
    "Employee Relations",
    "Compensation and Benefits",
    "Training and Development",
    "HR Management",
    "Talent Acquisition",
  ],
  Education: [
    "Teaching",
    "Educational Administration",
    "Curriculum Development",
    "Instructional Design",
    "Special Education",
    "Education Counseling",
  ],
  CustomerService: [
    "Customer Support",
    "Call Center Management",
    "Technical Support",
    "Customer Success",
    "Client Relations",
    "Service Management",
  ],
  Design: [
    "Graphic Design",
    "UI/UX Design",
    "Web Design",
    "Product Design",
    "Illustration",
    "3D Modeling",
  ],
  BusinessDev: [
    "Business Strategy",
    "Market Analysis",
    "Partnership Development",
    "Sales Strategy",
    "Business Planning",
    "Client Acquisition",
  ],
  Legal: [
    "Corporate Law",
    "Criminal Law",
    "Family Law",
    "Legal Consulting",
    "Legal Research",
    "Contract Law",
  ],
  Manufacturing: [
    "Production Management",
    "Quality Assurance",
    "Supply Chain Management",
    "Operations Management",
    "Manufacturing Engineering",
    "Process Improvement",
  ],
  RandD: [
    "Research",
    "Product Development",
    "Innovation",
    "Clinical Research",
    "Technology Research",
    "Scientific Research",
  ],
  Consulting: [
    "Management Consulting",
    "Strategy Consulting",
    "IT Consulting",
    "Financial Consulting",
    "Human Resources Consulting",
    "Marketing Consulting",
  ],
  Media: [
    "Journalism",
    "Public Relations",
    "Content Creation",
    "Media Planning",
    "Broadcasting",
    "Digital Media",
  ],
  Construction: [
    "Project Management",
    "Site Management",
    "Construction Engineering",
    "Safety Management",
    "Estimating",
    "Contract Management",
  ],
  Hospitality: [
    "Hotel Management",
    "Event Planning",
    "Food and Beverage Management",
    "Tourism",
    "Guest Services",
    "Catering",
  ],
  Transportation: [
    "Logistics",
    "Supply Chain Management",
    "Fleet Management",
    "Transportation Planning",
    "Shipping and Receiving",
    "Cargo Management",
  ],
  Retail: [
    "Store Management",
    "Visual Merchandising",
    "Retail Sales",
    "Inventory Management",
    "Customer Service",
    "Buying and Merchandising",
  ],
  RealEstate: [
    "Real Estate Sales",
    "Property Management",
    "Real Estate Development",
    "Real Estate Investment",
    "Leasing",
    "Brokerage",
  ],
  Government: [
    "Public Administration",
    "Policy Analysis",
    "Public Relations",
    "Government Affairs",
    "Urban Planning",
    "Legislative Affairs",
  ],
  NonProfit: [
    "Program Management",
    "Fundraising",
    "Community Outreach",
    "Grant Writing",
    "Volunteer Coordination",
    "Nonprofit Administration",
  ],
  Energy: [
    "Energy Management",
    "Renewable Energy",
    "Energy Engineering",
    "Utilities Management",
    "Energy Policy",
    "Energy Trading",
  ],
  Agriculture: [
    "Farm Management",
    "Agronomy",
    "Animal Science",
    "Soil Science",
    "Crop Production",
    "Agricultural Engineering",
  ],
  Environmental: [
    "Environmental Science",
    "Environmental Policy",
    "Sustainability",
    "Environmental Consulting",
    "Waste Management",
    "Conservation",
  ],
  Telecommunications: [
    "Network Engineering",
    "Telecom Sales",
    "Telecom Operations",
    "System Integration",
    "Technical Support",
    "Project Management",
  ],
  Automotive: [
    "Automotive Engineering",
    "Vehicle Maintenance",
    "Automotive Sales",
    "Parts Management",
    "Quality Assurance",
    "Automotive Design",
  ],
  Aerospace: [
    "Aerospace Engineering",
    "Aircraft Maintenance",
    "Space Systems",
    "Flight Operations",
    "Aerospace Manufacturing",
    "Aerospace Design",
  ],
  Pharmaceutical: [
    "Pharmaceutical Research",
    "Pharmacovigilance",
    "Drug Development",
    "Regulatory Affairs",
    "Clinical Trials",
    "Pharmaceutical Sales",
  ],
  Biotechnology: [
    "Biotech Research",
    "Genetic Engineering",
    "Biotech Sales",
    "Clinical Biotechnology",
    "Bioinformatics",
    "Biotech Manufacturing",
  ],
  Insurance: [
    "Underwriting",
    "Claims Management",
    "Actuarial Science",
    "Insurance Sales",
    "Risk Management",
    "Insurance Adjusting",
  ],
  Food: [
    "Food Production",
    "Food Safety",
    "Food Service Management",
    "Nutrition",
    "Food Quality Control",
    "Food Research",
  ],
  Arts: [
    "Visual Arts",
    "Performing Arts",
    "Art Direction",
    "Art Administration",
    "Gallery Management",
    "Art Education",
  ],
  Sports: [
    "Sports Management",
    "Athlete Training",
    "Sports Marketing",
    "Sports Coaching",
    "Sports Medicine",
    "Event Management",
  ],
  Fashion: [
    "Fashion Design",
    "Fashion Marketing",
    "Retail Buying",
    "Fashion Merchandising",
    "Textile Design",
    "Fashion Styling",
  ],
};

export const courseLevels = [
  { id: "Beginner", text: "Beginner" },
  { id: "Intermediate", text: "Intermediate" },
  { id: "Advanced", text: "Advanced" },
  { id: "Expert", text: "Expert" },
  { id: "All Levels", text: "All Levels" },
];

export const skillsList = {
  "Information Technology": {
    "Software Development": ["JavaScript", "React", "Node.js", "Python", "C++"],
    "Network Administration": [
      "Cisco",
      "Network Security",
      "Firewalls",
      "LAN/WAN",
    ],
    "Database Management": ["SQL", "Database Design", "Oracle", "MySQL"],
    Cybersecurity: [
      "Ethical Hacking",
      "Penetration Testing",
      "Cryptography",
      "Network Security",
    ],
    "Data Analysis": ["Data Mining", "Statistical Analysis", "R", "Python"],
    "IT Support": [
      "Technical Support",
      "Troubleshooting",
      "Customer Service",
      "Windows OS",
    ],
  },
  Healthcare: {
    Nursing: [
      "Patient Care",
      "Critical Care",
      "Medical Terminology",
      "IV Therapy",
    ],
    "Medical Assisting": [
      "Phlebotomy",
      "Medical Records",
      "Patient Preparation",
      "Vital Signs",
    ],
    Pharmacy: [
      "Prescription Management",
      "Pharmacology",
      "Drug Interactions",
      "Patient Counseling",
    ],
    "Health Administration": [
      "Healthcare Management",
      "Medical Billing",
      "Health Policy",
      "Compliance",
    ],
    "Medical Research": [
      "Clinical Trials",
      "Data Analysis",
      "Laboratory Techniques",
      "Biomedical Research",
    ],
    "Public Health": [
      "Epidemiology",
      "Health Education",
      "Community Health",
      "Disease Prevention",
    ],
  },
  Finance: {
    Accounting: [
      "Financial Reporting",
      "Auditing",
      "Tax Preparation",
      "Accounts Payable",
    ],
    "Financial Analysis": [
      "Data Analysis",
      "Financial Modeling",
      "Risk Assessment",
      "Investment Analysis",
    ],
    "Investment Banking": [
      "Mergers and Acquisitions",
      "Financial Markets",
      "Valuation",
      "Corporate Finance",
    ],
    "Risk Management": [
      "Risk Assessment",
      "Compliance",
      "Insurance",
      "Internal Controls",
    ],
    Insurance: [
      "Underwriting",
      "Claims Management",
      "Actuarial Science",
      "Risk Management",
    ],
    Audit: [
      "Internal Audit",
      "Compliance",
      "Risk Assessment",
      "Financial Reporting",
    ],
  },
  Engineering: {
    "Mechanical Engineering": [
      "CAD",
      "Thermodynamics",
      "Mechanics",
      "Material Science",
    ],
    "Electrical Engineering": [
      "Circuit Design",
      "Microcontrollers",
      "Signal Processing",
      "Power Systems",
    ],
    "Civil Engineering": [
      "Structural Analysis",
      "Construction Management",
      "Geotechnical Engineering",
      "Surveying",
    ],
    "Chemical Engineering": [
      "Process Engineering",
      "Chemical Reactions",
      "Thermodynamics",
      "Material Science",
    ],
    "Structural Engineering": [
      "Structural Analysis",
      "Finite Element Analysis",
      "Construction Materials",
      "Building Codes",
    ],
    "Environmental Engineering": [
      "Environmental Impact Assessment",
      "Water Treatment",
      "Sustainability",
      "Waste Management",
    ],
  },
  Sales: {
    "Sales Management": [
      "Sales Strategy",
      "Customer Relationship Management",
      "Team Leadership",
      "Negotiation",
    ],
    "Business Development": [
      "Market Analysis",
      "Lead Generation",
      "Partnerships",
      "Strategic Planning",
    ],
    "Account Management": [
      "Client Relations",
      "Customer Service",
      "Sales Strategy",
      "Negotiation",
    ],
    "Retail Sales": [
      "Customer Service",
      "Sales Techniques",
      "Product Knowledge",
      "Visual Merchandising",
    ],
    "Market Research": [
      "Data Analysis",
      "Consumer Behavior",
      "Survey Design",
      "Competitor Analysis",
    ],
    "Customer Relationship Management": [
      "CRM Software",
      "Customer Service",
      "Data Analysis",
      "Sales Strategy",
    ],
  },
  HR: {
    Recruitment: [
      "Talent Acquisition",
      "Interviewing",
      "Sourcing",
      "Onboarding",
    ],
    "Employee Relations": [
      "Conflict Resolution",
      "HR Policies",
      "Employee Engagement",
      "Labor Laws",
    ],
    "Compensation and Benefits": [
      "Payroll",
      "Benefits Administration",
      "Compensation Strategy",
      "HRIS",
    ],
    "Training and Development": [
      "Instructional Design",
      "Learning Management Systems",
      "Employee Training",
      "Performance Management",
    ],
    "HR Management": [
      "HR Policies",
      "Employee Relations",
      "Compliance",
      "Performance Management",
    ],
    "Talent Acquisition": [
      "Sourcing",
      "Interviewing",
      "Employer Branding",
      "Onboarding",
    ],
  },
  Education: {
    Teaching: [
      "Curriculum Development",
      "Classroom Management",
      "Lesson Planning",
      "Instructional Techniques",
    ],
    "Educational Administration": [
      "School Management",
      "Educational Policy",
      "Budgeting",
      "Leadership",
    ],
    "Curriculum Development": [
      "Instructional Design",
      "Lesson Planning",
      "Assessment Design",
      "Educational Standards",
    ],
    "Instructional Design": [
      "E-Learning",
      "Multimedia Design",
      "Instructional Strategies",
      "Course Development",
    ],
    "Special Education": [
      "IEP Development",
      "Behavior Management",
      "Differentiated Instruction",
      "Assistive Technology",
    ],
    "Education Counseling": [
      "Student Advising",
      "Career Counseling",
      "Academic Planning",
      "Mental Health Support",
    ],
  },
  CustomerService: {
    "Customer Support": [
      "Customer Service",
      "Technical Support",
      "Troubleshooting",
      "Communication Skills",
    ],
    "Call Center Management": [
      "Call Center Operations",
      "Team Leadership",
      "Customer Service",
      "Performance Metrics",
    ],
    "Technical Support": [
      "Troubleshooting",
      "Technical Knowledge",
      "Customer Service",
      "Communication Skills",
    ],
    "Customer Success": [
      "Customer Service",
      "Account Management",
      "Customer Retention",
      "Communication Skills",
    ],
    "Client Relations": [
      "Customer Service",
      "Account Management",
      "Negotiation",
      "Client Retention",
    ],
    "Service Management": [
      "Service Delivery",
      "Customer Satisfaction",
      "Team Management",
      "Service Improvement",
    ],
  },
  Design: {
    "Graphic Design": [
      "Adobe Creative Suite",
      "Typography",
      "Layout Design",
      "Branding",
    ],
    "UI/UX Design": [
      "User Research",
      "Wireframing",
      "Prototyping",
      "User Testing",
    ],
    "Web Design": [
      "HTML/CSS",
      "JavaScript",
      "Responsive Design",
      "User Experience",
    ],
    "Product Design": [
      "3D Modeling",
      "Prototyping",
      "User-Centered Design",
      "CAD",
    ],
    Illustration: [
      "Drawing",
      "Digital Illustration",
      "Adobe Illustrator",
      "Concept Art",
    ],
    "3D Modeling": ["3D Software", "Animation", "Texturing", "Rendering"],
  },
  BusinessDev: {
    "Business Strategy": [
      "Strategic Planning",
      "Market Analysis",
      "Competitive Analysis",
      "Business Planning",
    ],
    "Market Analysis": [
      "Data Analysis",
      "Consumer Behavior",
      "Market Research",
      "Competitor Analysis",
    ],
    "Partnership Development": [
      "Relationship Building",
      "Negotiation",
      "Strategic Alliances",
      "Business Development",
    ],
    "Sales Strategy": [
      "Sales Planning",
      "Sales Techniques",
      "Customer Relationship Management",
      "Sales Forecasting",
    ],
    "Business Planning": [
      "Business Analysis",
      "Financial Planning",
      "Market Research",
      "Strategic Planning",
    ],
    "Client Acquisition": [
      "Lead Generation",
      "Sales Techniques",
      "Customer Relationship Management",
      "Negotiation",
    ],
  },
  Legal: {
    "Corporate Law": [
      "Contract Law",
      "Mergers and Acquisitions",
      "Corporate Governance",
      "Compliance",
    ],
    "Criminal Law": [
      "Criminal Procedure",
      "Litigation",
      "Evidence",
      "Legal Research",
    ],
    "Family Law": [
      "Divorce Law",
      "Child Custody",
      "Family Mediation",
      "Estate Planning",
    ],
    "Legal Consulting": [
      "Legal Research",
      "Contract Drafting",
      "Compliance",
      "Risk Management",
    ],
    "Legal Research": ["Legal Research", "Writing", "Analysis", "Compliance"],
    "Contract Law": [
      "Contract Drafting",
      "Negotiation",
      "Legal Research",
      "Compliance",
    ],
  },
  Manufacturing: {
    "Production Management": [
      "Lean Manufacturing",
      "Production Planning",
      "Quality Control",
      "Process Improvement",
    ],
    "Quality Assurance": [
      "Quality Control",
      "Process Improvement",
      "ISO Standards",
      "Six Sigma",
    ],
    "Supply Chain Management": [
      "Logistics",
      "Inventory Management",
      "Procurement",
      "Supplier Management",
    ],
    "Operations Management": [
      "Process Optimization",
      "Production Planning",
      "Inventory Management",
      "Team Leadership",
    ],
    "Manufacturing Engineering": [
      "Process Improvement",
      "CAD",
      "Lean Manufacturing",
      "Production Planning",
    ],
    "Process Improvement": [
      "Lean Manufacturing",
      "Six Sigma",
      "Process Analysis",
      "Quality Control",
    ],
  },
  RandD: {
    Research: [
      "Data Analysis",
      "Laboratory Techniques",
      "Scientific Research",
      "Technical Writing",
    ],
    "Product Development": [
      "Prototyping",
      "Product Testing",
      "Market Research",
      "Project Management",
    ],
    Innovation: [
      "Creative Thinking",
      "Problem Solving",
      "Product Development",
      "Market Research",
    ],
    "Clinical Research": [
      "Clinical Trials",
      "Data Analysis",
      "Biomedical Research",
      "Regulatory Compliance",
    ],
    "Technology Research": [
      "Data Analysis",
      "Technical Writing",
      "Research Methodologies",
      "Innovation",
    ],
    "Scientific Research": [
      "Data Analysis",
      "Laboratory Techniques",
      "Scientific Writing",
      "Research Methodologies",
    ],
  },
  Consulting: {
    "Management Consulting": [
      "Business Analysis",
      "Strategic Planning",
      "Change Management",
      "Project Management",
    ],
    "Strategy Consulting": [
      "Market Analysis",
      "Business Strategy",
      "Competitive Analysis",
      "Financial Analysis",
    ],
    "IT Consulting": [
      "Technical Knowledge",
      "Project Management",
      "Systems Integration",
      "Business Analysis",
    ],
    "Financial Consulting": [
      "Financial Analysis",
      "Financial Planning",
      "Risk Management",
      "Investment Analysis",
    ],
    "Human Resources Consulting": [
      "Talent Management",
      "Organizational Development",
      "HR Strategy",
      "Employee Relations",
    ],
    "Marketing Consulting": [
      "Market Research",
      "Marketing Strategy",
      "Brand Management",
      "Digital Marketing",
    ],
  },
  Media: {
    Journalism: ["Writing", "Editing", "Reporting", "Research"],
    "Public Relations": [
      "Media Relations",
      "Communication Skills",
      "Crisis Management",
      "Content Creation",
    ],
    "Content Creation": [
      "Writing",
      "Video Production",
      "Photography",
      "Social Media",
    ],
    "Media Planning": [
      "Advertising",
      "Market Research",
      "Strategic Planning",
      "Budget Management",
    ],
    Broadcasting: [
      "Video Production",
      "Editing",
      "Public Speaking",
      "Media Relations",
    ],
    "Digital Media": ["Social Media", "Content Creation", "SEO", "Analytics"],
  },
  Construction: {
    "Project Management": [
      "Budgeting",
      "Scheduling",
      "Team Management",
      "Risk Management",
    ],
    "Site Management": [
      "Construction Supervision",
      "Safety Management",
      "Quality Control",
      "Scheduling",
    ],
    "Construction Engineering": [
      "Structural Analysis",
      "Construction Materials",
      "Project Planning",
      "CAD",
    ],
    "Safety Management": [
      "Safety Regulations",
      "Risk Assessment",
      "Incident Investigation",
      "Safety Training",
    ],
    Estimating: [
      "Cost Estimation",
      "Budgeting",
      "Project Planning",
      "Financial Analysis",
    ],
    "Contract Management": [
      "Contract Drafting",
      "Negotiation",
      "Compliance",
      "Risk Management",
    ],
  },
  Hospitality: {
    "Hotel Management": [
      "Customer Service",
      "Operations Management",
      "Budgeting",
      "Team Leadership",
    ],
    "Event Planning": [
      "Event Coordination",
      "Budget Management",
      "Vendor Relations",
      "Marketing",
    ],
    "Food and Beverage Management": [
      "Customer Service",
      "Operations Management",
      "Inventory Management",
      "Team Leadership",
    ],
    Tourism: ["Customer Service", "Travel Planning", "Sales", "Marketing"],
    "Guest Services": [
      "Customer Service",
      "Problem Solving",
      "Communication Skills",
      "Sales",
    ],
    Catering: [
      "Menu Planning",
      "Event Coordination",
      "Customer Service",
      "Food Safety",
    ],
  },
  Transportation: {
    Logistics: [
      "Supply Chain Management",
      "Inventory Management",
      "Transportation Planning",
      "Operations Management",
    ],
    "Supply Chain Management": [
      "Logistics",
      "Inventory Management",
      "Procurement",
      "Supplier Management",
    ],
    "Fleet Management": [
      "Vehicle Maintenance",
      "Logistics",
      "Operations Management",
      "Safety Management",
    ],
    "Transportation Planning": [
      "Logistics",
      "Urban Planning",
      "Data Analysis",
      "Operations Management",
    ],
    "Shipping and Receiving": [
      "Logistics",
      "Inventory Management",
      "Customer Service",
      "Data Entry",
    ],
    "Cargo Management": [
      "Logistics",
      "Inventory Management",
      "Safety Regulations",
      "Customer Service",
    ],
  },
  Retail: {
    "Store Management": [
      "Customer Service",
      "Sales",
      "Inventory Management",
      "Team Leadership",
    ],
    "Visual Merchandising": [
      "Visual Display",
      "Sales",
      "Customer Service",
      "Inventory Management",
    ],
    "Retail Sales": [
      "Customer Service",
      "Sales Techniques",
      "Product Knowledge",
      "Cash Handling",
    ],
    "Inventory Management": [
      "Inventory Control",
      "Data Entry",
      "Logistics",
      "Customer Service",
    ],
    "Customer Service": [
      "Customer Service",
      "Communication Skills",
      "Problem Solving",
      "Sales",
    ],
    "Buying and Merchandising": [
      "Vendor Relations",
      "Sales",
      "Inventory Management",
      "Negotiation",
    ],
  },
  RealEstate: {
    "Real Estate Sales": [
      "Sales",
      "Customer Service",
      "Negotiation",
      "Market Analysis",
    ],
    "Property Management": [
      "Property Maintenance",
      "Customer Service",
      "Budgeting",
      "Lease Management",
    ],
    "Real Estate Development": [
      "Project Management",
      "Market Analysis",
      "Negotiation",
      "Finance",
    ],
    "Real Estate Investment": [
      "Market Analysis",
      "Financial Analysis",
      "Risk Management",
      "Negotiation",
    ],
    Leasing: [
      "Customer Service",
      "Negotiation",
      "Sales",
      "Property Management",
    ],
    Brokerage: ["Sales", "Customer Service", "Negotiation", "Market Analysis"],
  },
  Government: {
    "Public Administration": [
      "Policy Analysis",
      "Budgeting",
      "Public Speaking",
      "Leadership",
    ],
    "Policy Analysis": [
      "Research",
      "Data Analysis",
      "Writing",
      "Public Speaking",
    ],
    "Public Relations": [
      "Media Relations",
      "Communication Skills",
      "Crisis Management",
      "Writing",
    ],
    "Government Affairs": [
      "Policy Analysis",
      "Public Speaking",
      "Research",
      "Writing",
    ],
    "Urban Planning": [
      "Urban Design",
      "Data Analysis",
      "Project Management",
      "Policy Analysis",
    ],
    "Legislative Affairs": [
      "Policy Analysis",
      "Public Speaking",
      "Research",
      "Writing",
    ],
  },
  NonProfit: {
    "Program Management": [
      "Project Management",
      "Budgeting",
      "Team Leadership",
      "Grant Writing",
    ],
    Fundraising: [
      "Donor Relations",
      "Event Planning",
      "Grant Writing",
      "Marketing",
    ],
    "Community Outreach": [
      "Public Speaking",
      "Relationship Building",
      "Event Planning",
      "Marketing",
    ],
    "Grant Writing": ["Writing", "Research", "Budgeting", "Project Management"],
    "Volunteer Coordination": [
      "Volunteer Management",
      "Event Planning",
      "Communication Skills",
      "Leadership",
    ],
    "Nonprofit Administration": [
      "Budgeting",
      "Grant Writing",
      "Project Management",
      "Leadership",
    ],
  },
  Energy: {
    "Energy Management": [
      "Energy Efficiency",
      "Sustainability",
      "Project Management",
      "Data Analysis",
    ],
    "Renewable Energy": [
      "Solar Power",
      "Wind Power",
      "Sustainability",
      "Project Management",
    ],
    "Energy Engineering": [
      "Electrical Engineering",
      "Sustainability",
      "Project Management",
      "Data Analysis",
    ],
    "Utilities Management": [
      "Operations Management",
      "Regulatory Compliance",
      "Customer Service",
      "Budgeting",
    ],
    "Energy Policy": [
      "Policy Analysis",
      "Regulatory Compliance",
      "Writing",
      "Research",
    ],
    "Energy Trading": [
      "Market Analysis",
      "Risk Management",
      "Negotiation",
      "Data Analysis",
    ],
  },
  Agriculture: {
    "Farm Management": [
      "Crop Management",
      "Budgeting",
      "Operations Management",
      "Team Leadership",
    ],
    Agronomy: ["Crop Management", "Soil Science", "Research", "Data Analysis"],
    "Animal Science": [
      "Animal Husbandry",
      "Veterinary Science",
      "Research",
      "Data Analysis",
    ],
    "Soil Science": [
      "Soil Analysis",
      "Research",
      "Data Analysis",
      "Environmental Science",
    ],
    "Crop Production": [
      "Crop Management",
      "Research",
      "Data Analysis",
      "Sustainability",
    ],
    "Agricultural Engineering": [
      "Mechanical Engineering",
      "Research",
      "Data Analysis",
      "Sustainability",
    ],
  },
  Environmental: {
    "Environmental Science": [
      "Research",
      "Data Analysis",
      "Environmental Impact Assessment",
      "Sustainability",
    ],
    "Environmental Policy": [
      "Policy Analysis",
      "Research",
      "Writing",
      "Regulatory Compliance",
    ],
    Sustainability: [
      "Sustainability Planning",
      "Research",
      "Data Analysis",
      "Environmental Impact Assessment",
    ],
    "Environmental Consulting": [
      "Environmental Impact Assessment",
      "Data Analysis",
      "Research",
      "Sustainability",
    ],
    "Waste Management": [
      "Waste Management Planning",
      "Regulatory Compliance",
      "Operations Management",
      "Sustainability",
    ],
    Conservation: [
      "Research",
      "Data Analysis",
      "Sustainability",
      "Environmental Impact Assessment",
    ],
  },
  Telecommunications: {
    "Network Engineering": [
      "Cisco",
      "Network Security",
      "LAN/WAN",
      "Troubleshooting",
    ],
    "Telecom Sales": [
      "Sales",
      "Customer Service",
      "Product Knowledge",
      "Negotiation",
    ],
    "Telecom Operations": [
      "Operations Management",
      "Network Security",
      "Troubleshooting",
      "Customer Service",
    ],
    "System Integration": [
      "Systems Integration",
      "Project Management",
      "Network Security",
      "Technical Support",
    ],
    "Technical Support": [
      "Troubleshooting",
      "Technical Knowledge",
      "Customer Service",
      "Communication Skills",
    ],
    "Project Management": [
      "Project Planning",
      "Budgeting",
      "Team Leadership",
      "Risk Management",
    ],
  },
  Automotive: {
    "Automotive Engineering": [
      "Mechanical Engineering",
      "CAD",
      "Vehicle Design",
      "Research",
    ],
    "Vehicle Maintenance": [
      "Vehicle Repair",
      "Diagnostics",
      "Customer Service",
      "Inventory Management",
    ],
    "Automotive Sales": [
      "Sales",
      "Customer Service",
      "Product Knowledge",
      "Negotiation",
    ],
    "Parts Management": [
      "Inventory Management",
      "Customer Service",
      "Supply Chain Management",
      "Sales",
    ],
    "Quality Assurance": [
      "Quality Control",
      "Process Improvement",
      "Auditing",
      "Compliance",
    ],
    "Automotive Design": [
      "CAD",
      "Vehicle Design",
      "Research",
      "Product Development",
    ],
  },
  Aerospace: {
    "Aerospace Engineering": [
      "Mechanical Engineering",
      "Aerodynamics",
      "CAD",
      "Research",
    ],
    "Aircraft Maintenance": [
      "Aircraft Repair",
      "Diagnostics",
      "Customer Service",
      "Inventory Management",
    ],
    "Space Systems": [
      "Systems Engineering",
      "CAD",
      "Project Management",
      "Research",
    ],
    "Flight Operations": [
      "Flight Planning",
      "Pilot Training",
      "Customer Service",
      "Safety Management",
    ],
    "Aerospace Manufacturing": [
      "Manufacturing Engineering",
      "Process Improvement",
      "Quality Control",
      "CAD",
    ],
    "Aerospace Design": [
      "CAD",
      "Aerodynamics",
      "Mechanical Engineering",
      "Product Development",
    ],
  },
  Pharmaceutical: {
    "Pharmaceutical Research": [
      "Laboratory Techniques",
      "Data Analysis",
      "Biomedical Research",
      "Regulatory Compliance",
    ],
    Pharmacovigilance: [
      "Data Analysis",
      "Regulatory Compliance",
      "Pharmacology",
      "Medical Writing",
    ],
    "Drug Development": [
      "Laboratory Techniques",
      "Data Analysis",
      "Biomedical Research",
      "Regulatory Compliance",
    ],
    "Regulatory Affairs": [
      "Regulatory Compliance",
      "Medical Writing",
      "Data Analysis",
      "Research",
    ],
    "Clinical Trials": [
      "Clinical Research",
      "Data Analysis",
      "Regulatory Compliance",
      "Medical Writing",
    ],
    "Pharmaceutical Sales": [
      "Sales",
      "Customer Service",
      "Product Knowledge",
      "Negotiation",
    ],
  },
  Biotechnology: {
    "Biotech Research": [
      "Laboratory Techniques",
      "Data Analysis",
      "Biomedical Research",
      "Genetic Engineering",
    ],
    "Genetic Engineering": [
      "Genetics",
      "Laboratory Techniques",
      "Data Analysis",
      "Research",
    ],
    "Biotech Sales": [
      "Sales",
      "Customer Service",
      "Product Knowledge",
      "Negotiation",
    ],
    "Clinical Biotechnology": [
      "Clinical Research",
      "Data Analysis",
      "Biomedical Research",
      "Regulatory Compliance",
    ],
    Bioinformatics: ["Data Analysis", "Programming", "Genetics", "Research"],
    "Biotech Manufacturing": [
      "Manufacturing Engineering",
      "Process Improvement",
      "Quality Control",
      "Regulatory Compliance",
    ],
  },
  Insurance: {
    Underwriting: [
      "Risk Assessment",
      "Data Analysis",
      "Customer Service",
      "Regulatory Compliance",
    ],
    "Claims Management": [
      "Claims Processing",
      "Customer Service",
      "Data Analysis",
      "Regulatory Compliance",
    ],
    "Actuarial Science": [
      "Data Analysis",
      "Risk Assessment",
      "Statistics",
      "Research",
    ],
    "Insurance Sales": [
      "Sales",
      "Customer Service",
      "Product Knowledge",
      "Negotiation",
    ],
    "Risk Management": [
      "Risk Assessment",
      "Data Analysis",
      "Regulatory Compliance",
      "Research",
    ],
    "Insurance Management": [
      "Operations Management",
      "Customer Service",
      "Data Analysis",
      "Regulatory Compliance",
    ],
  },
  Entertainment: {
    "Film Production": [
      "Video Production",
      "Editing",
      "Directing",
      "Scriptwriting",
    ],
    "Music Production": [
      "Audio Engineering",
      "Music Theory",
      "Editing",
      "Recording",
    ],
    "Theater Production": [
      "Stage Management",
      "Directing",
      "Set Design",
      "Scriptwriting",
    ],
    "Event Management": [
      "Event Planning",
      "Budgeting",
      "Vendor Relations",
      "Marketing",
    ],
    "Talent Management": [
      "Client Relations",
      "Negotiation",
      "Marketing",
      "Event Planning",
    ],
    Broadcasting: [
      "Video Production",
      "Editing",
      "Public Speaking",
      "Media Relations",
    ],
  },
};

export const jobIndustryTools = {
  "Information Technology": [
    "Visual Studio Code",
    "Git",
    "GitHub",
    "GitLab",
    "JIRA",
    "Slack",
    "Trello",
    "Docker",
    "Kubernetes",
    "Jenkins",
    "AWS",
    "Azure",
    "Google Cloud Platform",
    "Linux",
    "Postman",
    "Selenium",
    "Ansible",
    "Chef",
    "Puppet",
    "Nagios",
    "Splunk",
    "Terraform",
    "Vagrant",
    "npm",
    "Webpack",
    "Babel",
    "Node.js",
    "React",
    "Angular",
    "Vue.js",
    "Django",
    "Flask",
    "Spring Boot",
    "Ruby on Rails",
    "Laravel",
    "Express.js",
    "MongoDB",
    "MySQL",
    "PostgreSQL",
    "SQLite",
    "Redis",
    "ElasticSearch",
    "GraphQL",
    "Firebase",
    "DigitalOcean",
    "Heroku",
    "Netlify",
    "Vercel",
    "TypeScript",
    "PHP",
    "Java",
    "Python",
    "C++",
    "C#",
  ],
  Healthcare: [
    "Epic",
    "Cerner",
    "McKesson",
    "Allscripts",
    "Meditech",
    "NextGen Healthcare",
    "eClinicalWorks",
    "Athenahealth",
    "GE Healthcare",
    "Philips Healthcare",
    "Siemens Healthineers",
    "Carestream Health",
    "PACS",
    "Meditech",
    "Greenway Health",
    "Kareo",
    "DrChrono",
    "Practice Fusion",
    "AdvancedMD",
    "Centricity",
    "WebPT",
    "Clarity",
    "HealthFusion",
    "InSync",
    "TherapyNotes",
    "SimplePractice",
    "Elation Health",
    "ChartLogic",
    "PrognoCIS",
    "eMDs",
    "Amazing Charts",
    "CureMD",
    "CompuGroup Medical",
    "MEDHOST",
    "NextTech",
    "Valant",
    "Meditab",
    "PracticeAdmin",
    "NueMD",
    "Healthland",
    "Aprima",
    "iPatientCare",
    "CareCloud",
    "Eyefinity",
    "RevolutionEHR",
    "Compulink",
    "Eyefinity EHR",
    "Eyefinity Practice Management",
    "Doximity",
    "UpToDate",
    "Medscape",
    "PubMed",
  ],
  "Finance and Accounting": [
    "QuickBooks",
    "Xero",
    "Sage",
    "FreshBooks",
    "Wave",
    "Zoho Books",
    "NetSuite",
    "Microsoft Dynamics GP",
    "SAP",
    "Oracle Financials",
    "BlackLine",
    "Tipalti",
    "Intacct",
    "Tally",
    "FreeAgent",
    "KashFlow",
    "KPMG",
    "PwC",
    "Deloitte",
    "Ernst & Young",
    "Grant Thornton",
    "BDO",
    "Sage Intacct",
    "Adaptive Insights",
    "Host Analytics",
    "Workday",
    "Certify",
    "Expensify",
    "Concur",
    "Abacus",
    "Receipt Bank",
    "Bill.com",
    "Fundbox",
    "PayPal",
    "Stripe",
    "Square",
    "Braintree",
    "Adyen",
    "Authorize.Net",
    "Dwolla",
    "Klarna",
    "Gusto",
    "ADP",
    "Zenefits",
    "Patriot Software",
    "OnPay",
    "SurePayroll",
    "TriNet",
    "Paychex",
    "QuickBase",
    "CCH Axcess",
    "Thomson Reuters",
  ],
  Engineering: [
    "AutoCAD",
    "SolidWorks",
    "MATLAB",
    "Simulink",
    "ANSYS",
    "CATIA",
    "Revit",
    "SketchUp",
    "Rhino",
    "Civil 3D",
    "Inventor",
    "Fusion 360",
    "Creo",
    "STAAD.Pro",
    "Navisworks",
    "Bentley MicroStation",
    "Vectorworks",
    "Onshape",
    "Altium Designer",
    "KiCad",
    "Proteus",
    "LabVIEW",
    "Multisim",
    "SimScale",
    "COMSOL Multiphysics",
    "OpenFOAM",
    "Abaqus",
    "3D Studio Max",
    "Blender",
    "Maya",
    "Houdini",
    "ReCap",
    "InfraWorks",
    "Tekla Structures",
    "Etabs",
    "SAP2000",
    "Robot Structural Analysis",
    "Midas Gen",
    "SpaceGass",
    "GeoStudio",
    "PLAXIS",
    "FLAC",
    "DesignSpark Mechanical",
    "FreeCAD",
    "QCAD",
    "ZBrush",
    "Tinkercad",
    "FreeCAD",
    "Solid Edge",
    "Creo",
    "NX",
    "ArchiCAD",
    "Lumion",
    "Bluebeam Revu",
  ],
  "Sales and Marketing": [
    "Salesforce",
    "HubSpot",
    "Marketo",
    "Pardot",
    "Mailchimp",
    "Constant Contact",
    "AWeber",
    "ActiveCampaign",
    "Zoho CRM",
    "Microsoft Dynamics CRM",
    "SugarCRM",
    "Pipedrive",
    "Nimble",
    "Insightly",
    "SalesLoft",
    "Outreach",
    "Yesware",
    "LinkedIn Sales Navigator",
    "Google Analytics",
    "SEMrush",
    "Moz",
    "Ahrefs",
    "Hootsuite",
    "Buffer",
    "Sprout Social",
    "Google Ads",
    "Facebook Ads",
    "Twitter Ads",
    "Instagram Ads",
    "LinkedIn Ads",
    "AdRoll",
    "Taboola",
    "Outbrain",
    "Hotjar",
    "Crazy Egg",
    "Unbounce",
    "Optimizely",
    "VWO",
    "LeadPages",
    "Instapage",
    "ClickFunnels",
    "SurveyMonkey",
    "Typeform",
    "Qualtrics",
    "Gainsight",
    "Zendesk",
    "Freshdesk",
    "LiveChat",
    "Drift",
    "Intercom",
    "Klaviyo",
    "Sendinblue",
  ],
  "Human Resources": [
    "Workday",
    "ADP",
    "BambooHR",
    "Zenefits",
    "Gusto",
    "Namely",
    "Paycom",
    "Paycor",
    "SAP SuccessFactors",
    "Oracle HCM",
    "Ultimate Software",
    "Ceridian",
    "Kronos",
    "Cornerstone OnDemand",
    "PeopleSoft",
    "SumTotal Systems",
    "Saba Software",
    "iCIMS",
    "Greenhouse",
    "Jobvite",
    "Lever",
    "JazzHR",
    "SmartRecruiters",
    "Workable",
    "Bullhorn",
    "Recruiterbox",
    "ClearCompany",
    "Breezy HR",
    "Zoho Recruit",
    "ApplicantPro",
    "WorkBright",
    "JobAdder",
    "CEIPAL TalentHire",
    "Recruitee",
    "Crelate Talent",
    "TrackerRMS",
    "ATS OnDemand",
    "iRecruit",
    "RecruitBPM",
    "Avature",
    "HireHive",
    "Big Biller",
    "Manatal",
    "Odoo",
    "OrangeHRM",
    "HRMS",
    "CakeHR",
    "Vibe HCM",
    "SumoPayroll",
    "Xero Payroll",
  ],
  "Education and Training": [
    "Google Classroom",
    "Canvas",
    "Blackboard",
    "Moodle",
    "Edmodo",
    "Schoology",
    "Brightspace",
    "Kahoot!",
    "Quizlet",
    "Nearpod",
    "Flipgrid",
    "Seesaw",
    "Remind",
    "Padlet",
    "ClassDojo",
    "Zoom",
    "Microsoft Teams",
    "Google Meet",
    "Adobe Connect",
    "BlueJeans",
    "GoToMeeting",
    "Cisco Webex",
    "BigBlueButton",
    "Socrative",
    "Plickers",
    "Mentimeter",
    "Poll Everywhere",
    "Slack",
    "Trello",
    "Asana",
    "Notion",
    "Evernote",
    "Microsoft OneNote",
    "Google Keep",
    "Khan Academy",
    "Coursera",
    "edX",
    "Udemy",
    "LinkedIn Learning",
    "Pluralsight",
    "Skillshare",
    "Codecademy",
    "Duolingo",
    "Rosetta Stone",
    "Babbel",
    "Quizizz",
    "Edpuzzle",
    "Loom",
    "Screencastify",
    "Explain Everything",
  ],
  "Customer Service": [
    "Zendesk",
    "Freshdesk",
    "Zoho Desk",
    "Salesforce Service Cloud",
    "HubSpot Service Hub",
    "JIRA Service Desk",
    "ServiceNow",
    "Kayako",
    "LiveAgent",
    "Helpscout",
    "Front",
    "Intercom",
    "Drift",
    "Olark",
    "LiveChat",
    "SnapEngage",
    "Pure Chat",
    "Tawk.to",
    "Comm100",
    "ClickDesk",
    "Chatra",
    "HappyFox",
    "Kustomer",
    "Groove",
    "TeamSupport",
    "HelpCrunch",
    "FocalScope",
    "Kayako",
    "Vision Helpdesk",
    "Freshservice",
    "SysAid",
    "Spiceworks",
    "ManageEngine ServiceDesk Plus",
    "Cayzu",
    "Desk.com",
    "Mojo Helpdesk",
    "ProProfs Help Desk",
    "HelpSpot",
    "LiveZilla",
    "Talkdesk",
    "Aircall",
    "RingCentral",
    "Five9",
    "8x8",
    "Avaya",
    "NICE inContact",
    "Genesys",
    "CloudTalk",
    "Nextiva",
  ],
  "Design and Creative": [
    "Adobe Photoshop",
    "Adobe Illustrator",
    "Adobe InDesign",
    "Adobe XD",
    "Sketch",
    "Figma",
    "CorelDRAW",
    "Affinity Designer",
    "Canva",
    "Gravit Designer",
    "Procreate",
    "Inkscape",
    "GIMP",
    "Blender",
    "Maya",
    "3ds Max",
    "Cinema 4D",
    "ZBrush",
    "SketchUp",
    "Rhino",
    "AutoCAD",
    "Fusion 360",
    "Revit",
    "Substance Painter",
    "Houdini",
    "Marvelous Designer",
    "Unity",
    "Unreal Engine",
    "After Effects",
    "Premiere Pro",
    "Final Cut Pro",
    "DaVinci Resolve",
    "Avid Media Composer",
    "Lightroom",
    "Capture One",
    "Luminar",
    "DxO PhotoLab",
    "Darktable",
    "Snapseed",
    "VSCO",
    "Clip Studio Paint",
    "PaintTool SAI",
    "Krita",
    "Corel Painter",
    "ArtRage",
    "Tayasui Sketches",
    "MediBang Paint",
    "Rebelle",
    "Pixelmator",
  ],
  "Business Development": [
    "Salesforce",
    "HubSpot",
    "Marketo",
    "Pardot",
    "Mailchimp",
    "Constant Contact",
    "AWeber",
    "ActiveCampaign",
    "Zoho CRM",
    "Microsoft Dynamics CRM",
    "SugarCRM",
    "Pipedrive",
    "Nimble",
    "Insightly",
    "SalesLoft",
    "Outreach",
    "Yesware",
    "LinkedIn Sales Navigator",
    "Google Analytics",
    "SEMrush",
    "Moz",
    "Ahrefs",
    "Hootsuite",
    "Buffer",
    "Sprout Social",
    "Google Ads",
    "Facebook Ads",
    "Twitter Ads",
    "Instagram Ads",
    "LinkedIn Ads",
    "AdRoll",
    "Taboola",
    "Outbrain",
    "Hotjar",
    "Crazy Egg",
    "Unbounce",
    "Optimizely",
    "VWO",
    "LeadPages",
    "Instapage",
    "ClickFunnels",
    "SurveyMonkey",
    "Typeform",
    "Qualtrics",
    "Gainsight",
    "Zendesk",
    "Freshdesk",
    "LiveChat",
    "Drift",
    "Intercom",
    "Klaviyo",
    "Sendinblue",
  ],
  Legal: [
    "Clio",
    "MyCase",
    "PracticePanther",
    "Smokeball",
    "Rocket Matter",
    "AbacusLaw",
    "CosmoLex",
    "Amicus Attorney",
    "Zola Suite",
    "TimeSolv",
    "Bill4Time",
    "LEAP",
    "Tabs3",
    "ProLaw",
    "Firm Central",
    "Actionstep",
    "CaseFox",
    "HoudiniESQ",
    "Legal Files",
    "Prevail",
    "Docketwise",
    "Clara",
    "Logikcull",
    "Everlaw",
    "Relativity",
    "Concord",
    "ContractWorks",
    "DocuSign",
    "HelloSign",
    "Adobe Sign",
    "SignNow",
    "SignRequest",
    "LawPay",
    "Practice Ignition",
    "QuickBooks",
    "Xero",
    "FreshBooks",
    "NetDocuments",
    "Worldox",
    "iManage",
    "eFileCabinet",
    "Filevine",
    "Best Case",
    "NextChapter",
    "Trello",
    "Asana",
    "Microsoft Teams",
    "Slack",
    "Zoom",
    "Google Meet",
  ],
  "Manufacturing and Operations": [
    "SAP ERP",
    "Oracle ERP",
    "Microsoft Dynamics 365",
    "Infor ERP",
    "Epicor ERP",
    "NetSuite ERP",
    "IFS Applications",
    "SYSPRO",
    "Plex ERP",
    "QAD ERP",
    "Sage ERP",
    "Odoo",
    "Fishbowl",
    "MRPeasy",
    "Katana",
    "E2 Shop System",
    "Global Shop Solutions",
    "JobBOSS",
    "Priority ERP",
    "IQMS",
    "Deltek",
    "Workday",
    "Exact Macola",
    "Rootstock",
    "Acumatica",
    "POMeSYS",
    "Intellect",
    "MasterControl",
    "Arena PLM",
    "Autodesk Fusion Lifecycle",
    "Siemens Teamcenter",
    "PTC Windchill",
    "SolidWorks PDM",
    "BlueCherry",
    "FastReact",
    "Lectra",
    "Gerber Technology",
    "Optitex",
    "EFI Optitex",
    "YuniquePLM",
    "Centric PLM",
    "Assyst Bullmer",
    "CLO 3D",
    "TUKAcad",
    "TUKA3D",
    "Roman's CAD",
    "Audaces",
    "Pad System",
    "APQP",
    "SPC",
  ],
  "Research and Development": [
    "MATLAB",
    "Simulink",
    "LabVIEW",
    "OriginLab",
    "GraphPad Prism",
    "SPSS",
    "SAS",
    "JMP",
    "Minitab",
    "R",
    "Python",
    "Anaconda",
    "TensorFlow",
    "Keras",
    "PyTorch",
    "Scikit-learn",
    "Pandas",
    "NumPy",
    "SciPy",
    "Matplotlib",
    "Seaborn",
    "Plotly",
    "D3.js",
    "Tableau",
    "Power BI",
    "QlikView",
    "Qlik Sense",
    "SAP Lumira",
    "Alteryx",
    "KNIME",
    "RapidMiner",
    "DataRobot",
    "H2O.ai",
    "IBM Watson",
    "Google AI Platform",
    "Azure Machine Learning",
    "Amazon SageMaker",
    "BigML",
    "Dataiku",
    "Databricks",
    "Snowflake",
    "Apache Spark",
    "Hadoop",
    "Microsoft Azure",
    "AWS",
    "Google Cloud Platform",
    "Kaggle",
    "GitHub",
    "GitLab",
  ],
  Consulting: [
    "Microsoft Excel",
    "Tableau",
    "Power BI",
    "QlikView",
    "Qlik Sense",
    "SAP Lumira",
    "Alteryx",
    "KNIME",
    "RapidMiner",
    "DataRobot",
    "H2O.ai",
    "IBM Watson",
    "Google AI Platform",
    "Azure Machine Learning",
    "Amazon SageMaker",
    "BigML",
    "Dataiku",
    "Databricks",
    "Snowflake",
    "Apache Spark",
    "Hadoop",
    "Microsoft Azure",
    "AWS",
    "Google Cloud Platform",
    "JIRA",
    "Confluence",
    "Slack",
    "Trello",
    "Asana",
    "Notion",
    "Monday.com",
    "Smartsheet",
    "Wrike",
    "ClickUp",
    "Basecamp",
    "Podio",
    "Teamwork",
    "Zoho Projects",
    "ProofHub",
    "Mavenlink",
    "LiquidPlanner",
    "Scoro",
    "Clarizen",
    "Workfront",
    "MS Project",
    "Toggl",
    "Harvest",
    "Evernote",
    "Google Keep",
    "OneNote",
    "Zoom",
    "Microsoft Teams",
  ],
  "Media and Communications": [
    "Adobe Premiere Pro",
    "Final Cut Pro",
    "Avid Media Composer",
    "DaVinci Resolve",
    "Adobe After Effects",
    "Adobe Audition",
    "Logic Pro X",
    "Ableton Live",
    "Pro Tools",
    "GarageBand",
    "Audacity",
    "FL Studio",
    "Cubase",
    "Reaper",
    "Sony Vegas Pro",
    "Filmora",
    "HitFilm Express",
    "VSDC Free Video Editor",
    "Shotcut",
    "Camtasia",
    "OBS Studio",
    "Streamlabs OBS",
    "Wirecast",
    "vMix",
    "XSplit",
    "Ecamm Live",
    "Zoom",
    "Microsoft Teams",
    "Google Meet",
    "Skype",
    "Slack",
    "Discord",
    "WhatsApp",
    "Telegram",
    "Signal",
    "WeChat",
    "Facebook Messenger",
    "Instagram",
    "Twitter",
    "TikTok",
    "Snapchat",
    "YouTube",
    "Vimeo",
    "Hootsuite",
    "Buffer",
    "Sprout Social",
    "Mailchimp",
    "Constant Contact",
    "AWeber",
    "ActiveCampaign",
  ],
  Construction: [
    "AutoCAD",
    "Revit",
    "SketchUp",
    "Rhino",
    "Civil 3D",
    "Navisworks",
    "Bentley MicroStation",
    "Vectorworks",
    "Bluebeam Revu",
    "PlanGrid",
    "Procore",
    "Buildertrend",
    "CoConstruct",
    "BuildTools",
    "Fieldwire",
    "eSUB",
    "Sage 300 Construction and Real Estate",
    "Prolog",
    "P6 Primavera",
    "ProjectSight",
    "Trimble Connect",
    "Trimble FieldLink",
    "Autodesk BIM 360",
    "Tekla Structures",
    "STAAD.Pro",
    "Etabs",
    "SAP2000",
    "Robot Structural Analysis",
    "Midas Gen",
    "SpaceGass",
    "GeoStudio",
    "PLAXIS",
    "FLAC",
    "DesignSpark Mechanical",
    "FreeCAD",
    "QCAD",
    "ZBrush",
    "Tinkercad",
    "FreeCAD",
    "Solid Edge",
    "Creo",
    "NX",
    "ArchiCAD",
    "Lumion",
    "Bluebeam Revu",
    "SketchList",
    "Simpson Strong-Tie",
    "MiTek SAPPHIRE",
    "Hilti PROFIS",
  ],
  "Hospitality and Tourism": [
    "Opera PMS",
    "Micros",
    "Agilysys",
    "Infor HMS",
    "Cloudbeds",
    "Guestline",
    "RoomMaster",
    "Hotelogix",
    "RezOvation",
    "eZee FrontDesk",
    "Little Hotelier",
    "InnQuest",
    "Frontdesk Anywhere",
    "RDPWin",
    "Maestro PMS",
    "RMS Cloud",
    "Mews",
    "Protel",
    "ResNexus",
    "RoomKeyPMS",
    "SkyTouch Technology",
    "CheckMate",
    "Springer-Miller Systems",
    "StayNTouch",
    "Track Hospitality Software",
    "Alice",
    "Zingle",
    "Revinate",
    "TrustYou",
    "GuestRevu",
    "TripAdvisor",
    "Booking.com",
    "Expedia",
    "Airbnb",
    "VRBO",
    "Trivago",
    "Kayak",
    "Skyscanner",
    "Priceline",
    "Orbitz",
    "Hotwire",
    "Travelocity",
    "Agoda",
    "Cvent",
    "Eventbrite",
    "Ticketmaster",
    "RegFox",
    "Bizzabo",
    "Whova",
    "Social Tables",
  ],
  "Transportation and Logistics": [
    "SAP Transportation Management",
    "Oracle Transportation Management",
    "JDA Transportation Manager",
    "Manhattan TMS",
    "BluJay Solutions",
    "MercuryGate TMS",
    "Kuebix TMS",
    "AscendTMS",
    "3GTMS",
    "Transplace",
    "FreightPOP",
    "Cargomatic",
    "Descartes",
    "Freightos",
    "LoadPilot",
    "Aljex",
    "DAT Solutions",
    "Truckstop.com",
    "123Loadboard",
    "TransCore",
    "McLeod Software",
    "ProTransport",
    "Fleet Complete",
    "Samsara",
    "Geotab",
    "Omnitracs",
    "Verizon Connect",
    "KeepTruckin",
    "Teletrac Navman",
    "Trimble",
    "Spireon",
    "NexTraq",
    "Azuga",
    "Fleetio",
    "FleetLocate",
    "EROAD",
    "Mix Telematics",
    "Zonar Systems",
    "Motive",
    "Fleetmatics",
    "Lytx",
    "Quartix",
    "GPS Insight",
    "Rhino Fleet Tracking",
    "Route4Me",
    "Routific",
    "MyRouteOnline",
    "OptimoRoute",
    "Circuit",
    "Onfleet",
  ],
  "Retail and Wholesale": [
    "Shopify",
    "BigCommerce",
    "WooCommerce",
    "Magento",
    "PrestaShop",
    "OpenCart",
    "Salesforce Commerce Cloud",
    "SAP Commerce Cloud",
    "Oracle Commerce",
    "IBM WebSphere Commerce",
    "3dcart",
    "Volusion",
    "Wix",
    "Squarespace",
    "Weebly",
    "Ecwid",
    "Big Cartel",
    "Square",
    "PayPal",
    "Stripe",
    "Authorize.Net",
    "Adyen",
    "Klarna",
    "Afterpay",
    "Sezzle",
    "Affirm",
    "QuickBooks",
    "Xero",
    "NetSuite",
    "Sage",
    "Zoho Books",
    "Microsoft Dynamics 365",
    "Brightpearl",
    "TradeGecko",
    "Stitch Labs",
    "DEAR Inventory",
    "Cin7",
    "Unleashed",
    "Orderhive",
    "ShipStation",
    "ShipBob",
    "Easyship",
    "Shippo",
    "ShipHero",
    "AfterShip",
    "Route",
    "Shipwire",
    "FedEx",
    "UPS",
    "DHL",
    "USPS",
  ],
  "Real Estate": [
    "Zillow",
    "Realtor.com",
    "Redfin",
    "Trulia",
    "LoopNet",
    "CoStar",
    "CREXi",
    "Reonomy",
    "Buildout",
    "PropertyRadar",
    "AppFolio",
    "Yardi",
    "Entrata",
    "RealPage",
    "MRI Software",
    "Buildium",
    "TenantCloud",
    "Rentec Direct",
    "RentManager",
    "TurboTenant",
    "Avail",
    "Cozy",
    "ShowMojo",
    "Rently",
    "Tenant Turner",
    "SmartMove",
    "MyNewPlace",
    "Zumper",
    "PadMapper",
    "Apartment List",
    "Apartments.com",
    "Craigslist",
    "Facebook Marketplace",
    "OpenDoor",
    "Offerpad",
    "Knock",
    "HomeLight",
    "Ribbon",
    "EasyKnock",
    "Flyhomes",
    "NockNock",
    "Purplebricks",
    "RedDoor",
    "Roofstock",
    "Fundrise",
    "RealtyMogul",
    "CrowdStreet",
    "Groundfloor",
    "PeerStreet",
  ],
  "Government and Public Administration": [
    "Microsoft Office",
    "Google Workspace",
    "Adobe Acrobat",
    "DocuSign",
    "Adobe Sign",
    "Zoom",
    "Microsoft Teams",
    "Cisco Webex",
    "GoToMeeting",
    "Slack",
    "Trello",
    "Asana",
    "Monday.com",
    "Notion",
    "Evernote",
    "OneNote",
    "Salesforce",
    "Oracle",
    "SAP",
    "Workday",
    "PeopleSoft",
    "Kronos",
    "ADP",
    "Gusto",
    "Zenefits",
    "Paychex",
    "QuickBooks",
    "Xero",
    "NetSuite",
    "FreshBooks",
    "Sage",
    "JIRA",
    "ServiceNow",
    "Zendesk",
    "Freshdesk",
    "Intercom",
    "Drift",
    "LiveChat",
    "Olark",
    "Tawk.to",
    "Google Analytics",
    "Tableau",
    "Power BI",
    "QlikView",
    "Qlik Sense",
    "SAP Lumira",
    "Alteryx",
    "KNIME",
    "RapidMiner",
    "DataRobot",
  ],
  "Non-Profit and Social Services": [
    "Salesforce",
    "Blackbaud",
    "Bloomerang",
    "DonorPerfect",
    "NeonCRM",
    "Raiser's Edge",
    "Kindful",
    "Little Green Light",
    "EveryAction",
    "Network for Good",
    "Classy",
    "Funraise",
    "GiveGab",
    "Donately",
    "Qgiv",
    "Salsa Labs",
    "CharityEngine",
    "DonorBox",
    "GiveLively",
    "MightyCause",
    "Double the Donation",
    "VolunteerHub",
    "SignUpGenius",
    "Golden",
    "Mobilize",
    "BoardEffect",
    "OnBoard",
    "Diligent",
    "Boardable",
    "BoardDocs",
    "Charity Navigator",
    "GuideStar",
    "TechSoup",
    "Idealist",
    "VolunteerMatch",
    "JustServe",
    "HandsOn Connect",
    "CrowdRise",
    "GoFundMe",
    "Patreon",
    "Kickstarter",
    "Indiegogo",
    "Fundly",
    "YouHelp",
    "FirstGiving",
    "CauseVox",
    "GiveWP",
    "iDonate",
    "DonorDrive",
    "Network for Good",
  ],
  "Energy and Utilities": [
    "SAP IS-U",
    "Oracle Utilities",
    "Schneider Electric",
    "ABB Ability",
    "GE Power",
    "Siemens Energy",
    "OSIsoft PI",
    "Bentley Systems",
    "Emerson",
    "Honeywell",
    "Yokogawa",
    "Rockwell Automation",
    "AVEVA",
    "ETAP",
    "Schlumberger",
    "Halliburton",
    "Baker Hughes",
    "Weatherford",
    "Petrosoft",
    "P2 Energy Solutions",
    "Quorum",
    "Oildex",
    "WellEz",
    "EnergySys",
    "Enverus",
    "IHS Markit",
    "Rystad Energy",
    "Wood Mackenzie",
    "S&P Global Platts",
    "Argus Media",
    "Bloomberg New Energy Finance",
    "FERC Online",
    "NERC Compliance",
    "OSHA",
    "EPA Compliance",
    "OSHA",
    "EPA",
    "DOE",
    "EIA",
    "GIS",
    "ArcGIS",
    "QGIS",
    "Google Earth",
    "AutoCAD Map 3D",
    "MapInfo",
    "ERDAS IMAGINE",
    "Global Mapper",
    "Surfer",
    "Golden Software",
  ],
  "Agriculture and Forestry": [
    "John Deere Operations Center",
    "Trimble Ag Software",
    "Ag Leader",
    "FarmLogs",
    "Climate FieldView",
    "Conservis",
    "Agrian",
    "AgWorld",
    "Granular",
    "AgFiniti",
    "FarmWorks",
    "FarmERP",
    "FarmCommand",
    "AgSquared",
    "AgOS",
    "Trimble",
    "Topcon",
    "Raven Industries",
    "Precision Planting",
    "AgJunction",
    "Case IH AFS",
    "New Holland PLM",
    "Deere & Company",
    "AGCO",
    "CNH Industrial",
    "Kubota",
    "Mahindra",
    "Yanmar",
    "Arable",
    "Taranis",
    "Sentera",
    "DroneDeploy",
    "Pix4D",
    "Agremo",
    "Ceres Imaging",
    "Prospera",
    "FarmBot",
    "FarmQA",
    "Farmbrite",
    "Cropio",
    "CropX",
    "AgSense",
    "iCropTrak",
    "OnFarm",
    "AgriSync",
    "Agrivi",
    "AgriWebb",
    "Farmable",
    "Trimble Forestry",
    "TreePlotter",
    "Forest Metrix",
  ],
  "Environmental Services": [
    "ESRI ArcGIS",
    "QGIS",
    "AutoCAD Civil 3D",
    "Bentley MicroStation",
    "Trimble Business Center",
    "Global Mapper",
    "Surfer",
    "Golden Software",
    "ENVI",
    "ERDAS IMAGINE",
    "MapInfo",
    "Google Earth",
    "OpenStreetMap",
    "Mapbox",
    "Leaflet",
    "CartoDB",
    "GeoServer",
    "PostGIS",
    "GDAL",
    "OGR",
    "FME",
    "HEC-RAS",
    "HEC-HMS",
    "SWMM",
    "HydroCAD",
    "WinTR-55",
    "MIKE SHE",
    "EPA SWMM",
    "EPANET",
    "MODFLOW",
    "AQTESOLV",
    "GMS",
    "SMS",
    "WMS",
    "GEO-SLOPE",
    "PLAXIS",
    "FLAC",
    "GeoStudio",
    "SEEP/W",
    "SLOPE/W",
    "SIGMA/W",
    "DHI MIKE",
    "DHI MIKE URBAN",
    "EFDC",
    "TUFLOW",
    "RIVER2D",
    "SWAT",
    "HSPF",
  ],
  Telecommunications: [
    "Cisco Packet Tracer",
    "GNS3",
    "Wireshark",
    "SolarWinds",
    "Nagios",
    "Zabbix",
    "PRTG Network Monitor",
    "Spiceworks",
    "ManageEngine OpManager",
    "NetFlow Analyzer",
    "WhatsUp Gold",
    "ThousandEyes",
    "OpenNMS",
    "Cacti",
    "Observium",
    "LibreNMS",
    "PingPlotter",
    "MikroTik RouterOS",
    "Ubiquiti UniFi",
    "Cisco IOS",
    "Juniper Junos",
    "Arista EOS",
    "Huawei VRP",
    "Fortinet FortiOS",
    "Palo Alto PAN-OS",
    "Checkpoint Gaia",
    "F5 BIG-IP",
    "Citrix NetScaler",
    "Riverbed SteelHead",
    "Silver Peak SD-WAN",
    "VMware NSX",
    "AWS Direct Connect",
    "Azure ExpressRoute",
    "Google Cloud Interconnect",
    "Equinix Cloud Exchange",
    "Megaport",
    "PacketFabric",
    "Tata Communications",
    "AT&T",
    "Verizon",
    "CenturyLink",
    "Level 3",
    "Sprint",
    "T-Mobile",
    "Vodafone",
    "Telefonica",
    "Orange",
    "BT Group",
  ],
  Automotive: [
    "AutoCAD",
    "SolidWorks",
    "CATIA",
    "NX",
    "Creo",
    "Inventor",
    "Fusion 360",
    "MATLAB",
    "Simulink",
    "ANSYS",
    "ADAMS",
    "CarSim",
    "Simpack",
    "AVL Cruise",
    "GT-SUITE",
    "HyperWorks",
    "LS-DYNA",
    "Abaqus",
    "MSC Nastran",
    "MSC Adams",
    "Altair Radioss",
    "Altair MotionSolve",
    "Altair OptiStruct",
    "Siemens Simcenter",
    "AVL Fire",
    "Converge CFD",
    "STAR-CCM+",
    "Fluent",
    "Comsol Multiphysics",
    "OpenFOAM",
    "Ricardo WAVE",
    "GT-Power",
    "Abaqus",
    "HyperMesh",
    "HyperView",
    "HyperGraph",
    "Solid Edge",
    "SimScale",
    "RecurDyn",
    "VehicleSpy",
    "CANoe",
    "CANalyzer",
    "FlexRay",
    "Simulink",
    "Stateflow",
    "TargetLink",
    "dSPACE",
    "VectorCAST",
    "NI VeriStand",
    "ETAS INCA",
  ],
  "Aerospace and Defense": [
    "AutoCAD",
    "SolidWorks",
    "CATIA",
    "NX",
    "Creo",
    "Inventor",
    "Fusion 360",
    "MATLAB",
    "Simulink",
    "ANSYS",
    "NASTRAN",
    "Patran",
    "HyperWorks",
    "LS-DYNA",
    "Abaqus",
    "MSC Nastran",
    "MSC Adams",
    "Altair Radioss",
    "Altair MotionSolve",
    "Altair OptiStruct",
    "Siemens Simcenter",
    "Comsol Multiphysics",
    "OpenFOAM",
    "Fluent",
    "STAR-CCM+",
    "Converge CFD",
    "CFX",
    "SimScale",
    "RecurDyn",
    "RecurDyn",
    "Simulink",
    "Stateflow",
    "TargetLink",
    "dSPACE",
    "VectorCAST",
    "NI VeriStand",
    "ETAS INCA",
    "Agilent ADS",
    "HFSS",
    "CST Studio Suite",
    "FEKO",
    "EMPro",
    "Genesys",
    "Keysight PathWave",
    "ANSYS HFSS",
    "AWR Design Environment",
    "Mathematica",
    "Maple",
    "LabVIEW",
    "Simulink",
    "MATLAB",
  ],
  Pharmaceuticals: [
    "SAP",
    "Oracle",
    "Microsoft Dynamics",
    "NetSuite",
    "TrackWise",
    "MasterControl",
    "Veeva Vault",
    "Sparta Systems",
    "Greenlight Guru",
    "Arena Solutions",
    "QAD",
    "Infor",
    "Plex",
    "IQMS",
    "Sage",
    "Epicor",
    "Fishbowl",
    "BatchMaster",
    "Acumatica",
    "QuickBooks",
    "Xero",
    "FreshBooks",
    "Zoho Books",
    "Microsoft Excel",
    "Google Sheets",
    "Tableau",
    "Power BI",
    "QlikView",
    "Qlik Sense",
    "SAP Lumira",
    "Alteryx",
    "KNIME",
    "SAS",
    "JMP",
    "Minitab",
    "SPSS",
    "GraphPad Prism",
    "OriginLab",
    "LabWare",
    "STARLIMS",
    "LIMSABC",
    "LabVantage",
    "Labguru",
    "ELN",
    "Benchling",
    "Dotmatics",
    "BIOVIA",
    "ChemAxon",
    "PerkinElmer Signals",
    "Waters Empower",
  ],
  Biotechnology: [
    "Microsoft Excel",
    "Google Sheets",
    "Tableau",
    "Power BI",
    "QlikView",
    "Qlik Sense",
    "SAP Lumira",
    "Alteryx",
    "KNIME",
    "SAS",
    "JMP",
    "Minitab",
    "SPSS",
    "GraphPad Prism",
    "OriginLab",
    "LabWare",
    "STARLIMS",
    "LIMSABC",
    "LabVantage",
    "Labguru",
    "ELN",
    "Benchling",
    "Dotmatics",
    "BIOVIA",
    "ChemAxon",
    "PerkinElmer Signals",
    "Waters Empower",
    "Chromeleon",
    "SoftMax Pro",
    "Bio-Rad CFX Manager",
    "Gen5",
    "FlowJo",
    "BD FACSDiva",
    "CellQuest Pro",
    "MODDE",
    "DoE PRO XL",
    "JMP",
    "Minitab",
    "Design-Expert",
    "MATLAB",
    "Simulink",
    "R",
    "Python",
    "Anaconda",
    "TensorFlow",
    "Keras",
    "PyTorch",
    "Scikit-learn",
    "Pandas",
    "NumPy",
    "SciPy",
    "Matplotlib",
    "Seaborn",
    "Plotly",
  ],
  Insurance: [
    "Salesforce",
    "HubSpot",
    "Zoho CRM",
    "Microsoft Dynamics",
    "SAP",
    "Oracle",
    "NetSuite",
    "QuickBooks",
    "Xero",
    "FreshBooks",
    "Zoho Books",
    "Sage",
    "Wave",
    "Tableau",
    "Power BI",
    "QlikView",
    "Qlik Sense",
    "SAP Lumira",
    "Alteryx",
    "KNIME",
    "SAS",
    "JMP",
    "Minitab",
    "SPSS",
    "GraphPad Prism",
    "OriginLab",
    "MATLAB",
    "Simulink",
    "R",
    "Python",
    "Anaconda",
    "TensorFlow",
    "Keras",
    "PyTorch",
    "Scikit-learn",
    "Pandas",
    "NumPy",
    "SciPy",
    "Matplotlib",
    "Seaborn",
    "Plotly",
    "Zoom",
    "Microsoft Teams",
    "Google Meet",
    "Slack",
    "Trello",
    "Asana",
    "Monday.com",
    "Notion",
    "Evernote",
    "OneNote",
  ],
  "Food and Beverage": [
    "SAP",
    "Oracle",
    "Microsoft Dynamics",
    "NetSuite",
    "Fishbowl",
    "BatchMaster",
    "Acumatica",
    "QuickBooks",
    "Xero",
    "FreshBooks",
    "Zoho Books",
    "Sage",
    "Epicor",
    "IQMS",
    "Plex",
    "QAD",
    "Infor",
    "TrackWise",
    "MasterControl",
    "Veeva Vault",
    "Sparta Systems",
    "Greenlight Guru",
    "Arena Solutions",
    "Tableau",
    "Power BI",
    "QlikView",
    "Qlik Sense",
    "SAP Lumira",
    "Alteryx",
    "KNIME",
    "SAS",
    "JMP",
    "Minitab",
    "SPSS",
    "GraphPad Prism",
    "OriginLab",
    "LabWare",
    "STARLIMS",
    "LIMSABC",
    "LabVantage",
    "Labguru",
    "ELN",
    "Benchling",
    "Dotmatics",
    "BIOVIA",
    "ChemAxon",
    "PerkinElmer Signals",
    "Waters Empower",
  ],
  "Arts and Entertainment": [
    "Adobe Photoshop",
    "Adobe Illustrator",
    "Adobe InDesign",
    "Adobe XD",
    "Sketch",
    "Figma",
    "CorelDRAW",
    "Affinity Designer",
    "Canva",
    "Gravit Designer",
    "Procreate",
    "Inkscape",
    "GIMP",
    "Blender",
    "Maya",
    "3ds Max",
    "Cinema 4D",
    "ZBrush",
    "SketchUp",
    "Rhino",
    "AutoCAD",
    "Fusion 360",
    "Revit",
    "Substance Painter",
    "Houdini",
    "Marvelous Designer",
    "Unity",
    "Unreal Engine",
    "After Effects",
    "Premiere Pro",
    "Final Cut Pro",
    "DaVinci Resolve",
    "Avid Media Composer",
    "Lightroom",
    "Capture One",
    "Luminar",
    "DxO PhotoLab",
    "Darktable",
    "Snapseed",
    "VSCO",
    "Clip Studio Paint",
    "PaintTool SAI",
    "Krita",
    "Corel Painter",
    "ArtRage",
    "Tayasui Sketches",
    "MediBang Paint",
    "Rebelle",
    "Pixelmator",
  ],
  "Sports and Recreation": [
    "Hudl",
    "SportsEngine",
    "TeamSnap",
    "Sportlyzer",
    "Coach's Eye",
    "Dartfish",
    "Kinovea",
    "Tracker",
    "Opta Sports",
    "Catapult",
    "STATSports",
    "Zebra MotionWorks",
    "Krossover",
    "Rapsodo",
    "Blast Motion",
    "Zepp",
    "ShotTracker",
    "Huddle Technique",
    "Prozone",
    "iCoyote",
    "PlayerTek",
    "Polar",
    "Garmin",
    "Suunto",
    "Fitbit",
    "Nike Training Club",
    "Strava",
    "Runkeeper",
    "MapMyRun",
    "Endomondo",
    "Runtastic",
    "MyFitnessPal",
    "JEFIT",
    "Strong",
    "Gymshark",
    "BodySpace",
    "Fitbod",
    "Sworkit",
    "Freeletics",
    "8fit",
    "Daily Burn",
    "Beachbody",
    "Peloton",
    "Zwift",
    "TrainerRoad",
    "The Sufferfest",
    "Trainerize",
    "Virtuagym",
    "My PT Hub",
    "Trainerize",
  ],
  "Fashion and Apparel": [
    "Adobe Illustrator",
    "Adobe Photoshop",
    "CorelDRAW",
    "CLO 3D",
    "TUKAcad",
    "TUKA3D",
    "Browzwear",
    "Optitex",
    "Gerber Technology",
    "Lectra",
    "EFI Optitex",
    "YuniquePLM",
    "Centric PLM",
    "Assyst Bullmer",
    "TUKAcad",
    "TUKA3D",
    "PatternSmith",
    "PAD System",
    "Audaces",
    "Gerber AccuMark",
    "Lectra Modaris",
    "Optitex PDS",
    "CLO 3D",
    "Browzwear VStitcher",
    "Marvelous Designer",
    "Rhino",
    "SketchUp",
    "AutoCAD",
    "Fusion 360",
    "Revit",
    "ZBrush",
    "Tinkercad",
    "FreeCAD",
    "Solid Edge",
    "Creo",
    "NX",
    "ArchiCAD",
    "Lumion",
    "Bluebeam Revu",
    "SketchList",
    "Simpson Strong-Tie",
    "MiTek SAPPHIRE",
    "Hilti PROFIS",
    "SAP Apparel and Footwear",
    "Oracle Retail",
    "Microsoft Dynamics",
    "NetSuite",
    "QuickBooks",
    "Xero",
    "FreshBooks",
  ],
};

export const jobSkills = {
  "Information Technology": [
    "Programming",
    "Database Management",
    "Network Administration",
    "Cybersecurity",
    "Cloud Computing",
    "Web Development",
    "Software Development",
    "IT Support",
    "Data Analysis",
    "Machine Learning",
    "Artificial Intelligence",
    "System Administration",
    "Project Management",
    "UI/UX Design",
    "Agile Methodology",
    "Scrum",
    "DevOps",
    "Technical Writing",
    "Virtualization",
    "Mobile App Development",
    "Big Data",
    "Data Warehousing",
    "Internet of Things (IoT)",
    "Blockchain",
    "Ethical Hacking",
    "ITIL",
    "Linux/Unix Administration",
    "Quality Assurance",
    "Business Intelligence",
    "Robotic Process Automation",
    "Scripting Languages",
    "Version Control Systems",
    "API Integration",
    "Hardware Troubleshooting",
    "IT Governance",
    "Virtual Reality",
    "Augmented Reality",
    "Game Development",
    "Customer Relationship Management (CRM)",
    "Enterprise Resource Planning (ERP)",
    "Server Management",
    "Technical Support",
    "Telecommunications",
    "IT Compliance",
    "Vendor Management",
    "E-commerce Platforms",
    "Search Engine Optimization (SEO)",
    "Content Management Systems (CMS)",
    "Email Marketing",
  ],
  Healthcare: [
    "Patient Care",
    "Medical Terminology",
    "Clinical Procedures",
    "Nursing",
    "Pharmacy",
    "Medical Imaging",
    "Healthcare Management",
    "Surgical Technology",
    "Medical Coding",
    "Health Information Management",
    "Medical Research",
    "Patient Education",
    "Telemedicine",
    "Public Health",
    "Geriatric Care",
    "Pediatrics",
    "Emergency Care",
    "Nutrition",
    "Mental Health",
    "Medical Ethics",
    "Infection Control",
    "Laboratory Skills",
    "Medical Equipment Handling",
    "Phlebotomy",
    "Respiratory Therapy",
    "Physical Therapy",
    "Occupational Therapy",
    "Speech Therapy",
    "Healthcare IT",
    "Healthcare Compliance",
    "Medical Billing",
    "Cardiology",
    "Radiology",
    "Oncology",
    "Pathology",
    "Dermatology",
    "Neurology",
    "Orthopedics",
    "Anesthesiology",
    "Obstetrics and Gynecology",
    "Palliative Care",
    "Rehabilitation Services",
    "Hospital Administration",
    "Healthcare Consulting",
    "Clinical Trials",
    "Biomedical Engineering",
    "Epidemiology",
    "Healthcare Marketing",
    "Health Policy",
    "Home Healthcare",
  ],
  Finance: [
    "Financial Analysis",
    "Accounting",
    "Budgeting",
    "Financial Reporting",
    "Taxation",
    "Auditing",
    "Investment Management",
    "Risk Management",
    "Financial Planning",
    "Corporate Finance",
    "Financial Modeling",
    "Mergers and Acquisitions",
    "Banking",
    "Insurance",
    "Compliance",
    "Portfolio Management",
    "Credit Analysis",
    "Treasury Management",
    "Financial Forecasting",
    "Cash Flow Management",
    "Cost Accounting",
    "Asset Management",
    "Financial Consulting",
    "Forensic Accounting",
    "Internal Controls",
    "Payroll Management",
    "Accounts Payable",
    "Accounts Receivable",
    "Cost Analysis",
    "Wealth Management",
    "Revenue Recognition",
    "Investment Banking",
    "Venture Capital",
    "Private Equity",
    "Estate Planning",
    "Retirement Planning",
    "Financial Risk Assessment",
    "Quantitative Analysis",
    "Valuation",
    "Fraud Detection",
    "Economic Analysis",
    "Financial Software",
    "Strategic Planning",
    "Equity Research",
    "Debt Management",
    "Financial Regulations",
    "Bank Reconciliation",
    "Tax Planning",
    "Management Accounting",
  ],
  Engineering: [
    "Project Management",
    "Computer-Aided Design (CAD)",
    "Mechanical Engineering",
    "Electrical Engineering",
    "Civil Engineering",
    "Structural Analysis",
    "Materials Science",
    "Thermodynamics",
    "Fluid Dynamics",
    "Manufacturing Processes",
    "Quality Control",
    "Process Engineering",
    "Automation",
    "Robotics",
    "Engineering Design",
    "Testing and Evaluation",
    "Product Development",
    "Safety Engineering",
    "Renewable Energy",
    "Construction Management",
    "Environmental Engineering",
    "Geotechnical Engineering",
    "Transportation Engineering",
    "Urban Planning",
    "Chemical Engineering",
    "Industrial Engineering",
    "Aerospace Engineering",
    "Biomedical Engineering",
    "Software Engineering",
    "System Engineering",
    "Network Engineering",
    "Telecommunications",
    "Energy Management",
    "HVAC Systems",
    "Piping and Instrumentation",
    "Project Scheduling",
    "Cost Estimation",
    "Technical Writing",
    "Regulatory Compliance",
    "Lean Manufacturing",
    "Six Sigma",
    "Risk Management",
    "Supply Chain Management",
    "Reliability Engineering",
    "Finite Element Analysis",
    "Mechatronics",
    "Data Analysis",
    "Instrumentation",
    "Field Engineering",
  ],
  Sales: [
    "Sales Strategy",
    "Customer Relationship Management (CRM)",
    "Sales Presentations",
    "Lead Generation",
    "Negotiation",
    "Market Research",
    "Product Knowledge",
    "Sales Forecasting",
    "Salesforce",
    "Retail Sales",
    "Business Development",
    "Customer Service",
    "Account Management",
    "B2B Sales",
    "B2C Sales",
    "Digital Marketing",
    "Cold Calling",
    "Sales Pipeline Management",
    "Closing Techniques",
    "Referral Marketing",
    "Sales Training",
    "Sales Reporting",
    "Competitive Analysis",
    "Brand Management",
    "Public Relations",
    "Advertising",
    "Event Planning",
    "Trade Shows",
    "E-commerce",
    "SEO",
    "Content Marketing",
    "Email Marketing",
    "Social Media Marketing",
    "Telemarketing",
    "Client Retention",
    "Channel Sales",
    "Value Proposition",
    "Market Segmentation",
    "Product Launches",
    "Sales Operations",
    "Territory Management",
    "Sales Coaching",
    "Upselling",
    "Cross-selling",
    "Customer Insights",
    "Sales Metrics",
    "Networking",
    "Sales Collateral",
    "Presentation Skills",
  ],
  HR: [
    "Recruitment",
    "Talent Acquisition",
    "Employee Relations",
    "Performance Management",
    "Compensation and Benefits",
    "HR Compliance",
    "Training and Development",
    "Onboarding",
    "HR Information Systems (HRIS)",
    "Payroll Management",
    "Labor Laws",
    "Employee Engagement",
    "Organizational Development",
    "Succession Planning",
    "Diversity and Inclusion",
    "Workforce Planning",
    "HR Analytics",
    "Conflict Resolution",
    "Employee Retention",
    "Change Management",
    "Policy Development",
    "Job Analysis",
    "HR Metrics",
    "Leadership Development",
    "Team Building",
    "HR Strategy",
    "Employee Surveys",
    "Benefits Administration",
    "Compensation Analysis",
    "Job Descriptions",
    "Talent Management",
    "Employee Handbook",
    "Recruitment Marketing",
    "Interviewing",
    "Employee Wellness",
    "Performance Appraisal",
    "Labor Relations",
    "HR Reporting",
    "Workplace Safety",
    "HR Policies",
    "Employee Motivation",
    "Time and Attendance",
    "HR Audits",
    "Job Evaluation",
    "Career Development",
    "Training Programs",
    "Organizational Behavior",
    "HR Best Practices",
  ],
  Education: [
    "Curriculum Development",
    "Lesson Planning",
    "Classroom Management",
    "Educational Technology",
    "Student Assessment",
    "Special Education",
    "Early Childhood Education",
    "K-12 Education",
    "Higher Education",
    "Adult Education",
    "Online Teaching",
    "Instructional Design",
    "E-Learning",
    "Educational Psychology",
    "Teaching Strategies",
    "Differentiated Instruction",
    "Literacy Education",
    "STEM Education",
    "Classroom Technology",
    "Educational Leadership",
    "Professional Development",
    "Student Engagement",
    "Teacher Training",
    "Blended Learning",
    "Project-Based Learning",
    "Educational Research",
    "ESL Teaching",
    "Student Counseling",
    "School Administration",
    "Learning Management Systems (LMS)",
    "Student Discipline",
    "Educational Policy",
    "Parent Communication",
    "Peer Tutoring",
    "Academic Advising",
    "Student Mentoring",
    "Behavior Management",
    "Learning Disabilities",
    "Reading Intervention",
    "Math Intervention",
    "Science Education",
    "History Education",
    "Arts Education",
    "Physical Education",
    "Foreign Language Teaching",
    "Education Law",
    "School Safety",
    "Cultural Competence",
    "Study Skills",
    "Career Counseling",
  ],
  CustomerService: [
    "Customer Support",
    "Call Center Operations",
    "Client Relations",
    "Communication Skills",
    "Problem Solving",
    "Conflict Resolution",
    "Product Knowledge",
    "Technical Support",
    "Help Desk",
    "Customer Feedback",
    "Customer Retention",
    "Complaint Handling",
    "Order Processing",
    "Customer Satisfaction",
    "CRM Systems",
    "Live Chat Support",
    "Email Support",
    "Phone Support",
    "Service Level Agreements",
    "Customer Onboarding",
    "Customer Education",
    "Upselling",
    "Cross-Selling",
    "Customer Advocacy",
    "Customer Insights",
    "Customer Loyalty Programs",
    "Customer Journey Mapping",
    "Customer Service Training",
    "Quality Assurance",
    "Customer Interaction",
    "Customer Service Metrics",
    "Multitasking",
    "Time Management",
    "Customer Data Management",
    "Customer Experience",
    "Troubleshooting",
    "Ticketing Systems",
    "Customer Service Automation",
    "Customer Outreach",
    "Customer Relationship Building",
    "Social Media Support",
    "Customer Service Scripts",
    "Knowledge Base Management",
    "Customer Surveys",
    "Escalation Management",
    "Product Support",
    "Customer Engagement",
    "Customer Communication",
    "Customer Service Improvement",
  ],
  Design: [
    "Graphic Design",
    "UI/UX Design",
    "Web Design",
    "Product Design",
    "Illustration",
    "Adobe Creative Suite",
    "Typography",
    "Branding",
    "Logo Design",
    "Packaging Design",
    "Print Design",
    "Digital Media",
    "Motion Graphics",
    "Video Editing",
    "Photography",
    "Interaction Design",
    "User Research",
    "Wireframing",
    "Prototyping",
    "Visual Design",
    "3D Design",
    "Animation",
    "User Testing",
    "Mobile App Design",
    "Color Theory",
    "Layout Design",
    "Design Thinking",
    "Infographic Design",
    "Icon Design",
    "Creative Direction",
    "Storyboard Creation",
    "Character Design",
    "Environmental Design",
    "Exhibition Design",
    "Design Systems",
    "Design Strategy",
    "Game Design",
    "Art Direction",
    "Corporate Identity",
    "Editorial Design",
    "E-commerce Design",
    "Print Production",
    "Social Media Design",
    "Content Creation",
    "Marketing Design",
    "Interactive Design",
    "Visual Communication",
    "Presentation Design",
    "User Flows",
  ],
  BusinessDev: [
    "Sales Strategy",
    "Lead Generation",
    "Market Research",
    "Business Planning",
    "Negotiation",
    "Networking",
    "Partnership Development",
    "Sales Management",
    "Account Management",
    "Customer Relationship Management (CRM)",
    "Product Development",
    "Competitive Analysis",
    "Strategic Planning",
    "Revenue Growth",
    "Pitching",
    "Proposal Writing",
    "Market Analysis",
    "Sales Forecasting",
    "Financial Analysis",
    "Client Retention",
    "Cross-functional Collaboration",
    "Product Launches",
    "Business Analysis",
    "Sales Presentations",
    "Customer Insights",
    "Digital Marketing",
    "Content Marketing",
    "SEO",
    "Social Media Marketing",
    "Email Marketing",
    "Public Relations",
    "Brand Management",
    "Advertising",
    "Event Planning",
    "Trade Shows",
    "E-commerce",
    "Project Management",
    "Contract Management",
    "Vendor Management",
    "Channel Sales",
    "Value Proposition",
    "Customer Segmentation",
    "Product Management",
    "Customer Acquisition",
    "Sales Operations",
    "Revenue Forecasting",
    "Sales Pipeline Management",
    "Client Onboarding",
  ],
  Legal: [
    "Legal Research",
    "Contract Drafting",
    "Litigation",
    "Legal Writing",
    "Corporate Law",
    "Intellectual Property",
    "Employment Law",
    "Family Law",
    "Criminal Law",
    "Real Estate Law",
    "Mergers and Acquisitions",
    "Compliance",
    "Regulatory Affairs",
    "Legal Consulting",
    "Case Management",
    "Legal Technology",
    "Alternative Dispute Resolution",
    "Mediation",
    "Arbitration",
    "Legal Compliance",
    "Legal Administration",
    "Courtroom Procedures",
    "Legal Documentation",
    "Estate Planning",
    "Trusts and Estates",
    "Bankruptcy Law",
    "Tax Law",
    "Environmental Law",
    "Healthcare Law",
    "Immigration Law",
    "International Law",
    "Public Policy",
    "Government Affairs",
    "Privacy Law",
    "Data Protection",
    "Legal Ethics",
    "Trial Advocacy",
    "Discovery Management",
    "Legal Negotiation",
    "Paralegal Support",
    "Legal Education",
    "Jurisprudence",
    "Contract Management",
    "Legal Project Management",
    "Client Counseling",
    "Legal Risk Management",
    "Intellectual Property Management",
    "Legal Marketing",
    "Legal Operations",
  ],
  Manufacturing: [
    "Production Planning",
    "Quality Control",
    "Lean Manufacturing",
    "Six Sigma",
    "Process Improvement",
    "Supply Chain Management",
    "Inventory Management",
    "Manufacturing Engineering",
    "Safety Management",
    "Project Management",
    "Product Development",
    "CAD/CAM",
    "Automation",
    "Robotics",
    "Maintenance",
    "Industrial Engineering",
    "Cost Reduction",
    "Production Scheduling",
    "Materials Management",
    "Manufacturing Processes",
    "Inspection",
    "Quality Assurance",
    "Environmental Health and Safety",
    "Logistics",
    "Vendor Management",
    "Procurement",
    "Operations Management",
    "ISO Standards",
    "Continuous Improvement",
    "Root Cause Analysis",
    "Production Metrics",
    "Factory Management",
    "ERP Systems",
    "Supply Chain Optimization",
    "Product Lifecycle Management",
    "Workforce Management",
    "Production Reporting",
    "Capacity Planning",
    "Shop Floor Management",
    "Product Testing",
    "Industrial Automation",
    "Manufacturing Strategy",
    "Process Control",
    "Plant Layout Design",
    "Tool Design",
    "Lean Production",
    "Value Stream Mapping",
    "Total Quality Management",
  ],
  RandD: [
    "Research Methodology",
    "Data Analysis",
    "Experimental Design",
    "Project Management",
    "Statistical Analysis",
    "Product Development",
    "Prototyping",
    "Innovation Management",
    "Technical Writing",
    "Grant Writing",
    "Laboratory Skills",
    "Clinical Trials",
    "Regulatory Compliance",
    "Patent Research",
    "Literature Review",
    "Problem Solving",
    "Critical Thinking",
    "Technical Documentation",
    "Product Testing",
    "Market Research",
    "Scientific Research",
    "Data Collection",
    "Hypothesis Testing",
    "Research Ethics",
    "Feasibility Studies",
    "Simulation",
    "Process Optimization",
    "Technology Transfer",
    "Intellectual Property",
    "Research Presentation",
    "Collaboration",
    "Risk Assessment",
    "Product Launches",
    "Research Strategy",
    "Data Interpretation",
    "Report Writing",
    "Field Research",
    "Survey Design",
    "Knowledge Management",
    "Innovation Strategy",
    "Benchmarking",
    "Analytical Skills",
    "Research Communication",
    "Funding Acquisition",
    "Project Evaluation",
    "Scientific Analysis",
    "R&D Management",
    "Research Development",
  ],
  Consulting: [
    "Business Analysis",
    "Strategic Planning",
    "Project Management",
    "Financial Analysis",
    "Market Research",
    "Risk Management",
    "Change Management",
    "Process Improvement",
    "Data Analysis",
    "Management Consulting",
    "HR Consulting",
    "IT Consulting",
    "Operations Consulting",
    "Strategy Development",
    "Performance Management",
    "Organizational Development",
    "Client Relationship Management",
    "Proposal Writing",
    "Presentation Skills",
    "Negotiation",
    "Workshop Facilitation",
    "SWOT Analysis",
    "Benchmarking",
    "Competitor Analysis",
    "Stakeholder Management",
    "Communication Skills",
    "Problem Solving",
    "Business Intelligence",
    "Financial Modeling",
    "Business Process Reengineering",
    "Change Implementation",
    "Lean Consulting",
    "Six Sigma",
    "Supply Chain Consulting",
    "Customer Experience",
    "Business Development",
    "Revenue Growth",
    "Cost Reduction",
    "Training and Development",
    "Technical Consulting",
    "Performance Analysis",
    "Governance",
    "Regulatory Compliance",
    "Organizational Assessment",
    "Talent Management",
    "Brand Strategy",
    "Innovation Consulting",
    "Business Transformation",
  ],
  Media: [
    "Content Creation",
    "Journalism",
    "Copywriting",
    "Editing",
    "Public Relations",
    "Social Media Management",
    "Digital Marketing",
    "Video Production",
    "Photography",
    "Graphic Design",
    "SEO",
    "Content Strategy",
    "Media Planning",
    "Advertising",
    "Broadcasting",
    "Scriptwriting",
    "Audio Production",
    "Media Buying",
    "Event Planning",
    "Press Releases",
    "Marketing Campaigns",
    "Media Relations",
    "Public Speaking",
    "Brand Management",
    "Influencer Marketing",
    "Crisis Management",
    "Market Research",
    "Media Analytics",
    "Creative Direction",
    "Project Management",
    "Communications Strategy",
    "Email Marketing",
    "Storytelling",
    "Audience Engagement",
    "Campaign Management",
    "Media Sales",
    "Web Content Management",
    "Podcast Production",
    "Event Marketing",
    "Product Marketing",
    "Corporate Communications",
    "Media Law",
    "Editing Software",
    "Content Distribution",
    "Content Marketing",
    "Online Publishing",
    "Press Conference Management",
    "Talent Management",
    "Advertising Sales",
  ],
  Construction: [
    "Project Management",
    "Construction Management",
    "Blueprint Reading",
    "Estimating",
    "Scheduling",
    "Contract Administration",
    "Site Supervision",
    "Safety Management",
    "Quality Control",
    "Cost Control",
    "Materials Management",
    "Subcontractor Management",
    "Building Codes",
    "Construction Law",
    "Surveying",
    "Concrete Work",
    "Carpentry",
    "Electrical Work",
    "Plumbing",
    "HVAC Systems",
    "Steel Erection",
    "Masonry",
    "Roofing",
    "Landscaping",
    "Heavy Equipment Operation",
    "Earthworks",
    "Formwork",
    "Framing",
    "Drywall Installation",
    "Painting",
    "Flooring",
    "Tiling",
    "Windows and Doors Installation",
    "Insulation",
    "Demolition",
    "Paving",
    "Scaffolding",
    "Welding",
    "Excavation",
    "Survey Instruments",
    "Concrete Finishing",
    "Trench Safety",
    "Utility Installation",
    "Piping Systems",
    "Site Layout",
    "Construction Inspection",
    "Construction Documentation",
    "Construction Software",
  ],
  Hospitality: [
    "Customer Service",
    "Event Planning",
    "Hotel Management",
    "Food and Beverage Service",
    "Housekeeping",
    "Front Office Operations",
    "Sales and Marketing",
    "Banquet Coordination",
    "Hospitality Management",
    "Tourism Management",
    "Travel Planning",
    "Concierge Services",
    "Guest Relations",
    "Culinary Skills",
    "Bartending",
    "Event Coordination",
    "Catering Management",
    "Restaurant Management",
    "Revenue Management",
    "Reservation Systems",
    "Hospitality Software",
    "Cultural Awareness",
    "Multitasking",
    "Problem Solving",
    "Communication Skills",
    "Teamwork",
    "Time Management",
    "Customer Retention",
    "Crisis Management",
    "Menu Planning",
    "Food Safety",
    "Sanitation",
    "Budgeting",
    "Inventory Management",
    "Vendor Management",
    "Sustainability Practices",
    "Tour Guide",
    "Customer Feedback",
    "Upselling",
    "Cross-Selling",
    "Sales Strategy",
    "Hospitality Law",
    "Event Marketing",
    "Corporate Hospitality",
    "Wedding Planning",
    "Meeting Planning",
    "Destination Management",
    "Tour Operations",
    "Travel Sales",
  ],
  Transportation: [
    "Logistics Management",
    "Supply Chain Management",
    "Fleet Management",
    "Transportation Planning",
    "Traffic Management",
    "Warehouse Management",
    "Freight Forwarding",
    "Shipping",
    "Route Planning",
    "Delivery Management",
    "Inventory Management",
    "Customs Compliance",
    "Import/Export Management",
    "Transport Safety",
    "Regulatory Compliance",
    "Driver Management",
    "Vehicle Maintenance",
    "Transportation Software",
    "Customer Service",
    "Problem Solving",
    "Negotiation",
    "Communication Skills",
    "Time Management",
    "Cost Control",
    "Project Management",
    "Quality Assurance",
    "Risk Management",
    "Vendor Management",
    "Order Processing",
    "Packaging",
    "Distribution Management",
    "Transportation Analytics",
    "Network Optimization",
    "Performance Metrics",
    "Warehouse Operations",
    "Inventory Control",
    "Procurement",
    "Demand Planning",
    "Data Analysis",
    "Freight Management",
    "Customs Brokerage",
    "Route Optimization",
    "Supply Chain Optimization",
    "Asset Management",
    "Load Planning",
    "Inbound/Outbound Logistics",
    "Sustainability Practices",
  ],
  Retail: [
    "Customer Service",
    "Sales",
    "Merchandising",
    "Inventory Management",
    "Store Management",
    "Retail Operations",
    "Cash Handling",
    "Product Knowledge",
    "Visual Merchandising",
    "Sales Techniques",
    "Loss Prevention",
    "Point of Sale (POS) Systems",
    "Retail Marketing",
    "Customer Engagement",
    "Product Display",
    "Stock Replenishment",
    "Order Fulfillment",
    "E-commerce",
    "Retail Analytics",
    "Vendor Management",
    "Promotions",
    "Customer Retention",
    "Sales Reporting",
    "Sales Forecasting",
    "Customer Feedback",
    "Teamwork",
    "Problem Solving",
    "Communication Skills",
    "Time Management",
    "Product Launches",
    "Price Management",
    "Sales Training",
    "Store Layout Design",
    "Customer Insights",
    "Inventory Control",
    "Sales Strategy",
    "Retail Software",
    "Upselling",
    "Cross-Selling",
    "Customer Loyalty Programs",
    "Product Returns",
    "Retail Displays",
    "Customer Experience",
    "Retail Audits",
    "Sales Campaigns",
    "Market Research",
    "Brand Management",
    "Sales Goals",
    "Inventory Audits",
  ],
  RealEstate: [
    "Property Management",
    "Real Estate Sales",
    "Property Appraisal",
    "Market Analysis",
    "Lease Management",
    "Real Estate Law",
    "Real Estate Marketing",
    "Client Relationship Management",
    "Negotiation",
    "Contract Management",
    "Property Listings",
    "Home Staging",
    "Real Estate Transactions",
    "Investment Properties",
    "Real Estate Financing",
    "Tenant Relations",
    "Property Inspections",
    "Real Estate Software",
    "Property Development",
    "Commercial Real Estate",
    "Residential Real Estate",
    "Real Estate Investing",
    "Real Estate Valuation",
    "Mortgage Financing",
    "Real Estate Regulations",
    "Property Acquisition",
    "Title Examination",
    "Property Maintenance",
    "Zoning and Land Use",
    "Real Estate Consulting",
    "Property Taxation",
    "Construction Management",
    "Market Research",
    "Sales Strategy",
    "Customer Service",
    "Time Management",
    "Communication Skills",
    "Problem Solving",
    "Financial Analysis",
    "Investment Analysis",
    "Sales Forecasting",
    "Project Management",
    "Client Onboarding",
    "Sales Presentations",
    "Customer Retention",
    "Advertising",
    "Brand Management",
    "Networking",
    "Sales Goals",
  ],
  Government: [
    "Policy Development",
    "Regulatory Compliance",
    "Public Administration",
    "Legislative Research",
    "Government Relations",
    "Public Policy",
    "Community Engagement",
    "Program Management",
    "Budgeting",
    "Grant Writing",
    "Public Speaking",
    "Crisis Management",
    "Strategic Planning",
    "Stakeholder Management",
    "Public Relations",
    "Project Management",
    "Research and Analysis",
    "Data Analysis",
    "Legal Research",
    "Contract Management",
    "Procurement",
    "Human Resources",
    "Legislative Drafting",
    "Communication Skills",
    "Teamwork",
    "Leadership",
    "Conflict Resolution",
    "Negotiation",
    "Risk Management",
    "Problem Solving",
    "Public Safety",
    "Emergency Management",
    "Environmental Policy",
    "Transportation Planning",
    "Urban Planning",
    "Public Health",
    "Education Policy",
    "Economic Development",
    "International Relations",
    "Government Programs",
    "Public Finance",
    "Social Services",
    "Community Development",
    "Citizen Services",
    "Legislative Analysis",
    "Civic Engagement",
    "Performance Management",
    "Quality Assurance",
    "Regulatory Affairs",
    "Public Sector Innovation",
  ],
  NonProfit: [
    "Fundraising",
    "Grant Writing",
    "Volunteer Management",
    "Program Development",
    "Community Outreach",
    "Non-Profit Management",
    "Event Planning",
    "Donor Relations",
    "Marketing and Communications",
    "Social Media Management",
    "Public Relations",
    "Project Management",
    "Strategic Planning",
    "Budgeting",
    "Financial Management",
    "Advocacy",
    "Policy Analysis",
    "Board Development",
    "Non-Profit Governance",
    "Program Evaluation",
    "Case Management",
    "Client Services",
    "Data Analysis",
    "Grant Management",
    "Non-Profit Accounting",
    "Community Engagement",
    "Volunteer Recruitment",
    "Volunteer Training",
    "Volunteer Coordination",
    "Public Speaking",
    "Leadership",
    "Teamwork",
    "Time Management",
    "Communication Skills",
    "Conflict Resolution",
    "Event Coordination",
    "Public Policy",
    "Outreach Programs",
    "Impact Assessment",
    "Campaign Management",
    "Donor Stewardship",
    "Resource Development",
    "Non-Profit Marketing",
    "Program Implementation",
    "Crisis Management",
    "Community Development",
    "Human Services",
    "Client Advocacy",
    "Client Assessment",
  ],
  Energy: [
    "Energy Management",
    "Renewable Energy",
    "Energy Efficiency",
    "Power Generation",
    "Electrical Engineering",
    "Energy Audits",
    "Energy Policy",
    "Sustainability",
    "Energy Storage",
    "Solar Energy",
    "Wind Energy",
    "Hydropower",
    "Geothermal Energy",
    "Energy Modeling",
    "Project Management",
    "Energy Conservation",
    "Environmental Engineering",
    "Energy Analysis",
    "Energy Trading",
    "Power Systems",
    "Grid Management",
    "Smart Grid",
    "Energy Markets",
    "Energy Regulations",
    "Energy Consulting",
    "Energy Economics",
    "Energy Finance",
    "Energy Procurement",
    "Energy Risk Management",
    "Energy Research",
    "Energy Systems Design",
    "Energy Technology",
    "Energy Integration",
    "Energy Forecasting",
    "Sustainable Energy",
    "Bioenergy",
    "Energy Innovation",
    "Energy Storage Systems",
    "Energy Production",
    "Energy Monitoring",
    "Energy Optimization",
    "Energy Performance",
    "Energy Planning",
    "Energy Distribution",
    "Energy Compliance",
    "Energy Project Development",
    "Energy Solutions",
    "Energy Software",
    "Energy Strategy",
    "Energy Auditing",
  ],
  Agriculture: [
    "Crop Production",
    "Animal Husbandry",
    "Agronomy",
    "Farm Management",
    "Soil Science",
    "Irrigation Management",
    "Agricultural Engineering",
    "Sustainable Agriculture",
    "Agricultural Research",
    "Horticulture",
    "Pest Management",
    "Plant Breeding",
    "Agricultural Economics",
    "Agribusiness",
    "Food Safety",
    "Agricultural Policy",
    "Precision Agriculture",
    "Agroforestry",
    "Livestock Management",
    "Organic Farming",
    "Agricultural Extension",
    "Plant Pathology",
    "Soil Conservation",
    "Agricultural Biotechnology",
    "Agricultural Marketing",
    "Farm Equipment",
    "Crop Science",
    "Environmental Science",
    "Agricultural Education",
    "Aquaculture",
    "Agroecology",
    "Greenhouse Management",
    "Agricultural Finance",
    "Agricultural Consulting",
    "Post-harvest Technology",
    "Agricultural Trade",
    "Food Production",
    "Farm Safety",
    "Agricultural Technology",
    "Water Management",
    "Agricultural Law",
    "Agricultural Communications",
    "Agricultural Development",
    "Agricultural Innovation",
    "Climate-Smart Agriculture",
    "Agrochemicals",
    "Rural Development",
    "Food Security",
    "Sustainable Livelihoods",
    "Land Management",
  ],
  Environmental: [
    "Environmental Science",
    "Environmental Engineering",
    "Sustainability",
    "Environmental Policy",
    "Environmental Impact Assessment",
    "Environmental Management",
    "Environmental Consulting",
    "Conservation",
    "Climate Change",
    "Renewable Energy",
    "Environmental Compliance",
    "Waste Management",
    "Water Quality",
    "Air Quality",
    "Soil Science",
    "Ecology",
    "Environmental Monitoring",
    "Environmental Health",
    "Pollution Control",
    "Natural Resource Management",
    "Environmental Education",
    "Environmental Auditing",
    "Environmental Planning",
    "Sustainable Development",
    "Environmental Law",
    "Environmental Economics",
    "Environmental Research",
    "Geographic Information Systems (GIS)",
    "Environmental Reporting",
    "Biodiversity",
    "Environmental Risk Assessment",
    "Environmental Policy Analysis",
    "Environmental Communication",
    "Wildlife Conservation",
    "Habitat Restoration",
    "Environmental Technology",
    "Ecosystem Management",
    "Sustainable Agriculture",
    "Environmental Chemistry",
    "Green Building",
    "Sustainable Urban Planning",
    "Corporate Social Responsibility",
    "Environmental Advocacy",
    "Environmental Design",
    "Environmental Psychology",
    "Environmental Toxicology",
    "Environmental Impact Mitigation",
    "Environmental Management Systems",
    "Environmental Protection",
    "Renewable Resource Management",
  ],
  Telecommunications: [
    "Network Administration",
    "Telecommunications Engineering",
    "Wireless Communications",
    "Network Security",
    "VoIP",
    "Fiber Optics",
    "Satellite Communications",
    "Telecom Infrastructure",
    "Network Design",
    "Telecom Project Management",
    "Telecom Regulations",
    "Data Communications",
    "Telecom Equipment",
    "Telecom Software",
    "Mobile Communications",
    "Network Optimization",
    "Telecom Sales",
    "Telecom Support",
    "Telecom Analytics",
    "Telecom Consulting",
    "Broadband Networks",
    "Telecom Installation",
    "Telecom Troubleshooting",
    "Telecom Operations",
    "Telecom Testing",
    "Telecom Maintenance",
    "Telecom Networking",
    "Telecom Service Management",
    "Telecom Billing",
    "Telecom Product Development",
    "Telecom Market Research",
    "Telecom Strategy",
    "Telecom Procurement",
    "Telecom Finance",
    "Telecom Auditing",
    "Telecom Contracts",
    "Telecom Deployment",
    "Telecom Integration",
    "Telecom Innovation",
    "Telecom Performance",
    "Telecom Monitoring",
    "Telecom Capacity Planning",
    "Telecom Vendor Management",
    "Telecom Training",
    "Telecom Technology",
    "Telecom Risk Management",
    "Telecom Compliance",
    "Telecom Architecture",
    "Telecom Governance",
    "Telecom Support Systems",
  ],
  Automotive: [
    "Automotive Engineering",
    "Vehicle Maintenance",
    "Vehicle Diagnostics",
    "Automotive Repair",
    "Automotive Design",
    "Vehicle Safety",
    "Vehicle Testing",
    "Automotive Electronics",
    "Mechanical Engineering",
    "Electrical Systems",
    "Automotive Manufacturing",
    "Quality Control",
    "Customer Service",
    "Technical Support",
    "Automotive Sales",
    "Inventory Management",
    "Parts Management",
    "Warranty Management",
    "Vehicle Inspections",
    "Automotive Software",
    "Vehicle Performance",
    "Automotive Systems",
    "Automotive Innovation",
    "Automotive Technology",
    "Automotive Marketing",
    "Automotive Finance",
    "Vehicle Registration",
    "Fleet Management",
    "Automotive Consulting",
    "Automotive Regulations",
    "Vehicle Compliance",
    "Vehicle Operations",
    "Vehicle Safety Systems",
    "Vehicle Dynamics",
    "Vehicle Integration",
    "Automotive Supply Chain",
    "Automotive Production",
    "Automotive Project Management",
    "Automotive Strategy",
    "Vehicle Emissions",
    "Automotive Design Software",
    "Automotive Components",
    "Vehicle Electronics",
    "Vehicle Design",
    "Vehicle Manufacturing",
    "Vehicle Engineering",
    "Automotive Innovation",
    "Automotive Risk Management",
    "Automotive Support",
  ],
  Aerospace: [
    "Aerospace Engineering",
    "Aerodynamics",
    "Aircraft Design",
    "Spacecraft Design",
    "Propulsion Systems",
    "Aerospace Manufacturing",
    "Flight Mechanics",
    "Structural Analysis",
    "Avionics",
    "Aerospace Testing",
    "Systems Engineering",
    "Project Management",
    "Quality Assurance",
    "Aircraft Maintenance",
    "Space Mission Planning",
    "Rocketry",
    "Satellite Technology",
    "Flight Simulation",
    "Aerospace Materials",
    "Aerospace Research",
    "Control Systems",
    "Flight Safety",
    "Aerospace Regulations",
    "Aerospace Software",
    "Aerospace Systems",
    "Aerospace Operations",
    "Aerospace Technology",
    "Aerospace Innovation",
    "Aerospace Integration",
    "Aerospace Components",
    "Flight Control Systems",
    "Aircraft Performance",
    "Aerospace Structures",
    "Aerospace Dynamics",
    "Aerospace Simulation",
    "Aerospace Design",
    "Aerospace Manufacturing Processes",
    "Spacecraft Operations",
    "Aerospace Analysis",
    "Aerospace Instrumentation",
    "Aerospace Navigation",
    "Aerospace Communication Systems",
    "Aerospace Power Systems",
    "Aerospace Thermal Systems",
    "Aerospace Electronics",
    "Aerospace Reliability",
    "Aerospace Risk Management",
    "Aerospace Compliance",
    "Aerospace Support",
  ],
  Pharmaceutical: [
    "Pharmaceutical Research",
    "Drug Development",
    "Clinical Trials",
    "Regulatory Affairs",
    "Pharmacology",
    "Pharmaceutical Manufacturing",
    "Quality Control",
    "Pharmaceutical Sales",
    "Pharmaceutical Marketing",
    "Pharmacovigilance",
    "Pharmaceutical Compliance",
    "Pharmaceutical Packaging",
    "Drug Formulation",
    "Pharmaceutical Analytics",
    "Pharmaceutical Consulting",
    "Pharmaceutical Regulations",
    "Pharmaceutical Strategy",
    "Pharmaceutical Innovation",
    "Pharmaceutical Supply Chain",
    "Pharmaceutical Technology",
    "Pharmaceutical Finance",
    "Pharmaceutical Operations",
    "Pharmaceutical Training",
    "Pharmaceutical Safety",
    "Pharmaceutical Development",
    "Pharmaceutical Manufacturing Processes",
    "Pharmaceutical Project Management",
    "Pharmaceutical Research and Development",
    "Pharmaceutical Quality Assurance",
    "Pharmaceutical Logistics",
    "Pharmaceutical Distribution",
    "Pharmaceutical Product Management",
    "Pharmaceutical Risk Management",
    "Pharmaceutical Support",
    "Pharmaceutical Validation",
    "Pharmaceutical Documentation",
    "Pharmaceutical Testing",
    "Pharmaceutical Auditing",
    "Pharmaceutical Data Management",
    "Pharmaceutical Information Systems",
    "Pharmaceutical Product Development",
    "Pharmaceutical Technology Transfer",
    "Pharmaceutical Licensing",
    "Pharmaceutical Market Research",
    "Pharmaceutical Product Launches",
    "Pharmaceutical Customer Service",
    "Pharmaceutical Training Programs",
    "Pharmaceutical Strategy Development",
    "Pharmaceutical Brand Management",
  ],
  Biotechnology: [
    "Biotechnology Research",
    "Genetics",
    "Molecular Biology",
    "Cell Culture",
    "Bioinformatics",
    "Protein Purification",
    "Genetic Engineering",
    "Bioprocessing",
    "Biotechnology Manufacturing",
    "Quality Control",
    "Biotechnology Sales",
    "Regulatory Affairs",
    "Biotechnology Marketing",
    "Clinical Trials",
    "Biotechnology Compliance",
    "Biotechnology Analytics",
    "Biotechnology Consulting",
    "Biotechnology Regulations",
    "Biotechnology Strategy",
    "Biotechnology Innovation",
    "Biotechnology Supply Chain",
    "Biotechnology Technology",
    "Biotechnology Finance",
    "Biotechnology Operations",
    "Biotechnology Training",
    "Biotechnology Safety",
    "Biotechnology Development",
    "Biotechnology Manufacturing Processes",
    "Biotechnology Project Management",
    "Biotechnology Research and Development",
    "Biotechnology Quality Assurance",
    "Biotechnology Logistics",
    "Biotechnology Distribution",
    "Biotechnology Product Management",
    "Biotechnology Risk Management",
    "Biotechnology Support",
    "Biotechnology Validation",
    "Biotechnology Documentation",
    "Biotechnology Testing",
    "Biotechnology Auditing",
    "Biotechnology Data Management",
    "Biotechnology Information Systems",
    "Biotechnology Product Development",
    "Biotechnology Technology Transfer",
    "Biotechnology Licensing",
    "Biotechnology Market Research",
    "Biotechnology Product Launches",
    "Biotechnology Customer Service",
    "Biotechnology Training Programs",
    "Biotechnology Strategy Development",
    "Biotechnology Brand Management",
  ],
  Insurance: [
    "Underwriting",
    "Claims Management",
    "Risk Assessment",
    "Insurance Sales",
    "Policy Administration",
    "Customer Service",
    "Insurance Marketing",
    "Insurance Regulations",
    "Insurance Analytics",
    "Insurance Consulting",
    "Insurance Compliance",
    "Insurance Strategy",
    "Insurance Innovation",
    "Insurance Technology",
    "Insurance Finance",
    "Insurance Operations",
    "Insurance Training",
    "Insurance Safety",
    "Insurance Development",
    "Insurance Project Management",
    "Insurance Research and Development",
    "Insurance Quality Assurance",
    "Insurance Logistics",
    "Insurance Distribution",
    "Insurance Product Management",
    "Insurance Risk Management",
    "Insurance Support",
    "Insurance Validation",
    "Insurance Documentation",
    "Insurance Testing",
    "Insurance Auditing",
    "Insurance Data Management",
    "Insurance Information Systems",
    "Insurance Product Development",
    "Insurance Technology Transfer",
    "Insurance Licensing",
    "Insurance Market Research",
    "Insurance Product Launches",
    "Insurance Customer Service",
    "Insurance Training Programs",
    "Insurance Strategy Development",
    "Insurance Brand Management",
    "Insurance Underwriting",
    "Insurance Claims",
    "Insurance Policy",
    "Insurance Customer",
    "Insurance Regulation",
    "Insurance Compliance",
    "Insurance Technology",
    "Insurance Sales",
  ],
  Food: [
    "Food Safety",
    "Food Preparation",
    "Cooking Techniques",
    "Baking",
    "Food Presentation",
    "Menu Planning",
    "Nutritional Knowledge",
    "Culinary Arts",
    "Catering",
    "Food Service Management",
    "Restaurant Operations",
    "Beverage Preparation",
    "Customer Service",
    "Sanitation",
    "Food Storage",
    "Inventory Management",
    "Kitchen Management",
    "Cost Control",
    "Food Quality Control",
    "Recipe Development",
    "Food Styling",
    "Food Technology",
    "Food Processing",
    "Food Packaging",
    "Food Marketing",
    "Food Sales",
    "Food Production",
    "Food Distribution",
    "Food Safety Regulations",
    "Food Handling",
    "Food Innovation",
    "Food Science",
    "Food Chemistry",
    "Food Microbiology",
    "Food Engineering",
    "Food Product Development",
    "Food Labeling",
    "Food Testing",
    "Food Analysis",
    "Food Auditing",
    "Food Logistics",
    "Food Research",
    "Food Consulting",
    "Food Strategy",
    "Food Sustainability",
    "Food Operations",
    "Food Policy",
    "Food Supply Chain",
    "Food Compliance",
    "Food Risk Management",
  ],
  Arts: [
    "Painting",
    "Drawing",
    "Sculpting",
    "Graphic Design",
    "Photography",
    "Illustration",
    "Animation",
    "Digital Art",
    "Printmaking",
    "Art History",
    "Art Education",
    "Ceramics",
    "Textile Arts",
    "Jewelry Design",
    "Fashion Design",
    "Interior Design",
    "Landscape Design",
    "Set Design",
    "Costume Design",
    "Film Production",
    "Video Editing",
    "Sound Design",
    "Stage Management",
    "Lighting Design",
    "Directing",
    "Acting",
    "Dance",
    "Choreography",
    "Music Composition",
    "Music Performance",
    "Music Production",
    "Art Curation",
    "Art Conservation",
    "Art Restoration",
    "Art Criticism",
    "Art Therapy",
    "Public Art",
    "Art Installation",
    "Exhibition Design",
    "Gallery Management",
    "Art Marketing",
    "Art Sales",
    "Art Administration",
    "Art Documentation",
    "Art Licensing",
    "Art Law",
    "Art Policy",
    "Art Funding",
    "Art Grants",
    "Art Community Engagement",
  ],
  Sports: [
    "Athletic Training",
    "Coaching",
    "Sports Management",
    "Sports Marketing",
    "Fitness Training",
    "Sports Nutrition",
    "Sports Psychology",
    "Physical Therapy",
    "Sports Medicine",
    "Strength and Conditioning",
    "Sports Analytics",
    "Event Management",
    "Team Management",
    "Talent Scouting",
    "Sports Photography",
    "Broadcasting",
    "Sports Journalism",
    "Sports Commentary",
    "Refereeing",
    "Umpiring",
    "Sports Facility Management",
    "Sports Equipment Management",
    "Sports Sponsorship",
    "Sports Law",
    "Sports Policy",
    "Sports Governance",
    "Sports Funding",
    "Sports Technology",
    "Sports Innovation",
    "Sports Operations",
    "Sports Safety",
    "Sports Development",
    "Sports Project Management",
    "Sports Research",
    "Sports Consulting",
    "Sports Strategy",
    "Sports Risk Management",
    "Sports Compliance",
    "Sports Logistics",
    "Sports Branding",
    "Sports Advertising",
    "Sports Social Media",
    "Sports Public Relations",
    "Sports Event Coordination",
    "Sports Event Marketing",
    "Sports Coaching Certification",
    "Sports Training Programs",
    "Sports Performance Analysis",
    "Sports Rehabilitation",
    "Sports Health and Wellness",
  ],
  Fashion: [
    "Fashion Design",
    "Fashion Illustration",
    "Pattern Making",
    "Sewing",
    "Textile Design",
    "Fashion Marketing",
    "Fashion Merchandising",
    "Fashion Buying",
    "Trend Analysis",
    "Fashion Styling",
    "Fashion Photography",
    "Fashion Show Production",
    "Fashion Journalism",
    "Fashion Blogging",
    "Fashion Public Relations",
    "Fashion Branding",
    "Fashion Sales",
    "Fashion Retail",
    "Fashion E-commerce",
    "Fashion Visual Merchandising",
    "Fashion Product Development",
    "Fashion Production",
    "Fashion Supply Chain",
    "Fashion Sustainability",
    "Fashion Technology",
    "Fashion Innovation",
    "Fashion Operations",
    "Fashion Consulting",
    "Fashion Strategy",
    "Fashion Risk Management",
    "Fashion Compliance",
    "Fashion Logistics",
    "Fashion Trend Forecasting",
    "Fashion Design Software",
    "Fashion Illustration Software",
    "Fashion CAD",
    "Fashion Product Management",
    "Fashion Quality Control",
    "Fashion Textiles",
    "Fashion Fabrics",
    "Fashion Accessories",
    "Fashion Jewelry",
    "Fashion Footwear",
    "Fashion Advertising",
    "Fashion Social Media",
    "Fashion Event Planning",
    "Fashion Event Management",
    "Fashion Store Management",
    "Fashion Brand Management",
  ],
};

export const qualifications = [
  "High School",
  "Associate Degree",
  "Bachelor's Degree",
  "Master's Degree",
  "Doctorate",
  "Diploma",
  "Certificate",
  "Professional Degree",
  "Vocational Training",
  "No Formal Education",
];

export const FIELD_OF_STUDY_OPTIONS = [
  "Computer Science",
  "Business Administration",
  "Engineering",
  "Mathematics",
  "Physics",
  "Biology",
  "Chemistry",
  "Psychology",
  "Economics",
  "Art and Design",
  "Literature",
  "Education",
  "Other",
];
export const jobcertifications = {
  "Information Technology": [
    "Certified Information Systems Security Professional (CISSP)",
    "Cisco Certified Network Associate (CCNA)",
    "Certified ScrumMaster (CSM)",
    "CompTIA A+",
    "Certified Ethical Hacker (CEH)",
    "Microsoft Certified: Azure Administrator Associate",
    "AWS Certified Solutions Architect",
    "Google Cloud Professional Data Engineer",
    "Oracle Certified Professional, Java SE Programmer",
    "Certified Information Systems Auditor (CISA)",
    // Add more certifications to reach 50
  ],
  Healthcare: [
    "Certified Nursing Assistant (CNA)",
    "Registered Nurse (RN) License",
    "Certified Medical Assistant (CMA)",
    "Certified Phlebotomy Technician (CPT)",
    "Emergency Medical Technician (EMT)",
    "Certified Professional Coder (CPC)",
    "Certified Clinical Medical Assistant (CCMA)",
    "Certified Pharmacy Technician (CPhT)",
    "Certified Healthcare Financial Professional (CHFP)",
    "Certified Health Education Specialist (CHES)",
    // Add more certifications to reach 50
  ],
  Finance: [
    "Certified Public Accountant (CPA)",
    "Chartered Financial Analyst (CFA)",
    "Certified Management Accountant (CMA)",
    "Certified Internal Auditor (CIA)",
    "Financial Risk Manager (FRM)",
    "Certified Financial Planner (CFP)",
    "Certified Fraud Examiner (CFE)",
    "Chartered Global Management Accountant (CGMA)",
    "Certified Treasury Professional (CTP)",
    "Chartered Alternative Investment Analyst (CAIA)",
    // Add more certifications to reach 50
  ],
  Engineering: [
    "Professional Engineer (PE) License",
    "Certified Engineering Technician (CET)",
    "Project Management Professional (PMP)",
    "Six Sigma Certification",
    "Certified Automation Professional (CAP)",
    "Certified Reliability Engineer (CRE)",
    "Certified Quality Engineer (CQE)",
    "Certified Manufacturing Engineer (CMfgE)",
    "Certified Energy Manager (CEM)",
    "LEED Accredited Professional",
    // Add more certifications to reach 50
  ],
  Sales: [
    "Certified Sales Professional (CSP)",
    "Certified Sales Leadership Professional (CSLP)",
    "HubSpot Inbound Sales Certification",
    "Salesforce Certified Sales Cloud Consultant",
    "Certified Professional Sales Person (CPSP)",
    "Certified Inside Sales Professional (CISP)",
    "Sandler Sales Certification",
    "Certified Revenue Management Executive (CRME)",
    "Google Analytics Individual Qualification",
    "Advanced Sales Skills Certification",
    // Add more certifications to reach 50
  ],
  HR: [
    "Professional in Human Resources (PHR)",
    "Senior Professional in Human Resources (SPHR)",
    "SHRM Certified Professional (SHRM-CP)",
    "SHRM Senior Certified Professional (SHRM-SCP)",
    "Certified Compensation Professional (CCP)",
    "Global Professional in Human Resources (GPHR)",
    "Talent Management Practitioner (TMP)",
    "Certified Employee Benefit Specialist (CEBS)",
    "Human Resources Business Professional (HRBP)",
    "Certified Human Resources Professional (CHRP)",
    // Add more certifications to reach 50
  ],
  Education: [
    "Certified Teacher",
    "National Board Certification",
    "TESOL Certification",
    "Certified Academic Language Therapist (CALT)",
    "Certified Educational Planner (CEP)",
    "Certified Professional in Learning and Performance (CPLP)",
    "Reading Specialist Certification",
    "School Counselor Certification",
    "Special Education Certification",
    "Principal Certification",
    // Add more certifications to reach 50
  ],
  CustomerService: [
    "Certified Customer Service Professional (CCSP)",
    "Customer Service Excellence Certification",
    "Certified Call Center Manager (CCCM)",
    "Certified Support Manager (CSM)",
    "Certified Customer Experience Professional (CCXP)",
    "Customer Relationship Management Certification",
    "Certified Service Desk Manager (CSDM)",
    "Certified Technical Support Professional (CTSP)",
    "Customer Service Management Certification",
    "Certified Customer Contact Specialist (CCCS)",
    // Add more certifications to reach 50
  ],
  Design: [
    "Adobe Certified Expert (ACE)",
    "Certified Graphic Designer (CGD)",
    "Certified Interior Designer (CID)",
    "UX Design Certification",
    "Certified Web Designer (CWD)",
    "Certified Packaging Professional (CPP)",
    "Certified Visual Communications Professional (CVCP)",
    "Certified Digital Designer (CDD)",
    "Motion Graphics Certification",
    "Certified Product Designer (CPD)",
    // Add more certifications to reach 50
  ],
  BusinessDev: [
    "Certified Business Development Professional (CBDP)",
    "Certified Business Development Strategist (CBDS)",
    "Certified Strategic Business Development Professional (CSBDP)",
    "Certified Market Research Professional (CMRP)",
    "Certified Partnership Manager (CPM)",
    "Certified Business Development Executive (CBDE)",
    "Certified Sales Development Representative (CSDR)",
    "Certified Business Consultant (CBC)",
    "Certified Business Analysis Professional (CBAP)",
    "Certified Growth Marketing Professional (CGMP)",
    // Add more certifications to reach 50
  ],
  Legal: [
    "Licensed Attorney",
    "Certified Paralegal (CP)",
    "Certified Legal Manager (CLM)",
    "Certified e-Discovery Specialist (CEDS)",
    "Certified Compliance and Ethics Professional (CCEP)",
    "Certified Information Privacy Professional (CIPP)",
    "Certified Immigration Specialist (CIS)",
    "Certified Contracts Manager (CCM)",
    "Certified Litigation Specialist (CLS)",
    "Certified Legal Secretary (CLS)",
    // Add more certifications to reach 50
  ],
  Manufacturing: [
    "Certified Manufacturing Specialist (CMS)",
    "Certified Production Technician (CPT)",
    "Certified Quality Technician (CQT)",
    "Certified Manufacturing Engineer (CMfgE)",
    "Certified Maintenance & Reliability Technician (CMRT)",
    "Certified Six Sigma Black Belt (CSSBB)",
    "Certified Lean Manufacturing Practitioner (CLMP)",
    "Certified Plant Maintenance Manager (CPMM)",
    "Certified Automation Professional (CAP)",
    "Certified Production and Inventory Management (CPIM)",
    // Add more certifications to reach 50
  ],
  RandD: [
    "Certified Research Administrator (CRA)",
    "Certified Clinical Research Coordinator (CCRC)",
    "Certified Clinical Research Professional (CCRP)",
    "Project Management Professional (PMP)",
    "Certified Professional Innovator (CPI)",
    "Certified Quality Engineer (CQE)",
    "Certified Professional Researcher (CPR)",
    "Certified Laboratory Manager (CLM)",
    "Certified in Data Management (CDM)",
    "Certified Bioinformatics Specialist (CBS)",
    // Add more certifications to reach 50
  ],
  Consulting: [
    "Certified Management Consultant (CMC)",
    "Project Management Professional (PMP)",
    "Certified Business Consultant (CBC)",
    "Certified Professional Consultant (CPC)",
    "Certified Public Accountant (CPA)",
    "Certified Financial Planner (CFP)",
    "Certified Internal Auditor (CIA)",
    "Certified Information Systems Auditor (CISA)",
    "Certified Risk Management Professional (CRMP)",
    "Certified Business Analysis Professional (CBAP)",
    // Add more certifications to reach 50
  ],
  Media: [
    "Certified Broadcast Meteorologist (CBM)",
    "Certified Radio Marketing Consultant (CRMC)",
    "Google Analytics Individual Qualification (GAIQ)",
    "Certified Digital Marketing Professional (CDMP)",
    "Certified Public Relations Specialist (CPRS)",
    "Certified Social Media Strategist (CSMS)",
    "Certified Content Marketing Specialist (CCMS)",
    "Certified Media Specialist (CMS)",
    "Certified Film and Video Editor (CFVE)",
    "Certified Communications Professional (CCP)",
    // Add more certifications to reach 50
  ],
  Construction: [
    "Certified Construction Manager (CCM)",
    "LEED Accredited Professional",
    "Certified Professional Constructor (CPC)",
    "Certified Safety Professional (CSP)",
    "Certified Construction Contract Administrator (CCCA)",
    "Project Management Professional (PMP)",
    "Certified Construction Inspector (CCI)",
    "Certified Cost Professional (CCP)",
    "Certified Energy Manager (CEM)",
    "Certified Green Building Professional (CGBP)",
    // Add more certifications to reach 50
  ],
  Hospitality: [
    "Certified Hospitality Supervisor (CHS)",
    "Certified Hospitality Sales Professional (CHSP)",
    "Certified Hotel Administrator (CHA)",
    "Certified Restaurant Manager (CRM)",
    "Certified Meeting Professional (CMP)",
    "Certified Guest Service Professional (CGSP)",
    "Certified Food and Beverage Executive (CFBE)",
    "Certified Hospitality Trainer (CHT)",
    "Certified Hospitality Housekeeping Executive (CHHE)",
    "Certified Tourism Management Professional (CTMP)",
    // Add more certifications to reach 50
  ],
  Transportation: [
    "Certified Transportation Professional (CTP)",
    "Commercial Driver's License (CDL)",
    "Certified Logistics Technician (CLT)",
    "Certified Supply Chain Professional (CSCP)",
    "Certified Transportation Manager (CTM)",
    "Project Management Professional (PMP)",
    "Certified Safety Professional (CSP)",
    "Certified Air Transport Professional (CATP)",
    "Certified Transportation Broker (CTB)",
    "Certified Logistics Associate (CLA)",
    // Add more certifications to reach 50
  ],
  Retail: [
    "Certified Retail Management Professional (CRMP)",
    "Certified Sales Associate (CSA)",
    "Certified Retail Operations Manager (CROM)",
    "Certified Supply Chain Professional (CSCP)",
    "Certified Customer Service Professional (CCSP)",
    "Certified Inventory Management Specialist (CIMS)",
    "Certified Merchandise Planner (CMP)",
    "Certified E-Commerce Specialist (CES)",
    "Certified Retail Analyst (CRA)",
    "Certified Visual Merchandiser (CVM)",
    // Add more certifications to reach 50
  ],
  RealEstate: [
    "Licensed Real Estate Agent",
    "Certified Residential Specialist (CRS)",
    "Certified Commercial Investment Member (CCIM)",
    "Certified Property Manager (CPM)",
    "Accredited Buyer’s Representative (ABR)",
    "Certified Real Estate Brokerage Manager (CRB)",
    "Certified International Property Specialist (CIPS)",
    "Certified Real Estate Team Specialist (C-RETS)",
    "Certified Luxury Home Marketing Specialist (CLHMS)",
    "Certified Negotiation Expert (CNE)",
    // Add more certifications to reach 50
  ],
  Government: [
    "Certified Public Manager (CPM)",
    "Certified Government Financial Manager (CGFM)",
    "Certified Government Auditing Professional (CGAP)",
    "Certified Information Systems Auditor (CISA)",
    "Project Management Professional (PMP)",
    "Certified Government Affairs Specialist (CGAS)",
    "Certified Homeland Security Professional (CHSP)",
    "Certified Government Compliance Manager (CGCM)",
    "Certified Regulatory Compliance Manager (CRCM)",
    "Certified Fraud Examiner (CFE)",
    // Add more certifications to reach 50
  ],
  NonProfit: [
    "Certified Nonprofit Professional (CNP)",
    "Certified Fund Raising Executive (CFRE)",
    "Certified Grants Management Specialist (CGMS)",
    "Certified Association Executive (CAE)",
    "Certified Nonprofit Board Consultant (CNBC)",
    "Certified Fundraising Manager (CFM)",
    "Certified Nonprofit Leadership (CNL)",
    "Certified Volunteer Manager (CVM)",
    "Certified Nonprofit Accounting Professional (CNAP)",
    "Certified Fundraising Specialist (CFS)",
    // Add more certifications to reach 50
  ],
  Energy: [
    "Certified Energy Manager (CEM)",
    "Certified Energy Auditor (CEA)",
    "LEED Accredited Professional",
    "Certified Renewable Energy Professional (CREP)",
    "Certified Energy Procurement Professional (CEP)",
    "Certified Industrial Energy Professional (CIEP)",
    "Certified Measurement & Verification Professional (CMVP)",
    "Certified Power Quality Professional (CPQ)",
    "Certified Building Commissioning Professional (CBCP)",
    "Certified Lighting Efficiency Professional (CLEP)",
    // Add more certifications to reach 50
  ],
  Agriculture: [
    "Certified Crop Advisor (CCA)",
    "Certified Agricultural Consultant (CAC)",
    "Certified Animal Welfare Specialist (CAWS)",
    "Certified Organic Farmer (COF)",
    "Certified Farm Manager (CFM)",
    "Certified Professional Agronomist (CPAg)",
    "Certified Veterinary Technician (CVT)",
    "Certified Soil Scientist (CSS)",
    "Certified Agricultural Engineer (CAE)",
    "Certified Sustainable Agriculture Professional (CSAP)",
    // Add more certifications to reach 50
  ],
  Environmental: [
    "Certified Environmental Professional (CEP)",
    "LEED Accredited Professional",
    "Certified Hazardous Materials Manager (CHMM)",
    "Certified Environmental Scientist (CES)",
    "Certified Environmental Planner (CEP)",
    "Certified Energy Manager (CEM)",
    "Certified Environmental Auditor (CEA)",
    "Certified Indoor Environmentalist (CIE)",
    "Certified Sustainable Development Professional (CSDP)",
    "Certified Water Quality Professional (CWQP)",
    // Add more certifications to reach 50
  ],
  Telecommunications: [
    "Cisco Certified Network Associate (CCNA)",
    "Certified Telecommunications Network Specialist (CTNS)",
    "Certified Telecommunications Analyst (CTA)",
    "Certified Telecommunications Project Manager (CTPM)",
    "Certified Fiber Optic Technician (CFOT)",
    "Certified Satellite Installer (CSI)",
    "Certified Wireless Network Administrator (CWNA)",
    "Certified VoIP Engineer (CVE)",
    "Certified Broadband Technician (CBT)",
    "Certified Telecommunication Security Expert (CTSE)",
    // Add more certifications to reach 50
  ],
  Automotive: [
    "Automotive Service Excellence (ASE) Certification",
    "Certified Automotive Technician (CAT)",
    "Certified Auto Glass Technician (CAGT)",
    "Certified Collision Repair Technician (CCRT)",
    "Certified Diesel Mechanic (CDM)",
    "Certified Electric Vehicle Technician (CEVT)",
    "Certified Transmission Rebuilder (CTR)",
    "Certified Brake Technician (CBT)",
    "Certified Engine Performance Specialist (CEPS)",
    "Certified Auto Repair Manager (CARM)",
    // Add more certifications to reach 50
  ],
  Aerospace: [
    "Certified Aerospace Technician (CAT)",
    "Certified Aviation Manager (CAM)",
    "Certified Aerospace Engineer (CAE)",
    "Project Management Professional (PMP)",
    "Certified Spacecraft Operations Professional (CSOP)",
    "Certified Aircraft Maintenance Technician (CAMT)",
    "Certified Avionics Technician (CAT)",
    "Certified Flight Instructor (CFI)",
    "Certified Aerospace Quality Engineer (CAQE)",
    "Certified Safety Professional (CSP)",
    // Add more certifications to reach 50
  ],
  Pharmaceutical: [
    "Certified Pharmacy Technician (CPhT)",
    "Certified Clinical Research Professional (CCRP)",
    "Certified Regulatory Affairs Professional (CRAP)",
    "Certified Pharmaceutical Sales Representative (CPSR)",
    "Certified Drug Safety Associate (CDSA)",
    "Certified in Production and Inventory Management (CPIM)",
    "Certified Quality Auditor (CQA)",
    "Certified Pharmaceutical GMP Professional (CPGP)",
    "Certified Validation Professional (CVP)",
    "Certified Clinical Data Manager (CCDM)",
    // Add more certifications to reach 50
  ],
  Biotechnology: [
    "Certified Biotechnology Professional (CBP)",
    "Certified Clinical Research Coordinator (CCRC)",
    "Certified Bioinformatics Specialist (CBS)",
    "Certified Laboratory Technician (CLT)",
    "Certified Biomedical Equipment Technician (CBET)",
    "Certified Genetic Counselor (CGC)",
    "Certified Regulatory Compliance Manager (CRCM)",
    "Certified Molecular Biologist (CMB)",
    "Certified in Good Laboratory Practice (GLP)",
    "Certified Biomedical Auditor (CBA)",
    // Add more certifications to reach 50
  ],
  Insurance: [
    "Chartered Property Casualty Underwriter (CPCU)",
    "Certified Insurance Counselor (CIC)",
    "Certified Risk Manager (CRM)",
    "Certified Insurance Service Representative (CISR)",
    "Certified Financial Planner (CFP)",
    "Associate in Risk Management (ARM)",
    "Certified Insurance Data Analyst (CIDA)",
    "Certified Claims Professional (CCP)",
    "Certified Health Insurance Specialist (CHIS)",
    "Certified Life Underwriter (CLU)",
    // Add more certifications to reach 50
  ],
  Food: [
    "Certified Food Scientist (CFS)",
    "Certified Food Safety Professional (CFSP)",
    "Certified Executive Chef (CEC)",
    "Certified Culinary Educator (CCE)",
    "Certified Pastry Chef (CPC)",
    "Certified Food Manager (CFM)",
    "Certified Food Protection Professional (CFPP)",
    "Certified Dietary Manager (CDM)",
    "Certified Foodservice Professional (CFSP)",
    "Certified Nutrition Specialist (CNS)",
    // Add more certifications to reach 50
  ],
  Arts: [
    "Certified Arts Administrator (CAA)",
    "Certified Music Therapist (CMT)",
    "Certified Art Therapist (CAT)",
    "Certified Dance Instructor (CDI)",
    "Certified Theatre Technician (CTT)",
    "Certified Film and Video Editor (CFVE)",
    "Certified Museum Professional (CMP)",
    "Certified Graphic Designer (CGD)",
    "Certified Arts Educator (CAE)",
    "Certified Arts Management Professional (CAMP)",
    // Add more certifications to reach 50
  ],
  Sports: [
    "Certified Strength and Conditioning Specialist (CSCS)",
    "Certified Personal Trainer (CPT)",
    "Certified Athletic Trainer (ATC)",
    "Certified Sports Nutritionist (CSN)",
    "Certified Exercise Physiologist (CEP)",
    "Certified Sports Coach (CSC)",
    "Certified Sports Medicine Specialist (CSMS)",
    "Certified Sports Official (CSO)",
    "Certified Yoga Instructor (CYI)",
    "Certified Pilates Instructor (CPI)",
    // Add more certifications to reach 50
  ],
  Fashion: [
    "Certified Fashion Designer (CFD)",
    "Certified Fashion Stylist (CFS)",
    "Certified Textile Specialist (CTS)",
    "Certified Fashion Buyer (CFB)",
    "Certified Image Consultant (CIC)",
    "Certified Fashion Merchandiser (CFM)",
    "Certified Personal Shopper (CPS)",
    "Certified Wardrobe Consultant (CWC)",
    "Certified Luxury Fashion Professional (CLFP)",
    "Certified Sustainable Fashion Professional (CSFP)",
    // Add more certifications to reach 50
  ],
};

export const jobs = [
  {
    jobId: "1",
    title: "Web Designer",
    company: "Creative Design Agency",
    location: "New York, NY",
    employmentType: "Full-Time",
    salaryRange: "$50,000 - $70,000",
    description:
      "We are looking for a talented Web Designer to create amazing user experiences. The ideal candidate should have an eye for clean and artful web design.",
    responsibilities: [
      "Design and develop user-friendly websites",
      "Collaborate with development team",
      "Ensure the technical feasibility of UI/UX designs",
      "Optimize websites for maximum speed and scalability",
    ],
    requirements: [
      "Proven work experience as a Web Designer",
      "Proficiency in HTML, CSS, and JavaScript",
      "Experience with Adobe Creative Suite",
      "Strong portfolio of web design projects",
    ],
    postedDate: "2024-07-01",
    applicationDeadline: "2024-07-31",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "2",
    title: "Frontend Developer",
    company: "Tech Solutions Inc.",
    location: "San Francisco, CA",
    employmentType: "Contract",
    salaryRange: "$80,000 - $100,000",
    description:
      "We are seeking a skilled Frontend Developer to join our team on a contract basis. You will be responsible for implementing visual elements that users see and interact with in a web application.",
    responsibilities: [
      "Develop new user-facing features",
      "Build reusable code and libraries for future use",
      "Ensure the technical feasibility of UI/UX designs",
      "Optimize application for maximum speed and scalability",
    ],
    requirements: [
      "Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model",
      "Thorough understanding of React.js and its core principles",
      "Experience with popular React.js workflows (such as Flux or Redux)",
      "Familiarity with RESTful APIs",
    ],
    postedDate: "2024-06-25",
    applicationDeadline: "2024-07-25",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "3",
    title: "Backend Developer",
    company: "Innovative Tech",
    location: "Remote",
    employmentType: "Part-Time",
    salaryRange: "$60,000 - $80,000",
    description:
      "We are looking for an experienced Backend Developer to join our team remotely. You will be responsible for managing the interchange of data between the server and the users.",
    responsibilities: [
      "Develop server-side logic",
      "Ensure high performance and responsiveness to requests from the front-end",
      "Integrate user-facing elements developed by front-end developers",
      "Implement data storage solutions",
    ],
    requirements: [
      "Strong proficiency in Node.js, Express, and MongoDB",
      "Experience with RESTful APIs and web services",
      "Understanding the nature of asynchronous programming",
      "Proficient understanding of code versioning tools, such as Git",
    ],
    postedDate: "2024-07-05",
    applicationDeadline: "2024-08-05",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "4",
    title: "Full Stack Developer",
    company: "Global Tech",
    location: "Boston, MA",
    employmentType: "Full-Time",
    salaryRange: "$90,000 - $120,000",
    description:
      "We are looking for a Full Stack Developer to produce scalable software solutions. You'll be part of a cross-functional team that's responsible for the full software development life cycle.",
    responsibilities: [
      "Work with development teams and product managers to ideate software solutions",
      "Design client-side and server-side architecture",
      "Build the front-end of applications through appealing visual design",
      "Develop and manage well-functioning databases and applications",
    ],
    requirements: [
      "Proven experience as a Full Stack Developer or similar role",
      "Experience developing desktop and mobile applications",
      "Familiarity with common stacks",
      "Knowledge of multiple front-end languages and libraries (e.g., HTML/ CSS, JavaScript, XML, jQuery)",
      "Knowledge of multiple back-end languages (e.g., C#, Java, Python) and JavaScript frameworks (e.g., Angular, React, Node.js)",
    ],
    postedDate: "2024-07-10",
    applicationDeadline: "2024-08-10",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "5",
    title: "UX/UI Designer",
    company: "DesignPro",
    location: "Los Angeles, CA",
    employmentType: "Full-Time",
    salaryRange: "$70,000 - $90,000",
    description:
      "We are looking for a dynamic UX/UI Designer to be responsible for the user experience (UX) and user interface (UI) design of our various digital assets.",
    responsibilities: [
      "Investigate user experience design requirements for our suite of digital assets",
      "Develop and conceptualize a comprehensive UI/UX design strategy",
      "Produce high-quality UX design solutions through wireframes, visual and graphic designs, flow diagrams, storyboards, site maps, and prototypes",
      "Design UI elements and tools such as navigation menus, search boxes, tabs, and widgets for our digital assets",
    ],
    requirements: [
      "A portfolio of professional UI/UX design work for both web and mobile platforms",
      "Working knowledge of the following technologies and software: Sketch, InVision, Visio, HTML, CSS (SCSS), iOS, Android, Design Systems, and Adobe Creative Suite",
      "A team player but can work independently too",
      "Excellent written and verbal communication skills",
    ],
    postedDate: "2024-06-30",
    applicationDeadline: "2024-07-30",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "6",
    title: "Mobile App Developer",
    company: "AppInnovators",
    location: "Seattle, WA",
    employmentType: "Full-Time",
    salaryRange: "$100,000 - $130,000",
    description:
      "We are seeking a dedicated Mobile App Developer to join our growing company. You will collaborate as part of a team of developers to work on creating and maintaining mobile applications.",
    responsibilities: [
      "Support the entire application lifecycle (concept, design, test, release, and support)",
      "Produce fully functional mobile applications writing clean code",
      "Gather specific requirements and suggest solutions",
      "Write unit and UI tests to identify malfunctions",
    ],
    requirements: [
      "Demonstrable portfolio of released applications on the App Store or the Android market",
      "In-depth knowledge of at least one programming language like Swift and Java",
      "Experience with third-party libraries and APIs",
      "Familiarity with OOP design principles",
    ],
    postedDate: "2024-07-15",
    applicationDeadline: "2024-08-15",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "7",
    title: "DevOps Engineer",
    company: "CloudNet",
    location: "Austin, TX",
    employmentType: "Full-Time",
    salaryRange: "$110,000 - $140,000",
    description:
      "We are looking for a DevOps Engineer to help us build functional systems that improve customer experience. If you have a solid background in software engineering and are familiar with Ruby or Python, we’d like to meet you.",
    responsibilities: [
      "Implement integrations requested by customers",
      "Deploy updates and fixes",
      "Provide Level 2 technical support",
      "Build tools to reduce occurrences of errors and improve customer experience",
    ],
    requirements: [
      "Work experience as a DevOps Engineer or similar software engineering role",
      "Good knowledge of Ruby or Python",
      "Working knowledge of databases and SQL",
      "Problem-solving attitude",
      "Team spirit",
    ],
    postedDate: "2024-07-12",
    applicationDeadline: "2024-08-12",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "8",
    title: "Data Scientist",
    company: "DataPros",
    location: "Chicago, IL",
    employmentType: "Full-Time",
    salaryRange: "$120,000 - $150,000",
    description:
      "We are looking for a Data Scientist to analyze large amounts of raw information to find patterns that will help improve our company.",
    responsibilities: [
      "Identify valuable data sources and automate collection processes",
      "Undertake preprocessing of structured and unstructured data",
      "Analyze large amounts of information to discover trends and patterns",
      "Build predictive models and machine-learning algorithms",
    ],
    requirements: [
      "Proven experience as a Data Scientist or Data Analyst",
      "Experience in data mining",
      "Understanding of machine-learning and operations research",
      "Knowledge of R, SQL, and Python; familiarity with Scala, Java, or C++ is an asset",
    ],
    postedDate: "2024-07-18",
    applicationDeadline: "2024-08-18",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "1",
    title: "Web Designer",
    company: "Creative Design Agency",
    location: "New York, NY",
    employmentType: "Full-Time",
    salaryRange: "$50,000 - $70,000",
    description:
      "We are looking for a talented Web Designer to create amazing user experiences. The ideal candidate should have an eye for clean and artful web design.",
    responsibilities: [
      "Design and develop user-friendly websites",
      "Collaborate with development team",
      "Ensure the technical feasibility of UI/UX designs",
      "Optimize websites for maximum speed and scalability",
    ],
    requirements: [
      "Proven work experience as a Web Designer",
      "Proficiency in HTML, CSS, and JavaScript",
      "Experience with Adobe Creative Suite",
      "Strong portfolio of web design projects",
    ],
    postedDate: "2024-07-01",
    applicationDeadline: "2024-07-31",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "2",
    title: "Frontend Developer",
    company: "Tech Solutions Inc.",
    location: "San Francisco, CA",
    employmentType: "Contract",
    salaryRange: "$80,000 - $100,000",
    description:
      "We are seeking a skilled Frontend Developer to join our team on a contract basis. You will be responsible for implementing visual elements that users see and interact with in a web application.",
    responsibilities: [
      "Develop new user-facing features",
      "Build reusable code and libraries for future use",
      "Ensure the technical feasibility of UI/UX designs",
      "Optimize application for maximum speed and scalability",
    ],
    requirements: [
      "Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model",
      "Thorough understanding of React.js and its core principles",
      "Experience with popular React.js workflows (such as Flux or Redux)",
      "Familiarity with RESTful APIs",
    ],
    postedDate: "2024-06-25",
    applicationDeadline: "2024-07-25",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "3",
    title: "Backend Developer",
    company: "Innovative Tech",
    location: "Remote",
    employmentType: "Part-Time",
    salaryRange: "$60,000 - $80,000",
    description:
      "We are looking for an experienced Backend Developer to join our team remotely. You will be responsible for managing the interchange of data between the server and the users.",
    responsibilities: [
      "Develop server-side logic",
      "Ensure high performance and responsiveness to requests from the front-end",
      "Integrate user-facing elements developed by front-end developers",
      "Implement data storage solutions",
    ],
    requirements: [
      "Strong proficiency in Node.js, Express, and MongoDB",
      "Experience with RESTful APIs and web services",
      "Understanding the nature of asynchronous programming",
      "Proficient understanding of code versioning tools, such as Git",
    ],
    postedDate: "2024-07-05",
    applicationDeadline: "2024-08-05",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "4",
    title: "Full Stack Developer",
    company: "Global Tech",
    location: "Boston, MA",
    employmentType: "Full-Time",
    salaryRange: "$90,000 - $120,000",
    description:
      "We are looking for a Full Stack Developer to produce scalable software solutions. You'll be part of a cross-functional team that's responsible for the full software development life cycle.",
    responsibilities: [
      "Work with development teams and product managers to ideate software solutions",
      "Design client-side and server-side architecture",
      "Build the front-end of applications through appealing visual design",
      "Develop and manage well-functioning databases and applications",
    ],
    requirements: [
      "Proven experience as a Full Stack Developer or similar role",
      "Experience developing desktop and mobile applications",
      "Familiarity with common stacks",
      "Knowledge of multiple front-end languages and libraries (e.g., HTML/ CSS, JavaScript, XML, jQuery)",
      "Knowledge of multiple back-end languages (e.g., C#, Java, Python) and JavaScript frameworks (e.g., Angular, React, Node.js)",
    ],
    postedDate: "2024-07-10",
    applicationDeadline: "2024-08-10",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "5",
    title: "UX/UI Designer",
    company: "DesignPro",
    location: "Los Angeles, CA",
    employmentType: "Full-Time",
    salaryRange: "$70,000 - $90,000",
    description:
      "We are looking for a dynamic UX/UI Designer to be responsible for the user experience (UX) and user interface (UI) design of our various digital assets.",
    responsibilities: [
      "Investigate user experience design requirements for our suite of digital assets",
      "Develop and conceptualize a comprehensive UI/UX design strategy",
      "Produce high-quality UX design solutions through wireframes, visual and graphic designs, flow diagrams, storyboards, site maps, and prototypes",
      "Design UI elements and tools such as navigation menus, search boxes, tabs, and widgets for our digital assets",
    ],
    requirements: [
      "A portfolio of professional UI/UX design work for both web and mobile platforms",
      "Working knowledge of the following technologies and software: Sketch, InVision, Visio, HTML, CSS (SCSS), iOS, Android, Design Systems, and Adobe Creative Suite",
      "A team player but can work independently too",
      "Excellent written and verbal communication skills",
    ],
    postedDate: "2024-06-30",
    applicationDeadline: "2024-07-30",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "6",
    title: "Mobile App Developer",
    company: "AppInnovators",
    location: "Seattle, WA",
    employmentType: "Full-Time",
    salaryRange: "$100,000 - $130,000",
    description:
      "We are seeking a dedicated Mobile App Developer to join our growing company. You will collaborate as part of a team of developers to work on creating and maintaining mobile applications.",
    responsibilities: [
      "Support the entire application lifecycle (concept, design, test, release, and support)",
      "Produce fully functional mobile applications writing clean code",
      "Gather specific requirements and suggest solutions",
      "Write unit and UI tests to identify malfunctions",
    ],
    requirements: [
      "Demonstrable portfolio of released applications on the App Store or the Android market",
      "In-depth knowledge of at least one programming language like Swift and Java",
      "Experience with third-party libraries and APIs",
      "Familiarity with OOP design principles",
    ],
    postedDate: "2024-07-15",
    applicationDeadline: "2024-08-15",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "7",
    title: "DevOps Engineer",
    company: "CloudNet",
    location: "Austin, TX",
    employmentType: "Full-Time",
    salaryRange: "$110,000 - $140,000",
    description:
      "We are looking for a DevOps Engineer to help us build functional systems that improve customer experience. If you have a solid background in software engineering and are familiar with Ruby or Python, we’d like to meet you.",
    responsibilities: [
      "Implement integrations requested by customers",
      "Deploy updates and fixes",
      "Provide Level 2 technical support",
      "Build tools to reduce occurrences of errors and improve customer experience",
    ],
    requirements: [
      "Work experience as a DevOps Engineer or similar software engineering role",
      "Good knowledge of Ruby or Python",
      "Working knowledge of databases and SQL",
      "Problem-solving attitude",
      "Team spirit",
    ],
    postedDate: "2024-07-12",
    applicationDeadline: "2024-08-12",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
  {
    jobId: "8",
    title: "Data Scientist",
    company: "DataPros",
    location: "Chicago, IL",
    employmentType: "Full-Time",
    salaryRange: "$120,000 - $150,000",
    description:
      "We are looking for a Data Scientist to analyze large amounts of raw information to find patterns that will help improve our company.",
    responsibilities: [
      "Identify valuable data sources and automate collection processes",
      "Undertake preprocessing of structured and unstructured data",
      "Analyze large amounts of information to discover trends and patterns",
      "Build predictive models and machine-learning algorithms",
    ],
    requirements: [
      "Proven experience as a Data Scientist or Data Analyst",
      "Experience in data mining",
      "Understanding of machine-learning and operations research",
      "Knowledge of R, SQL, and Python; familiarity with Scala, Java, or C++ is an asset",
    ],
    postedDate: "2024-07-18",
    applicationDeadline: "2024-08-18",
    imageUrl:
      "https://banner2.cleanpng.com/20180509/qvw/kisspng-responsive-web-design-web-development-computer-ico-5af32df467de10.9348455015258864524255.jpg",
  },
];
export const jobTypes = [
  { value: "", label: "Select Job Type" },
  { value: "FullTime", label: "Full-Time" },
  { value: "PartTime", label: "Part-Time" },
  { value: "Contract", label: "Contract" },
  { value: "Temporary", label: "Temporary" },
  { value: "Internship", label: "Internship" },
  { value: "Freelance", label: "Freelance" },
  { value: "Remote", label: "Remote" },
  { value: "Seasonal", label: "Seasonal" },
  { value: "Permanent", label: "Permanent" },
  { value: "Volunteer", label: "Volunteer" },
];
export const employmentEligibility = [
  "Minimum age of 18 years",
  "Valid work visa or permit",
  "Permanent residency or citizenship status",
  "High school diploma or equivalent",
  "Bachelor's degree in relevant field",
  "Relevant work experience in the field",
  "Specific technical skills",
  "Soft skills (e.g., communication, teamwork)",
  "Certifications and licenses",
  "Criminal background check",
  "Medical clearance",
  "Proficiency in the official language of the workplace",
  "Provide references from previous employers",
  "Willingness to work shifts, weekends, and holidays",
  "Security clearance (for government jobs)",
  "Driving license (if job involves driving)",
  "Ability to relocate (if required)",
  "Compliance with EEO regulations",
  "Adherence to anti-discrimination laws",
];

export const reportReasons = [
  "Inappropriate Content",
  "Spam or Scam",
  "Misleading Information",
  "Discrimination or Hate Speech",
  "Illegal Activity",
  "Duplicate Listing",
  "Job No Longer Available",
  "Other",
];
export const experienceLevels = [
  { label: "0-1 years", value: "0-1" },
  { label: "1-3 years", value: "1-3" },
  { label: "3-5 years", value: "3-5" },
  { label: "5+ years", value: "5+" },
];

export const Remote = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];
export const initialState = [
  {
    qualification: "Education",
    details: [
      {
        details: "",
        level_of_qualification: "Bachelor’s Degree",
      },
    ],
  },
  {
    qualification: "Experience",
    details: [
      {
        details: "",
        level_of_qualification: "3-5 years of relevant experience",
      },
    ],
  },
  // Add other qualifications as needed
];
export const courseDurations = [
  { id: "1 week", text: "1 Week" },
  { id: "2 weeks", text: "2 Weeks" },
  { id: "1 month", text: "1 Month" },
  { id: "3 months", text: "3 Months" },
  { id: "6 months", text: "6 Months" },
  { id: "1 year", text: "1 Year" },
];

export const courseCurriculum = [
  { id: "intro", text: "Introduction to the Course" },
  { id: "module1", text: "Module 1: Basics" },
  { id: "module2", text: "Module 2: Intermediate Concepts" },
  { id: "module3", text: "Module 3: Advanced Topics" },
  { id: "project", text: "Project Work" },
  { id: "review", text: "Course Review and Assessment" },
  { id: "feedback", text: "Feedback and Next Steps" },
];

export const currentJobStatusOptions = [
  { id: "Immediate Joiner", text: "Immediate Joiner" },
  { id: "Actively Looking for Job", text: "Actively Looking for Job" },
  { id: "Currently Employed", text: "Currently Employed" },
  { id: "Not Looking for a Job", text: "Not Looking for a Job" },
];
export const jobAvailabilityOptions = [
  { id: "Immediate Availability", text: "Immediate Availability" },
  { id: "Available with Notice Period", text: "Available with Notice Period" },
  { id: "Available in [X] Weeks", text: "Available in [X] Weeks" },
  { id: "Not Available", text: "Not Available" },
];
export const jobSeekingStatusOptions = [
  { id: "Actively Seeking", text: "Actively Seeking" },
  { id: "Open to Opportunities", text: "Open to Opportunities" },
  { id: "Exploring Options", text: "Exploring Options" },
  { id: "Not Currently Seeking", text: "Not Currently Seeking" },
];
export const employmentStatusOptions = [
  { id: "Full-Time Employed", text: "Full-Time Employed" },
  { id: "Part-Time Employed", text: "Part-Time Employed" },
  { id: "Freelance/Contract", text: "Freelance/Contract" },
  { id: "Unemployed", text: "Unemployed" },
  { id: "Student", text: "Student" },
];

export const routesConfig = [
  { id: 1, name: "Home", url: `${domainpath}/` },
  { id: 3, name: "Signup", url: `${domainpath}/signup` },
  { id: 4, name: "Forgot Password", url: `${domainpath}/forgotpassword` },
  { id: 5, name: "Login", url: `${domainpath}/login` },
  { id: 6, name: "Register", url: `${domainpath}/register` },
  {
    id: 7,
    name: "Terms and Conditions",
    url: `${domainpath}/terms-conditions`,
  },
  { id: 8, name: "Privacy Policy", url: `${domainpath}/privacy-policy` },
  { id: 9, name: "Anti-Slavery", url: `${domainpath}/anti-slavery` },
  { id: 10, name: "Employers", url: `${domainpath}/employers` },
  { id: 11, name: "Candidates", url: `${domainpath}/candidates` },
  { id: 13, name: "Jobs", url: `${domainpath}/jobs` },
  { id: 16, name: "Post Job", url: `${domainpath}/post-job` },
  { id: 18, name: "Upload CV", url: `${domainpath}/upload-cv` },
  { id: 19, name: "Courses", url: `${domainpath}/courses` },
  { id: 20, name: "Blogs", url: `${domainpath}/blogs` },
];
