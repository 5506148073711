import React, { useState, useEffect } from "react";

const AddOnlineProfile = ({
  onClose,
  onSave,
  initialValues,
  category,
  index,
}) => {
  const [formData, setFormData] = useState({
    profile_url: "",
    profile_type: "",
    profile_description: "",
  });
  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
    }
  }, [initialValues]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose("");
    // Handle form submission logic here
  };
  return (
    <div className=" flex justify-center h-screen  login_inform-inner m-auto p-20 job-page">
      <div className="flex ">
        <div className="form_upto  jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <>
              <h1 className="hrp-heading-size text-center hrp-About">
                Add Online Profile
              </h1>

              <form
                onSubmit={handleSubmit}
                className="register-form-section animated-form"
              >
                <div className="form-group">
                  <input
                    type="text"
                    id="profileUrl"
                    placeholder=""
                    defaultValue={formData.profile_url}
                    name="profile_url"
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    required
                  />
                  <label htmlFor="profileUrl" className="form-input-label">
                    Profile URL
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="profileType"
                    name="profile_type"
                    placeholder=""
                    defaultValue={formData.profile_type}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    required
                  />
                  <label htmlFor="profileType" className="form-input-label">
                    Profile Type
                  </label>
                </div>
                <div className="form-group">
                  <textarea
                    id="description"
                    placeholder=""
                    name="profile_description"
                    defaultValue={formData.profile_description}
                    onChange={handleChange}
                    className="shadow w-full border rounded-full py-2 px-3"
                  />
                  <label htmlFor="description" className="form-input-label">
                    Description
                  </label>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="hrp-find-course-btn py-3 px-10 rounded-full"
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
        <button
          className="  text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6	 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};
export default AddOnlineProfile;
