import React, { useState, useEffect } from "react";

const Patent = ({ onClose, onSave, initialValues, category, index }) => {
  const [formData, setFormData] = useState({
    title: "",
    patent_number: "",
    date_filed: "",
    date_granted: "",
    inventor: "",
    description: "",
    url: "",
  });

  // Handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
    }
  }, [initialValues]);
  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose("");
    // Handle form submission logic here
  };
  return (
    <div className=" flex justify-center h-screen  login_inform-inner m-auto p-20 job-page">
      <div className="flex ">
        <div className="form_upto  jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <>
              <h1 className="hrp-heading-size text-center hrp-About">
                Add Patent
              </h1>

              <form
                onSubmit={handleSubmit}
                className="register-form-section animated-form"
              >
                <div className="form-group">
                  <input
                    type="text"
                    id="title"
                    placeholder=""
                    value={formData.title}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    required
                  />
                  <label htmlFor="title" className="form-input-label">
                    Patent Title
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="patent_number"
                    placeholder=""
                    value={formData.patent_number}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    required
                  />
                  <label htmlFor="patent_number" className="form-input-label">
                    Patent Number
                  </label>
                </div>
                <div className="mb-2 grid grid-cols-2 gap-x-5">
                  <div className="form-group">
                    <input
                      type="date"
                      id="date_filed"
                      placeholder=""
                      value={formData.date_filed}
                      onChange={handleChange}
                      className="shadow w-full border rounded py-2 px-3"
                      required
                    />
                    <label htmlFor="date_filed" className="form-input-label">
                      Date Filed
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="date"
                      id="date_granted"
                      placeholder=""
                      value={formData.date_granted}
                      onChange={handleChange}
                      className="shadow w-full border rounded py-2 px-3"
                      required
                    />
                    <label htmlFor="date_granted" className="form-input-label">
                      Date Granted
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="inventor"
                    placeholder=""
                    value={formData.inventor}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="inventor" className="form-input-label">
                    Inventors
                  </label>
                </div>
                <div className="form-group">
                  <textarea
                    id="description"
                    placeholder=""
                    value={formData.description}
                    onChange={handleChange}
                    className="shadow w-full border rounded-full py-2 px-3"
                  />
                  <label htmlFor="description" className="form-input-label">
                    Description
                  </label>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="hrp-find-course-btn py-3 px-10 rounded-full"
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
        <button
          className="  text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6	 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};
export default Patent;
